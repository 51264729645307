import { createBrowserRouter } from "react-router-dom";
import Root from "./Root"; // App.tsx를 Root.tsx로 이름 바꾼 상태
import PaymentList from "layouts/payment/paymentList/PaymentList";
import MerchantWithdrawl from "layouts/point/MerchantWithdrawl";
import CompanyCreate from "layouts/company/companyCreate/CompanyCreate";
import FranchiseCreate from "layouts/franchise/franchiseCreate/FranchiseCreate";
import TerminalCreate from "layouts/terminal/terminalCreate/TerminalCreate";
import NoticeCreate from "layouts/notice/noticeCreate/NoticeCreate";
import PageError404 from "layouts/errors/PageError404";
import PageError500 from "layouts/errors/PageError500";
import SignIn from "layouts/authentication/sign-in/SignIn";
import CompanyCommission from "layouts/company/companyCommission/CompanyCommission";
import PaymentTable from "layouts/payment/paymentList/PaymentTable";
import DeductTable from "layouts/payment/paymentList/DeductTable";
import PendingTable from "layouts/payment/paymentList/PendingTable";
import EasyPayTable from "layouts/payment/easyPayList/EasyPayTable";
import SettleFranchiseDateTable from "layouts/settlement/settleFranchise/SettleFranchiseDateTable";
import SettleFranchiseTermTable from "layouts/settlement/settleFranchise/SettleFranchiseTermTable";
import SettleFranchiseEtcTable from "layouts/settlement/settleFranchise/SettleFranchiseEtcTable";
import SettleFranchiseExecuteTable from "layouts/settlement/settleFranchise/SettleFranchiseExecuteTable";
import SalesFranchiseTable from "layouts/settlement/dashboards/SalesFranchiseTable";
import SettleCompanyTable from "layouts/settlement/dashboards/SettleCompanyTable";
import DepositFranchiseTable from "layouts/point/DepositFranchiseTable";
import CompanyTable from "layouts/company/companyList/CompanyTable";
import TitleOutlet from "components/TitleOutlet";
import FranchiseTable from "layouts/franchise/franchiseList/FranchiseTable";
import TerminalTable from "layouts/terminal/terminalList/TerminalTable";
import NoticeTable from "layouts/notice/noticeList/NoticeTable";
import EasyPay from "layouts/easy/EasyPay";

const router = createBrowserRouter(
  [
    {
      //첫번째 route는 Mainpage가 아닌 전체 route들의 컨테이너가 될 것.
      //1. path 정하기
      //2. 유저가 해당 url로 이동하거나 location이 그 url과 일치하면
      //3. element를 render
      //4. '/'를 부모로, Mainpage, Subpage 등을 자식으로 생각하기
      path: "/",
      element: <Root />,
      errorElement: <PageError404 />,
      children: [
        {
          index: true,
          element: <PaymentList />,
        },
        {
          path: "payment", //부모의 영향으로 '/about'을 의미함
          element: <TitleOutlet title="결제내역 관리" />, //! TitleOutlet 안에도 Outlet이 있음. Nested Routes
          errorElement: <PageError500 />,
          children: [
            {
              path: "list",
              element: <PaymentTable />,
            },
            {
              path: "deduct",
              element: <DeductTable />,
            },
            {
              path: "pending",
              element: <PendingTable />,
            },
            {
              path: "easy",
              element: <EasyPayTable />,
            },
            // ^ 미등록 단말기 결제내역 - 삭제
            //  {
            //   path: "unregistered",
            //   element: <UnregisteredPaymentTable />,
            // },
          ],
        },
        {
          path: "settlement/franchise",
          element: <TitleOutlet title="정산 관리" />,
          errorElement: <PageError500 />,
          children: [
            {
              path: "list",
              element: <SettleFranchiseExecuteTable />,
            },
            {
              path: "date/list",
              element: <SettleFranchiseDateTable />,
            },
            {
              path: "term/list",
              element: <SettleFranchiseTermTable />,
            },
            {
              path: "etc/list",
              element: <SettleFranchiseEtcTable />,
            },
            {
              path: "sales/list",
              element: <SalesFranchiseTable />,
            },
          ],
        },
        {
          path: "settlement/company",
          element: <TitleOutlet title="정산 관리" />,
          errorElement: <PageError500 />,
          children: [{ path: "list", element: <SettleCompanyTable /> }],
        },
        {
          path: "point",
          element: <TitleOutlet title="즉시출금 관리" />,
          errorElement: <PageError500 />,
          children: [
            {
              path: "list",
              element: <DepositFranchiseTable />,
            },
            {
              path: "merchant",
              element: <MerchantWithdrawl />,
            },
          ],
        },
        {
          path: "easy-pay",
          errorElement: <PageError500 />,
          element: <EasyPay />,
        },
        {
          path: "company",
          element: <TitleOutlet title="업체 관리" />,
          errorElement: <PageError500 />,
          children: [
            {
              path: "create",
              element: <CompanyCreate />,
            },
            {
              path: "list",
              element: <CompanyTable />,
            },
            {
              path: "commission",
              element: <CompanyCommission />,
            },
          ],
        },
        {
          path: "franchise",
          element: <TitleOutlet title="가맹점 관리" />,
          errorElement: <PageError500 />,
          children: [
            {
              path: "create",
              element: <FranchiseCreate />,
            },
            {
              path: "list",
              element: <FranchiseTable />,
            },
          ],
        },
        {
          path: "terminal",
          element: <TitleOutlet title="단말기 관리" />,
          errorElement: <PageError500 />,
          children: [
            {
              path: "create",
              element: <TerminalCreate />,
            },
            {
              path: "list",
              element: <TerminalTable />,
            },
          ],
        },
        {
          path: "notice",
          element: <TitleOutlet title="공지사항 관리" />,
          errorElement: <PageError500 />,
          children: [
            {
              path: "create",
              element: <NoticeCreate />,
            },
            {
              path: "list",
              element: <NoticeTable />,
            },
          ],
        },
      ],
    },
    { path: "authentication/sign-in", element: <SignIn /> },
  ]
  // 현재 위 상태로 돌리게 되면, root의 내용이 자식 주소에게도 보이게 됨.
  // 따라서, Root.tsx로 가서 <Outlet />을 적어줘야 함!
);
export default router;
