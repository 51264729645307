import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Divider,
  FormControl,
  InputAdornment,
  TextField,
  MenuItem,
} from "@material-ui/core";

import React, { useState } from "react";

const VaccountInput = ({ createValue, handleChange, error, helperText }) => {
  const paymentEasyType = [
    { label: "없음", value: "0" },
    { label: "2064", value: "1" },
    
  ];
  const minType = [
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
  ];

  return (
    <Card className="p-4 mb-4" display="block !important">
      <div className="font-size-lg font-weight-bold">가상계좌 선택</div>
      <Divider className="my-2" />
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <FormControl size="medium">
          &nbsp;&nbsp;&nbsp;&nbsp;
          <TextField
            size="small"
            className="m-2"
            id="immediateWithdrawalKey"
            name="immediateWithdrawalKey"
            variant="outlined"
            select
            value={`${createValue?.immediateWithdrawalKey}`}
            onChange={handleChange}
            helperText={helperText?.immediateWithdrawalKey}
            error={error?.immediateWithdrawalKey}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "center",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  가상계좌 선택&nbsp;
                  <FontAwesomeIcon
                    icon="fa-solid fa-asterisk"
                    size="xs"
                    color="red"
                  />
                </InputAdornment>
              ),
            }}
          >
            {paymentEasyType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
          <FormControl>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <TextField
              size="small"
              className="m-2"
              id="withdrawDelayMin"
              name="withdrawDelayMin"
              variant="outlined"
              select
              value={`${createValue?.withdrawDelayMin}`}
              onChange={handleChange}
              helperText={helperText?.withdrawDelayMin}
              error={error?.withdrawDelayMin}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    분&nbsp;
                    <FontAwesomeIcon
                      icon={["fas", "asterisk"]}
                      size="xs"
                      color="red"
                    />
                  </InputAdornment>
                ),
              }}
            >
              {minType.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
      </div>
    </Card>
  );
};

export default VaccountInput;
