import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  Button,
  TableBody,
  TableCell,
  TableRow,
  TableFooter,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Pagination from "react-js-pagination";
import { getAxios, postTerminalAxios } from "hook/axiosInstance";
import EnhancedTableHead from "./EnhancedTableHead";
import { formatToWon, listToDate, formatOnlyDate } from "utils/formatFunction";
import SelectLimit from "components/commonTable/SelectLimit";
import excelIcon from "assets/custom/excelIcon.svg";
import NoData from "components/commonTable/NoData";
import exportToXlsx from "utils/excelExport";
import PayAlert from "components/PayAlert";
import { Box } from "../../../../node_modules/@material-ui/core/index";
import PasswordModal from "layouts/company/companyCommission/PasswordModal";
import { useRecoilState } from "recoil";
import { listLinkStateAtom } from "recoilStates/searchRecoil";
import { Search } from "components/commonTable/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  selected: {
    backgroundColor: "rgba(245, 0, 87, 0.08) !important",
  },
}));

const EasyPayTable = () => {
  // ! Loading
  const [loading, setLoading] = useState(false);

  // ! GET 요청

  const [listData, setListData] = useState({ dataList: [] });
  const [limit, setLimit] = useState(15);
  const [address, setAddress] = useState();
  const [loadFlag, setLoadFlag] = useState(false);
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);
  const [page, setPage] = useState(1);

  const getListApi = async () => {
    setLoading(true);
    if (!loadFlag) setLoadFlag(true);
    if (listLinkState) {
      const tempAd = listLinkState;
      setAddress(tempAd);
      const resApiData = await getAxios(tempAd);
      if (resApiData.data.header.result_code === "200") {
        setLoading(false);
        setListData(resApiData.data.data);
      }
    }
  };

  useEffect(() => {
    if (listLinkState) {
      getListApi();
    }
  }, [page, limit]);

  useEffect(() => {
    if (listLinkState)
      if (!loadFlag) {
        getListApi();
      }
  }, [listLinkState, loading]);
  const emptyRows =
    limit - Math.min(limit, listData.totalCount - (page - 1) * limit);
  const classes = useStyles();
  const handleChangePage = (e) => {
    setPage(e);
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/page=[^&]*/, `page=${e}`);
    setListLinkState(tempLink);
  };
  // ! N개씩 보기

  // ! 검색 관련

  const checkFieldsThree = [
    { id: "all", name: "모두" },
    { id: "franchiseName", name: "가맹점명" },
    { id: "appNo", name: "승인번호" },
    { id: "cardNo", name: "카드번호" },
    { id: "commissionRate", name: "수수료율" },
    { id: "tid", name: "단말기코드" },
  ];

  // ! Sorting 관련
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("num");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // ! 권한 (가맹점 권한이면 취소 당일만 보여야함)
  const userAuth = sessionStorage.getItem("userAuth");

  // ! 취소 성공 여부
  // ^ 취소 성공 -> isSuccess = "success"
  // ^ 취소 실패 -> isSuccess = "error"
  const [isSuccess, setIsSuccess] = useState("");
  const [resultMsg, setResultMsg] = useState("");

  useEffect(() => {
    let timer = setTimeout(() => setIsSuccess(""), 10000);
    return () => {
      clearTimeout(timer);
    };
  }, [isSuccess]);

  // ! 잔액 확인 요청

  const [remainPoint, setRemainPoint] = useState("");

  const checkRemainPointFirst = async (row) => {
    const pointRes = await getAxios("app/payment/settlement");
    if (pointRes.data.header.result_code === "200") {
      setRemainPoint(formatToWon(pointRes.data.data.point));
    } else {
      setRemainPoint("출금 가능 금액을 불러올 수 없습니다.");
    }

    if (
      sessionStorage.getItem("userAuth") === "SF" && // ^ 관리자면 다 취소할수 있음
      remainPoint < row?.settlementAmount
    ) {
      alert("출금 가능 금액 초과");
      return "noPoint";
    } else {
      return "yesPoint";
    }
  };

  // ! 취소 요청
  const handleCancel = async (row) => {
    if (window.confirm("정말 취소하시겠습니까?")) {
      const trxId = row?.trxId;
      const amountMoney = row?.tradingAmount;

      const isOkCancel = await checkRemainPointFirst(row); //^ 출금 가능 금액이 있을 때만 결제취소 할 수 있음

      if (isOkCancel === "yesPoint") {
        try {
          const res = await postTerminalAxios("easypaycancel", {
            trxId: trxId,
            orgAmount: amountMoney,
          });
          if (res?.data.resultCd == "0000") {
            setIsSuccess("success");
            setResultMsg(res?.data.resultMsg);
            alert(res?.data.resultMsg);
          } else {
            setIsSuccess("error");
            setResultMsg(res?.data.resultMsg);
            alert(res?.data.resultMsg);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
      getListApi();
    }
  };
  const [modalFlag, setModalFlag] = useState(false);
  const [exportFlag, setExportFlag] = useState(false);
  useEffect(() => {
    if (exportFlag) {
      exportToXlsx("간편결제내역(앱/웹)_", address);
      setExportFlag(false);
      setModalFlag(false);
    }
  }, [exportFlag]);
  return (
    <>
      <PasswordModal
        postCodeFlag={modalFlag}
        handleClose={() => setModalFlag(false)}
        setExportFlag={() => setExportFlag(true)}
      />
      <Search
        dateFlag={true}
        pageFlag={true}
        link={"payment/easy/list?"}
        checkList={["pg", "payStatus", "searchField"]}
        getListApi={getListApi}
        limit={true}
        setPage={setPage}
        searchField={checkFieldsThree}
      />
      <Box height='5px' />
      {
        // ! 성공여부 알림창
        isSuccess === "" ? null : (
          <PayAlert
            isSuccess={isSuccess}
            actionName='결제 취소'
            resultMsg={resultMsg}
          />
        )
      }
      <Card className='card-box mb-4'>
        <div className='card-header pr-2'>
          <div className='card-header--title'>
            <small>결제내역 관리</small>
            <b>간편결제내역(앱/웹) 조회</b>
          </div>
          {sessionStorage.getItem("userAuth") === "SA" && (
            <div className='onlyAdminButtonGroup'>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => setModalFlag(true)}
                className='m-2'
                size='small'
              >
                <img src={excelIcon} alt='EXCEL' style={{ width: "23px" }} />
                &nbsp; 내보내기
              </Button>
            </div>
          )}
          <SelectLimit limit={limit} setLimit={setLimit} setPage={setPage} />
        </div>
        <CardContent className='p-0'>
          <div className='table-responsive'>
            <table className='text-nowrap mb-0 table table-striped'>
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                handleRequestSort={handleRequestSort}
                rowCount={listData.totalCount}
                userAuth={userAuth}
              />
              <TableBody>
                {listData.dataList.length > 0 ? (
                  stableSort(
                    listData.dataList,
                    getComparator(order, orderBy)
                  ).map((row, idx) => {
                    // ! 번호
                    const rowNum =
                      listData.totalCount - limit * (page - 1) - idx;

                    // ! 날짜
                    const tradingDate = listToDate(row?.tradingDate);
                    const cancelDate =
                      row?.paymentYn === "2"
                        ? listToDate(row?.tradingDate)
                        : "  ";
                    const orgDate =
                      row?.paymentYn === "2"
                        ? listToDate(row?.orgAppDate)
                        : "  ";

                    const now = new Date();
                    const parseNow = formatOnlyDate(now);
                    const parseTradingDate = formatOnlyDate(row?.tradingDate);
                    let isLaterDate = new Date(parseTradingDate);
                    isLaterDate.setDate(isLaterDate.getDate() + 1);

                    // ! 카드 복호화
                    const parsedCardNo = row?.cardNo;
                    // ! 결제구분 = 승인 or 취소 or 실패
                    let paymentYn;

                    if (row?.paymentYn === "1") {
                      paymentYn = "승인";
                    } else if (row?.paymentYn === "2") {
                      paymentYn = "취소";
                    } else if (row?.paymentYn === "3") {
                      paymentYn = "실패";
                    }

                    // ! 정산예정
                    let settlementYn;
                    if (row?.settlementYn === "N") {
                      settlementYn = "정산예정";
                    } else if (row?.settlementYn === "Y") {
                      settlementYn = "정산완료";
                    } else if (row?.settlementYn === "P") {
                      settlementYn = "정산보류";
                    }

                    // ! 할부
                    let installment;
                    if (row?.inMonth === "00" || row?.inMonth === "0") {
                      installment = "일시불";
                    } else {
                      installment = row?.inMonth + " 개월";
                    }

                    return (
                      <TableRow hover tabIndex={-1} key={idx}>
                        <TableCell className='text-center'>{rowNum}</TableCell>
                        <TableCell align='center'>{tradingDate}</TableCell>
                        <TableCell align='center'>
                          <span
                            className={
                              row?.paymentYn === "2"
                                ? "text-danger"
                                : row?.paymentYn === "3"
                                ? "text-warning"
                                : ""
                            }
                          >
                            {paymentYn}
                          </span>
                        </TableCell>
                        <TableCell align='left'>
                          {row?.franchise?.user?.agentName}
                        </TableCell>
                        <TableCell align='center'>
                          {row?.terminal?.tid}
                        </TableCell>
                        <TableCell align='center'>
                          {/* {row?.installment ? row?.installment : "NULL"} */}
                          {installment}
                        </TableCell>
                        <TableCell align='center'>
                          {row?.cardNo ? row?.cardNo : parsedCardNo}
                        </TableCell>
                        <TableCell align='center'>
                          {row?.approvalNumber}
                        </TableCell>
                        <TableCell align='right'>
                          {row?.paymentYn === "1"
                            ? formatToWon(row?.tradingAmount)
                            : formatToWon(0)}
                        </TableCell>
                        <TableCell align='right'>
                          {row?.paymentYn === "2" ? (
                            <span className='text-danger'>
                              {formatToWon(row?.tradingAmount)}
                            </span>
                          ) : (
                            formatToWon(0)
                          )}
                        </TableCell>
                        <TableCell align='right'>
                          {formatToWon(row?.tradingAmount)}
                        </TableCell>
                        <TableCell align='left'>
                          {row?.productName ? row?.productName : "없음"}
                        </TableCell>
                        <TableCell align='left'>
                          {row?.buyer ? row?.buyer : "없음"}
                        </TableCell>
                        <TableCell align='center'>
                          {row?.buyerNum ? row?.buyerNum : "없음"}
                        </TableCell>
                        <TableCell align='left'>
                          {row?.resultMsg ? row?.resultMsg : "없음"}
                        </TableCell>
                        <TableCell align='center' className='p-1'>
                          {row?.paymentYn === "1" ? null : (
                            <>
                              <span className='text-danger'>{cancelDate}</span>
                              <br />
                              <span className=''>{orgDate}</span>
                            </>
                          )}
                        </TableCell>

                        {row?.paymentYn === "1" ? (
                          userAuth === "SA" && (
                            <TableCell
                              align='right'
                              className='text-center p-1'
                            >
                              <Button
                                variant='outlined'
                                size='small'
                                onClick={() => handleCancel(row)}
                              >
                                취소
                              </Button>
                            </TableCell>
                          )
                        ) : (
                          <TableCell></TableCell>
                        )}
                      </TableRow>
                    );
                  })
                ) : (
                  <>
                    <NoData
                      comment='결제내역이 없습니다.'
                      colNum={22}
                      isLoading={loading}
                    />
                  </>
                )}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={60} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </table>
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={listData.totalCount}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              firstPageText={"‹‹"}
              lastPageText={"››"}
              onChange={(event) => {
                handleChangePage(event);
              }}
              // onPageChange={handleChangePage}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default EasyPayTable;
