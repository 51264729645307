import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import excelIcon from "assets/custom/excelIcon.svg";
import exportToXlsx from "utils/excelExport";
import {
  IconButton,
  Card,
  CardContent,
  Button,
  TableBody,
  TableCell,
  TableRow,
  TableFooter,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import FranchiseDetailModal from "./FranchiseDetailModal";
import {
  faHardDrive,
  faPenToSquare,
  faCreditCard,
} from "@fortawesome/free-regular-svg-icons";
import Pagination from "react-js-pagination";
import { SearchComponent } from "components/commonTable/SearchComponent";
import { getAxios } from "hook/axiosInstance";
import TerminalDetailModal from "layouts/terminal/terminalList/TerminalDetailModal";
import ExcelInputModal from "./ExcelInputModal";
import { listToDate } from "utils/formatFunction";
import SelectLimit from "components/commonTable/SelectLimit";
import NoData from "components/commonTable/NoData";
import { Box } from "../../../../node_modules/@material-ui/core/index";
import ExcelImport from "utils/excelImport";
import PasswordModal from "layouts/company/companyCommission/PasswordModal";
import sampleExcel from "assets/excel/가맹점등록예시엑셀파일.xlsx";
import axios from "../../../../node_modules/axios/index";
import { Search } from "components/commonTable/Search";
import { useRecoilState } from "recoil";
import { listLinkStateAtom } from "recoilStates/searchRecoil";
import CardAddModal from "layouts/terminal/terminalList/CardAddModal";
import { IpFlagFn } from "utils/ipFlag";
const paymentEasyTypeOptions = {
  0: "없음",
  1: "2064",
};
const FranchiseTable = () => {
  // ! Loading
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("franchise/list");

  // ! GET 요청

  const [listData, setListData] = useState({ dataList: [] });
  const [limit, setLimit] = useState(15);
  const [loadFlag, setLoadFlag] = useState(false);
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);
  const [page, setPage] = useState(1);

  const getListApi = async () => {
    setLoading(true);
    if (!loadFlag) setLoadFlag(true);
    if (listLinkState) {
      const tempAd = listLinkState;
      setAddress(tempAd);
      const resApiData = await getAxios(tempAd);
      if (resApiData.data.header.result_code === "200") {
        setLoading(false);
        setListData(resApiData.data.data);
      }
    }
  };

  useEffect(() => {
    if (listLinkState) {
      getListApi();
    }
  }, [page, limit]);

  useEffect(() => {
    if (listLinkState)
      if (!loadFlag) {
        getListApi();
      }
  }, [listLinkState, loading]);

  const handleChangePage = (e) => {
    setPage(e);
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/page=[^&]*/, `page=${e}`);
    setListLinkState(tempLink);
  };

  const [selected, setSelected] = useState([]);
  const [selectDetail, setSelectDetail] = useState("");
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const [cardModalOpen, setCardModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [terminalModalOpen, setTerminalModalOpen] = useState(false);
  const [excelModalOpen, setExcelModalOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");

  // const handleModalOpen = (event) => {
  //   setModalOpen(true);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   if (parseInt(event.target.value) > listData.dataList.length) {
  //     setLimit(-1);
  //     return;
  //   }
  //   setLimit(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleDetailClick = (row, idx) => {
    setModalOpen(true);
    setSelectDetail(row);
  };
  const handleCardClick = (row, idx) => {
    setCardModalOpen(true);
    setSelectDetail(row);
  };
  const handleTerminalIconClick = (row, idx) => {
    setTerminalModalOpen(true);
    setSelectDetail(row);
  };

  // const handleSelectAllClick = (event) => {
  //   setSelectedAll(!selectedAll);

  //   if (event.target.checked) {
  //     const newSelecteds = listData.dataList.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   } else {
  //     setSelected([]);
  //   }
  // };
  // ! N개씩 보기

  // ! 검색 관련
  const checkFields = [
    { id: "all", name: "모두" },
    { id: "companyName", name: "업체명" },
    { id: "agentName", name: "가맹점명" },
    { id: "ceoName", name: "대표자명" },
    { id: "managerContact", name: "담당자 연락처" },
    { id: "userId", name: "아이디" },
  ];

  // function formatDate(date) {
  //   if (date == null) {
  //     return "";
  //   }
  //   var d = new Date(date),
  //     month = "" + (d.getMonth() + 1),
  //     day = "" + d.getDate(),
  //     year = d.getFullYear();

  //   if (month.length < 2) month = "0" + month;
  //   if (day.length < 2) day = "0" + day;

  //   return [year, month, day].join("-");
  // }

  const userAuth = window.sessionStorage.getItem("userAuth");
  const [modalFlag, setModalFlag] = useState(false);
  const [exportFlag, setExportFlag] = useState(false);
  useEffect(() => {
    if (exportFlag) {
      exportToXlsx("가맹점목록조회_", address);
      setExportFlag(false);
      setModalFlag(false);
    }
  }, [exportFlag]);

  const [IP, setIP] = useState("");

  const getIP = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  const [ipFlag, setipFlag] = useState(false);

  useEffect(() => {
    if (userAuth === "SA") {
      setipFlag(IpFlagFn(IP));
    }
  }, [IP]);
  useEffect(() => getIP(), []);
  const downloadExcel = () => {
    const fileName = "가맹점등록예시.xlsx"; // 파일 이름 설정

    // 엑셀 파일 다운로드
    const link = document.createElement("a");
    link.href = sampleExcel; // 다운로드할 파일의 경로 설정
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const numberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <>
      <PasswordModal
        postCodeFlag={modalFlag}
        handleClose={() => setModalFlag(false)}
        setExportFlag={() => setExportFlag(true)}
      />
      <Search
        pageFlag={true}
        link={"franchise/list?"}
        checkList={["contractStatus", "pg", "immediate", "searchField"]}
        getListApi={getListApi}
        limit={true}
        setPage={setPage}
        searchField={checkFields}
      />
      <Box height='5px' />
      <Card className='card-box mb-4'>
        <div className='card-header pr-2'>
          <div className='card-header--title'>
            <small>가맹점 관리</small>
            <b>가맹점 목록</b>
          </div>
          <div className='card-header--actions'>
            {selected.length > 0 ? (
              <span>{selected.length} selected</span>
            ) : (
              <></>
            )}
            {/* <Link to="/franchise/create" style={{ textColor: "white" }}>
              <Button variant="contained" color="primary" className="m-2">
                가맹점 등록
              </Button>
            </Link> */}
            <Box display='flex' alignItems='center'>
              <Button
                variant='contained'
                color='secondary'
                style={{ marginRight: "8px" }}
                onClick={downloadExcel}
              >
                <img src={excelIcon} alt='EXCEL' style={{ width: "23px" }} />
                &nbsp; 업로드샘플
              </Button>
              {ipFlag && <ExcelImport userAuth={userAuth} />}
              {userAuth === "SA" ? (
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => setModalFlag(true)}
                >
                  <img src={excelIcon} alt='EXCEL' style={{ width: "23px" }} />
                  &nbsp; 내보내기
                </Button>
              ) : undefined}
              {/* <Button
              variant="contained"
              color="default"
              onClick={() => {
                setExcelModalOpen(true);
              }}
            >
              <img src={excelIcon} alt="EXCEL" style={{ width: "23px" }} />
              &nbsp; 등록
            </Button> */}
              <SelectLimit
                limit={limit}
                setLimit={setLimit}
                setPage={setPage}
              />
            </Box>
          </div>
        </div>
        <CardContent className='p-0'>
          <div className='table-responsive'>
            <table className='text-nowrap mb-0 table table-striped'>
              <thead className='thead-light'>
                <tr>
                  <th className='text-center'>번호</th>
                  <th className='text-center'>등록일자</th>
                  <th className='text-center'>코드</th>
                  <th className='text-center' style={{ width: "20%" }}>
                    가맹점명
                  </th>
                  <th className='text-center' style={{ width: "20%" }}>
                    업체명
                  </th>
                  <th className='text-center'>대표자명</th>
                  <th className='text-center'>담당자연락처</th>
                  <th className='text-center'>사</th>
                  <th className='text-center'>월별결제한도</th>
                  <th className='text-center'>수수료율</th>
                  <th className='text-center'>정산</th>
                  <th className='text-center'>정산보류</th>
                  <th className='text-center'>할부보류</th>
                  <th className='text-center'>PG</th>
                  <th className='text-center'>가상계좌</th>
                  <th className='text-center'>간편결제</th>
                  <th className='text-center'>상태</th>
                  <th className='text-center'>삼성페이</th>
                  <th className='text-center'>아이디</th>
                  {userAuth == "SA" && (
                    <th className='text-center'>비밀번호</th>
                  )}
                  {ipFlag && <th className='text-center'>단말기 등록</th>}
                  {ipFlag && <th className='text-center'>정보수정</th>}
                  {ipFlag && <th className='text-center'>카드등록</th>}
                </tr>
              </thead>
              <TableBody>
                {listData.dataList.length > 0 ? (
                  listData.dataList.map((row, idx) => {
                    const isItemSelected = isSelected(row?.franchise?.name);
                    const parsedDate = listToDate(
                      row?.franchise?.createDateTime
                    );

                    // ! 번호
                    const rowNum =
                      listData.totalCount - limit * (page - 1) - idx;

                    // ! 사업자구분
                    const cp = row?.franchise?.user?.companyType;
                    let companyType = "";
                    switch (cp) {
                      case "1":
                        companyType = "비";
                        break;
                      case "2":
                        companyType = "개";
                        break;
                      case "3":
                        companyType = "법";
                        break;
                      case "4":
                        companyType = "기타";
                        break;
                      default:
                        companyType = "";
                    }

                    // ! 계약 상태
                    const cs = row?.franchise?.user?.contractStatus;
                    let contractStatus = "";
                    let textColor = "#000";
                    switch (cs) {
                      case "1":
                        textColor = "#f00";
                        contractStatus = "신청";
                        break;
                      case "2":
                        contractStatus = "정상";
                        break;
                      case "3":
                        contractStatus = "해지";
                        break;

                      default:
                        contractStatus = "";
                    }
                    const samPay =
                      row.franchise?.samsungPayYn === "Y"
                        ? `${row.franchise?.vanPgSeq?.pgCompanyName?.pgName} ${row.franchise?.vanPgSeq?.mid} D+${row.franchise?.vanSettlementCycle}`
                        : "-";
                    return (
                      <TableRow
                        hover
                        role='checkbox'
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={idx}
                        selected={isItemSelected}
                      >
                        <TableCell
                          style={{ color: textColor }}
                          className='text-center'
                        >
                          {rowNum}
                        </TableCell>
                        {/* <TableCell align="center">{row?.franchise?.date}</TableCell> */}
                        <TableCell align='center' style={{ color: textColor }}>
                          {parsedDate}
                        </TableCell>
                        <TableCell align='center'>
                          {row?.franchise?.id}
                        </TableCell>

                        <TableCell align='left'>
                          {row?.franchise?.user?.agentName}
                        </TableCell>

                        <TableCell className='align-left'>
                          {row?.path}
                        </TableCell>

                        {/* <td className="text-center">
                        <IconButton size="small" color="primary">
                          <FontAwesomeIcon icon={["fas", "ellipsis-h"]} />
                        </IconButton>
                      </td> */}
                        <TableCell align='left'>
                          {row?.franchise?.user?.ceoName}
                        </TableCell>
                        <TableCell align='center'>
                          {row?.franchise?.user?.managerContact}
                        </TableCell>
                        <TableCell align='right'>{companyType}</TableCell>
                        <TableCell align='right'>
                          {numberWithCommas(row?.franchise?.paymentLimitMonth)}
                        </TableCell>
                        <TableCell align='center'>
                          {row?.franchise?.user?.commissionRate + "%"}
                        </TableCell>
                        <TableCell align='center'>
                          {`D + ${row?.franchise?.settlementCycle}`}
                        </TableCell>
                        <TableCell align='center'>
                          {row?.franchise?.settlementPendingYn}
                        </TableCell>
                        <TableCell align='center'>
                          {row?.franchise?.installmentPending}
                        </TableCell>
                        <TableCell align='right'>
                          {row?.franchise?.pgCompany?.pgCompanyName?.pgName +
                            "(" +
                            row?.franchise?.pgCompany?.mid +
                            ")"}
                        </TableCell>
                        <TableCell align='center'>
                          {paymentEasyTypeOptions[row?.immediateWithdrawalKey]}
                        </TableCell>
                        <TableCell align='center'>
                          {row?.franchise?.paymentEasyYn}
                        </TableCell>
                        <TableCell align='right' style={{ color: textColor }}>
                          {contractStatus}
                        </TableCell>
                        <TableCell align='right'>{samPay}</TableCell>
                        <TableCell align='left'>
                          {row?.franchise?.user?.userId}
                        </TableCell>
                        {userAuth == "SA" && (
                          <TableCell align='left'>
                            {row?.franchise?.user?.userPw}
                          </TableCell>
                        )}
                        {ipFlag && (
                          <TableCell align='center' className='p-1'>
                            <IconButton
                              size='small'
                              color='primary'
                              onClick={() => handleTerminalIconClick(row, idx)}
                            >
                              <FontAwesomeIcon icon={faHardDrive} />
                            </IconButton>
                          </TableCell>
                        )}
                        {ipFlag && (
                          <TableCell align='center' className='p-1'>
                            <IconButton
                              size='small'
                              color='primary'
                              onClick={() => handleDetailClick(row, idx)}
                            >
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </IconButton>
                          </TableCell>
                        )}
                        {ipFlag && (
                          <TableCell align='center' className='p-1'>
                            {row.franchise.franchiseCardYn === "Y" ? (
                              <IconButton
                                size='small'
                                color='primary'
                                onClick={() => handleCardClick(row, idx)}
                              >
                                <FontAwesomeIcon icon={faCreditCard} />
                              </IconButton>
                            ) : undefined}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
                ) : (
                  <>
                    <NoData
                      comment='등록된 가맹점이 없습니다.'
                      colNum={22}
                      isLoading={loading}
                    />
                  </>
                )}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={20} />
                  </TableRow>
                )} */}
              </TableBody>
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </table>
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={listData.totalCount}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              firstPageText={"‹‹"}
              lastPageText={"››"}
              onChange={(event) => {
                handleChangePage(event);
              }}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </div>
        </CardContent>
      </Card>
      {modalOpen ? (
        <FranchiseDetailModal
          selectDetail={selectDetail}
          scroll={scroll}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          getList={getListApi}
        />
      ) : (
        <></>
      )}
      {terminalModalOpen ? (
        <TerminalDetailModal
          selectDetail={selectDetail}
          scroll={scroll}
          modalOpen={terminalModalOpen}
          fromFranchiseList={true}
          setModalOpen={setTerminalModalOpen}
          handleModalClose={() => setTerminalModalOpen(false)}
        />
      ) : (
        <></>
      )}
      {excelModalOpen ? (
        <Dialog
          open={excelModalOpen}
          onClose={() => setExcelModalOpen(false)}
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
          maxWidth='lg'
        >
          <DialogTitle>엑셀 업로드</DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <ExcelInputModal />
          </DialogContent>
        </Dialog>
      ) : (
        <></>
      )}
      {cardModalOpen ? (
        <CardAddModal
          selectDetail={selectDetail}
          scroll={scroll}
          modalOpen={cardModalOpen}
          fromFranchiseList={true}
          setModalOpen={setTerminalModalOpen}
          handleModalClose={() => setCardModalOpen(false)}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default FranchiseTable;
