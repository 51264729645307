import React, { useEffect, useState } from "react";

import {
  Checkbox,
  Card,
  CardContent,
  Button,
  TableBody,
  TableCell,
  TableRow,
  TableFooter,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Pagination from "react-js-pagination";
import { getAxios, putAxios } from "hook/axiosInstance";
import EnhancedTableHead from "./EnhancedTableHead";
import { listToDate, formatToWon, formatOnlyDate } from "utils/formatFunction";
import SelectLimit from "components/commonTable/SelectLimit";
import excelIcon from "assets/custom/excelIcon.svg";
import NoData from "components/commonTable/NoData";
import { FontAwesomeIcon } from "../../../../node_modules/@fortawesome/react-fontawesome/index";
import MuiAlert from "@material-ui/lab/Alert";
import { Fade } from "@mui/material";
import exportToXlsx from "utils/excelExport";
import { Box } from "../../../../node_modules/@material-ui/core/index";
import PasswordModal from "layouts/company/companyCommission/PasswordModal";
import { Search } from "components/commonTable/Search";
import { useRecoilState } from "recoil";
import { listLinkStateAtom } from "recoilStates/searchRecoil";
// import "assets/custom/tables.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  selected: {
    backgroundColor: "rgba(245, 0, 87, 0.08) !important",
  },
}));

const PaymentTable = () => {
  // ! Loading
  const [loading, setLoading] = useState(false);

  // ! GET 요청

  const [listData, setListData] = useState({ dataList: [] });
  const [limit, setLimit] = useState(15);
  const [address, setAddress] = useState();
  const [userAuth, setUserAuth] = useState();
  const [loadFlag, setLoadFlag] = useState(false);
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);
  const [page, setPage] = useState(1);

  const getListApi = async () => {
    setLoading(true);
    if (!loadFlag) setLoadFlag(true);
    if (listLinkState) {
      const tempAd = listLinkState;
      setAddress(tempAd);
      const resApiData = await getAxios(tempAd);
      if (resApiData.data.header.result_code === "200") {
        setLoading(false);
        setListData(resApiData.data.data);
      }
    }
  };

  useEffect(() => {
    setUserAuth(window.sessionStorage.getItem("userAuth"));
  }, []);

  useEffect(() => {
    if (listLinkState) {
      getListApi();
    }
  }, [page, limit]);

  useEffect(() => {
    if (listLinkState)
      if (!loadFlag) {
        getListApi();
      }
  }, [listLinkState, loading]);

  const handleChangePage = (e) => {
    setPage(e);
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/page=[^&]*/, `page=${e}`);
    setListLinkState(tempLink);
  };

  const classes = useStyles();

  // ! 페이징

  // ! N개씩 보기

  // const checkEventHandler = ({ e }) => {
  //   setLimit(e);
  // };

  // ! 검색 관련

  // ! Sorting 관련
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("num");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // ! 선택 관련
  const [selected, setSelected] = useState([]);
  let isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listData.dataList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    } else {
      setSelected([]);
    }
  };

  // ! 정산보류 / 해제 로직

  const handlePending = async () => {
    if (window.confirm("정말 보류하시겠습니까?")) {
      const pendingData = {
        createId: sessionStorage.getItem("userId"),
        pendingData: selected,
      };
      try {
        const pendingRes = await putAxios("payment/pending", pendingData);
        if (pendingRes.status === 200) {
          setSelected([]);
          isSelected = "";
          handleSuccess("success", "pending");
          await getListApi(limit);
        } else {
          alert("오류! " + pendingRes?.data?.header?.result_message);
        }
      } catch (e) {
        handleSuccess("warning", "pending");
        // alert("오류! ");
      }
    }
  };

  const handleUnpending = async () => {
    if (window.confirm("정말 보류해제 하시겠습니까?")) {
      const pendingData = {
        createId: sessionStorage.getItem("userId"),
        pendingData: selected,
      };
      try {
        const pendingRes = await putAxios("payment/unpending", pendingData);
        if (pendingRes.status === 200) {
          handleSuccess("success", "unpending");
          setSelected([]);
          isSelected = "";
          await getListApi(limit);
        } else {
          handleSuccess("warning", "unpending");
          alert("오류! " + pendingRes?.data?.header?.result_message);
        }
      } catch (e) {
        handleSuccess("warning", "unpending");
      }
    }
  };

  // ! 전표
  // const handleOpenBill = (row) => {
  //   window.open(
  //     "https://payapi.welcomepayments.co.kr/api/receipt/print?tid=202301062766781&hash_value=684f6c79ec39e7f85f1b0144d131283c84c4850fc72c9ef811f13b22d250f8dc",
  //     "popUpWindow",
  //     "width=700px, height=1000px"
  //   );
  // };

  // ! ALERT
  const [isSuccess, setIsSuccess] = useState("");
  const [actionName, setActionName] = useState("");

  const handleSuccess = (successYn, action) => {
    setIsSuccess(successYn);
    setActionName(action);
    setTimeout(() => {
      setIsSuccess("");
    }, 10000);
  };

  let pendingAlertMessage = "";
  if (isSuccess === "warning") {
    pendingAlertMessage = "오류가 발생했습니다.";
  } else {
    if (actionName === "pending") {
      pendingAlertMessage = "정산 보류가 완료되었습니다.";
    } else if (actionName === "unpending") {
      pendingAlertMessage = "보류 해제가 완료되었습니다.";
    }
  }

  // ! 엑셀 export -> headers
  const [modalFlag, setModalFlag] = useState(false);
  const [exportFlag, setExportFlag] = useState(false);
  useEffect(() => {
    if (exportFlag) {
      exportToXlsx("결제내역_", address);
      setExportFlag(false);
      setModalFlag(false);
    }
  }, [exportFlag]);

  const searchField = [
    { id: "all", name: "모두" },
    { id: "franchiseName", name: "가맹점명" },
    { id: "companyName", name: "업체명" },
    { id: "appNo", name: "승인번호" },
    { id: "cardNo", name: "카드번호" },
    { id: "commissionRate", name: "수수료율" },
    { id: "tid", name: "단말기코드" },
  ];
  return (
    <>
      <PasswordModal
        postCodeFlag={modalFlag}
        handleClose={() => setModalFlag(false)}
        setExportFlag={() => setExportFlag(true)}
      />
      {/* <SearchComponent
        keyword={keyword}
        listData={listData}
        checkBool={checkBool}
        setCheckBool={setCheckBool}
        setKeyword={setKeyword}
        searchClick={searchClick}
        startDate={startDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        checkFields={checkFields}
        checkFieldsTwo={checkFieldsTwo}
        checkFieldsThree={checkFieldsThree}
        setLoadFlag={setLoadFlag}
      /> */}
      <Search
        dateFlag={true}
        pageFlag={true}
        link={"payment/list?"}
        checkList={["pg", "payStatus", "searchField"]}
        getListApi={getListApi}
        limit={true}
        setPage={setPage}
        searchField={searchField}
      />
      <Box height='5px' />
      {
        // ! ALERT
        isSuccess === "" ? null : (
          <Fade in={isSuccess}>
            <MuiAlert className='alerts-alternate mb-4' color={isSuccess}>
              <div className='d-flex align-items-center align-content-start'>
                <span className='font-size-lg d-block d-40 mr-3 text-center bg-success text-white rounded-sm'>
                  <FontAwesomeIcon icon={["far", "object-group"]} />
                </span>
                <span>
                  <strong className='d-block'>
                    {actionName === "unpending"
                      ? "보류 해제"
                      : actionName === "pending"
                      ? "정산 보류"
                      : null}
                  </strong>
                  {pendingAlertMessage}
                </span>
              </div>
            </MuiAlert>
          </Fade>
        )
      }
      <Card className='card-box mb-4'>
        <div className='card-header pr-2'>
          <div className='card-header--title'>
            <small>결제내역 관리</small>
            <b>결제내역 조회</b>
          </div>
          <div className='card-header--actions'>
            {selected.length > 0 ? (
              <span>{selected.length} 개 선택</span>
            ) : (
              <></>
            )}
          </div>
          {sessionStorage.getItem("userAuth") === "SA" && (
            <div className='onlyAdminButtonGroup'>
              <Button
                variant='contained'
                color='primary'
                className='m-2'
                size='small'
                onClick={handlePending}
              >
                정산보류
              </Button>
              <Button
                variant='outlined'
                color='primary'
                className='mr-2'
                size='small'
                onClick={handleUnpending}
              >
                보류해제
              </Button>
              {userAuth === "SA" ? (
                <Button
                  variant='contained'
                  color='secondary'
                  size='small'
                  onClick={() => setModalFlag(true)}
                >
                  <img src={excelIcon} alt='EXCEL' style={{ width: "23px" }} />
                  &nbsp; 내보내기
                </Button>
              ) : undefined}
            </div>
          )}
          <SelectLimit limit={limit} setLimit={setLimit} setPage={setPage} />
        </div>
        <CardContent className='p-0'>
          <div className='table-responsive'>
            <table className='text-nowrap mb-0 table table-striped'>
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                handleRequestSort={handleRequestSort}
                rowCount={listData.dataList.length}
              />

              <TableBody>
                {listData.dataList.length > 0 ? (
                  stableSort(
                    listData.dataList,
                    getComparator(order, orderBy)
                  ).map((row, idx) => {
                    // ! 번호
                    const rowNum =
                      listData.totalCount - limit * (page - 1) - idx;
                    const isItemSelected = isSelected(row.id);
                    const labelId = "enhanced-table-checkbox";
                    // ! 날짜
                    const tradingDate = listToDate(row.tradingDate);
                    const cancelDate =
                      row.paymentYn === "2"
                        ? listToDate(row.tradingDate)
                        : "  ";
                    const orgDate =
                      row.paymentYn === "2" ? listToDate(row.orgAppDate) : "  ";
                    const settlementPlanDate = formatOnlyDate(
                      row?.settlementPlanDate
                    );
                    const settlementDoneDate = formatOnlyDate(
                      row?.settlementDoneDate
                    );

                    // ! 카드
                    const parsedCardNo = row.cardNo;
                    // ! 결제구분 = 승인 or 취소 or 실패
                    let paymentYn;

                    if (row?.paymentYn === "1") {
                      paymentYn = "승인";
                    } else if (row?.paymentYn === "2") {
                      paymentYn = "취소";
                    } else {
                      paymentYn = "실패";
                    }

                    // ! 정산예정
                    let settlementYn;
                    if (row.settlementYn === "N") {
                      settlementYn = "정산예정";
                    } else if (row.settlementYn === "Y") {
                      settlementYn = "정산완료";
                    } else if (row.settlementYn === "P") {
                      settlementYn = "정산보류";
                    }

                    // ! 할부
                    let installment;
                    if (row.inMonth === "00" || row.inMonth === "0") {
                      installment = "일시불";
                    } else {
                      installment = row.inMonth + "개월";
                    }
                    return (
                      <TableRow
                        hover
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={idx}
                        selected={isItemSelected}
                        className={isItemSelected ? classes.selected : ""}
                        style={{ fontSize: "2px" }}
                      >
                        <TableCell className='p-0'>
                          {paymentYn !== "실패" && (
                            <Checkbox
                              checked={isItemSelected}
                              onClick={(event) => {
                                handleClick(event, row.id);
                              }}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          )}
                        </TableCell>
                        <TableCell className='text-center'>{rowNum}</TableCell>
                        <TableCell align='center'>{tradingDate}</TableCell>
                        <TableCell align='center'>
                          <span
                            className={
                              paymentYn === "취소"
                                ? "text-danger"
                                : paymentYn === "실패"
                                ? "text-warning"
                                : ""
                            }
                          >
                            {paymentYn}
                          </span>
                        </TableCell>
                        <TableCell align='center' className='p-0'>
                          {settlementYn === "정산예정" ? (
                            settlementPlanDate
                          ) : settlementYn === "정산완료" ? (
                            settlementDoneDate
                          ) : (
                            <span className='text-twitter fw-bolder'>
                              {settlementYn}
                            </span>
                          )}
                          <br />
                          {!settlementYn
                            ? "없음"
                            : settlementYn === "정산보류"
                            ? null
                            : settlementYn}
                        </TableCell>
                        <TableCell align='left'>
                          {row.franchise.user.agentName}
                        </TableCell>
                        <TableCell align='center'>{row.terminal.tid}</TableCell>
                        <TableCell align='center'>{row.cardAcqName}</TableCell>
                        <TableCell align='center'>{installment}</TableCell>
                        <TableCell align='center'>
                          {parsedCardNo ? parsedCardNo : row.cardNo}
                        </TableCell>
                        <TableCell align='center'>
                          {row.approvalNumber.length > 0
                            ? row.approvalNumber.padStart(8, "0")
                            : " "}
                        </TableCell>
                        <TableCell align='right'>
                          {row.paymentYn === "1"
                            ? formatToWon(row.tradingAmount)
                            : formatToWon(0)}
                        </TableCell>
                        <TableCell align='right'>
                          {row.paymentYn === "2" ? (
                            <span className='text-google'>
                              {formatToWon(row.tradingAmount)}
                            </span>
                          ) : (
                            formatToWon(0)
                          )}
                        </TableCell>
                        <TableCell align='right'>
                          {formatToWon(row.tradingAmount)}
                        </TableCell>
                        <TableCell align='right'>
                          {row.commissionAmount
                            ? formatToWon(row.commissionAmount || 0)
                            : "없음"}
                        </TableCell>
                        <TableCell align='right'>
                          {row.settlementAmount
                            ? formatToWon(row.settlementAmount || 0)
                            : "없음"}
                        </TableCell>
                        <TableCell align='center'>
                          {/* {row.franchise.user.commissionRate
                            ? row.franchise.user.commissionRate
                            : "없음"} */}

                          {row.commissionRate ? row.commissionRate : "없음"}
                        </TableCell>
                        <TableCell align='center'>{row.pgName}</TableCell>
                        <TableCell align='left'>{row.agent}</TableCell>

                        <TableCell align='center' className='p-0'>
                          {/* <IconButton
                            color="primary"
                            className="p-0"
                            onClick={() => handleOpenBill(row)}
                          >
                            <FontAwesomeIcon icon={faReceipt} />
                          </IconButton> */}
                        </TableCell>

                        <TableCell align='center' className='p-1'>
                          {row?.paymentYn === "2" ? (
                            <>
                              <span className='text-google'>{cancelDate}</span>
                              <br />
                              <span className=''>{orgDate}</span>
                            </>
                          ) : null}
                        </TableCell>
                        <TableCell align='center'>{row.resultMsg}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <>
                    <NoData
                      comment='결제 내역이 없습니다.'
                      colNum={23}
                      isLoading={loading}
                    />
                  </>
                )}
                <TableRow className='table-dark'>
                  <TableCell colSpan={10}></TableCell>
                  <TableCell align='center'>합계</TableCell>
                  <TableCell align='right'>
                    <span className='font-weight-bold font-size-sm'>
                      {formatToWon(
                        listData?.paymentListSumDto?.sumAccAmount || 0
                      )}
                    </span>
                  </TableCell>
                  <TableCell align='right'>
                    <span className='text-google'>
                      <span className='font-weight-bold font-size-sm'>
                        {formatToWon(
                          listData?.paymentListSumDto?.sumCancelAmount || 0
                        )}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell align='right'>
                    <span className='font-weight-bold font-size-sm'>
                      {formatToWon(
                        listData?.paymentListSumDto?.sumTradingAmount || 0
                      )}
                    </span>
                  </TableCell>
                  <TableCell align='right'>
                    <span className='font-weight-bold font-size-sm'>
                      {formatToWon(
                        listData?.paymentListSumDto?.sumCommissionAmount || 0
                      )}
                    </span>
                  </TableCell>
                  <TableCell align='right'>
                    <span className='font-weight-bold font-size-sm'>
                      {formatToWon(
                        listData?.paymentListSumDto?.sumSettlementAmount || 0
                      )}
                    </span>
                  </TableCell>
                  <TableCell colSpan={6} align='center'></TableCell>
                </TableRow>
              </TableBody>
              <TableFooter></TableFooter>
            </table>
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={listData.totalCount}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              firstPageText={"‹‹"}
              lastPageText={"››"}
              onChange={(event) => {
                handleChangePage(event);
              }}
              // onPageChange={handleChangePage}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default PaymentTable;
