import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import {
  IconButton,
  Card,
  CardContent,
  Button,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableFooter,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import TerminalDetailModal from "./TerminalDetailModal";
import Pagination from "react-js-pagination";
import excelIcon from "assets/custom/excelIcon.svg";
import { getAxios } from "hook/axiosInstance";
import ExcelInputModal from "layouts/franchise/franchiseList/ExcelInputModal";
import { listToDate } from "utils/formatFunction";
import SelectLimit from "components/commonTable/SelectLimit";
import NoData from "components/commonTable/NoData";
import { Box } from "../../../../node_modules/@material-ui/core/index";
import ExcelImport from "utils/tidExcelImport";
import sampleExcel from "assets/excel/단말기등록엑셀예시.xlsx";
import { Search } from "components/commonTable/Search";
import { useRecoilState } from "recoil";
import { listLinkStateAtom } from "recoilStates/searchRecoil";
import ExportToXlsx from "utils/excelExport";
import PasswordModal from "layouts/company/companyCommission/PasswordModal";

const TerminalTable = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [selected, _setSelected] = useState([]);
  const [selectDetail, setSelectDetail] = useState("");
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const [modalOpen, setModalOpen] = useState(false);
  const [excelModalOpen, setExcelModalOpen] = useState(false);
  const [scroll, _setScroll] = useState("paper");
  const [loadFlag, setLoadFlag] = useState(false);
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);
  const [loading, setLoading] = useState(false);

  const getListApi = async () => {
    setLoading(true);
    if (!loadFlag) setLoadFlag(true);
    if (listLinkState) {
      const tempAd = listLinkState;
      setAddress(tempAd);
      const resApiData = await getAxios(tempAd);
      if (resApiData.data.header.result_code === "200") {
        setLoading(false);
        setListData(resApiData.data.data);
      }
    }
  };

  useEffect(() => {
    setUserAuth(window.sessionStorage.getItem("userAuth"));
  }, []);

  useEffect(() => {
    if (listLinkState) {
      getListApi();
    }
  }, [page, limit]);

  useEffect(() => {
    if (listLinkState)
      if (!loadFlag) {
        getListApi();
      }
  }, [listLinkState, loading]);

  const handleChangePage = (e) => {
    setPage(e);
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/page=[^&]*/, `page=${e}`);
    setListLinkState(tempLink);
  };
  // const handleModalOpen = (event) => {
  //   setModalOpen(true);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   if (parseInt(event.target.value) > listData.dataList.length) {
  //     setLimit(-1);
  //     return;
  //   }
  //   setLimit(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleDetailClick = (row, idx) => {
    setModalOpen(true);
    setSelectDetail(row);
  };

  // ! N개씩 보기

  // ! 검색 관련
  const [address, setAddress] = useState("terminal/list");

  const checkFieldsTwo = [
    { id: "all", name: "모두" },
    { id: "tid", name: "단말기코드" },
    { id: "terminalCode", name: "시리얼번호" },
    { id: "agentName", name: "업체명" },
    { id: "franchiseName", name: "가맹점명" },
    { id: "ceoName", name: "대표자명" },
    { id: "managerContact", name: "담당자 연락처" },
  ];

  // ! Loading

  // ! GET 요청
  const [listData, setListData] = useState({ dataList: [] });

  const [userAuth, setUserAuth] = useState();

  const emptyRows =
    limit - Math.min(limit, listData.totalCount - (page - 1) * limit);

  // ! 엑셀 export
  const [modalFlag, setModalFlag] = useState(false);
  const [exportFlag, setExportFlag] = useState(false);
  useEffect(() => {
    if (exportFlag) {
      ExportToXlsx("단말기 목록_", address);
      setExportFlag(false);
      setModalFlag(false);
    }
  }, [exportFlag]);
  // ! 모달
  const handleModalClose = () => {
    setModalOpen(false);
    getListApi(limit);
  };
  const downloadExcel = () => {
    const fileName = "단말기 목록.xlsx"; // 파일 이름 설정
    // 엑셀 파일 다운로드
    const link = document.createElement("a");
    link.href = sampleExcel; // 다운로드할 파일의 경로 설정
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <PasswordModal
        postCodeFlag={modalFlag}
        handleClose={() => setModalFlag(false)}
        setExportFlag={() => setExportFlag(true)}
      />
      <Search
        dateFlag={false}
        pageFlag={true}
        link={"terminal/list?"}
        checkList={["pg", "searchField"]}
        getListApi={getListApi}
        limit={true}
        setPage={setPage}
        searchField={checkFieldsTwo}
      />
      <Box height='5px' />
      <Card className='card-box mb-4'>
        <div className='card-header pr-2'>
          <div className='card-header--title'>
            <small>단말기 관리</small>
            <b>단말기 목록</b>
          </div>
          <div className='card-header--actions'>
            {selected.length > 0 ? (
              <span>{selected.length} selected</span>
            ) : (
              <></>
            )}
            {/* <Link to="/terminal/create" style={{ textColor: "white" }}>
              <Button variant="contained" color="primary" className="m-2">
                단말기 등록
              </Button>
            </Link> */}
            <Box display='flex' alignItems='center'>
              {userAuth === "SA" ? (
                <>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => setModalFlag(true)}
                  >
                    <img
                      src={excelIcon}
                      alt='EXCEL'
                      style={{ width: "23px" }}
                    />
                    &nbsp; 내보내기
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant='contained'
                    color='primary'
                    style={{ marginRight: "8px" }}
                    onClick={downloadExcel}
                  >
                    <img
                      src={excelIcon}
                      alt='EXCEL'
                      style={{ width: "23px" }}
                    />
                    &nbsp; 업로드샘플
                  </Button>
                  <ExcelImport userAuth={userAuth} />
                </>
              ) : undefined}
              <SelectLimit
                limit={limit}
                setLimit={setLimit}
                setPage={setPage}
              />
            </Box>
          </div>
        </div>
        <CardContent className='p-0'>
          <div className='table-responsive'>
            <table className='text-nowrap mb-0 table table-striped'>
              <TableHead className='thead-light'>
                <tr>
                  <th className='text-center'>번호</th>
                  <th className='text-center'>등록일자</th>
                  <th className='text-center'>단말기코드(TID)</th>
                  <th className='text-center'>시리얼번호</th>
                  <th className='text-center'>PG</th>
                  <th className='text-center'>가맹점명</th>
                  <th className='text-center' style={{ width: "20%" }}>
                    업체명
                  </th>
                  <th className='text-center'>대표자명</th>
                  <th className='text-center'>담당자연락처</th>
                  <th className='text-center'>사</th>
                  <th className='text-center'>정산주기</th>
                  <th className='text-center'>상태</th>
                  <th className='text-center'>아이디</th>
                  {userAuth == "SA" && (
                    <th className='text-center'>정보수정</th>
                  )}
                </tr>
              </TableHead>
              <TableBody>
                {listData.dataList.length > 0 ? (
                  listData.dataList.map((row, idx) => {
                    const pgFlag =
                      row.terminalType === "N" &&
                      row.franchise?.samsungPayYn === "Y"
                        ? true
                        : false;
                    const vanPg =
                      row?.franchise?.vanPgSeq?.pgCompanyName?.pgName +
                      "(" +
                      row?.franchise?.vanPgSeq?.mid +
                      ")";
                    const pg =
                      row?.franchise?.pgCompany?.pgCompanyName?.pgName +
                      "(" +
                      row?.franchise?.pgCompany?.mid +
                      ")";
                    const isItemSelected = isSelected(row.name);
                    // ! 날짜
                    const parsedDate = listToDate(row.createDate);

                    // ! 번호
                    const rowNum =
                      listData.totalCount - limit * (page - 1) - idx;

                    // ! 사업자구분
                    const cp = row?.franchise?.user?.companyType;
                    let companyType = "";
                    switch (cp) {
                      case "1":
                        companyType = "비";
                        break;
                      case "2":
                        companyType = "개";
                        break;
                      case "3":
                        companyType = "법";
                        break;
                      case "4":
                        companyType = "기타";
                        break;
                      default:
                        console.log(`Sorry, we are out of .`);
                    }

                    return (
                      <TableRow
                        hover
                        role='checkbox'
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={idx}
                        selected={isItemSelected}
                      >
                        <TableCell className='text-center'>{rowNum}</TableCell>
                        <TableCell align='center'>{parsedDate}</TableCell>
                        <TableCell align='center'>{row.tid}</TableCell>
                        <TableCell align='center'>{row.terminalCode}</TableCell>

                        <TableCell className='text-center'>
                          {pgFlag ? vanPg : pg}
                          {/* {pg} */}
                          {/* {row.franchise.pgCompany.pgCompanyName.pgName}(
                          {row.franchise.pgCompany.mid}) */}
                        </TableCell>
                        <TableCell align='left'>
                          {row.franchise.user.agentName}
                        </TableCell>
                        <TableCell align='left'>{row.path}</TableCell>
                        <TableCell align='left'>
                          {row.franchise.user.ceoName}
                        </TableCell>
                        <TableCell align='center'>
                          {row.franchise.user.managerContact}
                        </TableCell>
                        <TableCell align='center'>{companyType}</TableCell>
                        <TableCell align='center'>
                          {"D + " + row.franchise.settlementCycle}
                        </TableCell>
                        <TableCell align='center'>
                          {row.terminalStatus}
                        </TableCell>
                        <TableCell align='left'>
                          {row.franchise.user.userId}
                        </TableCell>
                        {userAuth == "SA" && (
                          <TableCell
                            align='center'
                            onClick={() => handleDetailClick(row, idx)}
                            className='p-1'
                          >
                            <IconButton size='small' color='primary'>
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
                ) : (
                  <>
                    <NoData
                      comment='등록된 가맹점이 없습니다.'
                      colNum={22}
                      isLoading={loading}
                    />
                  </>
                )}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={14} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </table>
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={listData?.totalCount}
            pageRangeDisplayed={5}
            prevPageText={"‹"}
            nextPageText={"›"}
            firstPageText={"‹‹"}
            lastPageText={"››"}
            onChange={(event) => {
              handleChangePage(event);
            }}
            // onPageChange={handleChangePage}
            style={{ display: "flex", justifyContent: "center" }}
          />
        </CardContent>
      </Card>
      {modalOpen ? (
        <TerminalDetailModal
          selectDetail={selectDetail}
          scroll={scroll}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          fromFranchiseList={false}
          handleModalClose={handleModalClose}
        />
      ) : (
        <></>
      )}
      {excelModalOpen ? (
        <Dialog
          open={excelModalOpen}
          onClose={() => setExcelModalOpen(false)}
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
          maxWidth='lg'
        >
          <DialogTitle>엑셀 업로드</DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <ExcelInputModal />
          </DialogContent>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};

export default TerminalTable;
