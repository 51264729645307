import { ko } from "date-fns/locale";
import { postAxios } from "hook/axiosInstance";
import { useEffect, useState } from "react";
import { DateDiv, SDatePicker } from "utils/ButtonSelectDateBox";
import { now } from "utils/formatFunction";
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const DeductModal = ({
  modalOpen,
  handleModalClose,
  scroll,
  selectData,
  getList,
}) => {
  const [pickDate, setPickDate] = useState(now);


  // ! 전송로직
  const [deductValue, setDeductValue] = useState({
    franchiseCode: selectData?.franchiseCode,
    franchiseName: selectData?.franchiseName,
  });

  const handleChange = (e) => {
    setDeductValue({ ...deductValue, [e.target.name]: e.target.value });
  };
  const handleDeductClick = () => {
    if (!validationFunc(deductValue)) {
      alert("금액이 결제금액보다 큽니다.");
      return;
    }
    if (window.confirm("정말 입력하시겠습니까?")) {
      sendDeduct(deductValue);
    }
  };

  const sendDeduct = async (deductValue) => {

    const deductRes = await postAxios("settlement/deduct/deposit", deductValue);
    if (deductRes.data.header.result_code === "200") {
      alert("완료!");
      getList();
      handleModalClose();
    } else {}
  };

  // ! Validation
  const validationFunc = (values) => {
    return values.amountMoney > -selectData?.tradingAmount ? false : true;
  };

  const classes = useStyles();

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { height: 435 } }}
      open={modalOpen}
      onClose={handleModalClose}
      scroll="body"
      fullwidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      maxHeight="lg"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DialogTitle id="scroll-dialog-title">정산차감 등록</DialogTitle>
      </div>
      <DialogContent dividers={scroll === "paper"}>
        {/* <DialogContentText
          id="scroll-dialog-description"
          // ref={descriptionElementRef}
          tabIndex={-1}
        >
          {/* <TypeaheadSearch /> 
        </DialogContentText> */}

        <div className="table-responsive">
          <table className="text-nowrap mb-0 table table-striped">
            <thead className="thead-light">
              <tr>
                <th className="text-center">가맹점명</th>
                <th className="text-center">차감일자</th>
                <th className="text-center" style={{ width: "40%" }}>
                  차감내역
                </th>
                <th className="text-center" style={{ width: "20%" }}>
                  차감금액(원)
                </th>
                <th className="text-center">등록</th>
              </tr>
            </thead>
            <TableBody>
              {/* {(rowsPerPage > 0
            ? listData.dataList.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              
            : listData.dataList
          ).map((row, idx) => { */}
              {
                <TableRow tabIndex={-1}>
                  <TableCell align="center" style={{ width: "40%" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{ width: "100%", backgroundColor: "white" }}
                      disabled
                      value={selectData?.franchiseName}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <DateDiv>
                      <Icon
                        fontSize="small"
                        style={{
                          zIndex: "100",
                          position: "relative",
                          left: "45px",
                        }}
                      >
                        calendar_month
                      </Icon>
                      <SDatePicker
                        locale={ko}
                        selected={pickDate}
                        dateFormat="yyyy년 MM월 dd일"
                        onChange={(date) => setPickDate(date)}
                        disabled
                      />
                    </DateDiv>
                  </TableCell>
                  <TableCell align="center" style={{ width: "40%" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{ width: "100%", backgroundColor: "white" }}
                      name="deductMsg"
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{ width: "100%", backgroundColor: "white" }}
                      name="deductAmount"
                      type="number"
                      classes={{ root: classes.input }}
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleDeductClick}
                    >
                      등록
                    </Button>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} color="primary">
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeductModal;
