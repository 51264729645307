const { CardDecrypted } = require("./seed");

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;

  return [year, month].join("-");
};

export const now = () => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
};

export const yesterday = () => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() - 1
  );
};
export const threeDaysAgo = () => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() - 3
  );
};

export const oneMonthAgo = () => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDate()
  );
};

export const threeMonthAgo = () => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 3,
    new Date().getDate()
  );
};

export const lastMonthStart = () => {
  // return new Date(
  //   new Date().getFullYear(),
  //   new Date().getMonth() - 1,
  //   new Date().getDate()
  // );
  const date = new Date();
  const firstDayOfLastMonth = new Date(
    date.getFullYear(),
    date.getMonth() - 1,
    1
  );
  return firstDayOfLastMonth;
};

export const lastMonthEnd = () => {
  // return new Date(
  //   new Date().getFullYear(),
  //   new Date().getMonth() - 1,
  //   new Date().getDate()
  // );
  const date = new Date();
  const firstDayOfThisMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  return new Date(
    firstDayOfThisMonth.setDate(firstDayOfThisMonth.getDate() - 1)
  );
};

export const thisMonthStart = () => {
  const date = new Date();
  const firstDayOfThisMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  return firstDayOfThisMonth;
};

export const listToDate = (someArray) => {
  if (someArray) {
    const month = someArray[1]?.toString().padStart(2, "0");
    const day = someArray[2]?.toString().padStart(2, "0");
    const hour = someArray[3]?.toString().padStart(2, "0");
    const minute = someArray[4]?.toString().padStart(2, "0");
    const second = someArray[5]
      ? someArray[5]?.toString().padStart(2, "0")
      : "00";

    const parseDay = `${someArray[0]}-${month}-${day} ${hour}:${minute}:${second}`;
    return parseDay;
  }
};

export const formatOnlyDate = (someArray) => {
  if (someArray) {
    const year = someArray[0]?.toString().padStart(4, "0");
    const month = someArray[1]?.toString().padStart(2, "0");
    const day = someArray[2]?.toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
};

export const maskCardNo = (cardNo) => {
  const decrypted = CardDecrypted(cardNo);
  var card1 = String(decrypted.slice(0, 8));
  var mask_value = String(decrypted.slice(8, 13));
  var card2 = String(decrypted.slice(13, 16));
  var mask = "";

  for (var i = 0; i < mask_value.length; i++) {
    mask += "*";
  }

  return String(card1) + String(mask) + String(card2);
};

export const formatToWon = (money) => {
  return new Intl.NumberFormat("ko-KR").format(money);
};

export const todayDateTime = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return new Intl.DateTimeFormat("ko-KR", options).format(date);
};
