import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  TableCell,
  TableFooter,
  TableRow,
  TableBody,
} from "@mui/material";
import { getAxios } from "hook/axiosInstance";
import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { Box } from "../../../../node_modules/@material-ui/core/index";
import { SearchComponent } from "components/commonTable/SearchComponent";

const SearchFranchiseModal = ({
  modalOpen,
  scroll,
  handleModalClose,
  setCreateValue,
  createValue,
}) => {
  // ! 상위 검색 로직
  const [highListData, setHighListData] = useState({ dataList: [] });

  useEffect(() => {
    getHighCompanyListApi();
  }, []);

  useEffect(() => {}, [highListData]);

  const getHighCompanyListApi = async () => {
    const responseHighList = await getAxios(`franchise/list`);
    if (responseHighList.data.header.result_code === "200") {
      setHighListData(responseHighList.data.data);
    } else {
    }
  };

  // ! 테이블 관련
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [checkBool, setCheckBool] = useState("");
  const [checkFields, setCheckFields] = useState([
    { field: "pg", name: "PG사" },
  ]);
  const emptyRows =
    10 - Math.min(10, highListData.totalCount - (page - 1) * 10);

  // ! 페이징
  const handleChangePage = (page) => {
    setPage(page);
    const paging = async () => {
      const res = await getAxios(
        `franchise/list?&page=${page}&keyword=${encodeURI(
          keyword
        )}&searchField=agentName`
      );
      if (res.data.header.result_code === "200") {
        setHighListData(res.data.data);
      }
    };
    paging();
  };

  // ! 선택
  const handleFranchiseSelect = (row) => {
    setCreateValue({
      ...createValue,
      mid: row.franchise.mid,
      franchiseName: row.franchise.user.agentName,
      franchiseCode: row.franchise.id,
      useCardTn:"Y"
    });
    handleModalClose();
  };
  const searchClick = () => {
    const requestSearch = async () => {
      const tempAd = `franchise/list?keyword=${encodeURI(
        keyword
      )}&searchField=agentName`;
      const res = await getAxios(tempAd);
      if (res.data.header.result_code === "200") {
        setHighListData(res.data.data);
      }
    };
    requestSearch();
    setPage(1);
  };
  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        classes={{ paper: "modal-dark " }}
        maxWidth="xl"
        disableEnforceFocus
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DialogTitle id="scroll-dialog-title">가맹점 검색</DialogTitle>
        </div>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box>
              <SearchComponent
                keyword={keyword}
                listData={highListData}
                checkBool={checkBool}
                setCheckBool={setCheckBool}
                setKeyword={setKeyword}
                searchClick={searchClick}
                // checkFieldsTwo={checkFieldsTwo}
                // checkFields={checkFields}
                dateDisabled={true}
              />
            </Box>
            {/* <TypeaheadSearch label="가맹점명" list={highListData.dataList} /> */}
          </DialogContentText>

          <div className="table-responsive">
            <Box height="5px" />
            <table className="text-nowrap mb-0 table table-striped">
              <thead className="thead-light">
                <tr>
                  <th className="text-center">번호</th>
                  <th className="text-center" style={{ width: "30%" }}>
                    코드
                  </th>
                  <th className="text-center" style={{ width: "30%" }}>
                    가맹점명
                  </th>
                  {/* <th className="text-center">대표자명</th> */}
                  <th className="text-center">전화번호</th>
                  <th className="text-center">담당자</th>
                  <th className="text-center">담당자연락처</th>
                  <th className="text-center">아이디</th>
                  <th className="text-center">선택</th>
                </tr>
              </thead>
              <TableBody>
                {/* {(rowsPerPage > 0
                  ? listData.dataList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : listData.dataList
                ).map((row, idx) => { */}
                {highListData.dataList.map((row, idx) => {
                  // ! 번호
                  const rowNum =
                    highListData.totalCount - 10 * (page - 1) - idx;

                  return (
                    <TableRow tabIndex={-1} key={idx}>
                      <TableCell align="center">{rowNum}</TableCell>
                      <TableCell align="left">{row.path}</TableCell>
                      <TableCell align="center">
                        {row.franchise.user.agentName}
                      </TableCell>
                      {/* <TableCell align="center">
                        {row.franchise.user.ceoName}
                      </TableCell> */}
                      <TableCell align="center">
                        {row.franchise.user.managerContact}
                      </TableCell>
                      <TableCell align="center">
                        {row.franchise.user.managerName}
                      </TableCell>
                      <TableCell align="center">
                        {row.franchise.user.managerContact}
                      </TableCell>
                      <TableCell align="center">
                        {row.franchise.user.userId}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          color="primary"
                          // name={`${row.id}#${row.franchise.user.agentName}`}
                          onClick={() => handleFranchiseSelect(row)}
                        >
                          선택
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 60 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter></TableFooter>
            </table>
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={10}
            totalItemsCount={highListData.totalCount || 0}
            pageRangeDisplayed={5}
            prevPageText={"‹"}
            nextPageText={"›"}
            firstPageText={"‹‹"}
            lastPageText={"››"}
            onChange={(event) => {
              handleChangePage(event);
            }}
            style={{ display: "flex", justifyContent: "center" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="info">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SearchFranchiseModal;
