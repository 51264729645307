import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
} from "@material-ui/core";

const EnhancedTableHead = ({ classes, order, orderBy, handleRequestSort,userAuth }) => {
  const headCells = [
    { id: "num", numeric: true, label: "번호" },
    { id: "tid", numeric: true, label: "거래일자" },
    { id: "payment", numeric: true, label: "결제" },
    {
      id: "franchiseName",
      numeric: false,
      label: "가맹점명",
    },
    {
      id: "terminal",
      numeric: false,
      label: "터미널",
    },
    {
      id: "installment",
      numeric: true,
      label: "할부",
    },
    {
      id: "cardNo",
      numeric: true,
      label: "카드번호",
    },
    {
      id: "acceptNo",
      numeric: true,
      label: "승인번호",
    },
    {
      id: "acceptAmount",
      numeric: true,
      label: "승인금액",
    },
    {
      id: "cancelAmount",
      numeric: true,
      label: "취소금액",
    },
    {
      id: "dealAmount",
      numeric: true,
      label: "거래금액",
    },
    {
      id: "productName",
      numeric: true,
      label: "상품명",
    },
    {
      id: "buyer",
      numeric: true,
      label: "고객명",
    },
    {
      id: "buyerNum",
      numeric: true,
      label: "연락처",
    },

    {
      id: "resultMsg",
      numeric: false,
      label: "결과메세지",
    },

    {
      id: "cancelDate",
      numeric: true,
      label: "취소일자(원거래일자)",
    },
    {
      id: "cancelbutton",
      numeric: false,
      label: "취소",
    },
  ];

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  if(userAuth!=="SA"){
    headCells.pop()
  }
  return (
    <TableHead className="thead-light">
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            sortDirection={orderBy === headCell.id ? order : false}
            className="pt-1"
            sx={{}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon
              sx={{}}
            >
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
