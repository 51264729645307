import React from "react";
import { Card, Divider, Grid, TextField } from "@material-ui/core";
import { bizNoPattern } from "utils/validationCheckFunction";
import { socNoPattern } from "utils/validationCheckFunction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommonTextFieldFullwidth from "components/commonInput/CommonTextField";
import { Box, Button } from "../../../../node_modules/@material-ui/core/index";

const BusinessNumInput = ({
  noCompany,
  createValue,
  handleChange,
  error,
  helperText,
  clickChange
}) => {
  const customStyle = {
    width: "97.5%",
  };
  const tempCompanyName = sessionStorage.getItem("companyName");
  const tempCompanyNum = sessionStorage.getItem("companyNum");
  const propsClickChange = (e) => {
    if (e === 'companyName')
      clickChange({ value: tempCompanyName, name: 'companyName' })
    else
      clickChange({ value: tempCompanyNum, name: 'companyNum' })
  }
  return (
    <Card className="p-4 mb-4">
      <div className="font-size-lg font-weight-bold">사업자 정보</div>
      <Divider className="my-4" />
      <div>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={noCompany}
              field="companyName"
              meaning={`회사명 ${noCompany}`}
              required={true}
              style={customStyle}
            />
          </Grid>
          {tempCompanyName ? <Grid xs={4} style={{ padding: '5px 0 0 10px' }}>
            <Button
              variant="contained"
              color="primary"
              className="m-2"
              size="small"
              sx={{ whiteSpace: "nowrap", minWidth: "auto" }}
              onClick={() => propsClickChange('companyName')}
            >
              최근값 입력
            </Button>
          </Grid> : undefined}
          <Grid item xs={12} lg={12}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={true}
              field="companyContact"
              meaning="회사전화번호"
              required={true}
              placeholder="회사(사업장) 전화번호"
            // style={{ width: "97.5%" }}
            />
          </Grid>


          <Grid item xs={12} lg={6}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              className="m-2"
              id="companyNum"
              name="companyNum"
              variant="outlined"
              focused
              onChange={handleChange}
              label={
                <span>
                  사업자번호 &nbsp;
                  <FontAwesomeIcon
                    icon="fa-solid fa-asterisk"
                    size="xs"
                    color="red"
                  />
                </span>
              }
              placeholder="사업자번호"
              value={
                createValue.companyNum?.length === 10
                  ? bizNoPattern(createValue.companyNum)
                  : createValue.companyNum
              }
              error={error?.companyNum}
              helperText={helperText?.companyNum}
            />
          </Grid>
          {tempCompanyNum ? <Grid xs={4} style={{ padding: '5px 0 0 10px' }}>
            <Button
              variant="contained"
              color="primary"
              className="m-2"
              size="small"
              sx={{ whiteSpace: "nowrap", minWidth: "auto" }}
              onClick={() => propsClickChange('companyNum')}
            >
              최근값 입력
            </Button>
          </Grid> : undefined}
          <Grid item xs={12} lg={6}>
            {createValue.companyType == 3 && (
              <TextField
                style={{ width: "100%" }}
                className="m-2"
                size="small"
                id="corporationNumber"
                name="corporationNumber"
                variant="outlined"
                label={
                  <span>
                    법인번호 &nbsp;
                    <FontAwesomeIcon
                      icon="fa-solid fa-asterisk"
                      size="xs"
                      color="red"
                    />
                  </span>
                }
                placeholder="법인번호"
                value={
                  createValue.corporationNumber?.length === 13
                    ? socNoPattern(createValue.corporationNumber)
                    : createValue.corporationNumber
                }
                onChange={handleChange}
                error={error?.corporationNumber}
                helperText={helperText?.corporationNumber}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={12}>
            <div className="mr-2">
              <CommonTextFieldFullwidth
                handleChange={handleChange}
                createValue={createValue}
                helperText={helperText}
                error={error}
                field="companyBusiness"
                meaning="업태"
                required={false}
              />
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field="companySector"
              meaning="종목"
              required={false}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={noCompany}
              field="companyAddress"
              meaning="사업자주소"
              required={true}
            />
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default BusinessNumInput;
