import { Link } from "react-router-dom";
import { Paper } from "../../../node_modules/@material-ui/core/index";
import "./sidebars.css";
import clsx from "clsx";
import SidebarHeader from "./SidebarHeader";
import { FontAwesomeIcon } from "../../../node_modules/@fortawesome/react-fontawesome/index";
import { faBook } from "../../../node_modules/@fortawesome/free-solid-svg-icons/index";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
import { faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import { faShop } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faTabletScreenButton } from "@fortawesome/free-solid-svg-icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ConstructionOutlined } from "../../../node_modules/@mui/icons-material/index";
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../../node_modules/axios/index";
import { useRecoilState } from "recoil";
import { listLinkStateAtom } from "recoilStates/searchRecoil";
import {IpFlagFn} from "utils/ipFlag";

const menus = [
  {
    seq: "0200",
    name: "결제내역 관리",
    role: "parent",
    icon: faCreditCard,
    id: "payment",
    // auth :
    children: [
      {
        seq: "0210",
        name: "결제내역",
        role: "child",
        link: "payment/list",
      },
      {
        seq: "0220",
        name: "차감내역",
        role: "child",
        link: "payment/deduct",
      },
      {
        seq: "0230",
        name: "보류내역",
        role: "child",
        link: "payment/pending",
      },
      {
        seq: "0240",
        name: "간편결제내역(앱/웹)",
        role: "child",
        link: "payment/easy",
      },
      {
        seq: "0250",
        name: "간편결제 URL 내역",
        role: "child",
        link: "payment/easy-url",
      },
      {
        seq: "0260",
        name: "미등록 단말기 결제내역",
        role: "child",
        link: "payment/unregistered",
      },
    ],
  },
  {
    seq: "0300",
    name: "정산 관리",
    role: "parent",
    icon: faBook,
    id: "settlement",
    // auth :
    children: [
      {
        seq: "0310",
        name: "가맹점 정산",
        role: "child",
        link: "settlement/franchise/list",
      },
      {
        seq: "0320",
        name: "가맹점 정산금액 조회(기간별)",
        role: "child",
        link: "settlement/franchise/term/list",
      },
      {
        seq: "0330",
        name: "가맹점 정산금액 조회(일자별)",
        role: "child",
        link: "settlement/franchise/date/list",
      },
      {
        seq: "0340",
        name: "가맹점 정산차감 조회(통신비 등)",
        role: "child",
        link: "settlement/franchise/etc/list",
      },
      {
        seq: "0350",
        name: "가맹점 매출금액 조회",
        role: "child",
        link: "settlement/franchise/sales/list",
      },
      {
        seq: "0360",
        name: "업체 정산금액 조회",
        role: "child",
        link: "settlement/company/list",
      },
    ],
  },
  {
    seq: "0400",
    name: "정산금 입출금 관리",
    role: "parent",
    icon: faMoneyBillTransfer,
    id: "point",
    // auth :
    children: [
      {
        seq: "0410",
        name: "가맹점 정산금 입출금내역 조회",
        role: "child",
        link: "point/list",
      },
      {
        seq: "0420",
        name: "머천트 출금",
        role: "child",
        link: "point/merchant",
      },
    ],
  },
  {
    seq: "0500",
    name: "업체 관리",
    role: "parent",
    icon: faBuilding,
    id: "company",
    // auth :
    children: [
      {
        seq: "0510",
        name: "업체 조회",
        role: "child",
        link: "company/list",
      },
      {
        seq: "0520",
        name: "업체 등록",
        role: "child",
        link: "company/create",
      },
      {
        seq: "0530",
        name: "업체 수수료율 관리",
        role: "child",
        link: "company/commission",
      },
    ],
  },
  {
    seq: "0600",
    name: "가맹점 관리",
    role: "parent",
    icon: faShop,
    id: "franchise",
    // auth :
    children: [
      {
        seq: "0610",
        name: "가맹점 조회",
        role: "child",
        link: "franchise/list",
      },
      {
        seq: "0620",
        name: "가맹점 등록",
        role: "child",
        link: "franchise/create",
      },
    ],
  },
  {
    seq: "0700",
    name: "단말기 관리",
    role: "parent",
    icon: faTabletScreenButton,
    id: "terminal",
    // auth :
    children: [
      {
        seq: "0710",
        name: "단말기 조회",
        role: "child",
        link: "terminal/list",
      },
      {
        seq: "0720",
        name: "단말기 등록",
        role: "child",
        link: "terminal/create",
      },
    ],
  },
  {
    seq: "0800",
    name: "공지사항",
    role: "parent",
    icon: faBell,
    id: "notice",
    // auth :
    children: [
      {
        seq: "0810",
        name: "공지사항 조회",
        role: "child",
        link: "notice/list",
      },
      {
        seq: "0820",
        name: "공지사항 등록",
        role: "child",
        link: "notice/create",
      },
    ],
  },
  {
    seq: "0900",
    name: "간편결제 관리",
    role: "parent",
    icon: faCommentsDollar,
    id: "easypay",
    // auth :
    children: [
      {
        seq: "0810",
        name: "간편결제",
        role: "child",
        link: "easy-pay",
      },
    ],
  },
];

const SidebarWithAuth = () => {
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);
  const [IP, setIP] = useState("");
  const getIP = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };
  useEffect(() => getIP(), []);
  const [ipFlag, setipFlag] = useState(false);
  useEffect(() => {
    setipFlag(IpFlagFn(IP));
  }, [IP]);
  const setLinkFn = () => {
    setListLinkState("");
  };
  return (
    <>
      <SidebarHeader />
      <Paper
        elevation={5}
        style={{
          transition: "all .5s cubic-bezier(.685,.0473,.346,1)",
        }}
        className={clsx({
          "app-sidebar-menu": true,
          "app-sidebar-collapsed": true,
        })}
      >
        <PerfectScrollbar>
          <div
            className="flex-shrink-0 p-3 bg-white"
            style={{
              minWidth: "270px",
              minHeight: "100%",
            }}
          >
            <ul className="list-unstyled ps-0">
              {menus.map((parentMenu) => {
                return (
                  auth[parentMenu.seq] === "Y" && (
                    <li className="mb-1" key={parentMenu.seq}>
                      <button
                        className="btn btn-toggle align-items-center rounded collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target={"#" + parentMenu.id}
                        aria-expanded="true"
                        style={{ width: "100%", fontSize: "13.5px" }}
                      >
                        <FontAwesomeIcon icon={parentMenu.icon} />
                        &nbsp; {parentMenu.name}
                      </button>
                      <div className="collapse show" id={parentMenu.id}>
                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                          {parentMenu.children.map((child) => {
                            // let viewFlag = false;
                            // if (auth[child.seq] === "Y") {
                            //   if (
                            //     child.seq === "0520" ||
                            //     child.seq === "0620" ||
                            //     child.seq === "0720"
                            //   ) {
                            //     if (ipFlag) {
                            //       viewFlag = true;
                            //     }
                            //   } else viewFlag = true;
                            // }
                            return (
                              auth[child.seq] === "Y" && (
                                <li key={child.seq}>
                                  <Link
                                    style={{ width: "100%", fontSize: "12px" }}
                                    to={child.link}
                                    className="link-dark rounded"
                                    onClick={setLinkFn}
                                  >
                                    {child.name}
                                  </Link>
                                </li>
                              )
                            );
                          })}
                        </ul>
                      </div>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        </PerfectScrollbar>
      </Paper>
    </>
  );
};

/* global bootstrap: false */
(function () {
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  tooltipTriggerList.forEach((tooltipTriggerEl) => {
    new bootstrap.Tooltip(tooltipTriggerEl);
  });
})();

export default SidebarWithAuth;
