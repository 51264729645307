import { useState, useEffect } from "react";
import { formatToWon, listToDate, now } from "utils/formatFunction";
import { getAxios } from "hook/axiosInstance";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TableRow,
} from "../../../../node_modules/@material-ui/core/index";

const DeductDepositModal = ({
  modalOpen,
  handleModalClose,
  scroll,
  selectData,
}) => {
  const [pickDate, setPickDate] = useState(now);
  const [deductMsg, setDeductMsg] = useState([]);
  const [sumData, setSumData] = useState(0);

  const handleDeductClick = () => {
    console.log("누름");
  };

  const handleModalOpen = async (row) => {
    const temp = await getAxios(`payment/deduct/deposit/${row.id}`);
    const dataList = temp.data.data;
    if (dataList) {
      setDeductMsg(dataList.dataList);
      setSumData(dataList.depositDetailSum);
    }
  };

  useEffect(() => {
    if (modalOpen) {
      handleModalOpen(selectData);
    }
  }, [modalOpen, selectData]);
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { height: 435 } }}
      open={modalOpen}
      onClose={handleModalClose}
      scroll="body"
      fullwidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      maxHeight="lg"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          <h2 className="font-weight-bold font-size-xl">입금내역 조회</h2>
        </DialogTitle>
      </div>
      <DialogContent dividers={scroll === "paper"}>
        {/* <DialogContentText
          id="scroll-dialog-description"
          // ref={descriptionElementRef}
          tabIndex={-1}
        >
         <TypeaheadSearch /> 
        </DialogContentText> */}

        <div className="table-responsive">
          <table className="text-nowrap mb-0 table table-striped">
            <thead className="thead-light">
              <tr>
                <th className="text-center">가맹점명</th>
                <th className="text-center">입금일자</th>
                <th className="text-center" style={{ width: "40%" }}>
                  입금내역
                </th>
                <th className="text-center" style={{ width: "20%" }}>
                  입금금액(원)
                </th>
              </tr>
            </thead>
            <TableBody>
              {deductMsg
                ? deductMsg.map((obj, i) => {
                    return (
                      <TableRow tabIndex={-1} key={i}>
                        <TableCell align="center" style={{ width: "40%" }}>
                          {obj.franchiseName}
                        </TableCell>
                        <TableCell align="center">
                          {obj.deductDepositTime}
                        </TableCell>
                        <TableCell align="center" style={{ width: "40%" }}>
                          {obj.deductMsg}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ paddingRight: "50px" }}
                        >
                          {formatToWon(obj.deductAmount)}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
              <TableRow className="table-dark">
                <TableCell colSpan={2}></TableCell>
                <TableCell align="right">합계</TableCell>
                <TableCell align="right" style={{ paddingRight: "50px" }}>
                  {formatToWon(sumData)}
                </TableCell>
              </TableRow>
            </TableBody>
          </table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} color="primary">
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeductDepositModal;
