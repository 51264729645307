import {
  onlyInputNumber,
  onlyInputEngAndNumber,
  removeAllSpaces,
  terminalCodeInput,
} from "./validationCheckFunction";
export const validateInputs = ({
  event,
  error,
  setError,
  helperText,
  setHelperText,
}) => {
  if (event.target.name === "userId") {
    event.target.value = onlyInputEngAndNumber(event.target.value);
  }

  // ! 예금주명 띄어쓰기제거
  if (event.target.name === "accountHolder") {
    event.target.value = removeAllSpaces(event.target.value);
  }

  // ! 숫자만 인 경우
  if (
    event.target.name === "ceoId" ||
    event.target.name === "managerContact" ||
    event.target.name === "accountNumber" ||
    event.target.name === "companyContact" ||
    event.target.name === "companyNum" ||
    event.target.name === "corporationNumber" ||
    event.target.name === "commissionRate" ||
    event.target.name === "paymentLimitMonth" ||
    event.target.name === "paymentLimitForeach" ||
    event.target.name === "point" ||
    event.target.name === "paymentLimitDay"
  ) {
    if (isNaN(event.target.value)) {
      event.target.value = onlyInputNumber(event.target.value);
      // return alert("숫자만 입력해주세요");
      setError({ ...error, [event.target.name]: true });
      setHelperText({
        ...helperText,
        [event.target.name]: "숫자만 입력해주세요",
      });
    } else {
      setError((prev) => {
        const copy = { ...prev };
        delete copy[[event.target.name]];
        return copy;
      });
      setHelperText({ ...helperText, [event.target.name]: "" });
    }
  }

  //  ! 주민번호 / 사업자번호
  if (
    event.target.name === "ceoId" ||
    event.target.name === "corporationNumber"
  ) {
    event.target.value = event.target.value.split("-").join("");
    if (event.target.value.length > 13) {
      event.target.value = event.target.value.substr(0, 12);
      // return alert("주민등록번호 확인 해주세요");
      setError({
        ...error,
        [event.target.name]: true,
      });
      setHelperText({
        ...helperText,
        [event.target.name]: "주민등록번호 확인 해주세요",
      });
    }
  }

  //  ! 휴대폰번호
  if (event.target.name === "managerContact") {
    event.target.value = event.target.value.split("-").join("");
    if (event.target.value.length > 11) {
      event.target.value = event.target.value.substr(0, 11);
      // return alert("전화번호 확인 해주세요");
      setError({ ...error, [event.target.name]: "전화번호 확인 해주세요" });
    }
  }

  if (event.target.name === "companyNum") {
    event.target.value = event.target.value.split("-").join("");
    if (event.target.value.length > 10) {
      event.target.value = event.target.value.substr(0, 10);
      // return alert("사업자번호 확인 해주세요");
      setError({ ...error, [event.target.name]: "사업자번호 확인 해주세요" });
    }
  }
  // ! terminalCode, tid 는 숫자, 12자리
  if (event.target.name === "tid") {
    if (isNaN(event.target.value)) {
      event.target.value = onlyInputNumber(event.target.value);
      setError({
        ...error,
        [event.target.name]: "숫자 12자리 이하로 입력해주세요",
      });
      setHelperText({
        ...helperText,
        [event.target.name]: "숫자 12자리 이하로 입력해주세요",
      });
    } else {
      setError((prev) => {
        const copy = { ...prev };
        delete copy[[event.target.name]];
        return copy;
      });
      setHelperText({ ...helperText, [event.target.name]: "" });
    }

    if (event.target.value.length > 12) {
      event.target.value = event.target.value.substr(0, 12);
      setError({
        ...error,
        [event.target.name]: "숫자만 입력해주세요",
      });
      setHelperText({
        ...helperText,
        [event.target.name]: "숫자만 입력해주세요",
      });
    }
  }
  if (event.target.name === "terminalCode") {
    // if (isNaNS(event.target.value)) {
    //   event.target.value = onlyInputEngAndNumber(event.target.value);
    //   // event.target.value = terminalCodeInput(event.target.value);
    //   setError({
    //     ...error,
    //     [event.target.name]: "영문, 숫자포함 20자리 이하로 입력해주세요",
    //   });
    //   setHelperText({
    //     ...helperText,
    //     [event.target.name]: "영문, 숫자포함 20자리 이하로 입력해주세요",
    //   });
    // } else {
    event.target.value = onlyInputEngAndNumber(event.target.value);

    setError((prev) => {
      const copy = { ...prev };
      delete copy[[event.target.name]];
      return copy;
    });
    setHelperText({ ...helperText, [event.target.name]: "" });
  }

  if (event.target.value.length > 60) {
    event.target.value = event.target.value.substr(0, 60);
    setError({
      ...error,
      [event.target.name]: "영문, 숫자포함 60자리 이하로 입력해주세요",
    });
    setHelperText({
      ...helperText,
      [event.target.name]: "영문, 숫자포함 60자리 이하로 입력해주세요",
    });
  }
  // }
};

// ! 필드 이름 리스트 - 가맹점
export const franchiseFields = (companyType) => {
  let franchiseCompanyTypeOne = {
    highCompanyCode: "0",
    highCompanyName: "",
    highCompanyPath: "",
    // franchiseNickname: "",
    // ceoId: "",
    settlementCycle: "0",
    paymentCancelTodayYn: "N",
    settlementPendingYn: "N",
    installmentPending: "0",
    paymentLimitForeach: "3000000",
    paymentLimitMonth: "5000000",
    paymentLimitDay: "3000000",
    paymentEasyYn: "N",
    authYn: "1",
    paymentEasyLimitPeriod: "0",
    userId: "",
    agentName: "",
    // agentNickname: "",
    companyType: "1",
    ceoName: "",
    managerName: "",
    managerContact: "",
    // managerEmail: "",
    // companyName: "",
    // companyContact: "",
    // companyNum: "",
    // corporationNumber: "",
    // companyBusiness: "",
    // companySector: "",
    // companyAddress: "",
    loginAllowCheck: "N",
    contractStatus: "1",
    commissionRate: "",
    bankName: "KB국민은행",
    accountHolder: "",
    accountNumber: "",
    pgCompanyId: "1",
    // mid: "1",
    // noCompanyValue: "비사업자",
  };
  let franchiseCompanyTypeTwo = {
    highCompanyCode: "0",
    highCompanyName: "",
    highCompanyPath: "",
    // franchiseNickname: "",
    // ceoId: "",
    settlementCycle: "0",
    paymentCancelTodayYn: "N",
    settlementPendingYn: "N",
    installmentPending: "0",
    paymentLimitForeach: "3000000",
    paymentLimitMonth: "5000000",
    paymentLimitDay: "3000000",
    paymentEasyYn: "N",
    authYn: "1",
    paymentEasyLimitPeriod: "0",
    userId: "",
    agentName: "",
    // agentNickname: "",
    companyType: "1",
    ceoName: "",
    managerName: "",
    managerContact: "",
    // managerEmail: "",
    companyName: "",
    companyContact: "",
    companyNum: "",
    // corporationNumber: "",
    // companyBusiness: "",
    // companySector: "",
    companyAddress: "",
    loginAllowCheck: "N",
    contractStatus: "1",
    commissionRate: "",
    bankName: "KB국민은행",
    accountHolder: "",
    accountNumber: "",
    pgCompanyId: "1",
  };
  let franchiseCompanyTypeThree = {
    highCompanyCode: "0",
    highCompanyName: "",
    highCompanyPath: "",
    // franchiseNickname: "",
    // ceoId: "",
    settlementCycle: "0",
    paymentCancelTodayYn: "N",
    settlementPendingYn: "N",
    installmentPending: "0",
    paymentLimitForeach: "3000000",
    paymentLimitMonth: "5000000",
    paymentLimitDay: "3000000",
    paymentEasyYn: "N",
    authYn: "1",
    paymentEasyLimitPeriod: "0",
    userId: "",
    agentName: "",
    // agentNickname: "",
    companyType: "1",
    ceoName: "",
    managerName: "",
    managerContact: "",
    // managerEmail: "",
    companyName: "",
    companyContact: "",
    companyNum: "",
    // corporationNumber: "",
    // companyBusiness: "",
    // companySector: "",
    companyAddress: "",
    loginAllowCheck: "N",
    contractStatus: "1",
    commissionRate: "",
    bankName: "KB국민은행",
    accountHolder: "",
    accountNumber: "",
    pgCompanyId: "1",
  };
  let franchiseCompanyTypeSC = {
    highCompanyCode: "0",
    highCompanyName: "",
    highCompanyPath: "",
    userId: "",
    agentName: "",
    ceoName: "",
    managerName: "",
    managerContact: "",
    // franchiseNickname: "",
    // ceoId: "",
  };
  const userAuth = sessionStorage.getItem("userAuth");
  if (userAuth === "SC") {
    return franchiseCompanyTypeSC;
  } else {
    if (companyType == 1) {
      return franchiseCompanyTypeOne;
    } else if (companyType == 2) {
      return franchiseCompanyTypeTwo;
    } else {
      return franchiseCompanyTypeThree;
    }
  }
};

// ! 널체크 - 가맹점
export const checkoutFranchiseNullInputData = ({
  createValue,
  setError,
  setHelperText,
}) => {
  let errorFields = {};
  let helperTextFields = {};

  Object.keys(franchiseFields(createValue.companyType)).map((field) => {
    if (!createValue[field]) {
      errorFields[field] = true;
      helperTextFields[field] = "필수 입력값입니다.";
    }
  });
  setError(errorFields);
  setHelperText(helperTextFields);
  if (Object.keys(errorFields).length > 0) {
    return false;
  } else {
    return true;
  }
};

// ! 필드 이름 리스트 - 업체
export const companyFields = (companyType) => {
  if (companyType == 1) {
    let franchiseCompanyTypeOne = {
      // ceoId: "",
      userId: "",
      agentName: "",
      agentNickname: "",
      companyType: "1",
      ceoName: "",
      managerName: "",
      managerContact: "",
      // companyName: "",
      // companyContact: "",
      // companyNum: "",
      // corporationNumber: "",
      // companyBusiness: "",
      // companySector: "",
      // companyAddress: "",
      loginAllowCheck: "N",
      contractStatus: "1",
      bankName: "카카오뱅크",
      accountHolder: "",
      accountNumber: "",
      highCompanyCode: "",
      // highCompanyName:"",
      // highCompanyPath:"",
    };
    return franchiseCompanyTypeOne;
  } else if (companyType == 2) {
    let franchiseCompanyTypeTwo = {
      userId: "",
      agentName: "",
      agentNickname: "",
      companyType: "1",
      ceoName: "",
      managerName: "",
      managerContact: "",
      companyName: "",
      companyContact: "",
      companyNum: "",
      // corporationNumber: "",
      companyBusiness: "",
      companySector: "",
      companyAddress: "",
      loginAllowCheck: "N",
      contractStatus: "1",
      bankName: "카카오뱅크",
      accountHolder: "",
      accountNumber: "",
      highCompanyCode: "",
      // highCompanyName:"",
      // highCompanyPath:"",
    };
    return franchiseCompanyTypeTwo;
  } else {
    let franchiseCompanyTypeThree = {
      userId: "",
      agentName: "",
      agentNickname: "",
      companyType: "1",
      ceoName: "",
      managerName: "",
      managerContact: "",
      companyName: "",
      companyContact: "",
      companyNum: "",
      corporationNumber: "",
      companyBusiness: "",
      companySector: "",
      companyAddress: "",
      loginAllowCheck: "N",
      contractStatus: "1",
      bankName: "카카오뱅크",
      accountHolder: "",
      accountNumber: "",
      highCompanyCode: "",
      // highCompanyName:"",
      // highCompanyPath:"",
    };
    return franchiseCompanyTypeThree;
  }
};

// ! 널체크 - 업체등록
export const checkoutCompanyNullInputData = ({
  createValue,
  setError,
  setHelperText,
  update = false,
}) => {
  let errorFields = {};
  let helperTextFields = {};
  Object.keys(companyFields(createValue.companyType)).map((field) => {
    if (!createValue[field]) {
      if (!update) {
        if (field == "highCompanyCode") {
          alert("상위 업체 선택해주세요");
        }
      }

      errorFields[field] = true;
      helperTextFields[field] = "필수 입력값입니다.";
    }
  });
  setError(errorFields);
  setHelperText(helperTextFields);
  if (Object.keys(errorFields).length > 0) {
    // alert("오류!");
    return false;
  } else {
    return true;
  }
};

export const terminalFields = {
  tid: "",
  terminalCode: "",
  franchiseCode: "",
  terminalType: "N",
  // terminalStatus: "N",
};

// ! 널체크 - 단말기
export const checkoutTerminalNullInputData = ({
  createValue,
  setError,
  setHelperText,
}) => {
  let errorFields = {};
  let helperTextFields = {};
  Object.keys(terminalFields).map((field) => {
    if (!createValue[field]) {
      errorFields[field] = true;
      helperTextFields[field] = "필수 입력값입니다.";
    } else {
      // yesFields.push(field);
    }
  });
  setError(errorFields);
  setHelperText(helperTextFields);
  if (Object.keys(errorFields).length > 0) {
    alert("필수 입력값을 모두 입력해주세요");
    return false;
  } else {
    return true;
  }
};
