import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { deleteAxios, putAxios } from "hook/axiosInstance";
import { Box } from "../../../../node_modules/@material-ui/core/index";
import CompanyInput from "../companyCreate/CompanyInput";

const CompanyDetailModal = ({
  modalOpen,
  setModalOpen,
  scroll,
  selectDetail,
}) => {
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleUpdate = async (updateValue) => {
    try {
      const posted = await putAxios(
        // `franchise/detail/update/modify/${franchiseId}`,
        `company/detail/update/modify`,
        updateValue
      );
      if (posted.status === 200) {
        alert("업체 수정완료");
        window.location.reload();
      }
    } catch (err) {
      if (err.response.data.status === 500) {
        alert("500");
        // setCreateValue({ ...TerminalFields });
        return;
      }
      if (err.response.data.status === 509) {
        alert("509");
        // setCreateValue({ ...TerminalFields });
        return;
      }
    }
    return;
  };

  // ! 삭제 - 전송로직

  const handleDelete = () => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      deleteApi();
    }
  };

  const deleteApi = async () => {
    const resDelete = await deleteAxios(
      `company/detail/delete/${selectDetail.company.id}`
    );
    if (resDelete.data.header.result_code === "200") {
      alert("삭제가 완료되었습니다.");
      handleCloseModal();
      window.location.reload();
    } else {
      alert("삭제에 실패했습니다");
    }
  };

  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="xl"
        // PaperProps={{
        //   sx: {
        //     width: "50%",
        //     height: "80vh",
        //   },
        // }}
      >
        <DialogTitle id="scroll-dialog-title">
          {selectDetail?.company?.user?.agentName} <Box height="5px" />
          정보수정
        </DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          style={{ backgroundColor: "#f9faff" }}
        >
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
          ></DialogContentText>
          <CompanyInput
            selectDetail={selectDetail}
            handleUpdate={handleUpdate}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button
            onClick={activateUpdateButton}
            color="secondary"
            variant="outlined"
          >
            수정
          </Button> */}
          {/* <Button onClick={handleDelete} color="error" variant="contained">
            삭제
          </Button> */}
          <Button
            onClick={handleCloseModal}
            color="secondary"
            variant="outlined"
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default CompanyDetailModal;
