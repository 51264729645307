import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Divider,
  FilledInput,
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { FormLabel, Grid } from "@mui/material";

import { getAxios } from "hook/axiosInstance";

import React, { useEffect, useState } from "react";
import {
  Box,
  MenuItem,
} from "../../../../node_modules/@material-ui/core/index";

const BasicInput = ({
  classes,
  createValue,
  setCreateValue,
  handleChange,
  handleModalOpen,
  error,
  helperText,
  setError,
  setHelperText,
  setIsDuplicateChecked,
  handleCheck,
}) => {
  //
  // const pgList = [
  //   {
  //     value: "1",
  //     label: "다우",
  //   },
  //   {
  //     value: "2",
  //     label: "웰컴",
  //   },
  //   {
  //     value: "3",
  //     label: "미분류",
  //   },
  // ];

  // ! 중복체크
  // const [isDuplicateChecked, setIsDuplicateChecked] = useState(false);
  const [duplicateText, setDuplicateText] = useState("");
  const checkIdDuplicate = async () => {
    const tid = createValue.tid;
    try {
      const resDuplicate = await getAxios(`terminal/check/tid/${tid}`);
      if (resDuplicate.status === 200) {
        alert("사용 가능한 코드입니다.");
        setDuplicateText("사용 가능한 코드입니다.");
        setIsDuplicateChecked(true);
      } else {
        setHelperText({ ...helperText, [tid]: "중복체크 해주세요" });
        setError({ ...error, [tid]: true });
        setIsDuplicateChecked(false);
      }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        alert("사용중인 코드입니다.");
        setDuplicateText("사용 불가능한 코드입니다.");
        setIsDuplicateChecked(false);
      }
    }
  };

  // ! 토글 로직
  // const [checkedValue, setCheckedValue] = useState({
  //   // terminalStatus: "N",
  // });
  // const handleCheck = (e) => {
  //   // setCheckedValue({
  //   //   ...checkedValue,
  //   //   [e.target.name]: !checkedValue[e.target.name],
  //   // });
  //   // if (checkedValue[e.target.name]) {
  //   //   e.target.value = "N";
  //   // } else {
  //   //   e.target.value = "Y";
  //   // }
  //   handleChange(e);
  // };

  return (
    <Card className='p-4 mb-4'>
      <div className='font-size-lg font-weight-bold'>기본 정보</div>
      <Divider className='my-4' />
      <div>
        <Grid container columnSpacing={2}>
          <Grid item xs={5.5}>
            <FormControl disabled className='m-2' variant='filled' fullWidth>
              <InputLabel htmlFor='franchiseCode'>
                가맹점코드 &nbsp;
                <FontAwesomeIcon
                  icon='fa-solid fa-asterisk'
                  size='xs'
                  color='red'
                />
                &nbsp; 검색버튼 클릭
              </InputLabel>
              <FilledInput
                id='franchiseCode'
                name='franchiseCode'
                value={createValue?.franchiseCode}
                onChange={handleChange}
                aria-describedby='filled-weight-helper-text'
                inputProps={{
                  "aria-label": "weight",
                }}
                fullWidth
                error={error?.franchiseCode}
                helperText={helperText?.franchiseCode}
              />
            </FormControl>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FormControl disabled className='m-2' variant='filled'>
                <InputLabel htmlFor='franchiseName'>
                  {createValue?.franchiseName ? null : "가맹점명"}
                </InputLabel>
                <FilledInput
                  id='franchiseName'
                  value={createValue?.franchiseName}
                  aria-describedby='filled-weight-helper-text'
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  error={error?.franchiseName}
                  helperText={helperText?.franchiseName}
                />
              </FormControl>
              <Button
                variant='contained'
                color='primary'
                className='m-2'
                onClick={handleModalOpen}
                style={{ minWidth: "auto", whiteSpace: "nowrap" }}
              >
                가맹점 검색
              </Button>
            </div>
          </Grid>
          <Grid item xs={6}>
            <FormControl size='medium'>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <TextField
                // size="small"
                className='m-2'
                id='terminalType'
                name='terminalType'
                variant='outlined'
                select
                value={createValue?.terminalType}
                onChange={handleChange}
                helperText={helperText?.terminalType}
                error={error?.terminalType}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "center",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      단말기 타입&nbsp;
                      <FontAwesomeIcon
                        icon='fa-solid fa-asterisk'
                        size='xs'
                        color='red'
                      />
                    </InputAdornment>
                  ),
                }}
                style={{
                  display: createValue?.terminalType === "Y" ? "none" : "",
                }}
              >
                {createType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={7.5}>
            <Box display='flex' alignItems='center'>
              <FormControl
                className={classes.margin}
                variant='outlined'
                fullWidth
              >
                <TextField
                  id='tid'
                  name='tid'
                  variant='outlined'
                  label={
                    <span position='start'>
                      단말기코드 (tid) &nbsp;
                      <FontAwesomeIcon
                        icon='fa-solid fa-asterisk'
                        size='xs'
                        color='red'
                      />
                    </span>
                  }
                  type={"text"}
                  placeholder='단말기(터미널) 코드'
                  value={createValue?.tid}
                  onChange={handleChange}
                  error={error?.tid}
                  helperText={helperText?.tid}
                  inputProps={{
                    maxLength: createValue?.terminalType === "T" ? 6 : 12,
                  }}
                  //비인증이 T 6글자 인증이 N 12글자
                  disabled={createValue?.terminalType === "Y"}
                />
                <span style={{ color: "blue" }}>{duplicateText}</span>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Button
              variant='contained'
              color='primary'
              className='m-2'
              onClick={checkIdDuplicate}
              sx={{ whiteSpace: "nowrap", minWidth: "auto" }}
            >
              단말기 중복체크
            </Button>
          </Grid>
          {/* < style={{ fontSize: "0.6em" }}>%% 비밀번호는 자동으로 생성(1234)됩니다. </style> 
          </div>*/}
          <Grid item xs={12}>
            <TextField
              className='m-2'
              id='terminalCode'
              name='terminalCode'
              variant='outlined'
              label={
                <span position='start'>
                  시리얼번호 &nbsp;
                  <FontAwesomeIcon
                    icon='fa-solid fa-asterisk'
                    size='xs'
                    color='red'
                  />
                </span>
              }
              placeholder='시리얼번호'
              onChange={handleChange}
              value={createValue?.terminalCode}
              helperText={helperText?.terminalCode}
              error={error?.terminalCode}
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              className='m-2'
              id='terminalFee'
              name='terminalFee'
              variant='outlined'
              label={
                <span position='start'>
                  단말기요금 &nbsp;
                  <FontAwesomeIcon
                    icon='fa-solid fa-asterisk'
                    size='xs'
                    color='red'
                  />
                </span>
              }
              placeholder='단말기요금'
              onChange={handleChange}
              value={createValue?.terminalFee}
              helperText={helperText?.terminalFee}
              error={error?.terminalFee}
              fullWidth
            />
          </Grid> */}
          {/* <Grid item xs={5.5}>
            <TextField
              sx={{ width: "1200px" }}
              className="m-2"
              id="pgCompanyId"
              name="pgCompanyId"
              variant="outlined"
              select
              fullWidth
              value={createValue?.pgCompanyId}
              onChange={handleChange}
              helperText={helperText?.pgCompanyId}
              error={error?.pgCompanyId}
              defaultValue="0"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    PG&nbsp;
                    <FontAwesomeIcon
                      icon="fa-solid fa-asterisk"
                      size="xs"
                      color="red"
                    />
                  </InputAdornment>
                ),
              }}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
            >
              {pgList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
          <Grid item xs={5.5}>
            <FormLabel className='m-2'>
              구분&nbsp;
              <FontAwesomeIcon
                icon='fa-solid fa-asterisk'
                size='xs'
                color='red'
              />
            </FormLabel>
            <FormControlLabel
              control={<Switch color='primary' checked={true} />}
              name='terminalType'
              id='terminalType'
              label={"단말기"}
              // onClick={handleCheck}
              helperText={helperText?.terminalType}
              error={error?.terminalType}
            />
            <h6 className='ml-2'>※ 간편결제 단말기는 자동으로 등록됩니다.</h6>
          </Grid>
          <Grid item xs={6}>
            <FormLabel className='m-2'>
              운영상태&nbsp;
              <FontAwesomeIcon
                icon='fa-solid fa-asterisk'
                size='xs'
                color='red'
              />
            </FormLabel>
            <FormControlLabel
              control={
                <Switch
                  color='primary'
                  checked={createValue?.terminalStatus === "Y" ? true : false}
                  onChange={handleCheck}
                />
              }
              name='terminalStatus'
              id='terminalStatus'
              label={createValue?.terminalStatus === "Y" ? "사용" : "해지"}
              helperText={helperText?.terminalStatus}
              error={error?.terminalStatus}
            />
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default BasicInput;

const createType = [
  { label: "비인증", value: "T" },
  { label: "인증", value: "N" },
];
//비인증이 T 6글자 인증이 N 12글자
