import NoData from "components/commonTable/NoData";
import { SearchComponent } from "components/commonTable/SearchComponent";
import SelectLimit from "components/commonTable/SelectLimit";
import { getAxios, postAxios } from "hook/axiosInstance";
import { useLayoutEffect } from "react";
import { useState } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  TableCell,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { Card, TextField, CardContent } from "@material-ui/core";
import CommissionHistoryModal from "./CommissionHistoryModal";
import PasswordModal from "./PasswordModal";
import { useEffect } from "react";
import excelIcon from "assets/custom/excelIcon.svg";
import exportToXlsx from "utils/excelExport";
import { Box } from "../../../../node_modules/@material-ui/core/index";
import axios from "../../../../node_modules/axios/index";
import { Search } from "components/commonTable/Search";
import { useRecoilState } from "recoil";
import { listLinkStateAtom } from "recoilStates/searchRecoil";
import { IpFlagFn } from "utils/ipFlag";

const CompanyCommission = () => {
  const [page, setPage] = useState(1);
  const [address, setAddress] = useState();
  const [limit, setLimit] = useState(15);
  const [selected, setSelected] = useState([]);
  // const [selectDetail, setSelectDetail] = useState("");
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const [loading, setLoading] = useState(false);

  // const [modalOpen, setModalOpen] = useState(false);

  // ! 로딩
  const [isLoading, setIsLoading] = useState(false);
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);

  const getListApi = async () => {
    setLoading(true);
    if (!loadFlag) setLoadFlag(true);
    if (listLinkState) {
      const tempAd = listLinkState;
      setAddress(tempAd);
      const resApiData = await getAxios(tempAd);
      if (resApiData.data.header.result_code === "200") {
        setLoading(false);
        setListData(resApiData.data.data);
      }
    }
  };

  useEffect(() => {
    if (listLinkState) {
      getListApi();
    }
  }, [page, limit]);

  useEffect(() => {
    if (listLinkState)
      if (!loadFlag) {
        getListApi();
      }
  }, [listLinkState, loading]);

  const handleChangePage = (e) => {
    setPage(e);
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/page=[^&]*/, `page=${e}`);
    setListLinkState(tempLink);
  };

  // ! GET 요청
  const [listData, setListData] = useState({ dataList: [] });
  const [loadFlag, setLoadFlag] = useState(false);
  const [keyword, setKeyword] = useState("");
  const checkFields = [
    { id: "all", name: "모두" },
    { id: "companyName", name: "업체명" },
    { id: "franchiseName", name: "가맹점명" },
    { id: "loginId", name: "업체ID" },
  ];

  const onChangeCommission = (event, companyName, idx, idx2) => {
    let updatedDataList = [...listData.dataList];
    idx2 += 1;
    if (idx2 == 1) {
      updatedDataList[idx] = {
        ...updatedDataList[idx],
        commissionRate1: event.target.value,
      };
    }
    if (idx2 == 2) {
      updatedDataList[idx] = {
        ...updatedDataList[idx],
        commissionRate2: event.target.value,
      };
    }
    if (idx2 == 3) {
      updatedDataList[idx] = {
        ...updatedDataList[idx],
        commissionRate3: event.target.value,
      };
    }
    if (idx2 == 4) {
      updatedDataList[idx] = {
        ...updatedDataList[idx],
        commissionRate4: event.target.value,
      };
    }
    if (idx2 == 5) {
      updatedDataList[idx] = {
        ...updatedDataList[idx],
        commissionRate5: event.target.value,
      };
    }
    if (idx2 == 6) {
      updatedDataList[idx] = {
        ...updatedDataList[idx],
        commissionRate6: event.target.value,
      };
    }
    if (idx2 == 7) {
      updatedDataList[idx] = {
        ...updatedDataList[idx],
        commissionRate7: event.target.value,
      };
    }
    if (idx2 == 8) {
      updatedDataList[idx] = {
        ...updatedDataList[idx],
        commissionRate8: event.target.value,
      };
    }
    if (idx2 == 9) {
      updatedDataList[idx] = {
        ...updatedDataList[idx],
        commissionRate9: event.target.value,
      };
    }
    if (idx2 == 10) {
      updatedDataList[idx] = {
        ...updatedDataList[idx],
        commissionRate10: event.target.value,
      };
    }
    setListData({ ...listData, dataList: updatedDataList });
  };

  const emptyRows =
    limit - Math.min(limit, listData.totalCount - (page - 1) * limit);

  // ! N개씩 보기

  // ! 검색 관련

  //  ! 페이징

  // ! 모달
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [selectData, setSelectData] = useState({});
  const handleOpenHistoryModal = (row) => {
    setSelectData(row);
    setOpenHistoryModal(true);
  };

  // ! 수정하기

  // const [commissionValues, setCommissionValues] = useState({});

  // const handleInputChange = (e) => {
  //   setCommissionValues({
  //     ...commissionValues,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleUpdate = async (idx) => {
    if (window.confirm("정말 수정하시겠습니까?")) {
      const historyRes = await postAxios(listLinkState, listData.dataList[idx]);
      if (historyRes.data.header.result_code == 200) {
        // getListApi();
        alert("수정되었습니다");
        // window.location.reload();
      } else {
        alert("서버에러 관리자에게 문의하세요");
      }
    }
  };

  // ! 테이블 헤더 -> 최대 column 찾기
  const [howLongArray, setHowLongArray] = useState([]);
  const [howLongNum, setHowLongNum] = useState([]);
  useEffect(() => {
    setHowLongNum(Math.max.apply(howLongArray));
  }, [howLongArray]);

  const [modalFlag, setModalFlag] = useState(false);
  const [exportFlag, setExportFlag] = useState(false);
  useEffect(() => {
    if (exportFlag) {
      exportToXlsx("업체 수수료율 조회_", address);
      setExportFlag(false);
      setModalFlag(false);
    }
  }, [exportFlag]);
  const userAuth = window.sessionStorage.getItem("userAuth");
  const [IP, setIP] = useState("");
  const getIP = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };
  const [ipFlag, setipFlag] = useState(false);
  useEffect(() => {
    if (userAuth === "SA") {
      setipFlag(IpFlagFn(IP));
    }
  }, [IP]);
  useEffect(() => getIP(), []);
  return (
    <>
      <PasswordModal
        postCodeFlag={modalFlag}
        handleClose={() => setModalFlag(false)}
        setExportFlag={() => setExportFlag(true)}
      />
      <Search
        pageFlag={true}
        link={"franchise/commission/history?"}
        checkList={["searchField"]}
        getListApi={getListApi}
        limit={true}
        searchField={checkFields}
        setPage={setPage}
      />
      <Box height="5px" />
      <Card className="card-box mb-4">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <small>업체 수수료율 관리</small>
            <b>업체 수수료율 조회</b>
          </div>
          <div className="card-header--actions">
            <Box display="flex" alignItems="center">
              <Button
                variant="contained"
                style={{ backgroundColor: "#f50057" }}
                onClick={() => setModalFlag(true)}
                // onClick={() => exportToXlsx("업체 수수료율 조회_", address)}
              >
                <img src={excelIcon} alt="EXCEL" style={{ width: "23px" }} />
                &nbsp; 내보내기
              </Button>
              <SelectLimit
                limit={limit}
                setLimit={setLimit}
                setPage={setPage}
              />
            </Box>
          </div>
        </div>
        <CardContent className="p-0">
          <div className="table-responsive">
            <table className="text-nowrap mb-0 table table-striped">
              <TableHead className="thead-light">
                <tr>
                  <th className="text-center">번호</th>
                  <th className="text-center">가맹점코드</th>
                  <th className="text-center">가맹점명</th>
                  <th className="text-center">가맹점</th>
                  {Array.from(howLongNum).map((i) => {
                    return <th className="text-center">본사</th>;
                  })}
                  <th className="text-center">총판</th>
                  <th className="text-center">지사</th>
                  <th className="text-center">대리점1</th>
                  <th className="text-center">대리점2</th>
                  <th className="text-center">대리점3</th>
                  <th className="text-center">대리점4</th>
                  <th className="text-center">대리점5</th>
                  <th className="text-center">대리점6</th>
                  <th className="text-center">대리점7</th>
                  {/* <th className="text-center">대리점3</th> */}
                  {ipFlag && <th className="text-center">수정하기</th>}
                  <th className="text-center">이력확인</th>
                </tr>
              </TableHead>
              {listData.dataList.length === 0 ? (
                <NoData
                  colNum={14}
                  comment="조회할 업체가 없습니다."
                  isLoading={isLoading}
                />
              ) : (
                <TableBody>
                  {listData.dataList.map((row, idx) => {
                    const isItemSelected = isSelected(row?.name);

                    // ! 번호
                    const rowNum =
                      listData.totalCount - limit * (page - 1) - idx;

                    // ! 키밸류
                    const companyObjects = [
                      {
                        companyName: row?.companyName1,
                        commissionRate: row?.commissionRate1,
                      },
                      {
                        companyName: row?.companyName2,
                        commissionRate: row?.commissionRate2,
                      },
                      {
                        companyName: row?.companyName3,
                        commissionRate: row?.commissionRate3,
                      },
                      {
                        companyName: row?.companyName4,
                        commissionRate: row?.commissionRate4,
                      },
                      {
                        companyName: row?.companyName5,
                        commissionRate: row?.commissionRate5,
                      },
                      {
                        companyName: row?.companyName6,
                        commissionRate: row?.commissionRate6,
                      },
                      {
                        companyName: row?.companyName7,
                        commissionRate: row?.commissionRate7,
                      },
                      {
                        companyName: row?.companyName8,
                        commissionRate: row?.commissionRate8,
                      },
                      {
                        companyName: row?.companyName9,
                        commissionRate: row?.commissionRate9,
                      },
                    ];

                    return (
                      <TableRow
                        hover
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={idx}
                        selected={isItemSelected}
                      >
                        <TableCell className="text-center">
                          <h6 className="mb-0">{rowNum}</h6>
                        </TableCell>
                        <TableCell className="pt-1 pb-1" align="center">
                          {/* // ! 가맹점코드 */}
                          <h6 className="mb-0">{row?.franchiseCode}</h6>
                        </TableCell>
                        <TableCell className="text-center">
                          {/* // ! 가맹점명 */}
                          <h6 className="mb-0">{row?.franchiseName}</h6>
                        </TableCell>

                        <TableCell className="pt-1 pb-1" align="center">
                          <TextField
                            // className="m-0"
                            disabled
                            variant="outlined"
                            value={row?.franchiseCommission}
                            style={{ width: "50px" }}
                            inputProps={{
                              style: {
                                padding: "2px 5px",
                              },
                            }}
                            // sx={{ backgroundColor: "grey" }}
                          />
                        </TableCell>
                        {companyObjects?.map((company, idx2) => {
                          if (company.companyName != null) {
                            return (
                              <TableCell className="pt-1 pb-1" align="right">
                                <div
                                  className="d-flex flex-row align-items-center"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <h6 style={{ paddingTop: "5px" }}>
                                    {company?.companyName || "업체 없음"}
                                  </h6>
                                  &nbsp;
                                  <TextField
                                    className="m-0"
                                    variant="outlined"
                                    size="small"
                                    style={{
                                      width: "100px",
                                      backgroundColor: "white",
                                    }}
                                    inputProps={{
                                      style: {
                                        padding: "2px 5px",
                                      },
                                    }}
                                    value={company?.commissionRate}
                                    onChange={(event) =>
                                      onChangeCommission(
                                        event,
                                        company,
                                        idx,
                                        idx2
                                      )
                                    }
                                  />
                                </div>
                              </TableCell>
                            );
                          }
                        })}
                        {ipFlag && (
                          <TableCell align="center" className="p-1">
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => handleUpdate(idx)}
                            >
                              수정하기
                            </Button>
                          </TableCell>
                        )}

                        <TableCell className="pt-1 pb-1" align="center">
                          <Button
                            variant="outlined"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              handleOpenHistoryModal(row);
                            }}
                          >
                            이력확인
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={20} />
                    </TableRow>
                  )}
                </TableBody>
              )}
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </table>
          </div>
          {/* <TablePagination
            rowsPerPageOptions={[10, 25, { label: "All", value: -1 }]}
            colSpan={3}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={listData.totalCount}
            pageRangeDisplayed={5}
            prevPageText={"‹"}
            nextPageText={"›"}
            firstPageText={"‹‹"}
            lastPageText={"››"}
            onChange={(event) => {
              handleChangePage(event);
            }}
            // onPageChange={handleChangePage}
            style={{ display: "flex", justifyContent: "center" }}
          />
        </CardContent>
      </Card>

      {openHistoryModal ? (
        <CommissionHistoryModal
          selectedRow={selectData}
          scroll="paper"
          modalOpen={openHistoryModal}
          setModalOpen={setOpenHistoryModal}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CompanyCommission;
