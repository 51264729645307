import clsx from "clsx";
import { Link } from "react-router-dom";
import { Box, IconButton } from "../../../node_modules/@material-ui/core/index";
import projectLogo from "../../assets/images/react.svg";

const HeaderLogo = ({ sidebarHover, sidebarOpen }) => {
  return (
    <>
      {/* <div
        className={clsx("app-header-logo", {
          "app-header-logo-close": sidebarOpen,
          "app-header-logo-open": sidebarHover,
        })}
      >
        <Box
          className="header-logo-wrapper"
          title="Carolina React Admin Dashboard with Material-UI PRO"
        >
          <Link to="/DashboardDefault" className="header-logo-wrapper-link">
            <IconButton
              color="primary"
              size="medium"
              className="header-logo-wrapper-btn"
            >
              <img
                className="app-header-logo-img"
                alt="Carolina React Admin Dashboard with Material-UI PRO"
                src={projectLogo}
              />
            </IconButton>
          </Link>
          <Box className="header-logo-text">Carolina</Box>
        </Box>
      </div> */}
    </>
  );
};

export default HeaderLogo;
