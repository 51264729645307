import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { putAxios } from "hook/axiosInstance";
import { deleteAxios } from "hook/axiosInstance";
import FranchiseInput from "../franchiseCreate/FranchiseInput";
import TidListModal from "./TidListModal";
import { useState } from "react";

const FranchiseDetailModal = ({
  modalOpen,
  setModalOpen,
  scroll,
  selectDetail,
  getList,
}) => {
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // ! 수정 - 전송 로직
  const updateDataApi = async (updateValue, franchiseId) => {
    try {
      const posted = await putAxios(
        // `franchise/detail/update/modify/${franchiseId}`,
        `franchise/detail/update/modify`,
        updateValue
      );
      if (posted.status === 200) {
        alert("수정 완료");
        getList(15);
        setModalOpen(false);
      }
    } catch (err) {
      if (err.response.data.status === 500) {
        alert("500");
        // setCreateValue({ ...TerminalFields });
        return;
      }
      if (err.response.data.status === 509) {
        alert("509");
        // setCreateValue({ ...TerminalFields });
        return;
      }
    }
    return;
  };

  // ! 삭제 - 전송로직

  const handleDelete = () => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      deleteApi(selectDetail?.franchise?.id);
    }
  };

  const deleteApi = async (id) => {
    const resDelete = await deleteAxios(`franchise/detail/delete/${id}`);
    if (resDelete.data.header.result_code === "200") {
      alert("삭제가 완료되었습니다.");
      handleCloseModal();
      window.location.reload();
    } else {
      alert("삭제에 실패했습니다");
    }
  };
  const [tidModalFlag, setTidModalFlag] = useState(false);
  return (
    <>
      <TidListModal
        modalOpen={tidModalFlag}
        setModalOpen={setTidModalFlag}
        franchiseName={selectDetail?.franchise?.user?.agentName}
      />
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="xl"
      >
        <DialogTitle id="scroll-dialog-title">
          <h1>정보수정</h1>
          {`${selectDetail?.franchise?.user?.agentName} mid : ${selectDetail?.franchise?.mid}`}
        </DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          style={{ backgroundColor: "#f9faff" }}
        >
          <FranchiseInput
            selectDetail={{
              ...selectDetail?.franchise,
              path: selectDetail?.path,
              immediateWithdrawalKey: selectDetail?.immediateWithdrawalKey,
              pgFlag: selectDetail?.franchise?.pgCompany?.pgFlag,
            }}
            updateDataApi={updateDataApi}
          />
          <Button
            onClick={() => setTidModalFlag(true)}
            variant="contained"
            color="secondary"
          >
            단말기리스트
          </Button>
        </DialogContent>
        <DialogActions>
          {/* <Button variant="contained" color="primary">
            수정
          </Button> 
          <Button
            variant="contained"
            onClick={handleDelete}
            style={{ backgroundColor: "red", color: "white" }}
          >
            가맹점 삭제
          </Button>*/}
          <Button onClick={handleCloseModal} color="primary">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default FranchiseDetailModal;
