import { atom } from "recoil";

// atom 정의
export const pageStateAtom = atom({
  key: "pageStateAtom",
  default: 1,
});

export const listLinkStateAtom = atom({
  key: "listLinkStateAtom",
  default: "",
});

export const mobileFlagAtom = atom({
  key: "mobileFlagAtom",
  default: "",
});
