import styled from "styled-components";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { Icon } from "@mui/material";
import { ko } from "date-fns/esm/locale";

export const ButtonSelectDateBox = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  return (
    <>
      <DateContainer>
        <DateDiv>
          <SDatePicker
            locale={ko}
            selected={startDate}
            dateFormat="yyyy년 MM월 dd일"
            onChange={(date) => setStartDate(date)}
            selectsStart
          />
        </DateDiv>
        <span>~</span>
        <DateDiv>
          <SDatePicker
            locale={ko}
            selected={endDate}
            minDate={startDate}
            dateFormat="yyyy년 MM월 dd일"
            onChange={(date) => setEndDate(date)}
            selectsEnd
          />
        </DateDiv>
      </DateContainer>
    </>
  );
};
function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
const DateContainer = styled.div`
  /* margin-top: 10px; */
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const DateDiv = styled.div`
  margin: 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SDatePicker = styled(DatePicker)`
  width: 130px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  text-justify: center;
  border: 0.1px solid rgba(0, 0, 0, 0.2);
  z-index: -10;
  & :hover {
    background-color: blue;
    transition: background-color 0.5s;
  }
`;
