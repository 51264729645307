import { FontAwesomeIcon } from "../../../node_modules/@fortawesome/react-fontawesome/index";
import { TextField } from "../../../node_modules/@material-ui/core/index";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});
const CommonTextFieldFullwidthPoint = ({
  noCompany,
  handleChange,
  createValue,
  helperText,
  error,
  field,
  meaning,
  placeholder,
  type,
  required,
  style,
  medium,
  disabled,
}) => {
  const fieldName = field;
  const classes = useStyles();


  return (
    <TextField
      size={medium ? "medium" : "small"}
      className="m-2"
      classes={{ root: classes.input }}
      id={fieldName}
      name={fieldName}
      variant="outlined"
      type={type ? type : "text"}
      label={
        <span position="start">
          {meaning}&nbsp;
          {required ? (
            <FontAwesomeIcon
              icon="fa-solid fa-asterisk"
              size="xs"
              color="red"
            />
          ) : null}
        </span>
      }
      placeholder={placeholder ? placeholder : meaning}
      onChange={handleChange}
      value={createValue ? createValue[fieldName] : ""}
      helperText={helperText[fieldName]}
      error={error[fieldName]}
      style={style ? style : null}
      fullWidth
      disabled={disabled}
    />
  );
};

export default CommonTextFieldFullwidthPoint;
