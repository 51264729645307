import {
  MenuItem,
  TextField,
} from "../../../node_modules/@material-ui/core/index";
import { makeStyles } from "@material-ui/core/styles";
import { useRecoilState } from "recoil";
import { listLinkStateAtom } from "recoilStates/searchRecoil";

const useStyles = makeStyles((theme) => ({
  select: { width: "119px" },
  // menuItem: { paddingTop: "0px", paddingBottom: "0px" },
}));

const SelectLimit = ({ limit, setLimit, setPage }) => {
  const classes = useStyles();
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);
  const limitFn = (e) => {
    const { value } = e.target;
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/limit=[^&]*/, `limit=${value}`);
    setLimit(value);
    tempLink = tempLink.replace(/page=[^&]*/, `page=1`);
    setPage(1);
    setListLinkState(tempLink);
  };
  return (
    <TextField
      className="m-2"
      variant="outlined"
      select
      size="small"
      value={limit}
      defaultValue={15}
      fullwidth
      style={{ width: "100px", backgroundColor: "white" }}
      onChange={limitFn}
      label="보기"
      placeholder="15개씩 보기"
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: "center",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        },
      }}
    >
      {/* <MenuItem
        // className={classes.menuItem} // 필요
        disabled
        value="1" // <<< 바로 이 부분 : 빈 문자열 값을 가진 MenuItem을 별도로 넣는다.
        sx={{
          display: "none", // 보여줄 지 설정
        }}
      >
        <em></em>
      </MenuItem> */}
      {[15, 50, 100, 500].map((num) => {
        return (
          <MenuItem className={classes.menuItem} key={num} value={num}>
            {num}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default SelectLimit;
