import React from "react";
import ReactDOM from "react-dom";

import { RouterProvider } from "../node_modules/react-router-dom/dist/index";
import router from "Router";

ReactDOM.render(
  <>
    <RouterProvider router={router} />
  </>,
  document.getElementById("root")
);
