import { getAxios } from "hook/axiosInstance";
import { ExampleWrapperSimple } from "layouts/wrappers/ExampleWrapperSimple";
import { useLayoutEffect, useState } from "react";
import { formatToWon } from "utils/formatFunction";
import MerchantInput from "./MerchantInput";
import MuiAlert from "@material-ui/lab/Alert";
import { FontAwesomeIcon } from "../../../node_modules/@fortawesome/react-fontawesome/index";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import PayAlert from "components/PayAlert";

const MerchantWithdrawl = () => {

  return (
    <ExampleWrapperSimple sectionHeading="머천트 출금">
      <MerchantInput />
    </ExampleWrapperSimple>
  );
};

export default MerchantWithdrawl;
