import React from "react";
import { Card, Divider, Grid, TextField } from "@material-ui/core";
import { bizNoPattern } from "utils/validationCheckFunction";
import { socNoPattern } from "utils/validationCheckFunction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommonTextFieldFullwidth from "components/commonInput/CommonTextField";
import { ConstructionOutlined } from "../../../../node_modules/@mui/icons-material/index";

const BusinessNumInput = ({ createValue, handleChange, error, helperText }) => {
  return (
    <Card className="p-4 mb-4">
      <div className="font-size-lg font-weight-bold">사업자 정보</div>
      <Divider className="my-2" />
      <div>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field="companyName"
              meaning="회사명"
              style={{ width: "97.5%" }}
              required={true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={""}
              error={false}
              field="companyContact"
              meaning="회사전화번호"
              placeholder="회사(사업장) 전화번호"
              required={true}
            // style={{ width: "97.5%" }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              size="small"
              style={{ width: "97.5%" }}
              className="m-2"
              id="companyNum"
              name="companyNum"
              variant="outlined"
              onChange={handleChange}
              label={
                <span>
                  사업자번호&nbsp;
                  <FontAwesomeIcon
                    icon="fa-solid fa-asterisk"
                    size="xs"
                    color="red"
                  />
                </span>
              }
              placeholder="사업자번호"
              value={
                createValue?.companyNum?.length === 10
                  ? bizNoPattern(createValue?.companyNum)
                  : createValue?.companyNum
              }
              error={error?.companyNum}
              helperText={helperText?.companyNum}
            />
          </Grid>
          {createValue.companyType == 3 && (
            <Grid item xs={12} lg={6}>
              <TextField
                size="small"
                // style={{ width: "97.5%" }}
                style={{ width: "100%" }}
                className="m-2"
                id="corporationNumber"
                name="corporationNumber"
                variant="outlined"
                label={<span>법인번호</span>}
                placeholder="법인번호"
                value={
                  createValue?.corporationNumber?.length === 13
                    ? socNoPattern(createValue?.corporationNumber)
                    : createValue?.corporationNumber
                }
                onChange={handleChange}
                error={error?.corporationNumber}
                helperText={helperText?.corporationNumber}
              />
            </Grid>
          )}

          <Grid item xs={12} lg={6}>
            <TextField
              size="small"
              // fullwidth
              style={{ width: "97.5%" }}
              className="m-2"
              id="companyBusiness"
              name="companyBusiness"
              variant="outlined"
              label={<span>업태</span>}
              placeholder="업태"
              onChange={handleChange}
              value={createValue?.companyBusiness}
              error={error?.companyBusiness}
              helperText={helperText?.companyBusiness}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field="companySector"
              meaning="종목"
              required={false}
            // style={{ width: "97.5%" }}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field="companyAddress"
              meaning="사업자주소"
              required={true}
            />
          </Grid>
          <Grid xs={12}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field="ceoAddress"
              meaning="대표자주소"
              placeholder="비사업자만 해당"
            />
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default BusinessNumInput;
