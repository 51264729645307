import { useState } from "react";
import { Box, Button } from "../../node_modules/@material-ui/core/index";
import excelIcon from "assets/custom/excelIcon.svg";
import { postAxios } from "hook/axiosInstance";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
const XLSX = require("xlsx");

const TidExcelImport = (userAuth) => {
  const [data, setData] = useState([]);
  const [arrData, setArrData] = useState([]);
  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const binaryData = e.target.result;
      const workbook = XLSX.read(binaryData, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const filteredData = data.filter((row) => row.join("").trim() !== "");
      setData(filteredData);
    };
    reader.readAsBinaryString(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const confirmed = window.confirm(
        `업로드후에 단말기가 등록되면 이후에는 삭제할 수 없습니다,  엑셀파일의 단말기데이터를 확인한 경우에만 실행하세요`
      );
      if (confirmed) {
        handleFileUpload(acceptedFiles[0]);
      }
    },
  });

  useEffect(() => {
    if (data.length > 0) {
      const tempArr = [];
      data.map((obj, i) => {
        if (i > 0) {
          tempArr.push({
            authYn: "Y",
            terminalCode: obj[2],
            terminalType: obj[0],
            tid: obj[1],
            franchiseCode: obj[3],
            terminalStatus: obj[4],
          });
          tempArr.slice(0, 1);
        }
      });
      setArrData(tempArr);
      checkFn();
    }
  }, [data]);

  useEffect(() => {
    if (data.length > 0) checkFn();
  }, [arrData]);
  // const
  const checkFn = () => {
    const tn = /^[TN]$/;
    const yn = /^[YN]$/;
    const tidReg = /^\d{1,12}$/;
    const regex = /^[0-9]+$/;
    const codeReg = /^[a-zA-Z0-9]{1,20}$/;
    let alertMessage = "";
    const tempArr = arrData;
    tempArr.map(
      (obj, i) => {
        // if (i >= 1) {
        if (
          !obj.terminalCode ||
          !obj.terminalType ||
          !obj.tid ||
          !obj.franchiseCode ||
          !obj.terminalStatus
        ) {
          alertMessage = `${i}번째 단말기에 필수 요소가 들어가지 않았습니다.`;
        }
        if (!tidReg.test(obj.tid))
          alertMessage = `코드 ${obj.terminalCode} 단말기의 단말기코드 형태에 오류가 있습니다.`;
        if (!codeReg.test(obj.terminalCode))
          alertMessage = `코드 ${obj.terminalCode} 단말기의 시리얼번호 형태에 오류가 있습니다.`;
        if (!regex.test(obj.franchiseCode))
          alertMessage = `코드 ${obj.terminalCode} 단말기의 가맹점코드 형태에 오류가 있습니다.`;
        if (!tn.test(obj.terminalType) || !yn.test(obj.terminalStatus))
          alertMessage = `코드 ${obj.terminalCode} 단말기의 인증, 간편결제, 운영상태중 형태에 오류가 있는 요소가 있습니다.`;
        if (arrData.length === i + 1) {
          if (!alertMessage) sendFn();
        }
      }
      // }
    );

    if (alertMessage) {
      alert(alertMessage);
      setData([]);
      setArrData([]);
    }
  };

  const sendFn = async () => {
    await postAxios("/terminal/create/excel", arrData)
      .then((re) => {
        alert(re.data.data);
        setData([]);
        setArrData([]);
      })
      .catch((err) => {
        alert(err.response.data.message);
        setData([]);
        setArrData([]);
      });
  };

  return (
    <>
      <Box
        {...getRootProps()}
        display={userAuth.userAuth === "SA" ? "block" : "none"}
        pr={1}
      >
        <input {...getInputProps()} />
        {/* <p>엑셀업로드</p> */}
        <Button variant="contained" color="primary">
          <img src={excelIcon} alt="EXCEL" style={{ width: "23px" }} />
          &nbsp; 업로드
        </Button>
      </Box>
    </>
  );
};

export default TidExcelImport;
