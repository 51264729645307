import React, { Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Grid,
  InputAdornment,
  IconButton,
  Button,
  Tooltip,
  TextField,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";

import svgImage3 from "../../assets/images/illustrations/404.svg";

import { Link, NavLink as RouterLink } from "react-router-dom";

const PageError404 = () => {
  return (
    <Fragment>
      <div className="app-wrapper bg-white">
        <div className="app-main">
          <Link to="/">
            <Button
              size="large"
              color="secondary"
              variant="contained"
              className="text-white btn-go-back"
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={["fas", "arrow-left"]} />
              </span>
              <span className="btn-wrapper--label">첫화면으로</span>
            </Button>
          </Link>
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <div className="hero-wrapper bg-composed-wrapper min-vh-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <Grid
                        item
                        lg={6}
                        md={9}
                        className="px-4 px-lg-0 mx-auto text-center text-black"
                      >
                        <img
                          src={svgImage3}
                          className="w-50 mx-auto d-block my-5 img-fluid"
                          alt="..."
                        />

                        <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                          페이지가 존재하지 않습니다.
                        </h3>
                      </Grid>
                    </div>
                    <div className="hero-footer py-4">
                      {/* <Tooltip arrow title="Facebook">
                        <IconButton
                          color="primary"
                          size="medium"
                          variant="outlined"
                          className="text-facebook"
                        >
                          <FontAwesomeIcon
                            icon={["fab", "facebook"]}
                            className="font-size-md"
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow title="Twitter">
                        <IconButton
                          color="primary"
                          size="medium"
                          variant="outlined"
                          className="text-twitter"
                        >
                          <FontAwesomeIcon
                            icon={["fab", "twitter"]}
                            className="font-size-md"
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow title="Google">
                        <IconButton
                          color="primary"
                          size="medium"
                          variant="outlined"
                          className="text-google"
                        >
                          <FontAwesomeIcon
                            icon={["fab", "google"]}
                            className="font-size-md"
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow title="Instagram">
                        <IconButton
                          color="primary"
                          size="medium"
                          variant="outlined"
                          className="text-instagram"
                        >
                          <FontAwesomeIcon
                            icon={["fab", "instagram"]}
                            className="font-size-md"
                          />
                        </IconButton>
                      </Tooltip> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PageError404;
