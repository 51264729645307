import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  Button,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableFooter,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import NoticeDetailModal from "./NoticeDetailModal";
import { getAxios } from "hook/axiosInstance";
import { SearchComponent } from "components/commonTable/SearchComponent";
import Pagination from "react-js-pagination";

import "../../../assets/custom/pagination.css";
import SelectLimit from "components/commonTable/SelectLimit";
import { listToDate } from "utils/formatFunction";
import NoData from "components/commonTable/NoData";
import { Box } from "../../../../node_modules/@material-ui/core/index";

const NoticeTable = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selected] = useState([]);
  const [selectDetail, setSelectDetail] = useState("");
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const [address, setAddress] = useState("notice/list");
  const [loadFlag, setLoadFlag] = useState(false);
  useEffect(() => {
    if (loadFlag) getListApi(limit);
  }, [loadFlag]);
  const [modalOpen, setModalOpen] = useState(false);
  const [scroll] = useState("paper");

  // ! 로딩
  const [isLoading, setIsLoading] = useState(false);

  // ! GET 요청
  const [listData, setListData] = useState({ dataList: [] });

  const getListApi = async (limit) => {
    setIsLoading(true);
    const tempAd = `notice/list?limit=${limit}`;
    setAddress(tempAd);
    const listData = await getAxios(tempAd);
    if (listData.data.header.result_code === "200") {
      setListData(listData.data.data);
      setIsLoading(false);
    } else {
      setListData([]);
    }
  };

  useEffect(() => {
    getListApi(limit);
  }, [limit]);

  const handleModalOpen = async (row) => {
    await getAxios(`notice/viewCount/${row.id}`);
    setModalOpen(true);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   if (parseInt(event.target.value) > listData.dataList.length) {
  //     setRowsPerPage(-1);
  //     return;
  //   }
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleDetailClick = (row, idx) => {
    handleModalOpen(row);
    setSelectDetail(row);
  };

  // const handleSelectAllClick = (event) => {
  //   setSelectedAll(!selectedAll);

  //   if (event.target.checked) {
  //     const newSelecteds = listData.dataList.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   } else {
  //     setSelected([]);
  //   }
  // };

  // ! N개씩 보기

  const checkEventHandler = ({ target }) => {
    setLimit(target.value);
  };

  // ! 검색 관련
  const [keyword, setKeyword] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [checkBool, setCheckBool] = useState("");

  const checkFields = [
    { field: "searchField", name: "검색조건" },
    { id: "all", name: "모두" },
    { id: "noticeTitle", name: "제목" },
    { id: "noticeContent", name: "내용" },
    { id: "noticeAuthor", name: "작성자" },
  ];

  function formatDate(date) {
    if (date == null) {
      return "";
    }
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const searchClick = () => {
    if (endDate == null) {
      alert("날짜를 모두 입력하세요");
      return;
    }
    if (startDate == null) {
      alert("날짜를 모두 입력하세요");
      return;
    }

    const requestSearch = async () => {
      const tempAd = `notice/list?keyword=${encodeURI(
        keyword
      )}&startDate=${formatDate(startDate)}&endDate=${formatDate(
        endDate
      )}&limit=${limit}&columns=${checkBool}`;
      setAddress(tempAd);
      const res = await getAxios(tempAd);
      res.data.header.result_code === "200"
        ? setListData(res.data.data)
        : setListData([]);
    };
    requestSearch();
    setPage(1);
  };

  // ! 페이징
  const emptyRows =
    limit - Math.min(limit, listData.totalCount - (page - 1) * limit);

  const handlePageChange = (page) => {
    setPage(page);
    const paging = async () => {
      const res = await getAxios(
        "notice/list" +
          "?page=" +
          page +
          "&query=" +
          encodeURI(keyword) +
          "&startDate=" +
          formatDate(startDate) +
          "&endDate=" +
          formatDate(endDate) +
          "&limit=" +
          limit +
          "keyword=" +
          encodeURI(keyword)
      );

      res.data.header.result_code === "200"
        ? setListData(res.data.data)
        : setListData([]);
    };
    paging();
  };

  return (
    <>
      <SearchComponent
        keyword={keyword}
        listData={listData}
        checkBool={checkBool}
        setCheckBool={setCheckBool}
        setKeyword={setKeyword}
        searchClick={searchClick}
        startDate={startDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        checkFields={checkFields}
        setLoadFlag={setLoadFlag}
      />
      <Box height="5px" />

      <Card className="card-box mb-4">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <small>공지사항</small>
            <b>공지사항 목록</b>
          </div>
          <div className="card-header--actions">
            {selected.length > 0 ? (
              <span>{selected.length} selected</span>
            ) : (
              <></>
            )}

            {/* <Button
              onClick={() => {
                getListApi(limit);
              }}
              variant="outlined"
              >
              보기
            </Button> */}
            <Link to="/notice/create" style={{ textColor: "white" }}>
              <Button variant="contained" color="primary" className="m-2">
                글쓰기
              </Button>
            </Link>
            <SelectLimit checkEventHandler={checkEventHandler} />
          </div>
        </div>
        <CardContent className="p-0">
          <div className="table-responsive">
            <table className="text-nowrap mb-0 table table-striped">
              <TableHead className="thead-light">
                <tr>
                  <th className="text-center">번호</th>
                  <th className="text-center">제목</th>
                  <th className="text-center">내용</th>
                  <th className="text-center" style={{ width: "20%" }}>
                    작성자
                  </th>
                  <th className="text-center">작성일</th>
                  <th className="text-center">조회수</th>
                  <th className="text-center">상세보기</th>
                </tr>
              </TableHead>
              <TableBody>
                {/* {(rowsPerPage > 0
                  ? listData.dataList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : listData.dataList
                ).map((row, idx) => { */}
                {listData.dataList.map((row, idx) => {
                  const isItemSelected = isSelected(row.id);
                  // ;

                  // for (let i = 0; i++; i < row.noticeDateTime) {
                  //   if (i < 10) {
                  //     i = `0${i}`;
                  //   }
                  // }

                  // ! 번호
                  const rowNum = listData.totalCount - limit * (page - 1) - idx;

                  // let preprocessedDate = [];
                  // row.noticeDateTime.map((num) => {
                  //   if (parseInt(num) < 10) {
                  //     preprocessedDate.push(`0${num}`);
                  //   } else {
                  //     preprocessedDate.push(num);
                  //   }
                  // });

                  // const dateTimeParsed = `${preprocessedDate[0]}-${preprocessedDate[1]}-${preprocessedDate[2]} ${preprocessedDate[3]}:${preprocessedDate[4]}:${preprocessedDate[5]}`;

                  const parsedDate = listToDate(row.noticeDateTime);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={idx}
                      selected={isItemSelected}
                    >
                      <TableCell align="center">{rowNum}</TableCell>

                      <TableCell aligh="left">{row.noticeTitle}</TableCell>
                      <TableCell align="left">
                        {row.noticeContent.substr(0, 50)}...
                      </TableCell>
                      <TableCell className="text-center">
                        {row.noticeAuthor}
                      </TableCell>
                      <TableCell align="center">{parsedDate}</TableCell>
                      <TableCell align="center">{row.view}</TableCell>
                      <TableCell align="center" className="p-0">
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => {
                            handleDetailClick(row, idx);
                          }}
                        >
                          상세보기
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}

                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 60 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )} */}
                {listData.dataList.length > 0 ? null : (
                  <NoData
                    comment="공지사항이 없습니다."
                    colNum={7}
                    isLoading={isLoading}
                  />
                )}
              </TableBody>
              <TableFooter></TableFooter>
            </table>
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={listData.totalCount || 0}
            pageRangeDisplayed={5}
            prevPageText={"‹"}
            nextPageText={"›"}
            firstPageText={"‹‹"}
            lastPageText={"››"}
            onChange={(event) => {
              handlePageChange(event);
            }}
            style={{ display: "flex", justifyContent: "center" }}
          />
        </CardContent>
      </Card>
      {modalOpen ? (
        <NoticeDetailModal
          selectDetail={selectDetail}
          setSelectDetail={setSelectDetail}
          scroll={scroll}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          listData={listData}
          setListData={setListData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default NoticeTable;
