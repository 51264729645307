import { Dialog } from "@material-ui/core";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "../../../../node_modules/@material-ui/core/index";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { getAxios, postAxios, putAxios } from "hook/axiosInstance";
import { useEffect } from "react";
const useStyles = makeStyles((theme) => ({
  modalTitle: {
    fontSize: "23px",
    fontWeight: "700",
  },
  title: {
    fontSize: "18px",
    fontWeight: "600",
  },
  deleteBtn: {
    backgroundColor: "#f50057",
    color: "#fff",
  },
  cardText: {
    fontSize: "18px",
  },
}));
const CardAddModal = ({
  modalOpen,
  scroll,
  handleModalClose,
  selectDetail,
}) => {
  const classes = useStyles();
  const [cardNum, setCardNum] = useState({
    cardNum1: "",
    cardNum2: "",
    cardNum3: "",
    cardNum4: "",
  });
  const [listData, setListData] = useState([1, 2, 3]);
  const handleCard = (e) => {
    const { name, value } = e.target;
    setCardNum({ ...cardNum, [name]: value });
  };

  useEffect(() => {
    getListApi();
  }, []);


  const getListApi = async () => {
    const franchiseId = selectDetail?.franchise?.user?.id;
    if (franchiseId) {
      const resApiData = await getAxios(
        `franchise/card?userSeq=${franchiseId}`
      );
      if (resApiData.data.header.result_code === "200") {
        setListData(resApiData.data.data);
      }
    }
  };
  const sendCardFn = async () => {
    if (window.confirm("등록하시겠습니까?")) {
      const postCardNum =
        cardNum.cardNum1 +
        cardNum.cardNum2 +
        cardNum.cardNum3 +
        cardNum.cardNum4;
      const data = {
        cardNum: postCardNum,
        userSeq: selectDetail?.franchise?.user?.id,
        useYn: "Y",
      };
      const historyRes = await postAxios("franchise/card", data);
      if (historyRes.data.header.result_code == 200) {
        getListApi();
        alert("등록되었습니다");
      } else {
        alert("서버에러 관리자에게 문의하세요");
      }
    }
  };
  const handleDelete = async (e) => {
    if (window.confirm("삭제하시겠습니까?")) {
      const data = {
        franchiseCardSeq: e,
        userSeq: selectDetail?.franchise?.user?.id,
      };
      const historyRes = await postAxios("franchise/card/delete", data);
      if (historyRes.data.header.result_code == 200) {
        getListApi();
        alert("삭제되었습니다");
      } else {
        alert("서버에러 관리자에게 문의하세요");
      }
    }
  }
  const handleuseYnFn = async (e) => {
    let title = e.useYn === "Y" ? "카드 적용을 해제하시겠습니까?" : "카드를 적용하시겠습니까?"
    let setUseYn = e.useYn === "Y" ? "N" : "Y"
    if (window.confirm(title)) {
      const data = {
        franchiseCardSeq: e.franchiseCardSeq,
        useYn: setUseYn
      };
      const historyRes = await putAxios("franchise/card", data);
      if (historyRes.data.header.result_code == 200) {
        getListApi();
        alert("수정되었습니다");
      } else {
        alert("서버에러 관리자에게 문의하세요");
      }
    }
  }


  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        disableEnforceFocus
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography className={classes.modalTitle}>단골고객등록</Typography>
        </DialogTitle>
        <DialogContent>
          <Box pb={2}>
            <Box display="flex" justifyContent="space-between" alignItems="top">
              <Typography className={classes.title}>카드번호</Typography>
              <Button variant="contained" color="primary" onClick={sendCardFn}>
                등록
              </Button>
            </Box>
            <CardNum cardNum={cardNum} handleCard={handleCard} />
          </Box>
          <TableHead className="thead-light">
            <TableRow width="100%">
              {headerList.map((obj, i) => {
                return (
                  <TableCell
                    key={i}
                    align="center"
                    className="p-1"
                    width={`${obj.width}`}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {obj.title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {listData.length > 0 ? (
              listData.map((row, idx) => {
                const hyphens = `${row.cardNum}`.replace(/(\d{4})(?=\d)/g, '$1-');
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={idx}
                    // className={isItemSelected ? classes.selected : ""}
                    style={{ fontSize: "2px" }}
                  >
                    <TableCell className={"text-center"}>
                      <Typography className={classes.cardText}>{hyphens}</Typography>
                    </TableCell>
                    <TableCell className="text-center">
                      {row?.useYn === "Y" ? <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleuseYnFn(row)}
                      >
                        적용해제
                      </Button> : <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleuseYnFn(row)}
                      >
                        적용
                      </Button>}
                    </TableCell>
                    <TableCell className="text-center">
                      <Button
                        variant="contained"
                        className={classes.deleteBtn}
                        onClick={() => handleDelete(row.franchiseCardSeq)}
                      >
                        삭제
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>

                <TableCell style={{ width: "60%" }} className="text-center"></TableCell>
                <TableCell width="30%" className="text-center">
                  <Typography>카드를 추가해주세요</Typography>
                </TableCell>
                <TableCell width="50%" className="text-center"></TableCell>
              </TableRow>

            )}
          </TableBody>
          <Box display="flex" justifyContent="flex-end" pb={1} pt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleModalClose}
            >
              닫기
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CardAddModal;

const CardNum = ({ cardNum, handleCard }) => {
  const classes = useStyles();
  const handleCardProps = (e) => {
    handleCard(e);
  };
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <TextField
        size={"small"}
        className="m-2"
        classes={{ root: classes.input }}
        style={{ minWidth: "80px" }}
        variant="outlined"
        value={cardNum.cardNum1}
        name="cardNum1"
        onChange={handleCardProps}
        inputProps={{
          maxLength: 4,
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
      />
      <Typography>-</Typography>
      <TextField
        size={"small"}
        className="m-2"
        classes={{ root: classes.input }}
        style={{ minWidth: "80px" }}
        variant="outlined"
        value={cardNum.cardNum2}
        name="cardNum2"
        onChange={handleCardProps}
        inputProps={{
          maxLength: 4,
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
      />
      <Typography>-</Typography>
      <TextField
        size={"small"}
        className="m-2"
        classes={{ root: classes.input }}
        style={{ minWidth: "80px" }}
        variant="outlined"
        value={cardNum.cardNum3}
        name="cardNum3"
        onChange={handleCardProps}
        inputProps={{
          maxLength: 4,
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
      />
      <Typography>-</Typography>
      <TextField
        size={"small"}
        className="m-2"
        classes={{ root: classes.input }}
        style={{ minWidth: "80px" }}
        variant="outlined"
        value={cardNum.cardNum4}
        name="cardNum4"
        onChange={handleCardProps}
        inputProps={{
          maxLength: 4,
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
      />
    </Box>
  );
};

const headerList = [{ title: "카드번호", width: "60%" }, { title: "적용여부", width: "30%" }, { title: "삭제", width: "30%" }];
const insertHyphens = (cardNumber) => {
  return cardNumber.replace(/(.{4})/g, '$1-').slice(0, -1);
}