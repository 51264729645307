import { useState } from "react";
import { Box, Button } from "../../node_modules/@material-ui/core/index";
import excelIcon from "assets/custom/excelIcon.svg";
import { postAxios } from "hook/axiosInstance";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
const XLSX = require("xlsx");

const ExcelImport = (userAuth) => {
  const [data, setData] = useState([]);
  const [arrData, setArrData] = useState([]);
  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const binaryData = e.target.result;
      const workbook = XLSX.read(binaryData, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const filteredData = data.filter((row) => row.join("").trim() !== "");
      setData(filteredData);
    };
    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    if (data.length > 0) {
      const tempArr = [];
      data.map((obj, i) => {
        if (i > 0) {
          tempArr.push({
            agentName: obj[0],
            ceoName: obj[1],
            franchiseNickname: obj[2],
            managerContact: obj[3],
            managerEmail: obj[4],
            managerName: obj[5],
            userId: obj[6],
            userPw: "1234",
            userAuth: "SF",
            ceoId: obj[7],
            accountHolder: obj[8],
            accountNumber: obj[9],
            authYn: obj[10],
            bankName: obj[11],
            ceoAddress: obj[12],
            commissionRate: obj[13],
            companyAddress: obj[14],
            companyBusiness: obj[15],
            companyContact: obj[16],
            companyName: obj[17],
            companyNum: obj[18],
            companySector: obj[19],
            companyType: obj[20],
            contractStatus: obj[21],
            corporationNumber: obj[22],
            installmentPending: obj[23],
            loginAllowCheck: obj[24],
            paymentCancelTodayYn: obj[25],
            paymentEasyLimitPeriod: obj[26],
            paymentType: obj[27],
            paymentLimitDay: obj[28],
            paymentLimitForeach: obj[29],
            paymentLimitMonth: obj[30],
            pgCompanyId: obj[34],
            settlementCycle: `${obj[31]}`,
            settlementPendingYn: obj[32],
            highCompanyCode: obj[33],
            agentNickname: obj[2],
            immediateWithdrawalKey: obj[35],
            paymentEasyYn: obj[36],
            paymentCardLimit: obj[37],
            callCharges: obj[38],
            franchiseCardYn: obj[39],
            scale: obj[40],
          });
          tempArr.slice(0, 1);
        }
      });
      setArrData(tempArr);
      checkFn();
    }
  }, [data]);

  useEffect(() => {
    if (data.length > 0) checkFn();
  }, [arrData]);
  // const
  const checkFn = () => {
    // const textOnly = /^[a-zA-Z가-힣]+$/;
    const regex = /^[0-9]+$/;
    const phone = /^\d{11}$/;
    const ceoId = /^\d{13}$/;
    const yn = /^[YN]$/;
    const ynt = /^[YNTWV]$/;
    const selectNumType1 = /^[123]$/;
    const selectNumType2 = /^[1234]$/;
    const numberDot = /^\d*\.?\d+$/;
    // const email = /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})$/;
    let alertMessage = "";
    const tempArr = arrData;

    tempArr.map((obj, i) => {
      // if (i >= 1) {
      if (!obj.agentName) {
        alertMessage =
          "가맹점명은 필수값입니다. 가맹점명이 들어가지 않은 데이터가 있습니다.";
      } else if (
        !obj.ceoName ||
        !obj.franchiseNickname ||
        !obj.managerContact ||
        !obj.managerName ||
        !obj.userId ||
        !obj.accountHolder ||
        !obj.accountNumber ||
        !obj.authYn ||
        !obj.bankName ||
        !obj.commissionRate ||
        !obj.installmentPending ||
        !obj.loginAllowCheck ||
        !obj.paymentCancelTodayYn ||
        !obj.paymentEasyLimitPeriod ||
        !obj.paymentEasyYn ||
        !obj.paymentLimitDay ||
        !obj.paymentLimitForeach ||
        !obj.paymentLimitMonth ||
        !obj.settlementCycle ||
        !obj.settlementPendingYn ||
        !obj.highCompanyCode ||
        !obj.pgCompanyId ||
        !obj.paymentType ||
        !obj.paymentEasyYn
      ) {
        alertMessage = `${obj.agentName}가맹점에 필수 요소가 들어가지 않았습니다.`;
      } else if (
        obj.companyType === 2 ||
        obj.companyType === 3 ||
        obj.companyType === 4
      ) {
        if (
          !obj.companyContact ||
          !obj.companyName ||
          !obj.companyNum ||
          !obj.companyAddress
        ) {
          alertMessage = `${obj.agentName}가맹점에 필수 요소가 들어가지 않았습니다.`;
        } else if (
          !regex.test(obj.companyContact) ||
          !regex.test(obj.companyNum)
        ) {
          alertMessage = `${obj.agentName}의 회사전화번호 혹은 사업자번호 형태에 오류가 있습니다.`;
        }
        if (obj.companyType === 3)
          if (!obj.corporationNumber)
            alertMessage = `${obj.agentName}가맹점에 필수 요소가 들어가지 않았습니다.`;
          else if (!regex.test(obj.corporationNumber))
            alertMessage = `${obj.agentName}의 법인번호 형태에 오류가 있습니다.`;
      }
      // if (!textOnly.test(obj.ceoName) || !textOnly.test(obj.managerName)) {
      //   alertMessage = `${obj.agentName}의 대표자명 형태에 오류가 있습니다.`;
      // }
      if (
        !regex.test(obj.accountNumber) ||
        !regex.test(obj.paymentEasyLimitPeriod) ||
        !regex.test(obj.paymentLimitDay) ||
        !regex.test(obj.paymentLimitForeach) ||
        !regex.test(obj.paymentLimitMonth) ||
        !regex.test(obj.settlementCycle) ||
        !regex.test(obj.highCompanyCode) ||
        !regex.test(obj.pgCompanyId)
      ) {
        alertMessage = `${obj.agentName}의 형태에 숫자만 입력가능하지만 다른것이 입력된 요소가 있습니다.`;
      }
      if (!phone.test(obj.managerContact)) {
        alertMessage = `${obj.agentName}의 담당자연락처 형태에 오류가 있습니다.`;
      }
      if (obj.ceoId) {
        if (!ceoId.test(obj.ceoId))
          alertMessage = `${obj.agentName}의 대표자주민번호 형태에 오류가 있습니다.`;
      }
      if (
        !yn.test(obj.loginAllowCheck) ||
        !yn.test(obj.paymentCancelTodayYn) ||
        !yn.test(obj.paymentEasyYn) ||
        !yn.test(obj.settlementPendingYn)
      ) {
        alertMessage = `${obj.agentName}의 로그인 허용 여부, 당일취소여부, 정산보류여부, 간편결제여부 중 형태에 오류가 있는 요소가 있습니다.`;
      }
      if (!ynt.test(obj.paymentType)) {
        alertMessage = `${obj.agentName}의 간편결제여부 형태에 오류가 있습니다.`;
      }
      if (!selectNumType1.test(obj.contractStatus)) {
        alertMessage = `${obj.agentName}의 계약상태 형태에 오류가 있습니다.`;
      }
      if (!selectNumType2.test(obj.companyType)) {
        alertMessage = `${obj.agentName}의 사업자구분 형태에 오류가 있습니다.`;
      }
      if (!numberDot.test(obj.commissionRate)) {
        alertMessage = `${obj.agentName}의 수수료율 형태에 오류가 있습니다.`;
      }
      // if (obj.managerEmail) {
      //   if (!email.test(obj.managerEmail))
      //     alertMessage = `${obj.agentName}의 담당자이메일 형태에 오류가 있습니다.`;
      // }
      // }
      if (arrData.length === i + 1) {
        if (!alertMessage) sendFn();
      }
      if (obj.settlementCycle === "0") {
        if (!obj.immediateWithdrawalKey) {
          alertMessage = `${obj.agentName}의 정산 주기가 0이면 가상계좌값이 필수입니다.`;
        }
      }
    });
    if (alertMessage) {
      alert(alertMessage);
      setData([]);
      setArrData([]);
    }
  };

  const sendFn = async () => {
    await postAxios("/franchise/create/excel", arrData)
      .then((re) => {
        alert(re.data.data);
        setData([]);
        setArrData([]);
      })
      .catch((err) => {
        alert(err.response.data.message);
        setData([]);
        setArrData([]);
      });
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const confirmed = window.confirm(
        `업로드후에 가맹점이 등록되면 이후에는 삭제할 수 없습니다,  엑셀파일의 가맹점데이터를 확인한 경우에만 실행하세요`
      );
      if (confirmed) {
        handleFileUpload(acceptedFiles[0]);
      }
    },
  });

  return (
    <>
      <Box
        {...getRootProps()}
        display={userAuth.userAuth === "SA" ? "block" : "none"}
        pr={1}
      >
        <input {...getInputProps()} />
        {/* <p>엑셀업로드</p> */}
        <Button variant="contained" color="primary">
          <img src={excelIcon} alt="EXCEL" style={{ width: "23px" }} />
          &nbsp; 업로드
        </Button>
      </Box>
    </>
  );
};

export default ExcelImport;
