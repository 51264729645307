import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";

import { Grid, Button } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import BasicInput from "./BasicInput";
import VaccountInput from "./VaccountInput";
import ServiceInput from "./ServiceInput";
import BusinessNum from "./BusinessNumInput";
import EasyPayInput from "./EasyPayInput";
import SearchCompanyModal from "../../company/companyCreate/SearchCompanyModal";
import { postAxios } from "hook/axiosInstance";
import { validateInputs } from "utils/validateInputs";
import { checkoutFranchiseNullInputData } from "utils/validateInputs";
import { franchiseFields } from "utils/validateInputs";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// const handleClickShowPassword = () => {

//   setValues({ ...values, showPassword: !values.showPassword });
// };

// const handleMouseDownPassword = (event) => {
//   event.preventDefault();
// };
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 200,
  },
}));

const FranchiseInput = ({ selectDetail, updateDataApi }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [createValue, setCreateValue] = useState({
    ...franchiseFields,
    createId: sessionStorage.getItem("userId"),
    userAuth: "SF",
    userPw: "1234",
    paymentEasyYn: "Y",
    callCharges: "Y",
    samsungPayYN: "N",
    franchiseCardYn: "Y",
    loginAllowCheck: "Y",
    paymentLimitForeach: "3000000",
    paymentLimitMonth: "50000000",
    paymentLimitDay: "5000000",
    paymentCardLimit: "5000000",
    bankName: "KB국민은행",
    installmentPending: "0",
    settlementCycle: "0",
    paymentEasyLimitPeriod: "0",
    authYn: "1",
    paymentCancelTodayYn: "N",
    settlementPendingYn: "N",
    contractStatus: "2",
    companyType: "1",
  });
  // ! 전송 loading
  const [isLoading, setIsLoading] = useState(false);
  // ! 정보수정인 경우
  let copySelectDetail;
  if (selectDetail) {
    copySelectDetail = {
      ...selectDetail.user,
      ...selectDetail,
      highCompanyCode: selectDetail?.company.id,
      highCompanyPath: selectDetail?.path,
      highCompanyName: selectDetail?.company?.user?.agentName,
      samsungPayYN: selectDetail?.samsungPayYn,
      vanpgseq: selectDetail?.vanPgSeq?.id,
      createDateTime: "",
      user: "",
      userId: selectDetail?.user?.userId,
      agentName: selectDetail?.user?.agentName,
      pgCompanyId: selectDetail?.pgCompany?.id,
      immediateWithdrawalKey: selectDetail?.immediateWithdrawalKey,
      tid: selectDetail?.terminal?.tid,
      vanSettlementCycle: selectDetail?.vanSettlementCycle,
    };
  }

  // ! validation, error
  const [error, setError] = useState({});
  const [helperText, setHelperText] = useState({});
  // ^ ID 중복체크, 계좌인증
  const [isDuplicateChecked, setIsDuplicateChecked] = useState(false);
  const [isAccountValidate, setIsAccountValidate] = useState(false);
  const [accountNumberFlag, setAccountNumberFlag] = useState(false);

  // ! 체크박스 로직
  const handleCheck = (e) => {
    if (e.target.checked === true) {
      setCreateValue({ ...createValue, [e.target.name]: "Y" });
    } else if (e.target.checked === false) {
      setCreateValue({ ...createValue, [e.target.name]: "N" });
    }
  };

  // ! 등록 - 전송 로직
  const handleChange = (event) => {
    // setValues({ ...values, [prop]: event.target.value });
    // ^ 정보수정이면 copy값도 바까줘야 화면에서 바껴보임
    if (selectDetail) {
      copySelectDetail = {
        ...copySelectDetail,
        [event.target.name]: event.target.value,
      };
    }

    // handleDZero(event);
    validateInputs({ event, error, setError, helperText, setHelperText });
    setCreateValue({
      ...createValue,
      [event.target.name]: event.target.value,
    });
  };

  // ^ D+0 가맹점인 경우 -> 간편결제도 허용해야 함 ( 안됨 ㅜㅜ )

  // useEffect(() => {
  //   if (accountNumberFlag)
  //     setCreateValue({
  //       ...createValue,
  //       accountNumber: createValue.managerContact,
  //     });
  //   else
  //     setCreateValue({
  //       ...createValue,
  //       accountNumber: "",
  //     });
  // }, [accountNumberFlag]);
  // const handleDZero = (event) => {
  //   if (event.target.name === "paymentEasyYn" && event.target.value === "Y") {
  //     alert("간편결제 허용 가맹점은 정산주기가 0일입니다.");
  //     setCreateValue({ ...createValue, settlementCycle: "0" });
  //     return;
  //   } else if (
  //     event.target.name === "settlementCycle" &&
  //     event.target.value == "0"
  //   ) {
  //     alert("정산주기가 0일인 가맹점은 간편결제 허용입니다.");
  //     setCreateValue({ ...createValue, paymentEasyYn: "Y" });
  //     return;
  //   }
  // };

  useEffect(() => {
    // ^ createValue 바뀔때마다 실행해줘잉
    // ! 업데이트인 경우 - 계좌정보가 바뀌었을 때만 계좌인증 다시하라고 해야 함
    if (selectDetail) {
      //^ 처음 모달창 열었을때 alert 뜨는거 방지
      // if (
      //   createValue?.bankName == "" ||
      //   createValue?.accountNumber == "" ||
      //   createValue?.accountHolder == ""
      // ) {
      //   setIsAccountValidate(true);
      //   return;
      // }
      if (
        copySelectDetail?.bankName !== createValue?.bankName ||
        copySelectDetail?.accountNumber !== createValue?.accountNumber ||
        copySelectDetail?.accountHolder !== createValue?.accountHolder
      ) {
        return setIsAccountValidate(false);
        // alert("계좌인증 필요");
      } else {
        setIsAccountValidate(true);
        return;
      }
    }
  }, [createValue]);

  // ! 정보수정 - 전송로직
  useLayoutEffect(() => {
    if (selectDetail) {
      setCreateValue({ ...copySelectDetail });
      setIsDuplicateChecked(true);
      setIsAccountValidate(true);
    }
  }, [selectDetail]);

  // ! 등록 - 버튼 눌렀을 때
  const checkConfirm = () => {
    if (isDuplicateChecked === false) {
      alert("아이디 중복체크 해주세요");
      return;
    }

    if (createValue.companyType === "2" || createValue.companyType === "3") {
      if (!createValue.companyNum) {
        alert("사업자는 사업자번호가 필요합니다.");
        return;
      }
    }
    if (createValue.companyType === "1") {
      if (!createValue.ceoId) {
        alert("비사업자는 대표자주민번호가 필요합니다.");
        return;
      }
    }
    if (isAccountValidate === false) {
      if (userAuth !== "SC") {
        alert("계좌인증 필요");
        return;
      }
    }
    if (
      createValue.settlementCycle === "0" &&
      !createValue.immediateWithdrawalKey
    ) {
      if (userAuth !== "SC") {
        alert("가상계좌를 선택해주세요");
        return;
      }
    }
    if (isLoading === true) {
      alert("등록중입니다...");
      return;
    }
    if (
      checkoutFranchiseNullInputData({
        createValue,
        setError,
        error,
        setHelperText,
        helperText,
      }) === false
    ) {
      //^ 입력한 필드에 error 가 있으면
      alert("필수값을 모두 확인해주세요");
      return;
    }
    selectDetail ? updateDataApi(createValue) : postDataApi();
  };
  const postDataApi = async () => {
    try {
      setIsLoading(true); // 전송 보내기 직전에 로딩중 = true
      const posted = await postAxios("/franchise/create", createValue);
      if (posted.status === 200) {
        setIsLoading(false);
        alert("등록 완료");
        // navigate("/franchise/list");
      } else {
        alert("실패!");
      }
    } catch (err) {
      if (err.response.data.status === 500) {
        alert("500");
        return;
      }
      if (err.response.data.status === 509) {
        alert("509");
        return;
      }
    }
    return;
  };

  // ! 모달관련
  const [scroll, setScroll] = useState("paper");

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen("");
  };
  const [modalOpen, setModalOpen] = useState(false);
  const userAuth = sessionStorage.getItem("userAuth");
  // ! 계좌인증
  const validateBankAccount = async () => {
    const validationData = {
      // ^ 정보 수정이면 selectData의 정보에 createValue를 덮어써야함
      bankId: selectDetail?.bankName,
      bankOwnerName: selectDetail?.accountHolder,
      memAccntno: selectDetail?.accountNumber,

      bankId: createValue?.bankName,
      bankOwnerName: createValue?.accountHolder,
      memAccntno: createValue?.accountNumber,
    };

    const validationRes = await postAxios(
      `franchise/account/check`,
      validationData
    );

    if (validationRes.data.data == "정상거래") {
      setIsAccountValidate(true);
      alert("계좌 인증되었습니다.");
      return;
    } else {
      setIsAccountValidate(false);
      alert(validationRes.data.data);
      return;
    }
  };

  // ! 정보수정 + 상위 업체 수정인 경우
  const [highCompanyData, setHighCompanyData] = useState({
    highCompanyCode: copySelectDetail?.highCompanyCode,
    highCompanyPath: copySelectDetail?.highCompanyPath,
    highCompanyName: copySelectDetail?.highCompanyName,
  });

  const handleChangeUpdateHighCompany = (row) => {
    if (selectDetail) {
      setHighCompanyData({
        ...highCompanyData,
        highCompanyPath: row?.highCompanyName,
        highCompanyCode: row?.company?.id,
        highCompanyName: row?.company?.user?.agentName,
      });

      setCreateValue({
        ...createValue,
        highCompanyPath: row?.highCompanyName,
        highCompanyCode: row?.company?.id,
        highCompanyName: row?.company?.user?.agentName,
      });
    }
  };
  const [vAccountFlag, setVAccountFlag] = useState();
  useEffect(() => {
    if (createValue?.settlementCycle)
      setVAccountFlag(createValue.settlementCycle);
  }, [createValue]);
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>
          <BasicInput
            classes={classes}
            createValue={createValue}
            setCreateValue={setCreateValue}
            handleChange={handleChange}
            handleModalOpen={handleModalOpen}
            error={error}
            helperText={helperText}
            setIsDuplicateChecked={setIsDuplicateChecked}
            selectDetail={selectDetail}
            highCompanyData={highCompanyData}
            accountNumberFlag={accountNumberFlag}
            setAccountNumberFlag={setAccountNumberFlag}
          />
        </Grid>
        {/* ! 기본 정보 끝 */}

        {/* ! 서비스 설정 및 정산계좌 정보 시작 */}
        {userAuth === "SC" || userAuth === "SCLEVEL12" ? undefined : (
          <Grid item xs={12} xl={6}>
            <ServiceInput
              classes={classes}
              createValue={createValue}
              selectDetail={selectDetail}
              setCreateValue={setCreateValue}
              handleChange={handleChange}
              handleCheck={handleCheck}
              error={error}
              helperText={helperText}
              setIsAccountValidate={setIsAccountValidate}
              isAccountValidate={isAccountValidate}
              accountNumberFlag={accountNumberFlag}
              validateBankAccount={validateBankAccount}
            />
          </Grid>
        )}

        {/* !서비스 설정 및 정산계좌 정보 끝 */}

        {/* 사업자 정보 시작 !! */}
        {createValue.companyType != 1 && (
          <Grid item xs={12} xl={6}>
            <BusinessNum
              classes={classes}
              createValue={createValue}
              setCreateValue={setCreateValue}
              handleChange={handleChange}
              error={error}
              helperText={helperText}
            />
          </Grid>
        )}

        {/* !사업자 정보 끝 !! */}

        {/* 간편결제 정보 시작 !! */}

        {userAuth === "SC" || userAuth === "SCLEVEL12" ? undefined : (
          <Grid item xs={12} xl={6}>
            <EasyPayInput
              classes={classes}
              // createValue={  createValue}
              handleCheck={handleCheck}
              createValue={createValue}
              setCreateValue={setCreateValue}
              handleChange={handleChange}
              error={error}
              helperText={helperText}
            />
          </Grid>
        )}
        {userAuth === "SC" || userAuth === "SCLEVEL12" ? undefined : (
          <Grid
            item
            xs={12}
            xl={6}
            style={{ display: vAccountFlag === "0" ? "block" : "none" }}
          >
            <VaccountInput
              classes={classes}
              handleCheck={handleCheck}
              createValue={createValue}
              setCreateValue={setCreateValue}
              handleChange={handleChange}
              error={error}
              helperText={helperText}
            />
          </Grid>
        )}
      </Grid>
      <Button
        variant='contained'
        color='primary'
        className='m-2'
        onClick={checkConfirm}
      >
        {selectDetail ? "가맹점 수정" : "가맹점 등록"}
      </Button>
      {selectDetail ? null : (
        <Link to='/franchise/list'>
          <Button
            variant='contained'
            className='m-2 btn warning'
            style={{ backgroundColor: "white" }}
          >
            뒤로가기
          </Button>
        </Link>
      )}
      {modalOpen ? (
        <SearchCompanyModal
          modalOpen={true}
          setModalOpen={setModalOpen}
          scroll={scroll}
          handleModalClose={handleModalClose}
          setCreateValue={setCreateValue}
          handleChangeUpdateHighCompany={handleChangeUpdateHighCompany}
          createValue={selectDetail ? copySelectDetail : createValue}
          selectDetail={selectDetail}
        />
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default FranchiseInput;
