import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
} from "@material-ui/core";
import { deleteAxios, postAxios } from "hook/axiosInstance";
import { putAxios } from "hook/axiosInstance";
import SearchCompanyModal from "layouts/company/companyCreate/SearchCompanyModal";
import { useEffect, useState } from "react";
import { checkoutTerminalNullInputData } from "utils/validateInputs";
import { validateInputs } from "utils/validateInputs";
import BasicInput from "../terminalCreate/BasicInput";
import SearchFranchiseModal from "../terminalCreate/SearchFranchiseModal";

const TerminalDetailModal = ({
  modalOpen,
  setModalOpen,
  scroll,
  selectDetail,
  fromFranchiseList,
  handleModalClose,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: 200,
    },
  }));

  const classes = useStyles();

  // ! validation, error
  const [error, setError] = useState({});
  const [helperText, setHelperText] = useState({});

  // ! 수정 - 전송 로직
  const handleChange = (event) => {
    // setValues({ ...values, [prop]: event.target.value });
    validateInputs({ event, error, setError, helperText, setHelperText });

    setCreateValue({ ...createValue, [event.target.name]: event.target.value });
  };
  const handleCheck = () => {
    if (createValue?.terminalStatus === "Y") {
      setCreateValue({ ...createValue, terminalStatus: "N" });
    } else {
      setCreateValue({ ...createValue, terminalStatus: "Y" });
    }
    // setValues({ ...values, [prop]: event.target.value });
    // validateInputs({ event, error, setError, helperText, setHelperText });

    // setCreateValue({ ...createValue, [event.target.name]: event.target.value });
  };
  // ! 가맹점 테이블에서 연 경우를 위해
  const franchiseCode = selectDetail?.franchise?.id;
  const franchiseName = selectDetail?.franchise?.user?.agentName;
  const pgFlag = selectDetail?.pgCompany?.pgFlag;
  const [postFlag, setPostFlag] = useState(false);
  const [alertText, setAlertText] = useState("");

  const [createValue, setCreateValue] = useState({
    ...selectDetail,
    createId: sessionStorage.getItem("userId"),
    userAuth: "1",
    userPw: "1234",
    franchise: "",
    franchiseCode: franchiseCode,
    franchiseName: franchiseName,
    id: selectDetail?.id,
    terminalStatus: selectDetail?.terminalStatus
      ? selectDetail?.terminalStatus
      : "Y",
    // terminalStatus: selectDetail?.terminalStatus,
    // terminalType: "N",
    delYn: "N",
    authYn: "N",
    pgFlag: pgFlag,
  });

  useEffect(() => {
    if (
      createValue.franchiseCode &&
      createValue.franchiseName &&
      createValue.tid &&
      createValue.terminalCode
    )
      setPostFlag(true);
    else setPostFlag(false);
    if (!createValue.franchiseCode) setAlertText("가맹점코드를 입력하세요");
    if (!createValue.franchiseName) setAlertText("가맹점이름을 입력하세요");
    if (!createValue.tid) setAlertText("단말기코드를 입력하세요");
    if (!createValue.terminalCode) setAlertText("시리얼번호를 입력하세요");
  }, [createValue]);

  const sendUpdate = () => {
    if (postFlag) checkConfirm("update");
    else alert(alertText);
  };

  const sendCreate = () => {
    if (postFlag) checkConfirm("create");
    else alert(alertText);
  };

  const checkConfirm = (isUpdate) => {
    const codeCheck = /^[a-zA-Z0-9]{1,20}$/;
    if (!codeCheck.test(createValue.terminalCode)) {
      alert("시리얼넘버는 영문, 숫자포함 20자 미만으로 이루어져있습니다.");
      return;
    }
    if (window.confirm("정말로 등록하시겠습니까?")) {
      if (
        checkoutTerminalNullInputData({
          createValue,
          setError,
          setHelperText,
        })
      ) {
      }
      isUpdate === "update" ? updateDataApi() : createDataApi();
    }
  };

  const updateDataApi = async () => {
    try {
      const posted = await putAxios(
        `/terminal/detail/update/${selectDetail.tid}`,
        createValue
      );
      if (posted.status === 200) {
        alert("등록 완료");
        handleModalClose();
        // window.location.reload();
      } else {
        alert("실패!");
      }
    } catch (err) {
      if (err.response.data.status === 500) {
        alert("500");
        // setCreateValue({ ...TerminalFields });
        return;
      }
      if (err.response.data.status === 509) {
        alert("509");
        // setCreateValue({ ...TerminalFields });
        return;
      }
    }
    return;
  };

  const createDataApi = async () => {
    try {
      const posted = await postAxios(`/terminal/create`, createValue);
      if (posted.status === 200) {
        alert("등록 완료");
        // handleModalClose();
        // window.location.reload();
      } else {
        alert("실패!");
      }
    } catch (err) {
      if (err.response.data.status === 500) {
        alert("500");
        // setCreateValue({ ...TerminalFields });
        return;
      }
      if (err.response.data.status === 509) {
        alert("509");
        // setCreateValue({ ...TerminalFields });
        return;
      }
    }
    return;
  };

  // ! 삭제 - 전송로직

  const handleDelete = () => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      deleteApi();
    }
  };

  const deleteApi = async () => {
    const resDelete = await deleteAxios(
      `terminal/detail/delete/${selectDetail.tid}`
    );
    if (resDelete.data.header.result_code === "200") {
      alert("삭제가 완료되었습니다.");
      handleModalClose();
      // getListApi();
      window.location.reload();
    } else {
      alert("삭제에 실패했습니다");
    }
  };

  // ! 가맹점 검색모달
  const [franchiseSearchModalOpen, setFranchiseSearchModalOpen] =
    useState(false);

  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        scroll={scroll}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        maxWidth='lg'
        disableEnforceFocus
      >
        <DialogTitle id='scroll-dialog-title'>
          {!fromFranchiseList ? <h1>정보 수정</h1> : <h1>단말기 등록</h1>}
          {`${selectDetail?.franchise.user.agentName} mid : ${selectDetail?.franchise?.mid}`}
        </DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          style={{ backgroundColor: "#f9faff" }}
        >
          {fromFranchiseList ? (
            <BasicInput
              classes={classes}
              createValue={createValue}
              handleChange={handleChange}
              handleCheck={handleCheck}
              error={error}
              setError={setError}
              setCreateValue={setCreateValue}
              handleModalOpen={() => {
                setFranchiseSearchModalOpen((prev) => !prev);
              }}
            />
          ) : (
            <BasicInput
              classes={classes}
              createValue={createValue}
              handleChange={handleChange}
              error={error}
              setError={setError}
              handleCheck={handleCheck}
              handleModalOpen={() => {
                setFranchiseSearchModalOpen((prev) => !prev);
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          {fromFranchiseList ? (
            <Button onClick={sendCreate} variant='contained' color='primary'>
              등록하기
            </Button>
          ) : (
            <div className='onlywhenTerminalUpdate'>
              <Button onClick={sendUpdate} variant='contained' color='primary'>
                수정
              </Button>
              &nbsp;&nbsp;
              <Button
                variant='contained'
                onClick={handleDelete}
                style={{ backgroundColor: "red", color: "white" }}
              >
                삭제
              </Button>
            </div>
          )}
          <Button
            onClick={handleModalClose}
            color='secondary'
            variant='outlined'
          >
            닫기
          </Button>
        </DialogActions>
        {franchiseSearchModalOpen ? (
          <SearchFranchiseModal
            modalOpen={true}
            setModalOpen={setModalOpen}
            scroll={scroll}
            handleModalClose={() => setFranchiseSearchModalOpen(false)}
            setCreateValue={setCreateValue}
            createValue={createValue}
          />
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
};
export default TerminalDetailModal;
