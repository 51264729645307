import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
} from "@material-ui/core";

const EnhancedTableHead = ({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  handleRequestSort,
}) => {
  const headCells = [
    { id: "idx", numeric: true, label: "번호" },
    { id: "createDateTime", numeric: true, label: "거래일자" },
    { id: "paymentYn", numeric: true, label: "결제" },
    {
      id: "settlementYn",
      numeric: false,
      label: "정산예정",
    },

    {
      id: "franchise.user.agentName",
      numeric: false,
      label: "가맹점명",
    },
    {
      id: "terminal.tid",
      numeric: false,
      label: "터미널",
    },
    {
      id: "cardAcqName",
      numeric: false,
      label: "매입사",
    },
    {
      id: "inMonth",
      numeric: true,
      label: "할부",
    },
    {
      id: "cardNo",
      numeric: true,
      label: "카드번호",
    },
    {
      id: "approvalNumber",
      numeric: true,
      label: "승인번호",
    },
    {
      id: "tradingAmount",
      numeric: true,
      label: "승인금액",
    },
    {
      id: "cancelAmount",
      numeric: true,
      label: "취소금액",
    },
    {
      id: "dealAmount",
      numeric: true,
      label: "거래금액",
    },
    {
      id: "commissionAmount",
      numeric: true,
      label: "수수료",
    },
    {
      id: "settlementAmount",
      numeric: true,
      label: "정산금액",
    },
    {
      id: "franchise.user.commissionRate",
      numeric: true,
      label: "(%)▲",
    },
    {
      id: "franchise.pgCompany.pgCompanyName",
      numeric: false,
      label: "PG",
    },
    {
      id: "agent",
      numeric: false,
      label: "AGENT",
    },

    {
      id: "receipt",
      numeric: false,
      label: "전표",
    },
    {
      id: "cancelDate",
      numeric: true,
      label: "취소일자",
      label2: "(원거래일자)",
    },
    {
      id: "resultMsg",
      numeric: false,
      label: "결과메시지",
    },
  ];

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <TableHead className="thead-light">
      <TableRow>
        <TableCell className="p-0">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
            className="p-1"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{
                textDecorationStyle: "double",
                textAlign: "center",
              }}
              hideSortIcon
            >
              {headCell.label2 ? (
                <div style={{ whiteSpace: "pre-lined" }}>
                  <h6>{headCell.label}</h6>
                  {/* <br /> */}
                  <h6>{headCell.label2}</h6>
                </div>
              ) : (
                <>
                  <h6 style={{ fontSize: "12px" }}>{headCell.label}</h6>
                </>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
