import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  Button,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";

import Pagination from "react-js-pagination";
import { SearchComponent } from "components/commonTable/SearchComponent";
import { getAxios } from "hook/axiosInstance";
import excelIcon from "assets/custom/excelIcon.svg";
import { formatToWon } from "utils/formatFunction";
import NoData from "components/commonTable/NoData";
import SelectLimit from "components/commonTable/SelectLimit";
import exportToXlsx from "utils/excelExport";
import { Box } from "../../../node_modules/@material-ui/core/index";
import PasswordModal from "layouts/company/companyCommission/PasswordModal";
import { Search } from "components/commonTable/Search";
import { useRecoilState } from "recoil";
import { listLinkStateAtom } from "recoilStates/searchRecoil";
const paymentEasyTypeOptions = {
  0: "없음",
  1: "2064",
};
const DepositFranchiseTable = () => {
  // ! loading
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("settlement/point/list");
  const [loadFlag, setLoadFlag] = useState(false);
  const [listData, setListData] = useState({ dataList: [] });
  const [limit, setLimit] = useState(15);
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);
  const [page, setPage] = useState(1);
  const getListApi = async () => {
    setLoading(true);
    if (!loadFlag) setLoadFlag(true);
    if (listLinkState) {
      const tempAd = listLinkState;
      setAddress(tempAd);
      const resApiData = await getAxios(tempAd);
      if (resApiData.data.header.result_code === "200") {
        setLoading(false);
        setListData(resApiData.data.data);
      }
    }
  };

  useEffect(() => {
    if (listLinkState) {
      getListApi();
    }
  }, [page, limit]);

  useEffect(() => {
    if (listLinkState)
      if (!loadFlag) {
        getListApi();
      }
  }, [listLinkState, loading]);

  const handleChangePage = (e) => {
    setPage(e);
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/page=[^&]*/, `page=${e}`);
    setListLinkState(tempLink);
  };

  // ! N개씩 보기

  const checkFields = [
    { id: "all", name: "모두" },
    { id: "franchiseName", name: "가맹점명" },
    { id: "TID", name: "TID" },
  ];

  // // ! 엑셀 export
  // const XLSX = require("xlsx");
  // const fileType = "application/vnd.ms-excel;charset=utf-8";
  // const fileExtension = ".xlsx";

  // const exportToXlsx = (a, fileName) => {
  //   // ^ 요청 먼저 보냄
  //   getListApi(1000);

  //   const ws = XLSX.utils.json_to_sheet(a);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // };
  const [modalFlag, setModalFlag] = useState(false);
  const [exportFlag, setExportFlag] = useState(false);
  useEffect(() => {
    if (exportFlag) {
      exportToXlsx("가맹점 입출금 내역_", address);
      setExportFlag(false);
      setModalFlag(false);
    }
  }, [exportFlag]);
  return (
    <>
      <PasswordModal
        postCodeFlag={modalFlag}
        handleClose={() => setModalFlag(false)}
        setExportFlag={() => setExportFlag(true)}
      />
      <Search
        dateFlag={true}
        pageFlag={true}
        link={"settlement/point/list?"}
        checkList={["withdraw", "immediate", "searchField"]}
        getListApi={getListApi}
        limit={true}
        setPage={setPage}
        searchField={checkFields}
      />
      <Box height="5px" />
      <Card className="card-box mb-4">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <small>입출금 관리</small>
            <b>가맹점 정산금 입출금내역 조회</b>
          </div>
          <div className="card-header--actions"></div>

          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => setModalFlag(true)}
          >
            <img src={excelIcon} alt="EXCEL" style={{ width: "23px" }} />
            &nbsp; 내보내기
          </Button>
          <SelectLimit limit={limit} setLimit={setLimit} setPage={setPage} />
        </div>
        <CardContent className="p-0">
          <div className="table-responsive">
            <table className="text-nowrap mb-0 table table-striped">
              <TableHead className="thead-light">
                <tr>
                  <th className="text-center" style={{}}>
                    번호
                  </th>
                  <th className="text-center" style={{}}>
                    등록일자
                  </th>
                  <th className="text-center" style={{}}>
                    가맹점명
                  </th>
                  <th className="text-center" style={{}}>
                    내용
                  </th>
                  <th className="text-center" style={{}}>
                    구분
                  </th>
                  <th className="text-center" style={{}}>
                    출금요청금액
                  </th>
                  <th className="text-center">실입금금액</th>
                  <th className="text-center">수수료</th>
                  <th className="text-center">가상계좌</th>
                  <th className="text-center" style={{}}>
                    입금계좌정보
                  </th>
                  <th className="text-center">AGENT</th>
                  <th className="text-center">TID</th>
                  <th className="text-center">ID</th>
                </tr>
              </TableHead>

              <TableBody>
                {listData.dataList.length > 0 ? (
                  listData.dataList.map((row, idx) => {
                    // ! 번호
                    const rowNum =
                      listData.totalCount - limit * (page - 1) - idx;
                    // const parsedDate = listToDate(row.franchise.createDateTime);

                    return (
                      <>
                        <TableRow hover tabIndex={-1} key={idx}>
                          <TableCell className="text-center">
                            {rowNum}
                          </TableCell>
                          <TableCell align="center">
                            {row?.tradingDate}
                          </TableCell>
                          <TableCell align="left">{row?.agentName}</TableCell>
                          <TableCell align="center">
                            {row?.type === "출금"
                              ? "정산금 계좌이체"
                              : row?.paymentYn === "1"
                                ? "결제승인 정산금액 적립"
                                : "결제취소 정산금액 차감"}
                          </TableCell>
                          <TableCell align="center">{row?.type}</TableCell>
                          <TableCell align="right">
                            {formatToWon(row?.amount)}
                          </TableCell>
                          <TableCell align="right">
                            {formatToWon(row?.realAmount)}
                          </TableCell>
                          <TableCell align="right">
                            {formatToWon(row?.commission)}
                          </TableCell>
                          <TableCell align="right">
                            {row?.immediateWithdrawalKey}
                          </TableCell>
                          <TableCell align="left" className="">
                            {row?.bankName}&nbsp;
                            {row?.accountNumber}&nbsp;
                            {row?.accountHolder}
                          </TableCell>
                          <TableCell align="left">{row?.path}</TableCell>
                          <TableCell align="left">{row?.trxId}</TableCell>
                          <TableCell align="left">{row?.userId}</TableCell>
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  <NoData
                    colNum={8}
                    comment="즉시출금 내역이 없습니다."
                    isLoading={loadFlag}
                  />
                )}
              </TableBody>

              {/* <TableRow className="table-dark">
                <TableCell colSpan={4}></TableCell>
                <TableCell align="center">합계</TableCell>
                <TableCell align="right" className="pr-5">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(
                      listData?.paymentListSumDto?.sumAccAmount || 50000000
                    )}
                  </span>
                </TableCell>

                <TableCell colSpan={6} align="center"></TableCell>
              </TableRow> */}
            </table>
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={listData.totalCount}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              firstPageText={"‹‹"}
              lastPageText={"››"}
              onChange={(event) => {
                handleChangePage(event);
              }}
              // onPageChange={handleChangePage}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default DepositFranchiseTable;
