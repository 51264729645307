import FileSaver from "file-saver";
import { getAxios } from "hook/axiosInstance";
import { convertCompany } from "layouts/settlement/dashboards/SettleModalTable";

const XLSX = require("xlsx");
const fileType = "application/vnd.ms-excel;charset=utf-8";
const fileExtension = ".xlsx";

// ! 엑셀 export
const BankExportToXlsx = async (fileName, apiAddress, type) => {
  // ^ 요청 먼저 보냄
  const address = addexcel(apiAddress);
  const response = await getAxios(`${address}`);
  const sum = response.data.map((row) => {
    let arr = row;
    return arr;
  });
  const today = new Date().toISOString().substring(0, 10);
  const ws = XLSX.utils.json_to_sheet(sum, {
    skipHeader: true,
  });
  const new_worksheet_data = [
    type ? headerTopArrayDay : headerTopArray,
    type ? headerArrayDay : headerArray,
    ...XLSX.utils.sheet_to_json(ws, { header: 1 }),
  ];
  const new_workbook = XLSX.utils.book_new();
  const new_worksheet = XLSX.utils.aoa_to_sheet(new_worksheet_data);
  XLSX.utils.book_append_sheet(new_workbook, new_worksheet, "Sheet1");
  const merge = type
    ? [
        { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
        { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
        { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },
        { s: { r: 0, c: 3 }, e: { r: 0, c: 6 } },
        { s: { r: 0, c: 7 }, e: { r: 0, c: 11 } },
        { s: { r: 0, c: 12 }, e: { r: 1, c: 12 } },
        { s: { r: 0, c: 13 }, e: { r: 1, c: 13 } },
        { s: { r: 0, c: 14 }, e: { r: 1, c: 14 } },
        { s: { r: 0, c: 15 }, e: { r: 1, c: 15 } },
        { s: { r: 0, c: 16 }, e: { r: 1, c: 16 } },
        { s: { r: 0, c: 17 }, e: { r: 1, c: 17 } },
        { s: { r: 0, c: 18 }, e: { r: 1, c: 18 } },
      ]
    : [
        { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
        { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
        { s: { r: 0, c: 2 }, e: { r: 0, c: 5 } },
        { s: { r: 0, c: 6 }, e: { r: 0, c: 10 } },
        { s: { r: 0, c: 11 }, e: { r: 1, c: 11 } },
        { s: { r: 0, c: 12 }, e: { r: 1, c: 12 } },
        { s: { r: 0, c: 13 }, e: { r: 1, c: 13 } },
        { s: { r: 0, c: 14 }, e: { r: 1, c: 14 } },
        { s: { r: 0, c: 15 }, e: { r: 1, c: 15 } },
        { s: { r: 0, c: 16 }, e: { r: 1, c: 16 } },
        { s: { r: 0, c: 17 }, e: { r: 1, c: 17 } },
      ];
  new_worksheet["!merges"] = merge;
  const wb = { Sheets: { data: new_worksheet }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + today + fileExtension);
};

export default BankExportToXlsx;

const headerArray = [
  "",
  "",
  "건수",
  "거래금액",
  "수수료",
  "정산금액",
  "건수",
  "취소금액",
  "수수료",
  "차감할금액",
  "차감완료금액",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
];
const headerTopArray = [
  "NO",
  "가맹점명",
  "정상매출",
  "",
  "",
  "",
  "차감매출(정산후 취소)",
  "",
  "",
  "",
  "",
  "정산차감",
  "정산금액",
  "은행명",
  "코드번호",
  "계좌번호",
  "이체금액",
  "예금주",
];

const headerArrayDay = [
  "",
  "",
  "",
  "건수",
  "거래금액",
  "수수료",
  "정산금액",
  "건수",
  "취소금액",
  "수수료",
  "차감할금액",
  "차감완료금액",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
];
const headerTopArrayDay = [
  "NO",
  "정산일자",
  "가맹점명",
  "정상매출",
  "",
  "",
  "",
  "차감매출(정산후 취소)",
  "",
  "",
  "",
  "",
  "정산차감",
  "정산금액",
  "은행명",
  "코드번호",
  "계좌번호",
  "이체금액",
  "예금주",
];

// header받아서 넣어주기
// const ws = XLSX.utils.json_to_sheet(response?.data?.data?.dataList, {
//  skipHeader: true,
// });
// const new_worksheet_data = [headerArray, ...XLSX.utils.sheet_to_json(ws, { header: 1 })];
// const new_workbook = XLSX.utils.book_new();
// const new_worksheet = XLSX.utils.aoa_to_sheet(new_worksheet_data);
// XLSX.utils.book_append_sheet(new_workbook, new_worksheet, 'Sheet1');
// const wb = { Sheets: { data: new_worksheet }, SheetNames: ["data"] };
// const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
// const data = new Blob([excelBuffer], { type: fileType });
// FileSaver.saveAs(data, fileName + today + fileExtension);

// const limitRemove=(address)=>{
//   let pageAddress=address.replace(/limit=(15|50|100)(&|$)/g, "")
//   return pageAddress.replace(/page=\d+(&|$)/g, "")
//}
const addexcel = (address) => {
  return address.replace(/(.*\/)(\w+)(\?.*)/, "$1$2/excel$3");
};
