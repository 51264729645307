import React, { Fragment, useState } from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Card,
  MenuItem,
  TextField,
  FormControl,
  FormHelperText,
  Divider,
  Switch,
} from "@material-ui/core";

// import { FormLabel, FormGroup } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { getAxios, postAxios } from "../../../hook/axiosInstance";
import { Link, useNavigate } from "react-router-dom";

const NoticeInput = () => {
  //
  const navigate = useNavigate();

  //인풋값
  const [inputValue, setInputValue] = useState({
    noticeTitle: "",
    noticeContent: "",
    noticeYn: "Y",
    userId: sessionStorage.getItem("userId"),
  });

  const checkConfirm = () => {
    // if (window.confirm("정말로 등록하시겠습니까?")) {
    if (checkoutNullInputData()) {
      postDataApi();
      // window.location.reload()
    }
    // }
  };

  const postDataApi = async () => {
    try {
      const posted = await postAxios("/notice/create", inputValue);
      setInputValue({});
      alert("등록이 완료되었습니다.");
      navigate("/notice/list");
    } catch (e) {
      alert("등록 실패", e);
    }
  };

  const checkoutNullInputData = () => {
    if (!inputValue.noticeTitle) {
      alert("제목을 입력해주세요");
    }
    if (!inputValue.noticeContent) {
      return alert("내용을 입력해주세요");
    }
    return true;
  };

  // ! 체크박스
  const handleCheck = (e) => {
    let noticeYn;
    if (e.target.checked) {
      noticeYn = "Y";
    } else {
      noticeYn = "N";
    }
    setInputValue({ ...inputValue, [e.target.name]: noticeYn });
  };

  const handleInputValueChange = (event) => {
    setInputValue({ ...inputValue, [event.target.name]: event.target.value });
  };
  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={12}>
          <Card className="p-4 mb-4">
            <div className="font-size-lg font-weight-bold">공지사항 등록</div>
            <Divider className="my-4" />
            <Grid container spacing={4}>
              <Grid item xs={12} lg={12}>
                <FormControlLabel
                  control={<Switch color="primary" defaultChecked />}
                  name="noticeYn"
                  id="noticeYn"
                  // value={inputValue.noticeYn}
                  label={inputValue?.noticeYn === "Y" ? "공지체크" : "공지 X"}
                  onClick={handleCheck}
                />
                <div className="p-3">
                  <label htmlFor="noticeTitle">제목</label>
                  <TextField
                    fullWidth
                    className="m-2"
                    id="noticeTitle"
                    name="noticeTitle"
                    label="제목"
                    onChange={handleInputValueChange}
                  />
                  <br />
                  <br />
                  <label htmlFor="noticeContent">내용</label>
                  <TextField
                    fullWidth
                    className="m-2"
                    id="noticeContent"
                    label="내용"
                    multiline
                    minRows="4"
                    defaultValue="내용을 입력해주세요"
                    variant="outlined"
                    name="noticeContent"
                    onChange={handleInputValueChange}
                  />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  className="m-2"
                  onClick={() => checkConfirm()}
                >
                  공지 등록
                </Button>
                <Link to="/notice/list">
                  <Button
                    variant="contained"
                    className="m-2 btn warning"
                    style={{ backgroundColor: "white" }}
                  >
                    뒤로가기
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default NoticeInput;
