import { listToDate } from "utils/formatFunction";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { Button,Box } from "@mui/material";
import { useState,useEffect } from "react";
import { getAxios } from "hook/axiosInstance";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "react-js-pagination";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  selected: {
    backgroundColor: "rgba(245, 0, 87, 0.08) !important",
  },
}));

const CommissionHistoryModal = ({
  modalOpen,
  scroll,
  selectedRow,
  setModalOpen,
}) => {

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, _setLimit] = useState(15);
  const [listData, setListData] = useState();
  const emptyRows =
  limit - Math.min(limit, listData?.totalCount - (page - 1) * limit);

const classes = useStyles();
  const getListApi = async () => {
    setLoading(true);
    const tempAd=`franchise/commission/history/detail?franchiseCode=${selectedRow?.franchiseCode}`
    const resApiData = await getAxios(tempAd);
    if (resApiData.data.header.result_code === "200") {
      setLoading(false);
      setListData(resApiData.data.data);
    }
    // else {
    //   setListData([]);
    // }
  };
  useEffect(() => {
    getListApi();
  }, []);
  const handleChangePage = async (e) => {
     setPage(e);
     const tempAd=`franchise/commission/history/detail?franchiseCode=${selectedRow?.franchiseCode}&page=${e}`
     const res = await getAxios(tempAd);
     res.data.header.result_code === "200"
       ? setListData(res.data.data)
       : setListData([]);
   };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { height: 435 } }}
      open={modalOpen}
      onClose={handleModalClose}
      scroll="body"
      fullwidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      maxHeight="lg"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          <h2 className="font-weight-bold font-size-xl">
            수수료율 변경이력 조회
          </h2>
          <span className="font-weight-normal">가맹점명 : </span>
          <span className="font-weight-light">
            {selectedRow?.franchiseName}
          </span>
        </DialogTitle>
      </div>
      <DialogContent dividers={scroll === "paper"}>
        {/* <DialogContentText
          id="scroll-dialog-description"
          // ref={descriptionElementRef}
          tabIndex={-1}
        >
         <TypeaheadSearch /> 
        </DialogContentText> */}

        <div className="table-responsive">
          <table className="text-nowrap mb-0 table table-striped">
            <thead className="thead-light">
              <tr>
                <th className="text-center">NO</th>
                <th className="text-center">가맹점명</th>
                <th className="text-center">가맹점수수료</th>
                <th className="text-center">총판</th>
                <th className="text-center">지사</th>
                <th className="text-center">대리점1</th>
                <th className="text-center">대리점2</th>
                <th className="text-center">대리점3</th>
                <th className="text-center">대리점4</th>
                <th className="text-center">대리점5</th>
                <th className="text-center">대리점6</th>
                <th className="text-center">대리점7</th>
                <th className="text-center">등록날짜</th>
              </tr>
            </thead>
            <TableBody>
              {listData?.dataList.map((historyRow, idx) => {
                return (
                  <TableRow tabIndex={-1} key={idx}>
                    <TableCell align="center" style={{ width: "40%" }}>
                      {historyRow?.no}
                    </TableCell>
                    <TableCell align="center" style={{ width: "40%" }}>
                      {historyRow?.franchiseName}&nbsp;&nbsp;
                    </TableCell>
                    <TableCell align="center" style={{ width: "40%" }}>
                    {historyRow?.franchiseCommission}
                    </TableCell>
                    <TableCell align="center" style={{ width: "40%" }}>
                      <Box display='flex' justifyContent='space-between'>
                        {historyRow?.companyName1}&nbsp;&nbsp;
                      {historyRow?.companyCommission1}
                      </Box>
                    </TableCell>
                    <TableCell align="center" style={{ width: "40%" }}>
                      <Box display='flex' justifyContent='space-between'>
                        {historyRow?.companyName2}&nbsp;&nbsp;
                        {historyRow?.companyCommission2}
                      </Box>
                    </TableCell>
                    <TableCell align="center" style={{ width: "40%" }}>
                    <Box display='flex' justifyContent='space-between'>
                        {historyRow?.companyName3}&nbsp;&nbsp;
                        {historyRow?.companyCommission3}
                      </Box>
                    </TableCell>
                    <TableCell align="center" style={{ width: "40%" }}>
                    <Box display='flex' justifyContent='space-between'>
                        {historyRow?.companyName4}&nbsp;&nbsp;
                        {historyRow?.companyCommission4}
                      </Box>
                    </TableCell>
                    <TableCell align="center" style={{ width: "40%" }}>
                    <Box display='flex' justifyContent='space-between'>
                        {historyRow?.companyName5}&nbsp;&nbsp;
                        {historyRow?.companyCommission5}
                      </Box>
                    </TableCell>
                    <TableCell align="center" style={{ width: "40%" }}>
                    <Box display='flex' justifyContent='space-between'>
                        {historyRow?.companyName6}&nbsp;&nbsp;
                        {historyRow?.companyCommission6}
                      </Box>
                    </TableCell>
                    <TableCell align="center" style={{ width: "40%" }}>
                    <Box display='flex' justifyContent='space-between'>
                        {historyRow?.companyName7}&nbsp;&nbsp;
                        {historyRow?.companyCommission7}
                      </Box>
                    </TableCell>
                    <TableCell align="center" style={{ width: "40%" }}>
                    <Box display='flex' justifyContent='space-between'>
                        {historyRow?.companyName8}&nbsp;&nbsp;
                        {historyRow?.companyCommission8}
                      </Box>
                    </TableCell>
                    <TableCell align="center" style={{ width: "40%" }}>
                    <Box display='flex' justifyContent='space-between'>
                        {historyRow?.companyName9}&nbsp;&nbsp;
                        {historyRow?.companyCommission9}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      {historyRow?.createdDateTime}
                    </TableCell>
                  </TableRow>
                );
              })}

              {/* //! 등록 막 했을 때 - 다 0인 상태 */}
              {/* <TableRow tabIndex={-1}>
                <TableCell align="center" style={{ width: "40%" }}>
                  {selectedRow?.franchise?.id}
                </TableCell>
                <TableCell align="center" style={{ width: "40%" }}>
                  {selectedRow?.franchise?.user.agentName}
                </TableCell>
                <TableCell align="center" style={{ width: "40%" }}>
                  {selectedRow?.franchise?.user?.commissionRate}
                </TableCell>
                <TableCell align="center" style={{ width: "40%" }}>
                  {0}
                </TableCell>
                <TableCell align="center" style={{ width: "40%" }}>
                  {0}
                </TableCell>
                <TableCell align="center" style={{ width: "40%" }}>
                  {0}
                </TableCell>
                <TableCell align="center" style={{ width: "40%" }}>
                  {0}
                </TableCell>
                <TableCell align="center" style={{ width: "40%" }}>
                  {0}
                </TableCell>
                <TableCell align="center" style={{ width: "40%" }}>
                  {0}
                </TableCell>
                <TableCell align="center" style={{ width: "40%" }}>
                  {0}
                </TableCell>

                <TableCell align="center">
                  {listToDate(selectedRow?.franchise?.createDateTime)}
                </TableCell>
              </TableRow> */}
            </TableBody>
            {/* <TableRow className="table-dark">
              <TableCell colSpan={2}></TableCell>
              <TableCell align="right">합계</TableCell>
              <TableCell align="right" style={{ paddingRight: "50px" }}>
                {formatToWon(selectedRow?.tradingAmount)}
              </TableCell>
            </TableRow> */}
          </table>
          <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={listData?.totalCount}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              firstPageText={"‹‹"}
              lastPageText={"››"}
              onChange={(event) => {
                handleChangePage(event);
              }}
              // onPageChange={handleChangePage}
              style={{ display: "flex", justifyContent: "center" }}
            />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} color="primary" variant="outlined">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommissionHistoryModal;
