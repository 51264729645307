import React, { Fragment } from "react";

import clsx from "clsx";
import { Link } from "react-router-dom";

import { IconButton, Box, Tooltip } from "@material-ui/core";

import projectLogo from "../../assets/images/tyint_logo.png";

import MenuOpenRoundedIcon from "@material-ui/icons/MenuOpenRounded";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";

const SidebarHeader = (props) => {
  const { sidebarToggleMobile, sidebarToggle, sidebarHover } = props;

  return (
    <Fragment>
      <div
        className={clsx("app-sidebar-header", {
          "app-sidebar-header-close": sidebarToggle && !sidebarHover,
        })}
      >
        <Box className="header-logo-wrapper" title="홈">
          {/* <Link to="/" className="header-logo-wrapper-link"> */}
          <IconButton
            color="primary"
            size="medium"
            className="header-logo-wrapper-btn"
          >
            <img className="app-sidebar-logo" alt="홈" src={projectLogo} />
          </IconButton>
          {/* </Link> */}
          <Box className="header-logo-text">TYINT</Box>
        </Box>
        {/* <Box
          className={clsx("app-sidebar-header-btn", {
            "app-sidebar-header-btn-close": sidebarToggle && !sidebarHover,
          })}
        >
          <Tooltip title="Toggle Sidebar" placement="right">
            <IconButton color="inherit" size="medium">
              {sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="app-sidebar-header-btn-mobile">
          <Tooltip title="Toggle Sidebar" placement="right">
            <IconButton color="inherit" size="medium">
              {sidebarToggleMobile ? (
                <MenuOpenRoundedIcon />
              ) : (
                <MenuRoundedIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box> */}
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

export default SidebarHeader;
