import React, { useEffect, useLayoutEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  IconButton,
  Card,
  CardContent,
  Button,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableFooter,
} from "@material-ui/core";

import CompanyDetailModal from "./CompanyDetailModal";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Pagination from "react-js-pagination";
import { getAxios } from "hook/axiosInstance";
import excelIcon from "assets/custom/excelIcon.svg";
import { listToDate } from "utils/formatFunction";
import SelectLimit from "components/commonTable/SelectLimit";
import NoData from "components/commonTable/NoData";
import exportToXlsx from "utils/excelExport";
import { Box } from "../../../../node_modules/@material-ui/core/index";
import PasswordModal from "../companyCommission/PasswordModal";
import axios from "../../../../node_modules/axios/index";
import { Search } from "components/commonTable/Search";
import { useRecoilState } from "recoil";
import { listLinkStateAtom } from "recoilStates/searchRecoil";
import { IpFlagFn } from "utils/ipFlag";

const CompanyTable = () => {
  const [page, setPage] = useState(1);
  const [address, setAddress] = useState("company/list");

  const [limit, setLimit] = useState(15);
  const [selected, setSelected] = useState([]);
  const [selectDetail, setSelectDetail] = useState("");
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const [loadFlag, setLoadFlag] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");

  // ! Loading
  const [loading, setLoading] = useState(false);

  // ! GET 요청
  const [listData, setListData] = useState({ dataList: [] });
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);

  const getListApi = async () => {
    setLoading(true);
    if (!loadFlag) setLoadFlag(true);
    if (listLinkState) {
      const tempAd = listLinkState;
      setAddress(tempAd);
      const resApiData = await getAxios(tempAd);
      if (resApiData.data.header.result_code === "200") {
        setLoading(false);
        setListData(resApiData.data.data);
      }
    }
  };

  useEffect(() => {
    if (listLinkState) {
      getListApi();
    }
  }, [page, limit]);

  useEffect(() => {
    if (listLinkState)
      if (!loadFlag) {
        getListApi();
      }
  }, [listLinkState, loading]);

  const handleChangePage = (e) => {
    setPage(e);
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/page=[^&]*/, `page=${e}`);
    setListLinkState(tempLink);
  };

  // const handleModalOpen = (event) => {
  //   setModalOpen(true);
  // };
  const [IP, setIP] = useState("");
  const getIP = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };
  useEffect(() => getIP(), []);

  // const handleChangeRowsPerPage = (event) => {
  //   if (parseInt(event.target.value) > listData.dataList.length) {
  //     setRowsPerPage(-1);
  //     return;
  //   }
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleDetailClick = (row, idx) => {
    setModalOpen(true);
    setSelectDetail(row);
  };

  // const handleSelectAllClick = (event) => {
  //   setSelectedAll(!selectedAll);

  //   if (event.target.checked) {
  //     const newSelecteds = listData.dataList.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   } else {
  //     setSelected([]);
  //   }
  // };

  // ! N개씩 보기

  // ! 검색 관련
  const [keyword, setKeyword] = useState("");
  const checkFields = [
    { id: "all", name: "모두" },
    { id: "agentName", name: "업체명" },
    { id: "companyCode", name: "업체코드" },
    { id: "ceoName", name: "대표자명" },
    { id: "managerName", name: "담당자명" },
    { id: "managerContact", name: "담당자 연락처" },
    { id: "userId", name: "아이디" },
  ];

  // function formatDate(date) {
  //   if (date == null) {
  //     return "";
  //   }
  //   var d = new Date(date),
  //     month = "" + (d.getMonth() + 1),
  //     day = "" + d.getDate(),
  //     year = d.getFullYear();

  //   if (month.length < 2) month = "0" + month;
  //   if (day.length < 2) day = "0" + day;

  //   return [year, month, day].join("-");
  // }

  // ! 엑셀 export
  // const XLSX = require("xlsx");
  // const fileType = "application/vnd.ms-excel;charset=utf-8";
  // const fileExtension = ".xlsx";

  // const exportToXlsx = (a, fileName) => {
  //   // ^ 요청 먼저 보냄 - 현재 가지고 있는 totalCount값을 limit로
  //   getListApi(listData.totalCount);

  //   const ws = XLSX.utils.json_to_sheet(a);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  //   getListApi();
  // };

  const userAuth = window.sessionStorage.getItem("userAuth");
  const [modalFlag, setModalFlag] = useState(false);
  const [exportFlag, setExportFlag] = useState(false);
  const [ipFlag, setipFlag] = useState(false);
  useEffect(() => {
    if (exportFlag) {
      exportToXlsx("업체목록조회_", address);
      setExportFlag(false);
      setModalFlag(false);
    }
  }, [exportFlag]);

  useEffect(() => {
    if (userAuth == "SA") {
      setipFlag(IpFlagFn(IP));
    }
  }, [IP]);
  return (
    <>
      <PasswordModal
        postCodeFlag={modalFlag}
        handleClose={() => setModalFlag(false)}
        setExportFlag={() => setExportFlag(true)}
      />
      <Search
        pageFlag={true}
        link={"company/list?"}
        checkList={["searchField"]}
        getListApi={getListApi}
        limit={true}
        setPage={setPage}
        searchField={checkFields}
      />
      <Box height='5px' />
      <Card className='card-box mb-4'>
        <div className='card-header pr-2'>
          <div className='card-header--title'>
            <small>업체 관리</small>
            <b>업체 목록</b>
          </div>
          <div className='card-header--actions'>
            {selected.length > 0 ? (
              <span>{selected.length} selected</span>
            ) : (
              <></>
            )}
            {/* <Link to="/company/create" style={{ textColor: "white" }}>
              <Button variant="contained" color="primary" className="m-2">
                업체 등록
              </Button>
            </Link> */}
            <Box display='flex' alignItems='center'>
              {userAuth === "SA" ? (
                <Box>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => setModalFlag(true)}
                  >
                    <img
                      src={excelIcon}
                      alt='EXCEL'
                      style={{ width: "23px" }}
                    />
                    &nbsp; 내보내기
                  </Button>
                </Box>
              ) : undefined}
              <SelectLimit
                limit={limit}
                setLimit={setLimit}
                setPage={setPage}
              />
            </Box>
          </div>
        </div>
        <CardContent className='p-0'>
          <div className='table-responsive'>
            <table className='text-nowrap mb-0 table table-striped'>
              <TableHead className='thead-light'>
                <tr>
                  <th className='text-center'>번호</th>
                  <th className='text-center'>등록일자</th>
                  <th className='text-center'>업체코드</th>
                  <th className='text-center' style={{ width: "20%" }}>
                    업체명
                  </th>

                  <th className='text-center'>레벨</th>
                  <th className='text-center'>대표자명</th>
                  <th className='text-center'>담당자연락처</th>
                  <th className='text-center'>사</th>
                  <th className='text-center'>상태</th>
                  <th className='text-center'>아이디</th>
                  {userAuth == "SA" && (
                    <th className='text-center'>비밀번호</th>
                  )}
                  {ipFlag && <th className='text-center'>정보수정</th>}
                </tr>
              </TableHead>
              {listData.dataList.length === 0 ? (
                <TableBody>
                  <>
                    <NoData
                      comment='등록된 업체가 없습니다.'
                      colNum={22}
                      isLoading={loading}
                    />
                  </>
                </TableBody>
              ) : (
                <TableBody>
                  {listData.dataList.map((row, idx) => {
                    const isItemSelected = isSelected(row?.name);
                    const parsedDate = listToDate(row?.company?.createDateTime);

                    // ! 번호
                    const rowNum =
                      listData.totalCount - limit * (page - 1) - idx;

                    // ! 사업자구분
                    const cp = row?.company?.user?.companyType;
                    let companyType = "";
                    switch (cp) {
                      case "1":
                        companyType = "비";
                        break;
                      case "2":
                        companyType = "개";
                        break;
                      case "3":
                        companyType = "법";
                        break;
                      case "4":
                        companyType = "기타";
                        break;
                      default:
                      // companyType = "";
                    }

                    // ! 계약 상태
                    const cs = row?.company?.user?.contractStatus;
                    let contractStatus = "";
                    switch (cs) {
                      case "1":
                        contractStatus = "신청";
                        break;
                      case "2":
                        contractStatus = "정상";
                        break;
                      case "3":
                        contractStatus = "해지";
                        break;

                      default:
                        console.log(`Sorry, we are out of .`);
                    }
                    const companyLevelName = row?.company?.companyLevel;
                    let companyLevel = "";
                    switch (companyLevelName) {
                      case 0:
                        companyLevel = "본사";
                        break;
                      case 1:
                        companyLevel = "총판";
                        break;
                      case 2:
                        companyLevel = "지사";
                        break;
                      default:
                        companyLevel =
                          "대리점" + (companyLevelName - 2).toString();
                    }
                    return (
                      <TableRow
                        hover
                        // onClick={() => handleDetailClick(row, idx)}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={idx}
                        selected={isItemSelected}
                      >
                        <TableCell className='text-center'>{rowNum}</TableCell>
                        {/* <TableCell align="center">{row?.date}</TableCell> */}
                        <TableCell align='center'>{parsedDate}</TableCell>
                        <TableCell align='center'>{row?.company?.id}</TableCell>
                        <TableCell className='text-left'>
                          {row.highCompanyName}
                        </TableCell>
                        <TableCell align='center'>
                          {/* {row?.company?.companyLevel} */}
                          {companyLevel}
                        </TableCell>
                        {/* <td className="text-center">
                        <IconButton size="small" color="primary">
                          <FontAwesomeIcon icon={["fas", "ellipsis-h"]} />
                        </IconButton>
                      </td> */}
                        <TableCell align='left'>
                          {row?.company?.user?.ceoName}
                        </TableCell>
                        <TableCell align='center'>
                          {row?.company?.user?.managerContact}
                        </TableCell>
                        <TableCell align='center'>{companyType}</TableCell>

                        <TableCell align='center'>{contractStatus}</TableCell>
                        <TableCell align='left'>
                          {row?.company?.user?.userId}
                        </TableCell>
                        {userAuth == "SA" && (
                          <TableCell align='left'>
                            {row?.company?.user?.userPw}
                          </TableCell>
                        )}
                        {ipFlag && (
                          <TableCell align='center' className='p-1'>
                            <IconButton
                              size='small'
                              color='primary'
                              onClick={() => handleDetailClick(row)}
                            >
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}

                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={20} />
                    </TableRow>
                  )} */}
                </TableBody>
              )}
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </table>
          </div>
          {/* <TablePagination
            rowsPerPageOptions={[10, 25, { label: "All", value: -1 }]}
            colSpan={3}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={listData.totalCount}
            pageRangeDisplayed={5}
            prevPageText={"‹"}
            nextPageText={"›"}
            firstPageText={"‹‹"}
            lastPageText={"››"}
            onChange={(event) => {
              handleChangePage(event);
            }}
            // onPageChange={handleChangePage}
            style={{ display: "flex", justifyContent: "center" }}
          />
        </CardContent>
      </Card>
      {modalOpen ? (
        <CompanyDetailModal
          selectDetail={selectDetail}
          scroll={scroll}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CompanyTable;
