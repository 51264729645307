import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Avatar,
  IconButton,
  Checkbox,
  Card,
  CardContent,
  Button,
  TableBody,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  TableFooter,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Pagination from "react-js-pagination";
import { SearchComponent } from "components/commonTable/SearchComponent";
import { deleteAxios, getAxios } from "hook/axiosInstance";
import FileSaver from "file-saver";
import excelIcon from "assets/custom/excelIcon.svg";
import { formatToWon, formatOnlyDate } from "utils/formatFunction";
import SelectLimit from "components/commonTable/SelectLimit";
import NoData from "components/commonTable/NoData";
import { Box } from "../../../../node_modules/@material-ui/core/index";
import { Search } from "components/commonTable/Search";
import { useRecoilState } from "recoil";
import { listLinkStateAtom } from "recoilStates/searchRecoil";
import SettleFranchiseTermTableHeader from "./SettleFranchiseTermTableHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const SettleFranchiseEtcTable = () => {
  // ! 로딩
  const [isLoading, setIsLoading] = useState(false);

  // ! GET 요청

  const [listData, setListData] = useState({ dataList: [] });
  const [limit, setLimit] = useState(15);
  const [userAuth, setUserAuth] = useState();
  const [address, setAddress] = useState();
  const [loadFlag, setLoadFlag] = useState(false);
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const getListApi = async () => {
    setLoading(true);
    if (!loadFlag) setLoadFlag(true);
    if (listLinkState) {
      const tempAd = listLinkState;
      setAddress(tempAd);
      const resApiData = await getAxios(tempAd);
      if (resApiData.data.header.result_code === "200") {
        setLoading(false);
        setListData(resApiData.data.data);
      }
    }
  };

  useEffect(() => {
    setUserAuth(window.sessionStorage.getItem("userAuth"));
  }, []);

  useEffect(() => {
    if (listLinkState) {
      getListApi();
    }
  }, [page, limit]);

  useEffect(() => {
    if (listLinkState)
      if (!loadFlag) {
        getListApi();
      }
  }, [listLinkState, loading]);

  const handleChangePage = (e) => {
    setPage(e);
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/page=[^&]*/, `page=${e}`);
    setListLinkState(tempLink);
  };

  const emptyRows =
    limit - Math.min(limit, listData.totalCount - (page - 1) * limit);

  // const handleSelectAllClick = (event) => {
  //   setSelectedAll(!selectedAll);

  //   if (event.target.checked) {
  //     const newSelecteds = listData.dataList.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   } else {
  //     setSelected([]);
  //   }
  // };
  const checkFields = [
    { id: "all", name: "모두" },
    { id: "companyName", name: "업체명" },
    { id: "franchiseName", name: "가맹점명" },
    { id: "deductTrxId", name: "차감내역" },
  ];

  // ! 삭제
  const handleDelete = async (row) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      const deleteRes = await deleteAxios(`settlement/etc/delete?id=${row.id}`);
      if (deleteRes.data.header.result_code === "200") {
        alert("성공적으로 삭제되었습니다.");
        getListApi(15);
      } else {
        alert("오류! 삭제 실패");
      }
    }
  };
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("num");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  return (
    <>
      <Search
        dateFlag={true}
        pageFlag={true}
        link={"settlement/franchise/etc/list?"}
        checkList={["pg", "searchField"]}
        getListApi={getListApi}
        limit={true}
        setPage={setPage}
        searchField={checkFields}
      />
      <Box height="5px" />
      <Card className="card-box mb-4">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <small>가맹점 정산</small>
            <b>가맹점 정산차감 조회 (통신비 등)</b>
          </div>
          <div className="card-header--actions"></div>

          <SelectLimit limit={limit} setLimit={setLimit} setPage={setPage} />
        </div>
        <CardContent className="p-0">
          <div className="table-responsive">
            <table className="text-nowrap mb-0 table table-striped">
              <TableHead className="thead-light">
                <tr>
                  <th className="text-center">번호</th>
                  {cntArrtype.map((obj, i) => {
                    return <SettleFranchiseTermTableHeader
                      key={i}
                      id={obj.id}
                      order={order}
                      orderBy={orderBy}
                      label={obj.title}
                      handleRequestSort={handleRequestSort}
                      rowCount={listData.length}
                    />
                  })}
                  <th className="text-center">삭제</th>
                </tr>
              </TableHead>

              <TableBody>
                {listData.dataList.length > 0 ? (
                  stableSort(
                    listData.dataList,
                    getComparator(order, orderBy)
                  ).map((row, idx) => {
                    // ! 번호
                    const rowNum =
                      listData.totalCount - limit * (page - 1) - idx;

                    const labelId = "enhanced-table-checkbox";

                    // ! 날짜
                    const deductDate = row?.withdrawlDateTime;

                    let settlementPlanD = row?.settlementPlanDate
                      ? formatOnlyDate(row?.settlementPlanDate)
                      : "없음";

                    // ! 카드
                    // const parsedCardNo = maskCardNo(row.settlementDto.cardNo);

                    return (
                      <TableRow hover tabIndex={-1} key={idx}>
                        <TableCell className="text-center">{rowNum}</TableCell>
                        <TableCell align="center">
                          {deductDate || "9999-99-99"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.franchiseName}
                        </TableCell>
                        <TableCell align="center">
                          {formatToWon(-row?.amountMoney)}
                        </TableCell>
                        <TableCell align="center">
                          {row?.deductMsg || "차감내역이 없습니다."}
                        </TableCell>
                        <TableCell align="center">{row?.companyName}</TableCell>

                        <TableCell align="right" className="text-center p-1">
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => handleDelete(row)}
                          >
                            삭제
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <>
                    <NoData
                      isLoading={isLoading}
                      comment="차감 내역이 없습니다."
                      colNum={7}
                    />
                  </>
                )}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={20} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </table>
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={listData.totalCount}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              firstPageText={"‹‹"}
              lastPageText={"››"}
              onChange={(event) => {
                handleChangePage(event);
              }}
              // onPageChange={handleChangePage}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default SettleFranchiseEtcTable;

const cntArrtype = [
  { title: '차감일자', id: 'settlementPlanDate' },
  { title: '가맹점명', id: 'franchiseName' },
  { title: '차감금액', id: 'amountMoney' },
  { title: '차감내역', id: 'deductMsg' },
  { title: '업체명', id: 'companyName' },
]
