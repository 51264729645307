import React, { useEffect, useState } from "react";
import exportToXlsx from "utils/excelExport";

import {
  Card,
  CardContent,
  Button,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Pagination from "react-js-pagination";
import { getAxios } from "hook/axiosInstance";
import excelIcon from "assets/custom/excelIcon.svg";
import { formatToWon, listToDate } from "utils/formatFunction";
import SelectLimit from "components/commonTable/SelectLimit";
import NoData from "components/commonTable/NoData";
import { Box } from "../../../../node_modules/@material-ui/core/index";
import PasswordModal from "layouts/company/companyCommission/PasswordModal";
import { Search } from "components/commonTable/Search";
import { useRecoilState } from "recoil";
import { listLinkStateAtom } from "recoilStates/searchRecoil";
import SettleFranchiseTermTableHeader from "../settleFranchise/SettleFranchiseTermTableHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const SalesFranchiseTable = () => {
  // ! Loading
  const [loading, setLoading] = useState(false);

  // ! GET 요청

  const [listData, setListData] = useState({ dataList: [] });
  const [limit, setLimit] = useState(15);
  const [address, setAddress] = useState("settlement/franchise/sales/list");
  const [userAuth, setUserAuth] = useState();
  const [loadFlag, setLoadFlag] = useState(false);
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);
  const [page, setPage] = useState(1);

  const getListApi = async () => {
    setLoading(true);
    if (!loadFlag) setLoadFlag(true);
    if (listLinkState) {
      const tempAd = listLinkState;
      setAddress(tempAd);
      const resApiData = await getAxios(tempAd);
      if (resApiData.data.header.result_code === "200") {
        setLoading(false);
        setListData(resApiData.data.data);
      }
    }
  };

  useEffect(() => {
    setUserAuth(window.sessionStorage.getItem("userAuth"));
  }, []);

  useEffect(() => {
    if (listLinkState) {
      getListApi();
    }
  }, [page, limit]);

  useEffect(() => {
    if (listLinkState)
      if (!loadFlag) {
        getListApi();
      }
  }, [listLinkState, loading]);

  const handleChangePage = (e) => {
    setPage(e);
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/page=[^&]*/, `page=${e}`);
    setListLinkState(tempLink);
  };

  const classes = useStyles();

  // const handleSelectAllClick = (event) => {
  //   setSelectedAll(!selectedAll);

  //   if (event.target.checked) {
  //     const newSelecteds = listData.dataList.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   } else {
  //     setSelected([]);
  //   }
  // };

  // ! N개씩 보기

  // ! 검색 관련

  const checkFieldsThree = [
    { id: "all", name: "모두" },
    { id: "companyName", name: "업체명" },
    { id: "franchiseName", name: "가맹점명" },
  ];

  // ! 엑셀 export
  // const XLSX = require("xlsx");
  // const fileType = "application/vnd.ms-excel;charset=utf-8";
  // const fileExtension = ".xlsx";

  // const exportToXlsx = (a, fileName) => {
  //   // ^ 요청 먼저 보냄
  //   getListApi(-1);

  //   const ws = XLSX.utils.json_to_sheet(a);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // };
  const [modalFlag, setModalFlag] = useState(false);
  const [exportFlag, setExportFlag] = useState(false);
  useEffect(() => {
    if (exportFlag) {
      exportToXlsx("가맹점 매출내역_", address);
      setExportFlag(false);
      setModalFlag(false);
    }
  }, [exportFlag]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("num");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  return (
    <>
      <PasswordModal
        postCodeFlag={modalFlag}
        handleClose={() => setModalFlag(false)}
        setExportFlag={() => setExportFlag(true)}
      />
      <Search
        dateFlag={true}
        pageFlag={true}
        link={"settlement/franchise/sales/list?"}
        checkList={["pg", "dateType", "searchField"]}
        getListApi={getListApi}
        limit={true}
        setPage={setPage}
        searchField={checkFieldsThree}
      />
      <Box height="5px" />
      <Card className="card-box mb-4">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <small>가맹점 정산</small>
            <b>가맹점 매출금액</b>
          </div>
          <div className="card-header--actions"></div>
          {userAuth === "SA" ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setModalFlag(true)}
              size="small"
            >
              <img src={excelIcon} alt="EXCEL" style={{ width: "23px" }} />
              &nbsp; 내보내기
            </Button>
          ) : undefined}
          <SelectLimit limit={limit} setLimit={setLimit} setPage={setPage} />
        </div>
        <CardContent className="p-0">
          <div className="table-responsive">
            <table className="text-nowrap mb-0 table table-striped">
              <TableHead className="thead-light">
                <tr>
                  <th className="text-center">번호</th>
                  {cntArrtype.map((obj, i) => {
                    return <SettleFranchiseTermTableHeader
                      key={i}
                      id={obj.id}
                      order={order}
                      orderBy={orderBy}
                      label={obj.title}
                      handleRequestSort={handleRequestSort}
                      rowCount={listData.length}
                    />
                  })}
                  <th className="text-center">AGENT</th>
                </tr>
              </TableHead>

              <TableBody>
                {listData.dataList.length > 0 ? (
                  stableSort(
                    listData.dataList,
                    getComparator(order, orderBy)
                  ).map((row, idx) => {
                    // ! 번호
                    const rowNum =
                      listData.totalCount - limit * (page - 1) - idx;

                    const labelId = "enhanced-table-checkbox";

                    // ! 날짜
                    const appDate = listToDate(row?.createDateTime);

                    let settlementPlanD = row?.settlementPlanDate
                      ? listToDate(row?.settlementPlanDate)
                      : "없음";

                    // ! 카드
                    // const parsedCardNo = maskCardNo(row?.cardNo);

                    return (
                      <TableRow hover tabIndex={-1} key={idx}>
                        <TableCell className="text-center">{rowNum}</TableCell>
                        <TableCell align="left">{row?.franchiseName}</TableCell>
                        <TableCell align="right">{row?.payCount}</TableCell>
                        <TableCell align="right" className="pr-5">
                          {formatToWon(row?.appAmount)}
                        </TableCell>
                        <TableCell align="right" className="pr-5">
                          <span className="text-danger">
                            {formatToWon(row?.cancelAmount)}
                          </span>
                        </TableCell>

                        <TableCell align="right" className="pr-5">
                          {formatToWon(row?.tradingAmount)}
                        </TableCell>

                        <TableCell align="right" className="pr-5">
                          {formatToWon(row?.settlementAmount)}
                        </TableCell>
                        <TableCell align="left">{row?.path}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <>
                    <NoData
                      comment="정산내역이 없습니다."
                      colNum={22}
                      isLoading={loading}
                    />
                  </>
                )}

                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={20} />
                  </TableRow>
                )} */}
              </TableBody>
              <TableRow className="table-dark">
                <TableCell rowSpan={2}></TableCell>
                <TableCell align="center" rowSpan={2}>
                  합계
                </TableCell>
                <TableCell align="right">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(listData?.settleDoneResDto?.payCount)}
                  </span>
                </TableCell>
                <TableCell align="right" className="pr-5">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(listData?.settleDoneResDto?.appAmount || 0)}
                  </span>
                </TableCell>
                <TableCell align="right" className="pr-5">
                  <span className="text-danger">
                    <span className="font-weight-bold font-size-sm">
                      {formatToWon(
                        listData?.settleDoneResDto?.cancelAmount || 0
                      )}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="right" className="pr-5">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(
                      listData?.settleDoneResDto?.tradingAmount || 0
                    )}
                  </span>
                </TableCell>
                <TableCell align="right" className="pr-5">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(
                      listData?.settleDoneResDto?.settlementAmount || 0
                    )}
                  </span>
                </TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </table>
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={listData.totalCount}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              firstPageText={"‹‹"}
              lastPageText={"››"}
              onChange={(event) => {
                handleChangePage(event);
              }}
              // onPageChange={handleChangePage}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default SalesFranchiseTable;

const cntArrtype = [
  { title: '가맹점명', id: 'franchiseName' },
  { title: '결제건수', id: 'payCount' },
  { title: '승인금액', id: 'appAmount' },
  { title: '취소금액', id: 'cancelAmount' },
  { title: '거래금액', id: 'tradingAmount' },
  { title: '정산금액', id: 'settlementAmount' },
]
