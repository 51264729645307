import React, { Fragment } from "react";

// import {
//   Grid,
//   FormControlLabel,
//   Checkbox,
//   Card,
//   MenuItem,
//   TextField,
//   FormControl,
//   FormHelperText,
//   Divider,
// } from "@material-ui/core";

// import { FormLabel, FormGroup } from "@material-ui/core";

import FormsControls from "./FormsControls";

// const currencies = [
//   {
//     value: "USD",
//     label: "$",
//   },
//   {
//     value: "EUR",
//     label: "€",
//   },
//   {
//     value: "BTC",
//     label: "฿",
//   },
//   {
//     value: "JPY",
//     label: "¥",
//   },
// ];
// const [currency, setCurrency] = React.useState("EUR");

// const handleChange = (event) => {
//   setCurrency(event.target.value);
// };

// const [value2, setValue2] = React.useState("Controlled");

// const handleChange2 = (event) => {
//   setValue2(event.target.value);
// };

// const [state, setState] = React.useState({
//   gilad: true,
//   jason: false,
//   antoine: false,
// });

// const { gilad, jason, antoine } = state;

// const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

const NoticeCreate = () => {
  return <FormsControls />;
};

export default NoticeCreate;
