import { ExampleWrapperSimple } from "layouts/wrappers/ExampleWrapperSimple";
import React, { Fragment } from "react";

import { ExampleWrapperSeamless } from "../../wrappers/ExampleWrapperSeamless";
import TerminalInput from "./TerminalInput";
import FormsControlsBasic from "./FormsControlsBasic";
import FormsControlsInputGroups from "./FormsControlsInputGroups";

// import

const TerminalCreate = () => {
  return (
    <Fragment>
      {/* <PageTitle
        titleHeading="Controls"
        titleDescription="Wide selection of forms controls, using a standardised code base, specifically for React."
      /> */}
      <ExampleWrapperSimple sectionHeading="단말기(터미널) 등록">
        <TerminalInput />
      </ExampleWrapperSimple>
      {/* <ExampleWrapperSimple sectionHeading="Basic">
        <FormsControlsBasic />
      </ExampleWrapperSimple>
      <ExampleWrapperSimple sectionHeading="Input groups">
        <FormsControlsInputGroups />
      </ExampleWrapperSimple> */}
    </Fragment>
  );
};
export default TerminalCreate;
