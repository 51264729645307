import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Divider,
  InputAdornment,
  TextField,
  CardActions,
} from "@material-ui/core";
import { Grid } from "@mui/material";

import CommonTextFieldFullwidth from "components/commonInput/CommonTextField";
import { postTerminalAxios } from "hook/axiosInstance";
import SearchFranchiseModal from "layouts/terminal/terminalCreate/SearchFranchiseModal";

import React, { useState } from "react";
import { FormControl, MenuItem } from "@mui/material";

import "assets/custom/selectbox.css";
import { gogokeyEncrypt } from "utils/seed";
import PayAlert from "components/PayAlert";

const BasicInput = ({ besang }) => {
  // ! 선택지
  const validationMonth = [
    {
      value: "01",
      label: "01",
    },
    {
      value: "02",
      label: "02",
    },
    {
      value: "03",
      label: "03",
    },
    {
      value: "04",
      label: "04",
    },
    {
      value: "05",
      label: "05",
    },
    {
      value: "06",
      label: "06",
    },
    {
      value: "07",
      label: "07",
    },
    {
      value: "08",
      label: "08",
    },
    {
      value: "09",
      label: "09",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "11",
      label: "11",
    },
    {
      value: "12",
      label: "12",
    },
  ];

  const yearsNum = [...new Array(15)].map((_, i) => i + 2023); // [2023, 2024, 2025 ... 2037]
  let validationYear = [];
  yearsNum.map((year) => {
    validationYear.push({
      label: year,
      value: year - 2000,
    });
  });

  const paymentEasyLimitPeriodList = [
    { label: "일시불", value: "00" },
    { label: "1개월", value: "01" },
    { label: "2개월", value: "02" },
    { label: "3개월", value: "03" },
    { label: "4개월", value: "04" },
    { label: "5개월", value: "05" },
    { label: "6개월", value: "06" },
    { label: "7개월", value: "07" },
    { label: "8개월", value: "08" },
    { label: "9개월", value: "09" },
    { label: "10개월", value: "10" },
    { label: "11개월", value: "11" },
    { label: "12개월", value: "12" },
  ];

  // ! 세션에서 mid, tid 꺼내오기 => 얘네 있어야 결제됨, 로그인할때 넣어둠
  const mid = sessionStorage.getItem("mid");
  // const mid = "0000000376";
  const tid = sessionStorage.getItem("tid");
  // const tid = "E000376";
  // ! 모달
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  // ! 요청 성공여부, 알림창
  const [isSuccess, setIsSuccess] = useState("");
  const [resultMsg, setresultMsg] = useState("");

  // ! 로직
  const [error, setError] = useState({});
  const [helperText, setHelperText] = useState({});
  const [createValue, setCreateValue] = useState({
    mid: mid,
    tid: tid,
    payType: "A",
    payMethod: "C",
    taxFree: "0",
    taxFreeMoney: "0",
    tipMoney: "0",
  });

  const handleChange = (e) => {
    setCreateValue({ ...createValue, [e.target.name]: e.target.value });
  };

  // ! 요청

  const handleWithdrawl = async () => {
    const amountMoney = createValue?.amountMoney * 1;
    const vatMoney = Math.ceil(amountMoney / 1.1);
    const supplyMoney = amountMoney - vatMoney;

    const fullCardNo =
      createValue?.cardNo +
      "=" +
      createValue?.cardExpireYear +
      createValue?.cardExpireMonth;


    const encryptedCardNo = gogokeyEncrypt(fullCardNo);
    const willSend = {
      ...createValue,
      vatMoney: vatMoney,
      supplyMoney: supplyMoney,
      cardNo: encryptedCardNo,
    };

    if (window.confirm("정말 결제하시겠습니까?")) {
      const res = await postTerminalAxios("pay", willSend, "9999999999");
      if (res?.data.resultMsg == "정상") {
        alert("결제 완료");
        setIsSuccess("success");
        setresultMsg(res?.data.resultMsg);
      } else {
        alert(res?.data.resultMsg);
        setIsSuccess("error");
        setresultMsg(res?.data.resultMsg);
      }
    }
  };

  return (
    <>
      {isSuccess ? (
        <PayAlert
          isSuccess={isSuccess}
          actionName="간편결제"
          resultMsg={resultMsg}
        />
      ) : null}
      <Grid container spacing={4}>
        <Grid item xs={12} lg={5}>
          <Card className="p-4 mb-4" sx={{}}>
            <div className="font-size-lg font-weight-bold">
              신용카드 간편결제
            </div>
            <Divider className="my-4" />
            <span className="m-2 text-info">가맹점명 :</span>
            <span className="text-danger">
              {sessionStorage.getItem("userName")}
            </span>{" "}
            &nbsp;
            <span>{!besang ? "비인증" : "비생인증"}</span>
            <Grid container>
              <Grid item xs={12}>
                <CommonTextFieldFullwidth
                  handleChange={handleChange}
                  createValue={createValue}
                  helperText={helperText}
                  error={error}
                  field="productName"
                  meaning="상품명"
                  medium={true}
                  required={true}
                />
              </Grid>

              <Grid item xs={12}>
                <CommonTextFieldFullwidth
                  handleChange={handleChange}
                  createValue={createValue}
                  helperText={helperText}
                  error={error}
                  field="buyer"
                  meaning="고객명"
                  label="주문고객명"
                  medium={true}
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <CommonTextFieldFullwidth
                  handleChange={handleChange}
                  createValue={createValue}
                  helperText={helperText}
                  error={error}
                  field="buyerNum"
                  meaning="연락처"
                  placeholder="주문고객 휴대폰 번호"
                  type="tel"
                  medium={true}
                  required={true}
                />
              </Grid>

              <Grid item xs={12}>
                <CommonTextFieldFullwidth
                  handleChange={handleChange}
                  createValue={createValue}
                  helperText={helperText}
                  error={error}
                  field="amountMoney"
                  meaning="결제금액"
                  placeholder="숫자만입력"
                  medium={true}
                  required={true}
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <CommonTextFieldFullwidth
                  handleChange={handleChange}
                  createValue={createValue}
                  helperText={helperText}
                  error={error}
                  field="cardNo"
                  meaning="카드번호"
                  placeholder="카드번호(숫자만 입력)"
                  medium={true}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} className="m-0">
                <div className="d-flex flex-row">
                  <FormControl>
                    <TextField
                      id="cardExpireMonth"
                      name="cardExpireMonth"
                      variant="outlined"
                      className="m-2"
                      select
                      value={createValue?.cardExpireMonth}
                      onChange={handleChange}
                      helperText={helperText?.cardExpireMonth}
                      error={error?.cardExpireMonth}
                      defaultValue="0"
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "center",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            유효기간 (월 Month)&nbsp;
                            <FontAwesomeIcon
                              icon="fa-solid fa-asterisk"
                              size="xs"
                              color="red"
                            />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {validationMonth.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  <FormControl>
                    <TextField
                      id="cardExpireYear"
                      name="cardExpireYear"
                      className="mt-2 mb-2"
                      variant="outlined"
                      select
                      value={createValue?.cardExpireYear}
                      onChange={handleChange}
                      helperText={helperText?.cardExpireYear}
                      error={error?.cardExpireYear}
                      defaultValue="0"
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            유효기간 (연 Year)&nbsp;
                            <FontAwesomeIcon
                              icon="fa-solid fa-asterisk"
                              size="xs"
                              color="red"
                            />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {validationYear.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} className="m-0">
                <FormControl className="m-2">
                  <TextField
                    id="inMonth"
                    name="inMonth"
                    variant="outlined"
                    select
                    value={createValue?.inMonth}
                    onChange={handleChange}
                    helperText={helperText?.inMonth}
                    error={error?.inMonth}
                    defaultValue="0"
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: "center",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          간편결제할부기간&nbsp;
                          <FontAwesomeIcon
                            icon="fa-solid fa-asterisk"
                            size="xs"
                            color="red"
                          />
                        </InputAdornment>
                      ),
                    }}
                  >
                    {paymentEasyLimitPeriodList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              {/* //! 비생인증 */}
              {besang ? (
                <div className="password-birthday-auth">
                  <Grid item xs={12}>
                    <CommonTextFieldFullwidth
                      handleChange={handleChange}
                      createValue={createValue}
                      helperText={helperText}
                      error={error}
                      field="password"
                      meaning="비밀번호"
                      placeholder="숫자만입력"
                      medium={true}
                      required={true}
                      type="password"
                    />
                    <CommonTextFieldFullwidth
                      handleChange={handleChange}
                      createValue={createValue}
                      helperText={helperText}
                      error={error}
                      field="birthday"
                      meaning="생년월일"
                      placeholder="숫자만입력"
                      medium={true}
                      required={true}
                      type="number"
                    />
                  </Grid>
                </div>
              ) : null}
            </Grid>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                className="m-0"
                onClick={handleWithdrawl}
              >
                결제하기
              </Button>

              {modalOpen ? (
                <SearchFranchiseModal
                  modalOpen={true}
                  handleModalClose={handleModalClose}
                  createValue={createValue}
                  setCreateValue={setCreateValue}
                />
              ) : null}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default BasicInput;
