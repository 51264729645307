import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  TableCell,
  TableFooter,
  TableRow,
  TableBody,
  TableHead,
  GlobalStyles,
} from "@mui/material";
import { SearchComponent } from "components/commonTable/SearchComponent";
import { getAxios } from "hook/axiosInstance";
import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import TypeaheadSearch from "./TypeaheadSearch";
import { makeStyles } from "@material-ui/core/styles";
import { ConstructionOutlined } from "../../../../node_modules/@mui/icons-material/index";
import { Box } from "../../../../node_modules/@material-ui/core/index";

const useStyles = makeStyles((theme) => ({
  selected: {
    padding: "0.5rem",
    color: "red",
  },
}));

const SearchCompanyModal = ({
  modalOpen,
  setModalOpen,
  scroll,
  handleModalClose,
  setCreateValue,
  createValue,
  handleChangeUpdateHighCompany,
  selectDetail,
}) => {
  // ! 상위 검색 로직
  const [highListData, setHighListData] = useState({ dataList: [] });
  const [selectedValue,setSelectedValue] = useState("")
  const [companyData,setCompanyData] = useState({dataList:[]})
  const [keyword, setKeyword] = useState("");
  const [checkBool, setCheckBool] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const checkFields = [
    { field: "companyName", name: "업체명" },
    { id: "companyName", name: "업체명" },
  ];
  const classes = useStyles();

  useEffect(() => {
    getHighCompanyListApi();
  }, []);

  useEffect(() => {
  }, [highListData]);

  useEffect(() => {
    getHighCompanySearchListApi(selectedValue);
  }, [selectedValue]);

  const getHighCompanySearchListApi = async (keyword) => {
    const responseHighList = await getAxios(`company/list?level=${true}&keyword=${keyword.companyName || ""}&searchField=agentName`);
    
    if (responseHighList.data.header.result_code === "200") {
      setHighListData(responseHighList.data.data);
    } else {}
  };

  const getHighCompanyListApi = async () => {
    const responseHighList = await getAxios(`company/list?level=${true}`);
    if (responseHighList.data.header.result_code === "200") {
      setCompanyData(responseHighList.data.data);
      setHighListData(responseHighList.data.data)
    } else {}
  };

  // ! 선택
  const handleCompanySelect = (row) => {
    if (selectDetail) {
      handleChangeUpdateHighCompany(row);
    } else {
      
      setCreateValue({
        ...createValue,
        highCompanyPath: row?.highCompanyName,
        highCompanyCode: row?.company?.id,
        highCompanyName: row?.company?.user?.agentName,
        companyLevel: row?.company?.companyLevel + 1,
      });
    }
    handleModalClose();
  };

  // ! 페이징
  const [page, setPage] = useState(1);
  const emptyRows =
    10 - Math.min(10, highListData.totalCount - (page - 1) * 10);

  const handlePageChange = (page) => {
    setPage(page);
    const paging = async () => {
      const res = await getAxios(`company/list?level=${true}&page=${page}&keyword=${encodeURI(keyword)}&searchField=agentName`);
      res.data.header.result_code === "200"
        ? setHighListData(res.data.data)
        : setHighListData([]);
    };
    paging();
  };
  const searchClick = async() => {
    const responseHighList = await getAxios(`company/list?level=${true}&keyword=${encodeURI(keyword)}&searchField=agentName`);
    if (responseHighList.data.header.result_code === "200") {
      setCompanyData(responseHighList.data.data);
      setHighListData(responseHighList.data.data)
    } else {}
    setPage(1)
  };
  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            width: "80vw",
            height: "87vh",
            // maxHeight: 300,
            maxWidth: "1400px",
          },
        }}
        open={modalOpen}
        onClose={handleModalClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        classes={{ paper: "modal-dark " }}
        maxWidth="lg"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#000",
          }}
        >
          <DialogTitle id="scroll-dialog-title">상위업체 검색</DialogTitle>
        </div>
        <DialogContent
          dividers={scroll === "paper"}
          style={{ padding: "5px 20px" }}
        >
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
          >
          <SearchComponent
            keyword={keyword}
            listData={companyData}
            checkBool={checkBool}
            setCheckBool={setCheckBool}
            setKeyword={setKeyword}
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            dateDisabled={false}
            searchClick={searchClick}
          />
          {/* <TypeaheadSearch list={companyData.dataList} selectedValue={selectedValue} setSelectedValue={setSelectedValue}/> */}
            <Box height='5px' />
          </DialogContentText>

          <div className="table-responsive">
            <table
              className="text-nowrap mb-0 table table-striped"
              style={{ tableLayout: "fixed" }}
            >
              <TableHead>
                <tr>
                  <th className="text-center" style={{ width: "5%" }}>
                    선택
                  </th>
                  <th className="text-center" style={{ width: "5%" }}>
                    번호
                  </th>
                  <th className="text-center" style={{ width: "5%" }}>
                    코드
                  </th>
                  <th className="text-center" style={{ width: "5%" }}>
                    업체 레벨
                  </th>
                  <th className="text-center" style={{ width: "45%" }}>
                    업체명
                  </th>
                  <th className="text-center" style={{ width: "10%" }}>
                    대표자명
                  </th>
                  <th className="text-center" style={{ width: "10%" }}>
                    전화번호
                  </th>
                  <th className="text-center" style={{ width: "15%" }}>
                    담당자
                  </th>
                  <th className="text-center" style={{ width: "10%" }}>
                    아이디
                  </th>
                </tr>
              </TableHead>
              <TableBody>
                {/* {(rowsPerPage > 0
                  ? listData.dataList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : listData.dataList
                ).map((row, idx) => { */}
                {highListData.dataList.map((row, idx) => {
                  // ! 번호
                  const rowNum =
                    highListData.totalCount - 10 * (page - 1) - idx;

                  return (
                    <TableRow tabIndex={-1} key={idx}>
                      <TableCell style={{ padding: "0.5rem" }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="pb-1 pt-1"
                          // className={classes.selected}
                          name={`${row?.company?.id}#${row?.company?.user?.agentName}`}
                          onClick={() => handleCompanySelect(row)}
                          style={{ minWidth: "100%" }}
                          sx={{ minWidth: "25%" }}
                        >
                          선택
                        </Button>
                      </TableCell>
                      <TableCell align="center">{rowNum}</TableCell>
                      <TableCell align="center">{row?.company?.id}</TableCell>
                      <TableCell align="center">
                        {row?.company?.companyLevel}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }} // overflowX: "auto"
                      >
                        {row.highCompanyName}
                      </TableCell>
                      <TableCell align="left" >
                        {row?.company?.user?.ceoName}
                      </TableCell>
                      <TableCell align="center">
                        {row?.company?.user?.managerContact}
                      </TableCell>
                      <TableCell align="left">
                        {row?.company?.user?.managerName}
                      </TableCell>
                      <TableCell align="left">
                        {row?.company?.user?.userId}
                      </TableCell>
                    </TableRow>
                  );
                })}

                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 60 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )} */}
              </TableBody>
              <TableFooter></TableFooter>
            </table>
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={10}
            totalItemsCount={highListData.totalCount || 0}
            pageRangeDisplayed={5}
            prevPageText={"‹"}
            nextPageText={"›"}
            firstPageText={"‹‹"}
            lastPageText={"››"}
            onChange={(event) => {
              handlePageChange(event, page);
            }}
            style={{ display: "flex", justifyContent: "center" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="warning" variant="outlined">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SearchCompanyModal;
