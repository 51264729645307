import {
  TableCell,
  TableRow,
} from "../../../node_modules/@material-ui/core/index";
import BeatLoader from "react-spinners/BeatLoader";
import { ConstructionOutlined } from "../../../node_modules/@mui/icons-material/index";

const NoData = ({ comment, colNum, isLoading }) => {
  if (isLoading) {
    return (
      <>
        <TableRow className="d-none"></TableRow>
        <TableRow style={{ height: "30vh" }}>
          <TableCell
            className=" align-items-center justify-content-center"
            style={{ height: "30vh" }}
            colSpan={colNum}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BeatLoader color={"var(--first)"} loading={isLoading} />
              <h6>로딩중</h6>
            </div>
          </TableCell>
        </TableRow>
      </>
    );
  } else {
    return (
      <>
        <TableRow className="d-none"></TableRow>
        <TableRow style={{ height: "30vh" }}>
          {/* <TableCell colSpan={halfCol}></TableCell> */}
          <TableCell
            className=" align-items-center justify-content-center"
            style={{ height: "30vh" }}
            colSpan={colNum}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h6>{comment}</h6>
            </div>
          </TableCell>
          {/* <TableCell colSpan={halfCol + 1}></TableCell> */}
        </TableRow>
      </>
    );
  }
};

export default NoData;
