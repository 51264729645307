import { Box, Dialog } from "@mui/material";
import {
  Button,
  IconButton,
  TextField,
  Typography,
} from "../../../../node_modules/@material-ui/core/index";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

const PasswordModal = ({ setExportFlag, postCodeFlag, handleClose }) => {
  const [excelPassWord, setExcelPassWord] = useState();
  const tempPassword = "e34r";
  const handleCloseFn = () => {
    handleClose();
  };
  const passBtn = () => {
    if (excelPassWord === tempPassword) {
      setExportFlag();
    } else {
      alert("비밀번호를 확인해주세요");
    }
  };
  const handlePassword = (e) => {
    setExcelPassWord(e.target.value);
  };
  const handleKeyPress = (event) => {
    event.key === "Enter" && passBtn();
  };
  return (
    <Dialog
      scroll="paper"
      onClose={handleCloseFn}
      fullWidth
      open={postCodeFlag}
    >
      <Box height="500px" position="relative" paddingY={0.5}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={(_) => handleCloseFn(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box textAlign="center" pt={5}>
          <Typography style={{ fontWeight: 600, fontSize: "25px" }}>
            엑셀다운로드를 위해
          </Typography>
          <Typography style={{ fontWeight: 600, fontSize: "25px" }}>
            비밀번호를 입력하세요.
          </Typography>
        </Box>
        <Box textAlign="center" pt={10} pb={3}>
          <Box
            component="span"
            py={1}
            px={3}
            border={"1px solid #000"}
            borderRadius={"5px"}
            display="inline-block"
          >
            <TextField
              onChange={handlePassword}
              id="outlined-password-input"
              // label="Password"
              style={{ textAlign: "center" }}
              type="password"
              autoComplete="current-password"
              onKeyDown={(event) => handleKeyPress(event)}
            />
          </Box>
        </Box>

        <Box textAlign="center">
          <Button color="primary" variant="contained" onClick={passBtn}>
            확인
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
export default PasswordModal;
