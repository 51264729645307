

export const IpFlagFn = (e) => {
  if (
    e === "123.142.69.187" ||
    e === "183.98.138.61" ||
    e === "220.72.12.111" ||
    e === "1.243.47.151" ||
    e === "1.243.47.153" ||
    e === "211.117.71.181" ||
    e === "182.229.17.137" ||
    e === "220.76.52.117" ||
    e === "125.180.147.196" ||
    e === "121.135.220.231" ||
    e === "27.112.142.58"
  )
    return true
  else
    return false
}


