import { Fade } from "../../node_modules/@material-ui/core/index";
import MuiAlert from "@material-ui/lab/Alert";
import { FontAwesomeIcon } from "../../node_modules/@fortawesome/react-fontawesome/index";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const PayAlert = ({ isSuccess, actionName, resultMsg }) => {
  return (
    <Fade in={isSuccess === "success" || isSuccess === "error"}>
      <MuiAlert
        className="alerts-alternate mb-4"
        color={isSuccess}
        style={{
          transition: "all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);",
        }}
      >
        <div className="d-flex align-items-center align-content-start">
          <span
            className={
              isSuccess === "success"
                ? "font-size-lg d-block d-40 mr-3 text-center bg-success text-white rounded-sm"
                : "font-size-lg d-block d-40 mr-3 text-center bg-danger text-white rounded-sm"
            }
          >
            {isSuccess === "success" ? (
              <FontAwesomeIcon icon={["far", "object-group"]} />
            ) : (
              <FontAwesomeIcon icon={faExclamationTriangle} />
            )}
          </span>
          <span>
            <strong className="d-block">
              {isSuccess === "success"
                ? actionName + "가 완료되었습니다."
                : actionName + " 실패"}
            </strong>
            {resultMsg}
          </span>
        </div>
      </MuiAlert>
    </Fade>
  );
};

export default PayAlert;
