import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fab,
  faFacebook,
  faTwitter,
  faReact,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faFileExcel,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAsterisk,
  faPenToSquare,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faFileExcel
);
library.add(
  fab,
  faFacebook,
  faTwitter,

  faReact,

  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube
);
library.add(faAsterisk, faPenToSquare, faSearch);
