import styled from "styled-components";
import { ButtonSelectDateBox } from "../../utils/ButtonSelectDateBox";
import "react-datepicker/dist/react-datepicker.css";
import {
  now,
  oneMonthAgo,
  threeMonthAgo,
  yesterday,
  threeDaysAgo,
  lastMonthEnd,
  lastMonthStart,
  thisMonthStart,
} from "../../utils/formatFunction";
import { Button, Grid, MenuItem, TextField } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

export const SearchComponent = ({
  keyword,
  setKeyword,
  searchClick,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  checkBool,
  setCheckBool,
  checkFields,
  checkFieldsTwo,
  checkFieldsThree,
  setCheckFields,
  dateDisabled,
  setLoadFlag,
  noneKeyword,
  onlyTwoFlag,
}) => {
  // ! 날짜 함수
  const handleKeyPress = (event) => {
    event.key === "Enter" && searchClick();
  };

  const dateChangeNow = () => {
    if (setStartDate) {
      setStartDate(now);
      setEndDate(now);
    }
    checkFn();
  };

  const dateChangeYesterday = () => {
    setStartDate(yesterday);
    setEndDate(yesterday);
  };
  const dateChangeThreeDaysAgo = () => {
    setStartDate(threeDaysAgo);
    setEndDate(threeDaysAgo);
  };

  const dateChangeOneMonthAgo = () => {
    setStartDate(oneMonthAgo);
    setEndDate(now);
  };

  const dateChangeThreeMonthAgo = () => {
    setStartDate(threeMonthAgo);
    setEndDate(now);
  };

  const dateChangeThisMonth = () => {
    setStartDate(thisMonthStart);
    setEndDate(now);
  };

  const dateChangeLastMonth = () => {
    setStartDate(lastMonthStart);
    setEndDate(lastMonthEnd);
  };

  // ! 드롭다운 선택

  const checkEventHandler = ({ target }) => {
    setCheckBool({ ...checkBool, [target.name]: target.value });
  };

  // const checkEventAllHandler = () => {
  //   if()
  //   setCheckBool({ ...checkBool, [target.name]: 'all' });
  // };

  // useEffect(() => {
  //   dateChangeNow();
  //   // checkEventHandler();
  // }, []);

  // ! 검색 카테고리 관련

  const checkFieldsOneName = checkFields ? checkFields[0] : null;
  // ^ {field : "필드이름", name:"이름"}
  const checkFieldsOneFields = checkFields ? checkFields.slice(1) : null;
  //^ [{id: "영문이름", name:"한글이름"} 여러개]
  const checkFieldsTwoName = checkFieldsTwo ? checkFieldsTwo[0] : null;

  const checkFieldsTwoFields = checkFieldsTwo ? checkFieldsTwo.slice(1) : null;

  const checkFieldsThreeName = checkFieldsThree ? checkFieldsThree[0] : null;

  const checkFieldsThreeFields = checkFieldsThree
    ? checkFieldsThree.slice(1)
    : null;

  useEffect(() => {
    if (noneKeyword) checkFn();
    else dateChangeNow();
  }, []);
  const checkFn = () => {
    if (checkFieldsOneName?.field) {
      setCheckBool({ ...checkBool, [checkFieldsOneName?.field]: "all" });
      if (!checkFieldsTwoName) setLoadFlag(true);
      if (checkFieldsTwoName?.field) {
        if (checkFieldsTwoName?.field === "dateType") {
          setCheckBool({
            ...checkBool,
            dateType: "paymentDate",
            [checkFieldsOneName?.field]: "all",
          });
          if (!checkFieldsThreeName) setLoadFlag(true);
          if (checkFieldsThreeName?.field) {
            setCheckBool({
              ...checkBool,
              dateType: "paymentDate",
              [checkFieldsOneName?.field]: "all",
              [checkFieldsThreeName?.field]: "all",
            });
            setLoadFlag(true);
          }
        } else {
          setCheckBool({
            ...checkBool,
            [checkFieldsTwoName?.field]: "all",
            [checkFieldsOneName?.field]: "all",
            [checkFieldsThreeName?.field]: "all",
          });
          setLoadFlag(true);
        }
      }
    }
    if (onlyTwoFlag) {
      setCheckBool({
        ...checkBool,
        dateType: "paymentDate",
      });
      setLoadFlag(true);
    }
  };
  // useEffect(() => {
  //   if (checkFieldsTwo) {
  //   }
  // }, [checkFieldsTwo]);
  return (
    <>
      <SearchContainer>
        <Grid container spacing={0} justifyContent="flex-end">
          <Grid container item xs={12} lg={12} justifyContent="flex-end">
            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: "250px",
              }}
            > */}
            {!dateDisabled && (
              <>
                <Grid
                  item
                  xs={12}
                  xl={noneKeyword ? 8 : 6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <ButtonGroup>
                    <Button
                      className="m-1 p-1"
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={dateChangeNow}
                    >
                      오늘
                    </Button>
                    <Button
                      className="m-1 p-1"
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={dateChangeYesterday}
                    >
                      어제
                    </Button>
                    <Button
                      className="m-1 p-1"
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={dateChangeThreeDaysAgo}
                    >
                      3일전
                    </Button>
                    <Button
                      className="m-1 p-1"
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={dateChangeOneMonthAgo}
                    >
                      1개월 전
                    </Button>
                    <Button
                      className="m-1 p-1"
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={dateChangeThreeMonthAgo}
                    >
                      3개월 전
                    </Button>
                    <Button
                      className="m-1 p-1"
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={dateChangeThisMonth}
                    >
                      이번 달
                    </Button>
                    <Button
                      className="m-1 p-1"
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={dateChangeLastMonth}
                    >
                      지난 달
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  xl={noneKeyword ? 5 : 3}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <ButtonSelectDateBox
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    className="m-2"
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={8}
            spacing={0}
            justifyContent="flex-end"
          >
            <Grid
              item
              xs={12}
              xl={5}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {checkFields ? (
                <TextField
                  className="m-2"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={checkEventHandler}
                  name={checkFieldsOneName?.field}
                  defaultValue={checkFieldsOneName?.field ? "all" : undefined}
                  value={checkBool[checkFieldsOneName]}
                  size="small"
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       카테고리&nbsp;
                  //     </InputAdornment>
                  //   ),
                  // }}
                  label={checkFieldsOneName?.name}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "center",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    },
                  }}
                >
                  {checkFieldsOneFields?.map((field) => {
                    const id = field?.id;
                    const name = field?.name;
                    return (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              ) : undefined}
              {checkFieldsTwo && !noneKeyword ? (
                <TextField
                  className="m-2"
                  variant="outlined"
                  select
                  fullWidth
                  defaultValue={
                    checkFieldsTwoName?.field === "dateType"
                      ? "paymentDate"
                      : "all"
                  }
                  onChange={checkEventHandler}
                  name={checkFieldsTwoName?.field}
                  label={checkFieldsTwoName?.name}
                  value={checkBool[checkFieldsTwoName]}
                  size="small"
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       카테고리&nbsp;
                  //     </InputAdornment>
                  //   ),
                  // }}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "center",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    },
                  }}
                >
                  {checkFieldsTwoFields?.map((field) => {
                    const id = field?.id;
                    const name = field?.name;
                    return (
                      <MenuItem key={id} value={id} select={true}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              ) : null}
            </Grid>
            {/* <SearchInput
                value={keyword}
                onChange={(event) => setKeyword(event.target.value)}
                onKeyDown={(event) => handleKeyPress(event)}
              /> */}
            <Grid
              item
              xs={12}
              xl={7}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeywordDiv style={{ justifyContent: "flex-end" }}>
                {checkFieldsThree ? (
                  <TextField
                    className="m-2"
                    variant="outlined"
                    select
                    fullWidth
                    onChange={checkEventHandler}
                    name={checkFieldsThreeName?.field}
                    label={checkFieldsThreeName?.name}
                    value={checkBool[checkFieldsThreeName]}
                    defaultValue={
                      checkFieldsThreeName?.field ? "all" : undefined
                    }
                    size="small"
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: "center",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      },
                    }}
                  >
                    {checkFieldsThreeFields?.map((field) => {
                      const id = field?.id;
                      const name = field?.name;
                      return (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                ) : null}
                {noneKeyword ? (
                  <TextField
                    className="m-2"
                    variant="outlined"
                    select
                    fullWidth
                    defaultValue={
                      checkFieldsTwoName?.field === "dateType"
                        ? "paymentDate"
                        : "all"
                    }
                    onChange={checkEventHandler}
                    name={checkFieldsTwoName?.field}
                    label={checkFieldsTwoName?.name}
                    value={checkBool[checkFieldsTwoName]}
                    size="small"
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       카테고리&nbsp;
                    //     </InputAdornment>
                    //   ),
                    // }}
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: "center",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      },
                    }}
                  >
                    {checkFieldsTwoFields?.map((field) => {
                      const id = field?.id;
                      const name = field?.name;
                      return (
                        <MenuItem key={id} value={id} select={true}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                ) : (
                  <TextField
                    id="tid"
                    name="tid"
                    variant="outlined"
                    size="small"
                    label={
                      <span position="start">
                        검색어 &nbsp;
                        <FontAwesomeIcon icon="fa-solid fa-search" size="xs" />
                      </span>
                    }
                    type={"text"}
                    placeholder="검색어"
                    value={keyword}
                    onChange={(event) => setKeyword(event.target.value)}
                    onKeyDown={(event) => handleKeyPress(event)}
                    // className="m-2"
                    fullWidth
                    className="m-2"
                  />
                )}

                {/* <Button
                  variant="outlined"
                  color="inherit"
                  className="m-2 p-2"
                  onClick={() => {
                    setCheckBool({
                      ...checkBool,
                      [checkFieldsOneName]: undefined,
                      [checkFieldsTwoName]: undefined,
                      [checkFieldsThreeName]: undefined,
                    });
                    setKeyword("");
                    dateChangeNow();
                  }}
                >
                  초기화
                </Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  className="m-2 p-2"
                  onClick={searchClick}
                >
                  검색
                </Button>
              </KeywordDiv>
            </Grid>
          </Grid>
        </Grid>
      </SearchContainer>
    </>
  );
};

const ButtonGroup = styled.div`
  /* margin: 20px 25px 10px 25px;
  padding: 10px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

const SearchInput = styled.input`
  margin-top: 10px;
  width: 100px;
  height: 25px;
  font-size: 15px;
  border: 0;
  border-radius: 15px;
  outline: none;
  padding-left: 10px;
  background-color: rgb(233, 233, 233);
`;

const SearchContainer = styled.div`
  /* border: 1px solid #797872; */
  border-radius: 10px;
  padding: 5px 15px;
  min-width: 200px;
  background-color: white;
`;

const SearchKeyword = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const KeywordDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;