import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  Button,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableFooter,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Pagination from "react-js-pagination";
import { SearchComponent } from "components/commonTable/SearchComponent";
import { getAxios } from "hook/axiosInstance";
import excelIcon from "assets/custom/excelIcon.svg";
import { formatToWon } from "utils/formatFunction";
import SelectLimit from "components/commonTable/SelectLimit";
import DeductModal from "./DeductModal";
import NoData from "components/commonTable/NoData";
import bankExportToXlsx from "utils/bankExcelExport";
import {
  Box,
  TableSortLabel,
} from "../../../../node_modules/@material-ui/core/index";
import PasswordModal from "layouts/company/companyCommission/PasswordModal";
import { Search } from "components/commonTable/Search";
import { useRecoilState } from "recoil";
import { listLinkStateAtom } from "recoilStates/searchRecoil";
import SettleFranchiseTermTableHeader from "./SettleFranchiseTermTableHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const SettleFranchiseTermTable = () => {
  // ! Loading
  const [loading, setLoading] = useState(false);

  // ! GET 요청

  const [listData, setListData] = useState({ dataList: [] });
  const [limit, setLimit] = useState(15);
  const [address, setAddress] = useState();
  const [page, setPage] = useState(1);
  const [userAuth, setUserAuth] = useState();
  const [loadFlag, setLoadFlag] = useState(false);
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);

  const getListApi = async () => {
    setLoading(true);
    if (!loadFlag) setLoadFlag(true);
    if (listLinkState) {
      const tempAd = listLinkState;
      setAddress(tempAd);
      const resApiData = await getAxios(tempAd);
      if (resApiData.data.header.result_code === "200") {
        setLoading(false);
        setListData(resApiData.data.data);
      }
    }
  };

  useEffect(() => {
    setUserAuth(window.sessionStorage.getItem("userAuth"));
  }, []);

  useEffect(() => {
    if (listLinkState) {
      getListApi();
    }
  }, [page, limit]);

  useEffect(() => {
    if (listLinkState)
      if (!loadFlag) {
        getListApi();
      }
  }, [listLinkState, loading]);

  const handleChangePage = (e) => {
    setPage(e);
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/page=[^&]*/, `page=${e}`);
    setListLinkState(tempLink);
  };

  const emptyRows =
    limit - Math.min(limit, listData.totalCount - (page - 1) * limit);

  // ! N개씩 보기

  // ! 검색 관련

  // ^ 검색조건

  const checkFieldsTwo = [
    { id: "all", name: "모두" },
    { id: "franchiseName", name: "가맹점명" },
    { id: "companyName", name: "업체명" },
    { id: "accountHolderName", name: "예금주명" },
  ];

  // ! 선택 관련
  const [selected, setSelected] = useState([]);
  // const [selectDetail, setSelectDetail] = useState("");
  // const [selectedAll, setSelectedAll] = useState(false);
  // const isSelected = (tid) => selected.indexOf(tid) !== -1;

  // const handleClick = (event, tid) => {

  //   const selectedIndex = selected.indexOf(tid);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, tid);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = listData.dataList.map((n) => n.mid);
  //     setSelected(newSelecteds);
  //     return;
  //   } else setSelected([]);
  // };

  // ! 모달
  const [modalOpen, setModalOpen] = useState(false);
  const [selectData, setSelectData] = useState({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("num");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const [modalFlag, setModalFlag] = useState(false);
  const [exportFlag, setExportFlag] = useState(false);
  useEffect(() => {
    if (exportFlag) {
      bankExportToXlsx(
        "BANKING_가맹점_정산금액(기간별_계좌이체용도)_",
        address
      );
      setModalFlag(false);
    }
  }, [exportFlag]);
  useEffect(() => {
    if (modalFlag) {
      setExportFlag(false);
    }
  }, [modalFlag]);
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listData.dataList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    } else {
      setSelected([]);
    }
  };
  return (
    <>
      <PasswordModal
        postCodeFlag={modalFlag}
        handleClose={() => setModalFlag(false)}
        setExportFlag={() => setExportFlag(true)}
      />
      <Search
        dateFlag={true}
        pageFlag={true}
        link={"settlement/franchise/term/list?"}
        checkList={["pg", "searchField"]}
        getListApi={getListApi}
        limit={true}
        setPage={setPage}
        searchField={checkFieldsTwo}
      />
      <Box height='5px' />
      <Card className='card-box mb-4'>
        <div className='card-header pr-2'>
          <div className='card-header--title'>
            <small>가맹점 정산</small>
            <b>가맹점 정산금액 조회 (기간별)</b>
          </div>
          <div className='card-header--actions'>
            {selected.length > 0 ? (
              <span>{selected.length} 개 선택</span>
            ) : (
              <></>
            )}
          </div>
          {userAuth === "SA" ? (
            <div className='onlyAdminButtonGroup'>
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => setModalFlag(true)}
              >
                <img src={excelIcon} alt='EXCEL' style={{ width: "23px" }} />
                &nbsp; Banking
              </Button>
              {/* &nbsp;&nbsp;&nbsp;
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() =>
                  exportToXlsx(
                    "정산관리_기간별_",
                    address
                    // listData,
                    // headerArray
                  )
                }
              >
                <img src={excelIcon} alt="EXCEL" style={{ width: "23px" }} />
                &nbsp; 내보내기
              </Button>
              &nbsp; */}
            </div>
          ) : undefined}
          <SelectLimit limit={limit} setLimit={setLimit} setPage={setPage} />
        </div>
        <CardContent className='p-0'>
          <div className='table-responsive'>
            <table className='text-nowrap mb-0 table table-striped'>
              <TableHead className='thead-light'>
                <TableRow>
                  <th
                    className='list-th text-center'
                    rowSpan='2'
                    style={{
                      paddingBottom: "32px",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    NO
                  </th>
                  <th
                    className='list-th text-center'
                    rowSpan='2'
                    style={{
                      paddingBottom: "32px",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    가맹점명
                  </th>
                  <th
                    className='list-th text-center'
                    colSpan='4'
                    style={{ borderRight: "1px solid #ccc" }}
                  >
                    정상매출
                  </th>
                  <th
                    className='list-th text-center'
                    colSpan='5'
                    style={{ borderRight: "1px solid #ccc" }}
                  >
                    차감매출(정산후취소)
                  </th>
                  <SettleFranchiseTermTableHeader
                    id='amountMoneyMinus'
                    order={order}
                    orderBy={orderBy}
                    label='정산차감'
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.dataList.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id='settlementResultAmount'
                    order={order}
                    orderBy={orderBy}
                    label='정산금액'
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.dataList.length}
                    numSelected={selected.length}
                  />

                  {/* <th
                    className="list-th text-center"
                    rowSpan="2"
                    style={{ width: "70px", paddingBottom: "32px" }}
                  >
                    수수료
                  </th>
                  <th
                    className="list-th text-center"
                    rowSpan="2"
                    style={{
                      width: "110px",
                      paddingBottom: "32px",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    입금금액
                  </th> */}
                  <th
                    className='list-th text-center'
                    rowSpan='2'
                    style={{ width: "110px", paddingBottom: "18px" }}
                  >
                    은행명
                  </th>
                  <th
                    className='list-th text-center'
                    rowSpan='2'
                    style={{ width: "150px", paddingBottom: "18px" }}
                  >
                    계좌번호
                  </th>
                  <th
                    className='list-th text-center'
                    rowSpan='2'
                    style={{ width: "110px", paddingBottom: "18px" }}
                  >
                    예금주
                  </th>
                  <th
                    className='list-th text-center'
                    rowSpan='2'
                    style={{ width: "70px", paddingBottom: "18px" }}
                  >
                    정산차감
                  </th>
                </TableRow>

                <tr>
                  <SettleFranchiseTermTableHeader
                    id='cntOk'
                    order={order}
                    orderBy={orderBy}
                    label='건수'
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.dataList.length}
                    numSelected={selected.length}
                  />
                  {/* <TableCell
                    className="list-th text-center"
                    rowSpan="2"
                    style={{ width: "80px", paddingBottom: "32px" }}
                    key={'cntOk'}
                  >
                    <TableSortLabel
                      active={orderBy === listData.dataList.cntOk}
                      onClick={createSortHandler(listData.dataList.cntOk)}
                      direction={orderBy === listData.dataList.cntOk ? order : "asc"}
                      style={{
                        textDecorationStyle: "double",
                        textAlign: "center",
                      }}
                      hideSortIcon
                    >
                      건수
                    </TableSortLabel>

                  </TableCell> */}
                  {/* <th className="list-th text-center" style={{ width: "70px" }}>
                    건수
                  </th> */}
                  <SettleFranchiseTermTableHeader
                    id='tradingAmountOk'
                    order={order}
                    orderBy={orderBy}
                    label='거래금액'
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.dataList.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id='commissionAmountOk'
                    order={order}
                    orderBy={orderBy}
                    label='수수료'
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.dataList.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id='settlementAmountOk'
                    order={order}
                    orderBy={orderBy}
                    label='정산금액'
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.dataList.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id='cntCancel'
                    order={order}
                    orderBy={orderBy}
                    label='건수'
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.dataList.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id='tradingAmountCancel'
                    order={order}
                    orderBy={orderBy}
                    label='취소금액'
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.dataList.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id='commissionAmountCancel'
                    order={order}
                    orderBy={orderBy}
                    label='수수료'
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.dataList.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id='settlementAmountCancel'
                    order={order}
                    orderBy={orderBy}
                    label='차감할금액'
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.dataList.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id='tradingAmountCancelFinish'
                    order={order}
                    orderBy={orderBy}
                    label='차감완료금액'
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.dataList.length}
                    numSelected={selected.length}
                  />
                </tr>
              </TableHead>

              <TableBody>
                {listData.dataList.length > 0 ? (
                  stableSort(
                    listData.dataList,
                    getComparator(order, orderBy)
                  ).map((row, idx) => {
                    // ! 번호
                    const rowNum =
                      listData.totalCount - limit * (page - 1) - idx;
                    return (
                      <TableRow hover tabIndex={-1} key={idx}>
                        <TableCell className='text-center'>{rowNum}</TableCell>
                        {/* <TableCell align="center">{appDate}</TableCell> */}
                        <TableCell align='center'>
                          {row?.franchiseName}
                        </TableCell>
                        <TableCell align='right'>{row?.cntOk}</TableCell>
                        {/* //! 정상매출-건수 */}
                        <TableCell align='right'>
                          {formatToWon(row?.tradingAmountOk || 0)}
                          {/* //! 정상매출-거래금액 */}
                        </TableCell>
                        <TableCell align='right'>
                          {formatToWon(row?.commissionAmountOk || 0)}
                          {/* //! 정상매출-수수료 */}
                        </TableCell>
                        <TableCell align='right'>
                          {formatToWon(row?.settlementAmountOk || 0)}
                          {/* //! 정상매출-정산금액 */}
                        </TableCell>
                        <TableCell align='right'>{row?.cntCancel}</TableCell>
                        {/* //! 차감매출-건수 */}
                        <TableCell align='right'>
                          {row?.tradingAmountCancel !== null
                            ? formatToWon(row?.tradingAmountCancel || 0)
                            : "없음"}
                          {/* //! 차감매출-취소금액 */}
                        </TableCell>
                        <TableCell align='right'>
                          {row?.commissionAmountCancel !== null
                            ? formatToWon(row?.commissionAmountCancel || 0)
                            : "0"}
                          {/* //! 정상매출-수수료 */}
                        </TableCell>
                        <TableCell align='right'>
                          {row?.settlementAmountCancel !== null
                            ? formatToWon(row?.settlementAmountCancel || 0)
                            : "0"}
                          {/* //! 정상매출-차감할금액 */}
                        </TableCell>
                        <TableCell align='right'>
                          {row?.tradingAmountCancelFinish !== null
                            ? formatToWon(row?.tradingAmountCancelFinish || 0)
                            : "없음"}
                          {/* //! 정상매출-차감완료금액 */}
                        </TableCell>

                        <TableCell align='right'>
                          {row?.amountMoneyMinus !== null
                            ? formatToWon(row?.amountMoneyMinus || 0)
                            : "없음"}
                          {/* //! 정산차감 */}
                        </TableCell>
                        <TableCell align='right'>
                          <span
                            className={
                              row?.settlementResultAmount > 0
                                ? "text-primary"
                                : row?.settlementResultAmount === 0
                                ? ""
                                : "text-danger"
                            }
                          >
                            {row?.settlementResultAmount !== null
                              ? formatToWon(row?.settlementResultAmount || 0)
                              : "없음"}
                            {/* //! 정산금액 */}
                          </span>
                        </TableCell>

                        <TableCell align='center'>
                          {row?.bankName}
                          {/* //! 은행명 */}
                        </TableCell>
                        {/* <TableCell align="center">{appDate}</TableCell> */}
                        <TableCell align='center'>
                          {row?.accountNumber}
                          {/* //! 계좌번호 */}
                        </TableCell>

                        <TableCell align='right' className='text-center p-1'>
                          {row?.accountHolder}
                          {/* //! 예금주 */}
                        </TableCell>
                        <TableCell className='p-1'>
                          <Button
                            variant='outlined'
                            size='small'
                            onClick={() => {
                              setSelectData({
                                franchiseCode: row?.franchiseCode,
                                franchiseName: row?.franchiseName,
                              });
                              setModalOpen(true);
                            }}
                          >
                            정산차감
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <>
                    <NoData
                      comment='정산내역이 없습니다.'
                      colNum={22}
                      isLoading={loading}
                    />
                  </>
                )}
                <TableRow className='table-dark'>
                  <TableCell colSpan={1}></TableCell>
                  <TableCell align='center'>
                    <span class='font-weight-bold'>합계</span>
                  </TableCell>
                  <TableCell align='right'>
                    <span class='font-weight-bold'>
                      {formatToWon(listData?.paymentTotal?.cntOk || 0)}
                      {/* //! 정상매출-건수 */}
                    </span>
                  </TableCell>
                  <TableCell align='right'>
                    <span class='font-weight-bold'>
                      {formatToWon(
                        listData?.paymentTotal?.tradingAmountOk || 0
                      )}
                      {/* //! 정상매출-거래금액 */}
                    </span>
                  </TableCell>
                  <TableCell align='right'>
                    <span class='font-weight-bold'>
                      {formatToWon(
                        listData?.paymentTotal?.commissionAmountOk || 0
                      )}
                      {/* //! 정상매출-수수료 */}
                    </span>
                  </TableCell>
                  <TableCell align='right'>
                    <span class='font-weight-bold'>
                      {formatToWon(
                        listData?.paymentTotal?.settlementAmountOk || 0
                      )}
                      {/* //! 정상매출-정산금액 */}
                    </span>
                  </TableCell>
                  <TableCell align='right'>
                    <span class='font-weight-bold'>
                      {formatToWon(listData?.paymentTotal?.cntCancel || 0)}
                      {/* //! 차감매출-건수 */}
                    </span>
                  </TableCell>
                  <TableCell align='right'>
                    <span class='font-weight-bold'>
                      {formatToWon(
                        listData?.paymentTotal?.tradingAmountCancel || 0
                      )}
                      {/* //! 차감매출-취소금액 */}
                    </span>
                  </TableCell>
                  <TableCell align='right'>
                    <span class='font-weight-bold'>
                      {formatToWon(
                        listData?.paymentTotal?.commissionAmountCancel || 0
                      )}
                      {/* //! 차감매출-수수료 */}
                    </span>
                  </TableCell>
                  <TableCell align='right'>
                    <span class='font-weight-bold'>
                      {formatToWon(
                        listData?.paymentTotal?.settlementAmountCancel || 0
                      )}
                      {/* //! 차감매출-차감할금액 */}
                    </span>
                  </TableCell>
                  <TableCell align='right'>
                    <span class='font-weight-bold'>
                      {formatToWon(
                        listData?.paymentTotal?.tradingAmountCancelFinish || 0
                      )}
                      {/* //! 차감매출-차감완료금액 */}
                    </span>
                  </TableCell>
                  <TableCell align='right'>
                    <span class='font-weight-bold'>
                      {formatToWon(
                        listData?.paymentTotal?.amountMoneyMinus || 0
                      )}
                      {/* //! 정산차감 */}
                    </span>
                  </TableCell>
                  <TableCell align='right'>
                    <span class={"font-weight-bold"}>
                      {formatToWon(
                        listData?.paymentTotal?.settlementResultAmount || 0
                      )}
                      {/* //! 정산금액 */}
                    </span>
                  </TableCell>
                  {/* <TableCell align="right"> */}
                  {/* <span class="font-weight-bold">
                      {formatToWon(listData?.paymentTotal?.sumCommission || 0)} */}
                  {/* //! 수수료 - 출금 수수료였던 것임. 지금은 0이라고 함. */}
                  {/* </span>
                  </TableCell> */}
                  {/* <TableCell align="right">
                    <span class="font-weight-bold">
                      {formatToWon(
                        listData?.paymentTotal?.sumSettlementAmount || 0
                      )} */}
                  {/* //! 입금금액 */}
                  {/* </span> */}
                  {/* </TableCell> */}
                  <TableCell colSpan={6} align='center'></TableCell>
                </TableRow>
              </TableBody>
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </table>
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={listData.totalCount}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              firstPageText={"‹‹"}
              lastPageText={"››"}
              onChange={(event) => {
                handleChangePage(event);
              }}
              // onPageChange={handleChangePage}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </div>
        </CardContent>
      </Card>
      {modalOpen ? (
        <DeductModal
          modalOpen={true}
          handleModalClose={() => setModalOpen(false)}
          selectData={selectData}
          getList={getListApi}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SettleFranchiseTermTable;
