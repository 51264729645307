import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";

// import { FormLabel, FormGroup } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { getAxios, postAxios } from "../../../hook/axiosInstance";
import { Link } from "react-router-dom";
import BasicInput from "./BasicInput";
import {
  validateInputs,
  checkoutTerminalNullInputData,
  terminalFields,
} from "utils/validateInputs";
// import { checkoutNullInputData } from "utils/validateInputs";
import SearchCompanyModal from "./SearchFranchiseModal";
import { useNavigate } from "react-router-dom";

const TerminalInput = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: 200,
    },
  }));

  const classes = useStyles();

  // ! validation, error
  const [error, setError] = useState({});
  const [helperText, setHelperText] = useState({});
  const [postFlag, setPostFlag] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [isDuplicateChecked, setIsDuplicateChecked] = useState(false);
  // ! 전송 로직
  const handleChange = (event) => {
    // setValues({ ...values, [prop]: event.target.value });
    validateInputs({ event, error, setError, helperText, setHelperText });

    setCreateValue({ ...createValue, [event.target.name]: event.target.value });
  };

  const [createValue, setCreateValue] = useState({
    ...terminalFields,
    createId: sessionStorage.getItem("userId"),
    userId: sessionStorage.getItem("userId"),
    // userAuth: "1",
    authYn: "N",
    terminalStatus: "Y",
    terminalType: "N",
  });
  const handleCheck = () => {
    if (createValue?.terminalStatus === "Y") {
      setCreateValue({ ...createValue, terminalStatus: "N" });
    } else {
      setCreateValue({ ...createValue, terminalStatus: "Y" });
    }
    // setValues({ ...values, [prop]: event.target.value });
    // validateInputs({ event, error, setError, helperText, setHelperText });

    // setCreateValue({ ...createValue, [event.target.name]: event.target.value });
  };
  useEffect(() => {
    if (
      createValue.franchiseCode &&
      createValue.franchiseName &&
      createValue.tid &&
      createValue.terminalCode
      //  &&
      // createValue.terminalFee
    )
      setPostFlag(true);
    else setPostFlag(false);
    if (!createValue.franchiseCode) setAlertText("가맹점코드를 입력하세요");
    if (!createValue.franchiseName) setAlertText("가맹점이름을 입력하세요");
    if (!createValue.tid) setAlertText("단말기코드를 입력하세요");
    if (!createValue.terminalCode) setAlertText("시리얼번호를 입력하세요");
    // if (!createValue.terminalFee) setAlertText("단말기요금을 입력하세요");
  }, [createValue]);
  // ! 전송 loading
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const checkConfirm = () => {
    if (postFlag) {
      if (window.confirm("정말로 등록하시겠습니까?")) {
        if (isLoading === true) {
          return;
        }
        if (
          checkoutTerminalNullInputData({
            createValue,
            setError,
            setHelperText,
          })
        ) {
          if (isDuplicateChecked) postDataApi();
          else alert("단말기 중복체크가 필요합니다");
        }
      }
    } else alert(alertText);
  };
  const postDataApi = async () => {
    try {
      setIsLoading(true);
      const posted = await postAxios("/terminal/create", createValue);
      if (posted.status === 200) {
        setIsLoading(false);
        alert("등록 완료!");
        navigate("/terminal/list");
      }
    } catch (err) {
      setIsLoading(false);
      if (err.response.data.status === 500) {
        alert("500");
        // setCreateValue({ ...franchiseFields });
        return;
      }
      if (err.response.data.status === 509) {
        alert("509");
        // setCreateValue({ ...franchiseFields });
        return;
      }
    }
    return;
  };

  // ! useEffect
  // useEffect(() => {
  // }, [createValue]);

  // ! 모달관련
  const [scroll, setScroll] = useState("paper");

  const handleModalOpen = (e) => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const [modalOpen, setModalOpen] = useState("");
  console.log(createValue);
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} sm={12} lg={6}>
          <BasicInput
            classes={classes}
            createValue={createValue}
            setCreateValue={setCreateValue}
            handleChange={handleChange}
            handleModalOpen={handleModalOpen}
            error={error}
            helperText={helperText}
            setError={setError}
            setHelperText={setHelperText}
            setIsDuplicateChecked={setIsDuplicateChecked}
            handleCheck={handleCheck}
          />
        </Grid>
        {/* ! 기본 정보 끝 */}
      </Grid>
      <Button
        variant='contained'
        color='primary'
        className='m-2'
        onClick={checkConfirm}
      >
        단말기 등록
      </Button>
      <Link to='/terminal/list'>
        <Button
          variant='contained'
          className='m-2 btn warning'
          style={{ backgroundColor: "white" }}
        >
          뒤로가기
        </Button>
      </Link>
      {modalOpen ? (
        <SearchCompanyModal
          modalOpen={true}
          setModalOpen={setModalOpen}
          scroll={scroll}
          handleModalClose={handleModalClose}
          setCreateValue={setCreateValue}
          createValue={createValue}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default TerminalInput;
