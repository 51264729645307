import PageTitle from "components/PageTitle/PageTitle";
import { ExampleWrapperSeamless } from "layouts/wrappers/ExampleWrapperSeamless";
import { ExampleWrapperSimple } from "layouts/wrappers/ExampleWrapperSimple";
import React from "react";
import PaymentTable from "./PaymentTable";

const PaymentList = () => {
  return (
    <>
      <PageTitle titleHeading="결제내역 관리" />
      <PaymentTable />
    </>
  );
};

export default PaymentList;
