import React from "react";

import { ExampleWrapperSimple } from "layouts/wrappers/ExampleWrapperSimple";
import CompanyInput from "./CompanyInput";

const CompanyCreate = () => {
  return (
    <ExampleWrapperSimple sectionHeading="업체 등록">
      <CompanyInput />
    </ExampleWrapperSimple>
  );
};

export default CompanyCreate;
