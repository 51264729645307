import Header from "components/Header/Header";
import React, { useEffect } from "react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import {
  CssBaseline,
  Hidden,
  Paper,
} from "../node_modules/@material-ui/core/index";
// import { useMediaQuery } from "../node_modules/@material-ui/core";
import "./assets/sass/base.scss";
import "./assets/custom/tables.css";
import "./assets/custom/customBase.css";
import "./utils/fasImport";
import { ProSidebarProvider } from "../node_modules/react-pro-sidebar/dist/index";
import ShortFooter from "components/Footer/ShortFooter";
import SidebarWithAuth from "components/BootstrapSidebar/SidebarWithAuth";
import { RecoilRoot } from "recoil";
import { useSetRecoilState } from "recoil";
import { mobileFlagAtom } from "recoilStates/searchRecoil";
import { useMediaQuery } from "react-responsive";

const Root = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [sidebarHover, setSidebarHover] = useState(false);

  function handleSidebarClose() {
    setSidebarOpen((prev) => !prev);
  }

  function handleMouseHover() {
    setSidebarHover(true);
  }

  function handleMouseOut() {
    setSidebarHover(false);
  }

  return (
    <>
      <ProSidebarProvider>
        <RecoilRoot>
          <CssBaseline />
          <div className="app-wrapper bg-gray-400">
            {/* //! 헤더 */}
            <Header
              handleSidebarClose={handleSidebarClose}
              sidebarOpen={sidebarOpen}
              isCollapsedLayout={true}
            />
            {/* //! 메인 */}
            <WindowLayoutEvent>
              <div className="app-main">
                {/* //! 사이드바 */}
                <Hidden mdDown>
                  {/* //! md(width:600px) 보다 작으면 숨김 */}
                  <Paper
                    elevation={11}
                    className={
                      sidebarOpen
                        ? "app-sidebar-wrapper app-sidebar-wrapper-fixed"
                        : sidebarHover
                        ? "app-sidebar-wrapper app-sidebar-wrapper-fixed app-sidebar-wrapper-open"
                        : "app-sidebar-wrapper app-sidebar-wrapper-fixed app-sidebar-wrapper-close"
                    }
                    onMouseOver={handleMouseHover}
                    onMouseOut={handleMouseOut}
                    style={{ zIndex: "110" }}
                  >
                    {/* <BootstrapSidebar sidebarOpen={sidebarOpen} /> */}
                    <SidebarWithAuth />
                  </Paper>
                </Hidden>
                {/* //! 래퍼 */}
                <ContentWrapper
                  className={
                    sidebarOpen
                      ? "app-content app-content-sidebar-fixed"
                      : "app-content app-content-sidebar-fixed app-content-sidebar-collapsed"
                  }
                >
                  <div
                    className="app-content--inner"
                    style={{ padding: "15px 3px" }}
                  >
                    <div className="app-content--inner__wrapper">
                      {/* //! react-router-dom v6.4 참고 - Router.js 의 children이 Outlet 안에 들어감 */}
                      <Outlet />
                    </div>
                  </div>
                  <ShortFooter
                    className={
                      sidebarOpen
                        ? "app-footer"
                        : "app-footer app-footer--fixed"
                    }
                  />
                </ContentWrapper>
              </div>
            </WindowLayoutEvent>
          </div>
        </RecoilRoot>
      </ProSidebarProvider>
    </>
  );
};

const WindowLayoutEvent = ({ children }) => {
  const setMobileFlag = useSetRecoilState(mobileFlagAtom);
  const isMobile = useMediaQuery({ maxWidth: 1645 });
  const [mobileType, setMobileType] = useState("pc");

  useEffect(() => {
    const handleResize = () => {
      if (isMobile) {
        if (window.innerWidth <= 680) {
          setMobileType("mo"); // 모바일
        } else {
          setMobileType("ta"); // 태블릿
        }
      } else {
        setMobileType("pc"); // 데스크탑
      }
    };

    handleResize(); // 초기 실행

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    setMobileFlag(mobileType);
  }, [mobileType, setMobileFlag]);

  return <>{children}</>;
};

// const ContentBox = styled.div`
//   position: relative;
//   min-height: 100vh;
//   transition: all 0.5s $app-sidebar-transition;
//   padding-top: 94px;
//   /* position: absolute;
//   left: 300px;
//   top: 100px; */
//   @media (min-width: 1200px) {
//     padding-left: 330px;
//   }
// `;

const ContentWrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  /* height: 100%; */
  /* min-height: 100vh; */
  /* margin-top: 20px; 
  overflow: auto; */
`;

export default Root;
