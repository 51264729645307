import NoData from "components/commonTable/NoData";
import { getAxios } from "hook/axiosInstance";
import { useEffect } from "react";
import { useState } from "react";
import { formatToWon } from "utils/formatFunction";
import {
  Box,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../../../node_modules/@material-ui/core/index";
import styled from "styled-components";
import SettleCompanyDetailTable from "./SettleCompanyDetailTable";
import exportToXlsx from "utils/excelExport";
import PasswordModal from "layouts/company/companyCommission/PasswordModal";
import { SearchComponent } from "components/commonTable/SearchComponent";

const SettleModalTable = ({
  companyCodeSeq,
  propsStartDate,
  propsEndDate,
  propsCheckBool,
}) => {
  // ! Loading
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [companyCodeUserSeq, setCompanyCodeUserSeq] = useState();
  const handleModalOpen = (row) => {
    setCompanyCodeUserSeq(row[24]);
    setModalOpen(true);
  };

  // ! GET 요청

  const [listData, setListData] = useState({ dataList: [] });
  const [limit, setLimit] = useState(15);
  const [searchFlag, setSearchFlag] = useState(true);
  const [loadFlag, setLoadFlag] = useState(false);
  useEffect(() => {
    if (loadFlag) getListApi(limit);
  }, [loadFlag]);
  const [userAuth, setUserAuth] = useState(
    window.sessionStorage.getItem("userAuth")
  );
  const [userCompanyLevel, setUserCompanyLevel] = useState(
    window.sessionStorage.getItem("level")
  );
  const [address, setAddress] = useState(
    `settlement/company/list/detail?companyCodeSeq=` + companyCodeSeq
  );
  const type = true;
  const [startDate, setStartDate] = useState(
    propsStartDate ? propsStartDate : undefined
  );
  const [endDate, setEndDate] = useState(
    propsEndDate ? propsEndDate : undefined
  );
  const [searchKeyword, setSearchKeyword] = useState();
  const [keyword, setKeyword] = useState("");
  useEffect(() => {
    getListApi(limit);
  }, []);
  // useEffect(() => {
  //   getListApi(limit);
  // }, [limit]);
  useEffect(() => {
    if (searchKeyword) {
      if (!searchFlag) {
        getListApi(limit);
        setSearchFlag(true);
      }
    }
  }, [searchKeyword]);
  function formatDate(date) {
    if (date == null) {
      return "";
    }
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  const getListApi = async (limit) => {
    setLoading(true);
    const tempAd = `settlement/company/list/detail?startDate=${formatDate(
      startDate
    )}&endDate=${formatDate(
      endDate
    )}&searchField=${searchKeyword}&limit=${limit}&companyCodeSeq=${companyCodeSeq}&columns=${parseCheckBool}`;
    setAddress(tempAd);
    const resApiData = await getAxios(tempAd);

    if (resApiData.data.header.result_code === "200") {
      setLoading(false);
      setListData(resApiData.data.data);
    }
    // else {
    //   setListData([]);
    // }
  };

  const searchApi = async () => {
    setLoading(true);
    const tempAd = `settlement/company/list/detail?startDate=${formatDate(
      startDate
    )}&endDate=${formatDate(endDate)}&searchField=${encodeURI(
      keyword
    )}&limit=${limit}&companyCodeSeq=${companyCodeSeq}&columns=${parseCheckBool}`;
    setAddress(tempAd);
    const res = await getAxios(tempAd);
    res.data.header.result_code === "200"
      ? setListData(res.data.data)
      : setListData([]);
    setLoading(false);
  };

  // ! N개씩 보기

  const checkEventHandler = ({ target }) => {
    setLimit(target.value);
  };

  const onchageDateType = ({ target }) => {
    setSearchKeyword(target.value);
  };
  //  ! 페이징
  const [checkBool, setCheckBool] = useState("");
  // ^ 검색조건
  let parseCheckBool = "";
  for (let key in checkBool) {
    parseCheckBool = parseCheckBool + "&" + key;
    parseCheckBool = parseCheckBool + "=" + checkBool[key];
  }
  const emptyRows = limit;
  const checkFieldsTwo = [
    { field: "dateType", name: "날짜기준" },
    { id: "settlementDate", name: "정산일기준" },
    { id: "paymentDate", name: "결제일기준" },
  ];

  function TableHeader() {
    return (
      <th className="list-th text-center" style={{ width: "" }}>
        정산금액
      </th>
    );
  }
  const [modalFlag, setModalFlag] = useState(false);
  const [exportFlag, setExportFlag] = useState(false);
  useEffect(() => {
    if (exportFlag) {
      exportToXlsx("업체정산 내역_", address, type);
      setExportFlag(false);
      setModalFlag(false);
    }
  }, [exportFlag]);
  return (
    <>
      <PasswordModal
        postCodeFlag={modalFlag}
        handleClose={() => setModalFlag(false)}
        setExportFlag={() => setExportFlag(true)}
      />
      {modalOpen || (
        <div>
          <SearchComponent
            keyword={keyword}
            listData={listData}
            checkBool={checkBool}
            setCheckBool={setCheckBool}
            // setKeyword={setSearchKeyword}
            setKeyword={setKeyword}
            searchClick={searchApi}
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            onlyTwoFlag={true}
            checkFieldsTwo={checkFieldsTwo}
            setLoadFlag={setLoadFlag}
            noneKeyword={true}
          // checkFieldsThree={checkFieldsThree}
          />
          {/* <MiniSearchBox>
            <TextField
              className="m-2"
              variant="outlined"
              select
              fullWidth
              onChange={onchageDateType}
              label="날짜 구분"
              size="small"
              value={`${searchKeyword}`}
              style={{ backgroundColor: "white", width: "200px" }}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
            >
              {dateType?.map((field) => {
                const id = field?.id;
                const name = field?.name;
                return (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                );
              })}
            </TextField>
            <div
              style={{
                display: "flex",
                position: "relative",
                alignItems: "space-between",
                width: "100%",
              }}
            >
              <div className="button-box d-flex align-center justify-center">
                <ButtonSelectDateBox
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  className="m-2"
                />
                {userAuth === "SA" ? (
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    className="p-0 mb-2 mt-2"
                    onClick={() => setModalFlag(true)}
                  >
                    <img
                      src={excelIcon}
                      alt="EXCEL"
                      style={{ width: "23px" }}
                    />
                  </Button>
                ) : undefined}
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className="p-0 m-2"
                  onClick={searchApi}
                >
                  검색
                </Button>
              </div>
            </div>
          </MiniSearchBox> */}
          {/* <Box display="flex" justifyContent="flex-end">
            <SelectLimit checkEventHandler={checkEventHandler} limit={limit} />
          </Box> */}

          <Box height="5px" />
          <div className="table-responsive">
            <table className="text-nowrap mb-0 table table-striped">
              <TableHead className="thead-light">
                <tr>
                  <th className="list-th text-center" rowSpan="2">
                    <p style={{ paddingBottom: "10px" }}>업체명</p>
                  </th>
                  <th className="list-th text-center" colSpan="5">
                    구분
                  </th>
                  {userCompanyLevel <= 1 && (
                    <th className="list-th text-center" colSpan="1">
                      총판
                    </th>
                  )}
                  {userCompanyLevel <= 2 && (
                    <th className="list-th text-center" colSpan="1">
                      지사
                    </th>
                  )}
                  {userCompanyLevel <= 3 && (
                    <th className="list-th text-center" colSpan="1">
                      대리점1
                    </th>
                  )}
                  {userCompanyLevel <= 4 && (
                    <th className="list-th text-center" colSpan="1">
                      대리점2
                    </th>
                  )}

                  {userCompanyLevel <= 5 && (
                    <th className="list-th text-center" colSpan="1">
                      대리점3
                    </th>
                  )}

                  {userCompanyLevel <= 6 && (
                    <th className="list-th text-center" colSpan="1">
                      대리점4
                    </th>
                  )}

                  {userCompanyLevel <= 7 && (
                    <th className="list-th text-center" colSpan="1">
                      대리점5
                    </th>
                  )}

                  {userCompanyLevel <= 8 && (
                    <th className="list-th text-center" colSpan="1">
                      대리점6
                    </th>
                  )}

                  {userCompanyLevel <= 9 && (
                    <th className="list-th text-center" colSpan="1">
                      대리점7
                    </th>
                  )}
                  <th className="list-th text-center" colSpan="1"></th>

                  <th className="list-th text-center" colSpan="5">
                    은행정보
                  </th>
                  <th
                    className="list-th text-center"
                    style={{ width: "100px" }}
                    rowSpan="2"
                  >
                    <p style={{ paddingBottom: "10px" }}>상세보기</p>
                  </th>
                </tr>
                <tr>
                  <th className="list-th text-center" style={{ width: "" }}>
                    가맹점 수수료율
                  </th>
                  <th className="list-th text-center" style={{ width: "" }}>
                    결제건수
                  </th>
                  <th className="list-th text-center" style={{ width: "" }}>
                    승인금액
                  </th>
                  <th className="list-th text-center" style={{ width: "" }}>
                    취소금액
                  </th>
                  <th className="list-th text-center" style={{ width: "" }}>
                    거래금액
                  </th>
                  {userAuth == "SA"
                    ? Array.from({ length: 10 - userCompanyLevel - 1 }).map(
                      (_, index) => <TableHeader key={index} />
                    )
                    : Array.from({ length: 10 - userCompanyLevel }).map(
                      (_, index) => <TableHeader key={index} />
                    )}
                  <th className="list-th text-center" colSpan="1">
                    정산합계
                  </th>
                  <th className="list-th text-center" style={{ width: "" }}>
                    은행명
                  </th>
                  <th className="list-th text-center" style={{ width: "" }}>
                    코드
                  </th>
                  <th className="list-th text-center" style={{ width: "" }}>
                    계좌번호
                  </th>
                  <th className="list-th text-center" style={{ width: "" }}>
                    이체금액
                  </th>
                  <th className="list-th text-center" style={{ width: "" }}>
                    예금주
                  </th>
                </tr>
              </TableHead>
              <TableBody>
                {listData.length > 0 ? (
                  listData.map((row, idx) => {
                    let total = 0;
                    userAuth == "SA"
                      ? (total = row
                        .slice(14 + userCompanyLevel * 1, 23 + 1)
                        .reduce((acc, cur) => acc + parseInt(cur), 0))
                      : (total = row
                        .slice(14 + userCompanyLevel * 1 - 1, 23 + 1)
                        .reduce((acc, cur) => acc + parseInt(cur), 0));
                    return (
                      <>
                        <TableRow hover tabIndex={-1} key={idx}>
                          <TableCell className="text-left">
                            {convertCompany(row)}
                          </TableCell>

                          <TableCell className="text-right">
                            {row[10]}
                          </TableCell>
                          <TableCell className="text-right">
                            {row[11]}
                          </TableCell>
                          <TableCell className="text-right">
                            {formatToWon(row[12])}
                          </TableCell>
                          <TableCell className="text-right">
                            {formatToWon(row[13])}
                          </TableCell>
                          <TableCell className="text-right">
                            {formatToWon(row[13] * 1 + row[12] * 1)}
                          </TableCell>
                          {userCompanyLevel <= 1 && (
                            <TableCell
                              className="text-right"
                              style={{
                                color:
                                  row[0] && !row[1] && !row[10]
                                    ? "red"
                                    : "inherit",
                              }}
                            >
                              {formatToWon(row[14])}
                            </TableCell>
                          )}
                          {userCompanyLevel <= 2 && (
                            <TableCell
                              className="text-right"
                              style={{
                                color:
                                  row[1] && !row[2] && !row[10]
                                    ? "orange"
                                    : "inherit",
                              }}
                            >
                              {formatToWon(row[15])}
                            </TableCell>
                          )}
                          {userCompanyLevel <= 3 && (
                            <TableCell
                              className="text-right"
                              style={{
                                color:
                                  row[2] && !row[3] && !row[10]
                                    ? "green"
                                    : "inherit",
                              }}
                            >
                              {formatToWon(row[16])}
                            </TableCell>
                          )}

                          {userCompanyLevel <= 4 && (
                            <TableCell
                              className="text-right"
                              style={{
                                color:
                                  row[3] && !row[4] && !row[10]
                                    ? "green"
                                    : "inherit",
                              }}
                            >
                              {formatToWon(row[17])}
                            </TableCell>
                          )}

                          {userCompanyLevel <= 5 && (
                            <TableCell
                              className="text-right"
                              style={{
                                color:
                                  row[4] && !row[5] && !row[10]
                                    ? "green"
                                    : "inherit",
                              }}
                            >
                              {formatToWon(row[18])}
                            </TableCell>
                          )}

                          {userCompanyLevel <= 6 && (
                            <TableCell
                              className="text-right"
                              style={{
                                color:
                                  row[5] && !row[6] && !row[10]
                                    ? "green"
                                    : "inherit",
                              }}
                            >
                              {formatToWon(row[19])}
                            </TableCell>
                          )}

                          {userCompanyLevel <= 7 && (
                            <TableCell
                              className="text-right"
                              style={{
                                color:
                                  row[6] && !row[7] && !row[10]
                                    ? "green"
                                    : "inherit",
                              }}
                            >
                              {formatToWon(row[20])}
                            </TableCell>
                          )}

                          {userCompanyLevel <= 8 && (
                            <TableCell
                              className="text-right"
                              style={{
                                color:
                                  row[7] && !row[8] && !row[10]
                                    ? "green"
                                    : "inherit",
                              }}
                            >
                              {formatToWon(row[21])}
                            </TableCell>
                          )}
                          {userCompanyLevel <= 9 && (
                            <TableCell
                              className="text-right"
                              style={{
                                color:
                                  row[8] && !row[9] && !row[10]
                                    ? "green"
                                    : "inherit",
                              }}
                            >
                              {formatToWon(row[22])}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {formatToWon(total)}
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell className="p-1">
                            {row[10] == "" && (
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={() => handleModalOpen(row)}
                                sx={{ backgroundColor: "blue" }}
                                color="primary"
                              >
                                결제건 조회
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  <NoData
                    colNum={14}
                    comment="결제내역이 없습니다."
                    isLoading={loading}
                  />
                )}
                {/* {emptyRows > 0 && (
                <TableRow style={{ height: 43 * emptyRows }}>
                  <TableCell colSpan={60} />
                </TableRow>
              )} */}
              </TableBody>
            </table>
          </div>
        </div>
      )}
      {modalOpen && (
        <SettleCompanyDetailTable
          setModalOpen={setModalOpen}
          companyCodeUserSeq={companyCodeUserSeq}
          propsStartDate={startDate}
          propsEndDate={endDate}
          propsCheckBool={propsCheckBool}
        />
      )}
    </>
  );
};

export default SettleModalTable;

const MiniSearchBox = styled.div`
  display: ${({ userAuth }) => userAuth || "flex"};
  flex-direction: row;
  border: 0.3px solid rgba(20, 100, 200, 0.4);
  border-radius: 10px;
  width: 100%;
  background-color: rgba(20, 100, 200, 0.05);
  padding: 5px;
  /* margin: 10px; */
`;
export const convertCompany = (row) => {
  let companyText = "";
  for (let i = 0; i < 10; i++) {
    if (row[i] != "") {
      if (i == 0) {
        companyText = row[i];
      } else {
        companyText = "　".repeat(i) + "L" + row[i];
      }
    }
  }

  if (row[10] != "") companyText = "　".repeat(1);
  return companyText;
};
