import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { putAxios } from "hook/axiosInstance";
import { getAxios } from "hook/axiosInstance";
import { deleteAxios } from "hook/axiosInstance";
import { useState } from "react";

const NoticeDetailModal = ({
  modalOpen,
  setModalOpen,
  scroll,
  selectDetail,
  setSelectDetail,
  listData,
  setListData,
}) => {
  // ! 모달 닫기
  const handleCloseModal = async () => {
    const getRowsAfterModalClose = await getAxios("notice/list");
    setListData(getRowsAfterModalClose.data.data);
    setModalOpen(false);
  };

  // ! 수정
  const [isInputActivate, setIsInputActivate] = useState(false);
  const activateUpdateButton = () => {
    setIsInputActivate(true);
  };

  const onChangeSelectDetail = (e) => {
    setSelectDetail({ ...selectDetail, [e.target.name]: e.target.value });
  };

  const updateDetailApi = async () => {
    if (window.confirm("정말로 수정하시겠습니까?")) {
      const updateResult = await putAxios(
        `notice/detail/update/modify`,
        selectDetail
      );
      updateResult.status === 200 && window.alert("수정이 완료되었습니다.");

      handleCloseModal();
    }
  };

  // ! 삭제
  const handleDeleteDetail = async () => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      const deleteResult = await deleteAxios(
        `notice/detail/delete/${selectDetail.id}`
      );
      deleteResult.status === 200 && alert("삭제가 완료되었습니다.");
      handleCloseModal();
    }
  };

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            width: "80vw",
            height: "80vh",
            // maxHeight: 300,
          },
        }}
        open={modalOpen}
        onClose={handleCloseModal}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        classes={{ paper: "modal-dark " }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {isInputActivate ? (
            <DialogTitle
              id="scroll-dialog-title"
              style={{
                width: "100%",
                display: "flex",
                justifyContents: "space-between",
              }}
            >
              <input
                value={selectDetail.noticeTitle}
                name="noticeTitle"
                onChange={onChangeSelectDetail}
              />
              <FontAwesomeIcon icon={["fas", "times"]} />
            </DialogTitle>
          ) : (
            <DialogTitle id="scroll-dialog-title">
              <Box display="flex" alignItems="center">
                <span className="text-facebook">
                  {selectDetail.noticeTitle}
                </span>
                <Button onClick={handleCloseModal} style={{ color: "black" }}>
                  <FontAwesomeIcon
                    icon={["fas", "times"]}
                    className="d-flex align-self-center display-3"
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  />
                </Button>
              </Box>
            </DialogTitle>
          )}

          <div style={{}}>{selectDetail.noticeAuthor}</div>
        </div>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
          >
            {isInputActivate ? (
              <input
                value={selectDetail.noticeContent}
                name="noticeContent"
                onChange={onChangeSelectDetail}
              />
            ) : (
              <>{selectDetail.noticeContent}</>
            )}
            {selectDetail.date}
            {selectDetail.status}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isInputActivate ? (
            <Button onClick={updateDetailApi} color="primary">
              확인
            </Button>
          ) : (
            <Button onClick={activateUpdateButton} color="primary">
              수정
            </Button>
          )}

          <Button
            onClick={() => handleDeleteDetail()}
            color="warning"
            variant="contained"
          >
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default NoticeDetailModal;
