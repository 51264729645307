import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  TableBody,
  TableHead,
  TableCell,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { SearchComponent } from "components/commonTable/SearchComponent";
import { getAxios } from "hook/axiosInstance";
import styled from "styled-components";
import SettlePaymentModal from "./SettlePaymentModal";
import "assets/custom/button.css";
import { Box, Button } from "../../../../node_modules/@material-ui/core/index";
import { formatToWon } from "utils/formatFunction";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const SettleCompanyTable = () => {
  // ! Loading
  const [loading, setLoading] = useState(false);

  // ! GET 요청

  const [listData, setListData] = useState([]);
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [address, setAddress] = useState("settlement/company/list/total");
  const [loadFlag, setLoadFlag] = useState(false);
  useEffect(() => {
    if (loadFlag) getListApi(limit);
  }, [loadFlag]);
  const getListApi = async (limit) => {
    setLoading(true);
    let tempAd = `settlement/company/list/total?keyword=${encodeURI(
      keyword
    )}&startDate=${formatDate(startDate)}&endDate=${formatDate(
      endDate
    )}&limit=${limit}&${parseCheckBool}`;
    const resApiData = await getAxios(tempAd);
    setAddress(tempAd);
    if (resApiData.data.header.result_code === "200") {
      setLoading(false);
      const dataList = resApiData.data.data;
      const DATA_OBJ = {};
      dataList.forEach((e) => {
        let fristCompany = e.path.split(">")[0].trim();
        if (DATA_OBJ[fristCompany] === undefined) {
          DATA_OBJ[fristCompany] = {
            franchiseCount: e.franchiseCount,
            tradingCount: e.franchiseCount,
            tradingAmountOk: e.tradingAmountOk,
            tradingAmountCancel: e.tradingAmountCancel,
            tradingAmountTotal: e.tradingAmountTotal,
            companyCodeUserSeq: e.companyCodeUserSeq,
            payDataList: [e],
          };
        } else {
          DATA_OBJ[fristCompany].franchiseCount += e.franchiseCount;
          DATA_OBJ[fristCompany].tradingCount += e.franchiseCount;
          DATA_OBJ[fristCompany].tradingAmountOk += e.tradingAmountOk;
          DATA_OBJ[fristCompany].tradingAmountCancel += e.tradingAmountCancel;
          DATA_OBJ[fristCompany].tradingAmountTotal += e.tradingAmountTotal;
          DATA_OBJ[fristCompany].payDataList.push(e);
        }
      });

      // setListData(resApiData.data.data);
      setListData(DATA_OBJ);
    }
    // else {
    //   setListData([]);
    // }
  };

  useEffect(() => {
    getListApi(limit);
  }, [limit]);

  const emptyRows =
    limit - Math.min(limit, listData.totalCount - (page - 1) * limit);

  const classes = useStyles();

  // const handleChangePage = (page) => {
  //   setPage(page);
  //   const paging = async () => {
  //     const res = await getAxios(
  //       `settlement/company/list?limit=${limit}&page=${page}&${parseCheckBool}`
  //     );
  //     res.data.header.result_code === "200"
  //       ? setListData(res.data.data)
  //       : setListData([]);
  //   };
  //   paging();
  // };

  // ! 검색 관련
  const [keyword, setKeyword] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [checkBool, setCheckBool] = useState("");

  // ^ 검색조건
  let parseCheckBool = "";
  for (let key in checkBool) {
    parseCheckBool = parseCheckBool + "&" + key;
    parseCheckBool = parseCheckBool + "=" + checkBool[key];
  }
  const checkFields = [
    { field: "searchField", name: "검색조건" },
    { id: "all", name: "모두" },
    { id: "companyName", name: "업체명" },
    { id: "companyCode", name: "업체코드" },
  ];

  const checkFieldsTwo = [
    { field: "dateType", name: "날짜기준" },
    { id: "settlementDate", name: "정산일기준" },
    { id: "paymentDate", name: "결제일기준" },
  ];

  function formatDate(date) {
    if (date == null) {
      return "";
    }
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const searchClick = () => {
    if (endDate == null) {
      alert("날짜를 모두 입력하세요");
      return;
    }
    if (startDate == null) {
      alert("날짜를 모두 입력하세요");
      return;
    }

    const requestSearch = async () => {
      let tempAd = `settlement/company/list/total?keyword=${encodeURI(
        keyword
      )}&startDate=${formatDate(startDate)}&endDate=${formatDate(
        endDate
      )}&limit=${limit}&${parseCheckBool}`;
      const res = await getAxios(tempAd);
      setAddress(tempAd);
      // res.data.header.result_code === "200"
      //   ? setListData(res.data.data)
      //   : setListData([]);

      const dataList = res.data.data;
      const DATA_OBJ = {};
      dataList.forEach((e) => {
        let fristCompany = e.path.split(">")[0].trim();
        if (DATA_OBJ[fristCompany] === undefined) {
          DATA_OBJ[fristCompany] = {
            franchiseCount: e.franchiseCount,
            tradingCount: e.franchiseCount,
            tradingAmountOk: e.tradingAmountOk,
            tradingAmountCancel: e.tradingAmountCancel,
            tradingAmountTotal: e.tradingAmountTotal,
            companyCodeUserSeq: e.companyCodeUserSeq,
            payDataList: [e],
          };
        } else {
          DATA_OBJ[fristCompany].franchiseCount += e.franchiseCount;
          DATA_OBJ[fristCompany].tradingCount += e.franchiseCount;
          DATA_OBJ[fristCompany].tradingAmountOk += e.tradingAmountOk;
          DATA_OBJ[fristCompany].tradingAmountCancel += e.tradingAmountCancel;
          DATA_OBJ[fristCompany].tradingAmountTotal += e.tradingAmountTotal;
          DATA_OBJ[fristCompany].payDataList.push(e);
        }
      });
      setListData(DATA_OBJ);
    };
    requestSearch();
    setPage(1);
  };

  // ! Sorting 관련
  // const [order, setOrder] = useState("asc");

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  // function stableSort(array, comparator) {
  //   const stabilizedThis = array.map((el, index) => [el, index]);
  //   stabilizedThis.sort((a, b) => {
  //     const order = comparator(a[0], b[0]);
  //     if (order !== 0) return order;
  //     return a[1] - b[1];
  //   });
  //   return stabilizedThis.map((el) => el[0]);
  // }

  // function descendingComparator(a, b, orderBy) {
  //   if (b[orderBy] < a[orderBy]) {
  //     return -1;
  //   }
  //   if (b[orderBy] > a[orderBy]) {
  //     return 1;
  //   }
  //   return 0;
  // }

  // function getComparator(order, orderBy) {
  //   return order === "desc"
  //     ? (a, b) => descendingComparator(a, b, orderBy)
  //     : (a, b) => -descendingComparator(a, b, orderBy);
  // }

  // // ! 결제건 조회
  // const handleOpenPaymentModal = (row) => {
  //   window.open(
  //     "http://localhost:3000/payment/list",
  //     "popUpWindow",
  //     "width=1000px, height=600px"
  //   );
  // };

  // ! 모달
  const [companyCodeUserSeq, setCompanyCodeUserSeq] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [companyOpen, setCompanyOpen] = useState(true);
  const handleCloseCompanyModal = () => {
    setCompanyOpen(false);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleModalOpen = (row) => {
    setCompanyCodeUserSeq(row.companyCodeUserSeq);
    setModalOpen(true);
  };
  const [userAuth, setUserAuth] = useState(sessionStorage.getItem("userAuth"));
  return (
    <>
      {userAuth == "SA" && (
        <SearchComponent
          keyword={keyword}
          listData={listData}
          checkBool={checkBool}
          setCheckBool={setCheckBool}
          setKeyword={setKeyword}
          searchClick={searchClick}
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          checkFields={checkFields}
          checkFieldsTwo={checkFieldsTwo}
          setLoadFlag={setLoadFlag}
        />
      )}
      <Box height="5px" />
      <Card className="card-box mb-4">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <small>업체 정산</small>
            <b>업체 정산금액</b>
          </div>
          <div className="card-header--actions"></div>
        </div>
        <CardContent className="p-0">
          {userAuth != "SA" && (
            <SettlePaymentModal
              setModalOpen={setCompanyOpen}
              modalOpen={companyOpen}
              // companyCodeUserSeq={14}
              handleCloseModal={handleCloseCompanyModal}
            />
          )}
          {userAuth == "SA" && (
            <div className="table-responsive">
              <table className="text-nowrap mb-0 table ">
                <TableHead className="thead-light">
                  <tr>
                    <th
                      className="list-th text-center"
                      style={{ width: "70px" }}
                      rowSpan="2"
                    >
                      <p style={{ paddingBottom: "10px" }}>NO</p>
                    </th>
                    <th className="list-th text-center" rowSpan="2">
                      <p style={{ paddingBottom: "10px" }}>업체명</p>
                    </th>
                    <th className="list-th text-center" colSpan="6">
                      구분
                    </th>
                    <th
                      className="list-th text-center"
                      style={{ width: "100px" }}
                      rowSpan="2"
                    >
                      <p style={{ paddingBottom: "10px" }}>상세보기</p>
                    </th>
                  </tr>

                  <tr>
                    <th className="list-th text-center" style={{ width: "" }}>
                      수수료율
                    </th>
                    <th className="list-th text-center" style={{ width: "" }}>
                      수수료율등록건수
                    </th>
                    <th className="list-th text-center" style={{ width: "" }}>
                      결제건수
                    </th>
                    <th className="list-th text-center" style={{ width: "" }}>
                      승인금액
                    </th>
                    <th className="list-th text-center" style={{ width: "" }}>
                      취소금액
                    </th>
                    <th className="list-th text-center" style={{ width: "" }}>
                      거래금액
                    </th>
                  </tr>
                </TableHead>
                <TableBody>
                  {Object.keys(listData).map((key, idx) => {
                    const keyObjData = listData[key];
                    return (
                      <>
                        <TrStyle key={idx}>
                          <TableCell align="center">{idx + 1}</TableCell>
                          <TableCell
                            className="p-0 hover-overlay hover-zoom hover-shadow ripple"
                            align="center"
                          >
                            {key}
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell align="right">
                            {keyObjData?.franchiseCount}
                          </TableCell>
                          <TableCell align="right">
                            {keyObjData?.franchiseCount}
                          </TableCell>
                          <TableCell align="right">
                            {formatToWon(keyObjData?.tradingAmountOk)}
                          </TableCell>
                          <TableCell align="right">
                            {formatToWon(keyObjData?.tradingAmountCancel)}
                          </TableCell>
                          <TableCell align="right">
                            {formatToWon(keyObjData?.tradingAmountTotal)}
                          </TableCell>
                          <TableCell className="p-0">
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => handleModalOpen(keyObjData)}
                              sx={{ backgroundColor: "blue" }}
                              color="primary"
                            >
                              결제건 조회
                            </Button>
                          </TableCell>
                        </TrStyle>
                        {Object.keys(keyObjData.payDataList).map(
                          (paykey, idx) => {
                            const paymentDataList =
                              keyObjData.payDataList[paykey];
                            return (
                              <>
                                <TrStyle key={idx}>
                                  <TableCell align="center"></TableCell>
                                  <TableCell
                                    className="p-0 hover-overlay hover-zoom hover-shadow ripple"
                                    align="center"
                                  ></TableCell>
                                  <TableCell align="right">
                                    {formatToWon(
                                      paymentDataList?.franchiseCommissionRate
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    {formatToWon(
                                      paymentDataList?.franchiseCount
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    {formatToWon(
                                      paymentDataList?.franchiseCount
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    {formatToWon(
                                      paymentDataList?.tradingAmountOk
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    {formatToWon(
                                      paymentDataList?.tradingAmountCancel
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    {formatToWon(
                                      paymentDataList?.tradingAmountTotal
                                    )}
                                  </TableCell>
                                </TrStyle>
                              </>
                            );
                          }
                        )}
                      </>
                    );
                  })}
                </TableBody>
              </table>
            </div>
          )}

          {modalOpen && (
            <SettlePaymentModal
              startDate={startDate}
              endDate={endDate}
              propsCheckBool={checkBool}
              setModalOpen={setModalOpen}
              modalOpen={modalOpen}
              companyCodeUserSeq={companyCodeUserSeq}
              handleCloseModal={handleCloseModal}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default SettleCompanyTable;

const TrStyle = styled.tr`
  background-color: ${(props) =>
    props.status === "true" ? `rgb(200,200,200,${1 - props.level * 0.3})` : ""};
  font-weight: ${(props) => 1000 - props.level * 300};
`;
