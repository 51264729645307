import React, { Fragment, useEffect, useState } from "react";

import { Grid, Button } from "@mui/material";

import { makeStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";
import BasicInput from "./BasicInput";
import ServiceInput from "./ServiceInput";
import BusinessNum from "./BusinessNumInput";
import SearchCompanyModal from "./SearchCompanyModal";
import {
  checkoutCompanyNullInputData,
  validateInputs,
} from "utils/validateInputs";
import { postAxios } from "hook/axiosInstance";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 200,
  },
}));

const CompanyInput = ({ selectDetail, handleUpdate }) => {
  const [createValue, setCreateValue] = useState({
    createId: sessionStorage.getItem("userId"),
    userAuth: "SC",
    userPw: "1234",
    loginAllowCheck: "Y",
  });

  // ! 비사업자 선택 시
  const [noCompany, setNoCompany] = useState("");

  // ! 전송 loading
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const classes = useStyles();

  // ! validation, error
  const [error, setError] = useState({});
  const [helperText, setHelperText] = useState("");
  const [isDuplicateChecked, setIsDuplicateChecked] = useState(false);

  const handleChange = (event) => {
    validateInputs({ event, error, setError, helperText, setHelperText });
    setCreateValue({
      ...createValue,
      [event.target.name]: event.target.value,
    });
  };
  const clickChange = (event) => {
    setCreateValue({
      ...createValue,
      [event.name]: event.value,
    });
  };

  const [checkBoxValue, setCheckBoxValue] = useState(false);

  // ! 체크박스 로직
  const handleCheck = (e) => {
    if (e.target.checked === true) {
      setCreateValue({ ...createValue, [e.target.name]: "Y" });
    } else if (e.target.checked === false) {
      setCreateValue({ ...createValue, [e.target.name]: "N" });
    }
  };

  // ! 정보수정인 경우

  // let shallowHighCompanyName = ""; // 미안합니다 ㅜㅜ
  // let shallowHighCompanyNameParsed = ""; // 미안합니다 ㅜㅜ
  let copySelectDetail;
  if (selectDetail) {
    copySelectDetail = {
      ...selectDetail.company,
      ...selectDetail.company.user,
      highCompanyCode: selectDetail?.company?.parent,
      companyPath: selectDetail?.highCompanyPath,
      user: null,
      parent: null,
      id: selectDetail?.company.id,
    };
  }

  // ! 정보수정 - 전송로직
  useEffect(() => {
    if (selectDetail) {
      setIsDuplicateChecked(true);
      // shallowHighCompanyName = selectDetail.company.highCompanyPath;
      // shallowHighCompanyNameParsed =
      //   shallowHighCompanyName.split(">")[
      //     shallowHighCompanyName.split(">").length - 1
      //   ];
      setCreateValue({
        ...copySelectDetail,
        // highCompanyName: shallowHighCompanyNameParsed,
      });
    }
  }, []);

  const checkConfirm = () => {
    if (isDuplicateChecked === false) {
      alert("ID 중복체크 해주세요");
      return;
    }

    if (isLoading === true) {
      // alert("등록중입니다...") // ^ 두번 눌러서 들어가는거 방지

      return;
    }

    if (
      checkoutCompanyNullInputData({
        createValue,
        setError,
        error,
        setHelperText,
        helperText,
      }) === false
    ) {
      //^ 입력한 필드에 error 가 있으면
      alert("필수값을 모두 확인해주세요");
      return;
    } // ^ 에러 없으면

    postDataApi();
  };
  const postDataApi = async () => {
    sessionStorage.setItem("companyName", createValue.companyName);
    sessionStorage.setItem("companyNum", createValue.companyNum);
    try {
      setIsLoading(true); // 전송 보내기 직전에 로딩중 = true
      const posted = await postAxios("/company/create", createValue);
      if (posted.status === 200) {
        setIsLoading(false);
        alert("등록 완료");
        navigate("/company/list");
      } else {
        alert("등록 실패");
      }
    } catch (err) {
      if (err.response.data.status === 500) {
        alert("500 에러");
        return;
      }
      if (err.response.data.status === 509) {
        alert("509 에러");
        return;
      }
    }

    return;
  };

  // ! 모달관련
  const [scroll, setScroll] = useState("paper");

  const handleModalOpen = (e) => {
    setModalOpen(e.target.innerText);
  };
  const handleModalClose = () => {
    setModalOpen("");
  };
  const [modalOpen, setModalOpen] = useState("");

  // ! 정보수정 + 상위 업체 수정인 경우
  const [highCompanyData, setHighCompanyData] = useState({
    highCompanyCode: copySelectDetail?.highCompanyCode,
    highCompanyPath: selectDetail?.highCompanyName,
    highCompanyName: copySelectDetail?.agentName,
  });
  const handleChangeUpdateHighCompany = (row) => {
    if (selectDetail) {
      setHighCompanyData({
        ...highCompanyData,
        highCompanyPath: row?.highCompanyName,
        highCompanyCode: row?.company?.id,
        highCompanyName: row?.company?.user?.agentName,
        companyLevel: row?.company?.companyLevel + 1,
      });

      setCreateValue({
        ...createValue,
        highCompanyPath: row?.highCompanyName,
        highCompanyCode: row?.company?.id,
        highCompanyName: row?.company?.user?.agentName,
      });
    }
  };

  const companyUpdateValidate = (createValue) => {
    sessionStorage.setItem("companyName", createValue.companyName);
    sessionStorage.setItem("companyNum", createValue.companyNum);
    let update = true;
    if (
      checkoutCompanyNullInputData({
        createValue,
        setError,
        error,
        setHelperText,
        helperText,
        update,
      }) === false
    ) {
      //^ 입력한 필드에 error 가 있으면
      alert("필수값을 모두 확인해주세요");
      return;
    } // ^ 에러 없으면

    handleUpdate(createValue);
  };
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} sm={12} lg={6}>
          <BasicInput
            classes={classes}
            createValue={createValue}
            handleChange={handleChange}
            error={error}
            helperText={helperText}
            setIsDuplicateChecked={setIsDuplicateChecked}
            handleModalOpen={handleModalOpen}
            selectDetail={selectDetail}
            setCreateValue={setCreateValue}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            highCompanyData={highCompanyData}
            setNoCompany={setNoCompany}
            handleCheck={handleCheck}
          />
        </Grid>
        {/* ! 기본 정보 끝 */}

        {/* ! 서비스 설정 및 정산계좌 정보 시작 */}
        <Grid item xs={12} lg={6}>
          <ServiceInput
            classes={classes}
            createValue={createValue}
            handleChange={handleChange}
            error={error}
            helperText={helperText}
            checkBoxValue={checkBoxValue}
            handleCheck={handleCheck}
          />
          {createValue.companyType != "1" && (
            <BusinessNum
              classes={classes}
              createValue={createValue}
              handleChange={handleChange}
              error={error}
              helperText={helperText}
              checkBoxValue={checkBoxValue}
              noCompany={noCompany}
              clickChange={clickChange}
            />
          )}
        </Grid>

        {/* !서비스 설정 및 정산계좌 정보 끝 */}

        {/* 간편결제 정보 시작 !! */}
        <Grid item xs={12} lg={6}>
          {/* <EasyPayInput
            classes={classes}
            createValue={createValue}
            handleChange={handleChange}
            values={values}
            error={error}
            helperText={helperText}
            checkBoxValue={checkBoxValue}
          /> */}
        </Grid>
        {/* 간편결제 정보 끝 !! */}
      </Grid>
      <div>
        {selectDetail ? (
          <Button
            variant='contained'
            color='primary'
            className='m-2'
            onClick={() => companyUpdateValidate(createValue)}
          >
            업체 수정하기
          </Button>
        ) : (
          <>
            <Button
              variant='contained'
              color='primary'
              className='m-2'
              onClick={() => checkConfirm()}
            >
              업체 등록
            </Button>
            <Link to='/company/list'>
              <Button variant='contained' className='m-2' color='warning'>
                뒤로가기
              </Button>
            </Link>
          </>
        )}
      </div>
      {modalOpen === "업체코드검색" || modalOpen === "상위업체수정" ? (
        <SearchCompanyModal
          modalOpen={true}
          setModalOpen={setModalOpen}
          scroll={scroll}
          handleModalClose={handleModalClose}
          setCreateValue={setCreateValue}
          handleChangeUpdateHighCompany={handleChangeUpdateHighCompany}
          createValue={selectDetail ? copySelectDetail : createValue}
          selectDetail={selectDetail}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CompanyInput;
