import PageTitle from "components/PageTitle/PageTitle";
import { ExampleWrapperSeamless } from "layouts/wrappers/ExampleWrapperSeamless";
import { ExampleWrapperSimple } from "layouts/wrappers/ExampleWrapperSimple";
import React from "react";
import { Outlet } from "../../node_modules/react-router-dom/dist/index";

const TitleOutlet = ({ title }) => {
  return (
    <>
      <PageTitle titleHeading={title} />
      <Outlet />
    </>
  );
};

export default TitleOutlet;
