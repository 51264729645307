// 수정, 등록할때 널처리
// 전화번호, 우편번호 등 값 맞춰서
// 계좌번호 숫자만 입력하게

// 은행명 셀렉트박스로

module.exports = {
  //해당 입력된 칸에 Null 체크
  nullCheck: (data) => {
    return data.trim();
  },
  //숫자만 입력 가능하게
  onlyInputNumber: (value) => {
    return value.replace(/[^0-9]/g, "");
  },
  //휴대폰 패턴
  phonePattern: (data) => {
    return data.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  },
  //사업자 등록 번호 패턴
  bizNoPattern: (data) => {
    return data.replace(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`);
  },
  //주민등록번호
  socNoPattern: (data) => {
    return data.replace(/^(\d{6})(\d{7})$/, `$1-$2`);
  },
  // 수수료율 (숫자 + .)
  commissionPattern: (data) => {
    return data.replace(/[^0-9.]/g, "");
  },
  // 영문, 숫자만 입력가능하도록
  onlyInputEngAndNumber: (data) => {
    return data.replace(/[^A-Za-z0-9]/g, "");
  },
  // 공백, 제거 
  removeAllSpaces:(data) =>{
    return data.replace(/\s+/g, '');
  }
};
