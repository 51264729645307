import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  Button,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableFooter,
} from "@material-ui/core";
import Pagination from "react-js-pagination";
import { getAxios } from "hook/axiosInstance";
import { formatOnlyDate, formatToWon, listToDate } from "utils/formatFunction";
import SelectLimit from "components/commonTable/SelectLimit";
import NoData from "components/commonTable/NoData";
import DeductDepositModal from "./DeductDepositModal";
import DeductCreateModal from "./DeductCreateModal";
import { Box } from "../../../../node_modules/@material-ui/core/index";
import { Search } from "components/commonTable/Search";
import { useRecoilState } from "recoil";
import { listLinkStateAtom } from "recoilStates/searchRecoil";

const DeductTable = () => {
  // ! Loading
  const [loading, setLoading] = useState(false);

  // ! GET 요청

  const [listData, setListData] = useState({ dataList: [] });
  const [limit, setLimit] = useState(15);
  const [address, setAddress] = useState();
  const [loadFlag, setLoadFlag] = useState(false);
  const [page, setPage] = useState(1);
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);

  const getListApi = async () => {
    setLoading(true);
    if (!loadFlag) setLoadFlag(true);
    if (listLinkState) {
      const tempAd = listLinkState;
      setAddress(tempAd);
      const resApiData = await getAxios(tempAd);
      if (resApiData.data.header.result_code === "200") {
        setLoading(false);
        setListData(resApiData.data.data);
      }
    }
  };

  useEffect(() => {
    if (listLinkState) {
      getListApi();
    }
  }, [page, limit]);

  useEffect(() => {
    if (listLinkState)
      if (!loadFlag) {
        getListApi();
      }
  }, [listLinkState, loading]);

  const handleChangePage = (e) => {
    setPage(e);
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/page=[^&]*/, `page=${e}`);
    setListLinkState(tempLink);
  };

  const emptyRows =
    limit - Math.min(limit, listData.totalCount - (page - 1) * limit);

  // ! 모달
  const [modalOpen, setModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  // ! N개씩 보기

  // ! 검색 관련

  // ! Sorting 관련
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("num");

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // ! 선택 관련
  const [selected, setSelected] = useState([]);

  const isSelected = (tid) => selected.indexOf(tid) !== -1;

  // ^ 입금내역
  const [selectData, setSelectData] = useState({});

  const handleModalOpen = async (row) => {
    await getAxios(`payment/deduct/deposit/${row.id}`);
    setModalOpen(true);
  };

  const handleDetailClick = (row, idx) => {
    handleModalOpen(row);
    setSelectData(row);
  };
  const searchField = [
    { id: "all", name: "모두" },
    { id: "franchiseName", name: "가맹점명" },
    { id: "appNo", name: "승인번호" },
    { id: "cardNo", name: "카드번호" },
    { id: "commissionRate", name: "수수료율" },
    { id: "tid", name: "단말기코드" },
  ];
  return (
    <>
      <Search
        dateFlag={true}
        pageFlag={true}
        link={"payment/deduct/list?"}
        checkList={["deductType", "settlementYn", "searchField"]}
        getListApi={getListApi}
        limit={true}
        setPage={setPage}
        searchField={searchField}
        dateNull={true}
      />
      <Box height="5px" />
      <Card className="card-box mb-4">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <small>결제내역 관리</small>
            <b>차감내역 조회</b>
          </div>
          <div className="card-header--actions">
            {selected.length > 0 ? (
              <span>{selected.length} 개 선택</span>
            ) : (
              <></>
            )}
            <SelectLimit limit={limit} setLimit={setLimit} setPage={setPage} />
          </div>
        </div>
        <CardContent className="p-0">
          <div className="table-responsive">
            <table className="text-nowrap mb-0 table table-striped">
              <TableHead className="thead-light">
                <tr>
                  <th
                    class="list-th text-center"
                    rowSpan="2"
                    style={{ width: "60px", paddingBottom: "32px" }}
                  >
                    NO
                  </th>
                  <th
                    class="list-th text-center"
                    rowSpan="2"
                    style={{ width: "130px", paddingBottom: "24px" }}
                  >
                    취소일자
                    <br />
                    (원거래일자)
                  </th>
                  <th
                    class="list-th text-center"
                    rowSpan="2"
                    style={{ width: "80px", paddingBottom: "24px" }}
                  >
                    정산예정일
                    <br />
                    (정산완료일)
                  </th>
                  <th
                    class="list-th text-center"
                    rowSpan="2"
                    style={{ minwidth: "110px", paddingBottom: "32px" }}
                  >
                    가맹점명
                  </th>
                  <th
                    class="list-th text-center"
                    rowSpan="2"
                    style={{ width: "80px", paddingBottom: "32px" }}
                  >
                    터미널
                  </th>
                  <th
                    class="list-th text-center"
                    rowSpan="2"
                    style={{
                      width: "56px",
                      paddingBottom: "32px",
                    }}
                  >
                    매입사
                  </th>
                  <th
                    class="list-th text-center"
                    rowSpan="2"
                    style={{
                      width: "56px",
                      paddingBottom: "32px",
                    }}
                  >
                    할부
                  </th>
                  <th
                    class="list-th text-center"
                    rowSpan="2"
                    style={{ width: "70px", paddingBottom: "32px" }}
                  >
                    승인번호
                  </th>
                  <th
                    class="list-th text-center"
                    rowSpan="2"
                    style={{ width: "80px", paddingBottom: "32px" }}
                  >
                    취소금액
                  </th>
                  <th
                    class="list-th text-center"
                    rowSpan="2"
                    style={{ width: "80px", paddingBottom: "32px" }}
                  >
                    수수료
                  </th>
                  <th
                    class="list-th text-center"
                    rowSpan="2"
                    style={{
                      width: "80px",
                      paddingBottom: "24px",
                    }}
                  >
                    차감할
                    <br />
                    금액
                  </th>
                  <th
                    class="list-th text-center"
                    style={{ textAlign: "center" }}
                    colSpan="3"
                  >
                    차감완료금액(A)
                  </th>
                  <th
                    class="list-th text-center"
                    rowSpan="2"
                    style={{
                      width: "80px",
                      paddingBottom: "24px",
                    }}
                  >
                    미차감
                    <br />
                    금액
                  </th>
                  <th
                    class="list-th text-center"
                    rowSpan="2"
                    style={{ width: "140px", paddingBottom: "32px" }}
                  >
                    입금처리/내역
                  </th>
                  <th
                    class="list-th text-center"
                    rowSpan="2"
                    style={{ width: "54px", paddingBottom: "32px" }}
                  >
                    PG
                  </th>
                  <th
                    class="list-th text-center"
                    rowSpan="2"
                    style={{ width: "210px", paddingBottom: "32px" }}
                  >
                    AGENT
                  </th>
                </tr>
                <tr>
                  <th class="list-th text-center" style={{ width: "80px" }}>
                    수기입금(ⓐ)
                  </th>
                  <th class="list-th text-center" style={{ width: "80px" }}>
                    정산차감(ⓑ)
                  </th>
                  <th class="list-th text-center" style={{ width: "80px" }}>
                    (A)=(ⓐ+ⓑ)
                  </th>
                </tr>
              </TableHead>

              <TableBody>
                {listData.dataList.length > 0 ? (
                  stableSort(
                    listData.dataList,
                    getComparator(order, orderBy)
                  ).map((row, idx) => {
                    // ! 번호
                    const rowNum =
                      listData.totalCount - limit * (page - 1) - idx;

                    const isItemSelected = isSelected(row?.mid);
                    const labelId = "enhanced-table-checkbox";

                    // const listToDate = new Date(row?.createDateTime);
                    // const parsedDate = new Intl.DateTimeFormat("fr-CA").format(
                    //   listToDate
                    // );

                    // ! 날짜
                    const tradingDate = listToDate(row?.tradingDate);
                    const cancelDate =
                      row?.paymentYn === "2"
                        ? listToDate(row?.tradingDate)
                        : "  ";
                    const orgDate =
                      row?.paymentYn === "2"
                        ? listToDate(row?.orgAppDate)
                        : "  ";
                    const settlementPlanDate = formatOnlyDate(
                      row?.settlementPlanDate
                    );
                    const settlementDoneDate = formatOnlyDate(
                      row?.settlementDoneDate || "0000-00-00"
                    );

                    // ! 할부
                    let installment;
                    if (row?.inMonth === "00" || row?.inMonth === "0") {
                      installment = "일시불";
                    } else {
                      installment = row?.inMonth + " 개월";
                    }
                    return (
                      <TableRow
                        hover
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={idx}
                        selected={isItemSelected}
                      >
                        <TableCell className="text-center">{rowNum}</TableCell>
                        <TableCell align="center" className="p-1">
                          {row?.paymentYn === "1" ? null : (
                            <>
                              <span className="text-danger">{cancelDate}</span>
                              <br />
                              <span>{orgDate}</span>
                            </>
                          )}
                        </TableCell>
                        <TableCell align="center" className="p-1">
                          {settlementPlanDate}
                          <br />
                          <span className="text-twitter">
                            {settlementDoneDate}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          {row?.franchise?.user?.agentName}
                        </TableCell>
                        <TableCell align="center">
                          {row?.terminal?.tid}
                        </TableCell>
                        <TableCell align="center">
                          {row?.cardIssuerName}
                        </TableCell>
                        <TableCell align="center">{installment}</TableCell>

                        <TableCell align="center">
                          {row?.approvalNumber}
                        </TableCell>
                        <TableCell align="right">
                          {row?.tradingAmount
                            ? formatToWon(-row?.tradingAmount)
                            : "tradingAmount 없음!"}
                        </TableCell>
                        <TableCell align="right">
                          {formatToWon(-row?.commissionAmount || 0)}
                        </TableCell>
                        <TableCell align="right">
                          {formatToWon(-row?.settlementAmount || 0)}
                        </TableCell>
                        <TableCell align="right">
                          {formatToWon(row?.depositAmount || 0)}
                        </TableCell>
                        <TableCell align="right">
                          {formatToWon(row?.depositAmountss || 0)}
                        </TableCell>
                        <TableCell align="right">
                          {formatToWon(
                            row?.depositAmount + (row?.depositAmountss || 0)
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {formatToWon(
                            -(
                              row?.settlementAmount -
                              -(
                                row?.depositAmount + (row?.depositAmountss || 0)
                              )
                            )
                          )}
                        </TableCell>
                        <TableCell align="center" className="p-1">
                          <Button
                            variant="outlined"
                            color="warning"
                            size="small"
                            className="mx-2 p-1"
                            // style={{ fontSize: "5px" }}
                            onClick={() => {
                              setCreateModalOpen(true);
                              setSelectData(row);
                            }}
                          >
                            입금처리
                          </Button>
                          <Button
                            variant="outlined"
                            color="warning"
                            size="small"
                            className="mx-2 p-1"
                            // style={{ fontSize: "5px" }}
                            onClick={() => {
                              handleDetailClick(row, idx);
                            }}
                          >
                            입금내역
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          {row?.franchise?.pgCompany?.pgCompanyName?.pgName}(
                          {row?.franchise?.pgCompany?.mid})
                        </TableCell>
                        <TableCell align="center">{row?.agent}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <>
                    <NoData
                      comment="차감내역이 없습니다."
                      colNum={18}
                      isLoading={loading}
                    />
                  </>
                )}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={20} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </table>
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={listData.totalCount}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              firstPageText={"‹‹"}
              lastPageText={"››"}
              onChange={(event) => {
                handleChangePage(event);
              }}
              // onPageChange={handleChangePage}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </div>
          {modalOpen ? (
            <DeductDepositModal
              modalOpen={modalOpen}
              handleModalClose={() => setModalOpen(false)}
              setSelectData={setSelectData}
              selectData={selectData}
              setModalOpen={setModalOpen}
            />
          ) : (
            <></>
          )}
          {createModalOpen ? (
            <DeductCreateModal
              modalOpen={true}
              handleModalClose={() => {
                setCreateModalOpen(false);
                getListApi(limit);
              }}
              selectedRow={selectData}
            />
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default DeductTable;
