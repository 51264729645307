import React from "react";
import {
  TableCell,
} from "@material-ui/core";
import { TableSortLabel } from "../../../../node_modules/@material-ui/core/index";


const SettleFranchiseTermTableHeader = ({ onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  id,
  handleRequestSort,
  label }) => {
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  return (
    <TableCell
      className="list-th text-center"
      rowSpan="2"
    // style={{ width: "80px", paddingBottom: "15px" }}
    >
      <TableSortLabel
        active={orderBy === id}
        onClick={createSortHandler(id)}
        direction={orderBy === id ? order : "asc"}
        style={{
          textDecorationStyle: "double",
          textAlign: "center",
          fontWeight: 700

        }}
        hideSortIcon
      >
        {label}
      </TableSortLabel>

    </TableCell>
  );
};

export default SettleFranchiseTermTableHeader;
