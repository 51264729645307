import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Divider,
  FilledInput,
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
  Box,
} from "@material-ui/core";
import { Grid } from "@mui/material";

import clsx from "clsx";
import CommonTextFieldFullwidth from "components/commonInput/CommonTextField";
import { getAxios } from "hook/axiosInstance";

import React, { useEffect } from "react";
import { useState } from "react";
import { phonePattern } from "utils/validationCheckFunction";
import { socNoPattern } from "utils/validationCheckFunction";
import {
  FormControlLabel,
  FormLabel,
  MenuItem,
  Switch,
} from "../../../../node_modules/@material-ui/core/index";
import { ControlPointSharp } from "../../../../node_modules/@material-ui/icons/index";

const BasicInput = ({
  classes,
  createValue,
  setCreateValue,
  handleChange,
  handleModalOpen,
  error,
  helperText,
  setIsDuplicateChecked,
  selectDetail,
  highCompanyData,
  handleCheck,
}) => {
  const companyTypeList = [
    {
      value: "1",
      label: "비사업자",
    },
    {
      value: "2",
      label: "개인사업자",
    },
    {
      value: "3",
      label: "법인사업자",
    },
    {
      value: "4",
      label: "기타",
    },
  ];

  const checkIdDuplicate = async (createValue) => {
    const userId = createValue?.userId;
    if (!userId) {
      alert("아이디를 입력해주세요");
      return;
    }
    const resDuplicate = await getAxios(`company/check/userId/${userId}`);
    if (resDuplicate.data === false) {
      setIsDuplicateChecked(true);
      alert("사용 가능한 ID입니다.");
      return;
    } else {
      setIsDuplicateChecked(false);
      alert("이미 존재하는 ID입니다.");
      return;
    }
  };
  const handleSelect = (row) => {
    setCreateValue({
      ...createValue,
      highCompanyPath: row?.company?.highCompanypath,
      highCompanyCode: row.company.user?.id,
      highCompanyName: row?.company?.user?.agentName,
      companyLevel: row?.company?.companyLevel + 1,
    });
  };

  // ! 수정 + 상위업체수정

  const [updateHighCompany, setUpdateHighCompany] = useState({});
  useEffect(() => {
    if (updateHighCompany) {
      setCreateValue({ ...createValue, ...updateHighCompany });
    }
  }, [updateHighCompany]);
  return (
    <Card className='p-4 mb-4'>
      <div className='font-size-lg font-weight-bold'>기본 정보</div>
      <Divider className='my-4' />
      <div>
        <Grid container columnSpacing={2}>
          <Grid xs={12}>
            <Box>
              <FormControl
                size='small'
                disabled
                fullWidth
                className={classes.margin}
                variant='outlined'
              >
                {/* <InputLabel htmlFor="company-code-search-input">업체코드 검색버튼 클릭</InputLabel> */}
                <FilledInput
                  id='highCompanyPath'
                  name='highCompanyPath'
                  value={
                    selectDetail
                      ? highCompanyData?.highCompanyPath
                      : createValue?.highCompanyPath
                  }
                  labelwidth={60}
                  startAdornment={
                    <InputAdornment position='start'>
                      상위업체 Path &nbsp;
                      <FontAwesomeIcon
                        icon='fa-solid fa-asterisk'
                        size='xs'
                        color='red'
                      />
                    </InputAdornment>
                  }
                  inputProps={{ "aria-label": "weight" }}
                  error={error?.companyPath}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid xs={5.5}>
            <FormControl
              className={classes.margin}
              variant='filled'
              size='small'
              fullWidth
            >
              <FilledInput
                size='small'
                disabled
                id='highCompayCode'
                name='highCompayCode'
                value={
                  selectDetail
                    ? highCompanyData?.highCompanyCode
                    : createValue?.highCompanyCode
                }
                onChange={handleChange}
                aria-describedby='filled-weight-helper-text'
                fullWidth
                startAdornment={
                  <InputAdornment position='start'>
                    상위업체코드 &nbsp;
                    <FontAwesomeIcon
                      icon='fa-solid fa-asterisk'
                      size='xs'
                      color='red'
                    />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid xs={0.5}></Grid>
          <Grid xs={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FormControl
                disabled
                className={clsx(classes.margin, classes.textField)}
                variant='filled'
                size='small'
              >
                <InputLabel htmlFor='outlined-adornment-amount'>
                  {selectDetail
                    ? null
                    : createValue?.highCompanyName
                    ? null
                    : "업체명"}
                </InputLabel>
                <FilledInput
                  size='small'
                  id='darijom'
                  value={
                    selectDetail
                      ? highCompanyData?.highCompanyName
                      : createValue?.highCompanyName
                  }
                  aria-describedby='filled-weight-helper-text'
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
              {selectDetail ? null : (
                <Button
                  variant='contained'
                  color='primary'
                  className='m-2'
                  size='small'
                  onClick={handleModalOpen}
                >
                  업체코드검색
                </Button>
              )}
            </div>
          </Grid>

          <Grid xs={7.5}>
            <FormControl
              size='small'
              className={classes.margin}
              variant='outlined'
              fullWidth
            >
              <TextField
                disabled={selectDetail ? true : false}
                id='userId'
                name='userId'
                variant='outlined'
                size='small'
                label={
                  <span position='start'>
                    아이디&nbsp;
                    <FontAwesomeIcon
                      icon='fa-solid fa-asterisk'
                      size='xs'
                      color='red'
                    />
                  </span>
                }
                type={"text"}
                placeholder='2~12자 이내 영문과 숫자만 가능'
                value={createValue?.userId}
                onChange={handleChange}
                error={error?.userId}
                helperText={
                  helperText?.userId ||
                  "※ 비밀번호는 자동으로 생성(1234)됩니다."
                }
              />
            </FormControl>
          </Grid>
          <Grid xs={0.5}> </Grid>
          <Grid xs={4}>
            <Button
              variant='contained'
              color='primary'
              className='m-2'
              size='small'
              onClick={() => checkIdDuplicate(createValue)}
              sx={{ whiteSpace: "nowrap", minWidth: "auto" }}
            >
              ID 중복체크
            </Button>
          </Grid>
          {selectDetail ? (
            <Grid xs={12}>
              <CommonTextFieldFullwidth
                handleChange={handleChange}
                createValue={createValue}
                helperText={helperText}
                error={error}
                field='userPw'
                meaning='비밀번호'
                required={true}
                type='password'
              />
            </Grid>
          ) : null}
          <Grid xs={5.5}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field='agentName'
              meaning='업체'
              placeholder='업체명'
              required={true}
              // disabled={selectDetail ? true : false}
            />
          </Grid>
          <Grid xs={0.5}></Grid>
          <Grid xs={6}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field='agentNickname'
              meaning='업체별칭'
              required={true}
            />
          </Grid>

          <Grid xs={5.5}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field='ceoName'
              meaning='대표자명'
              required={true}
            />
          </Grid>
          <Grid xs={0.5}></Grid>
          <Grid xs={6}>
            <TextField
              className='m-2'
              size='small'
              id='ceoId'
              name='ceoId'
              variant='outlined'
              type={"text"}
              maxLength='14'
              label='대표자주민번호'
              placeholder='000000-0000000'
              // error={}
              value={
                createValue.ceoId?.length === 13
                  ? socNoPattern(createValue.ceoId)
                  : createValue.ceoId
              }
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          {/* <Grid xs={0.5}></Grid> */}

          <Grid xs={5.5}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field='managerName'
              meaning='담당자명'
              required={true}
            />
          </Grid>
          <Grid xs={0.5}></Grid>
          <Grid xs={6}>
            <TextField
              size='small'
              className='m-2'
              id='managerContact'
              name='managerContact'
              variant='outlined'
              onChange={handleChange}
              label={
                <span position='start'>
                  담당자연락처&nbsp;
                  <FontAwesomeIcon
                    icon='fa-solid fa-asterisk'
                    size='xs'
                    color='red'
                  />
                </span>
              }
              placeholder='010-xxxx-xxxx'
              value={
                createValue.managerContact?.length === 11
                  ? phonePattern(createValue.managerContact)
                  : createValue.managerContact
              }
              error={error?.managerContact}
              helperText={helperText?.managerContact}
              fullWidth
            />
          </Grid>
          <Grid xs={5.5}>
            <TextField
              sx={{ width: "1200px" }}
              className='m-2'
              id='companyType'
              name='companyType'
              variant='outlined'
              select
              fullWidth
              size='small'
              value={createValue?.companyType * 1}
              onChange={handleChange}
              helperText={helperText?.companyType}
              error={error?.companyType}
              // onClick={(e) => {
              //   e.target.value == 1
              //     ? setNoCompany("비사업자")
              //     : setNoCompany("사업자");
              // }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    사업자구분&nbsp;
                    <FontAwesomeIcon
                      icon='fa-solid fa-asterisk'
                      size='xs'
                      color='red'
                    />
                  </InputAdornment>
                ),
              }}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
            >
              {companyTypeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid xs={0.5}></Grid>
          <Grid xs={6}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field='managerEmail'
              meaning='담당자이메일'
              type='email'
            />
          </Grid>
          <Grid xs={12}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field='ceoAddress'
              meaning='대표자주소'
              placeholder='비사업자만 해당'
            />
          </Grid>
          <Grid xs={6}>
            <div className='d-flex flex-column align-center'>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <FormLabel className='m-2'>
                  가맹점 등록 권한&nbsp;
                  <FontAwesomeIcon
                    icon='fa-solid fa-asterisk'
                    size='xs'
                    color='red'
                  />
                </FormLabel>
                <FormControlLabel
                  control={
                    <Switch
                      color='primary'
                      checked={createValue?.canRegister === "Y"}
                      onChange={handleCheck}
                    />
                  }
                  name='canRegister'
                  id='canRegister'
                  label={createValue?.canRegister === "Y" ? "허용" : "비허용"}
                  // onClick={handleCheck}
                  helperText={helperText?.canRegister}
                  error={error?.canRegister}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default BasicInput;
