import { Link } from "react-router-dom";
import {
  faBell,
  faBuilding,
  faCreditCard,
} from "../../../node_modules/@fortawesome/free-regular-svg-icons/index";
import {
  faBook,
  faCommentsDollar,
  faMoneyBillTransfer,
  faShop,
  faTabletScreenButton,
} from "../../../node_modules/@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "../../../node_modules/@fortawesome/react-fontawesome/index";
import PerfectScrollbar from "react-perfect-scrollbar";
import SidebarWithAuth from "./SidebarWithAuth";

const DrawerSidebar = () => {
  return (
    <SidebarWithAuth />
  );
};

export default DrawerSidebar;
