import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Divider,
  FilledInput,
  FormControl,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  FormControlLabel,
  MenuItem,
} from "@material-ui/core";
import { FormLabel } from "@mui/material";

import React, { useEffect, useState } from "react";
import { Box } from "../../../../node_modules/@material-ui/core/index";

const EasyPayInput = ({
  createValue,
  handleChange,
  error,
  helperText,
  setCreateValue,
  handleCheck,
}) => {
  // // ! 정산주기 0이면 간편결제 허용.
  // if (
  //   e.target.name === "paymentEasyYn"
  //   // createValue?.paymentEasyYn === "Y"
  // ) {
  //   setCreateValue({ ...createValue, settlementCycle: "0" });
  //   handleChange({ target: { name: "settlementCycle", value: "0" } });
  //   // alert("간편결제 허용 가맹점은 정산주기가 0일입니다.");
  // }
  const paymentEasyType = [
    { label: "", value: "" },
    { label: "K-App", value: "Y" },
    { label: "K-TER", value: "N" },
    { label: "K-WEP", value: "W" },
    { label: "N-TER", value: "T" },
    { label: "N-S", value: "V" },
  ];
  const paymentEasyTypeTemp = [
    { label: "허용", value: "Y" },
    { label: "미허용", value: "N" },
  ];

  const paymentEasyLimitPeriodList = [
    { label: "일시불", value: "0" },
    { label: "1개월", value: "1" },
    { label: "2개월", value: "2" },
    { label: "3개월", value: "3" },
    { label: "4개월", value: "4" },
    { label: "5개월", value: "5" },
    { label: "6개월", value: "6" },
    { label: "7개월", value: "7" },
    { label: "8개월", value: "8" },
    { label: "9개월", value: "9" },
    { label: "10개월", value: "10" },
    { label: "11개월", value: "11" },
    { label: "12개월", value: "12" },
  ];
  const [pgFlag, setPgFlag] = useState(
    createValue?.pgFlag ? createValue.pgFlag : ""
  );
  useEffect(() => {
    if (createValue) setPgFlag(createValue.pgFlag);
  }, [createValue]);
  return (
    <Card className="p-4 mb-4">
      <div className="font-size-lg font-weight-bold">간편결제 정보</div>
      <Divider className="my-2" />
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <FormControl size="medium">
          &nbsp;&nbsp;&nbsp;&nbsp;
          <TextField
            size="small"
            className="m-2"
            id="pgFlag"
            name="pgFlag"
            variant="outlined"
            select
            value={pgFlag}
            onChange={handleChange}
            // helperText={helperText?.pgFlag}
            // error={error?.pgFlag}
            disabled
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "center",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  결제유형&nbsp;
                  <FontAwesomeIcon
                    icon="fa-solid fa-asterisk"
                    size="xs"
                    color="red"
                  />
                </InputAdornment>
              ),
            }}
          >
            {paymentEasyType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        {/* <FormLabel className="m-2">
          간편결제&nbsp;
          <FontAwesomeIcon icon="fa-solid fa-asterisk" size="xs" color="red" />
        </FormLabel> */}
        {/* <FormControl error={error?.paymentEasyYn}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                defaultChecked={createValue?.paymentEasyYn === "Y"}
                onChange={handleCheck}
              />
            }
            name="paymentEasyYn"
            id="paymentEasyYn"
            label={createValue?.paymentEasyYn === "Y" ? "허용" : "차단"}
          /> */}
        {/* <RadioGroup row name="paymentEasyYn" onChange={handleChange}>
            <FormControlLabel
              control={
                <Radio
                  id="settlementPendingY"
                  className="align-self-start"
                  color="primary"
                />
              }
              label="허용"
              value="Y"
            />

            <FormControlLabel
              control={
                <Radio
                  id="settlementPendingN"
                  className="align-self-start"
                  color="primary"
                />
              }
              label="불가"
              value="N"
            />
          </RadioGroup> */}
        {/* <FormHelperText>{helperText?.paymentEasyYn}</FormHelperText>
        </FormControl> */}
        <Box display="flex" alignItems="center">
          <FormLabel className="m-2">
            인증여부&nbsp;
            <FontAwesomeIcon
              icon="fa-solid fa-asterisk"
              size="xs"
              color="red"
            />
          </FormLabel>
          <FormControl style={{ display: "flex", alignItems: "center" }}>
            <RadioGroup
              row
              name="authYn"
              onChange={handleChange}
              defaultValue="1"
            >
              <FormControlLabel
                control={
                  <Radio
                    id="settlementPendingY"
                    className="align-self-start"
                    color="primary"
                  />
                }
                label="비인증"
                value="1"
              />

              <FormControlLabel
                control={
                  <Radio
                    id="settlementPendingN"
                    color="primary"
                    className="align-self-start"
                  />
                }
                label="비생인증"
                value="2"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <FormControl size="medium">
          &nbsp;&nbsp;&nbsp;&nbsp;
          <TextField
            size="small"
            className="m-2"
            id="paymentEasyYn"
            name="paymentEasyYn"
            variant="outlined"
            select
            value={
              createValue?.paymentEasyYn
                ? createValue?.paymentEasyYn
                : undefined
            }
            defaultValue={1}
            onChange={handleChange}
            helperText={helperText?.paymentEasyYn}
            error={error?.paymentEasyYn}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "center",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  간편결제 허용여부&nbsp;
                  <FontAwesomeIcon
                    icon="fa-solid fa-asterisk"
                    size="xs"
                    color="red"
                  />
                </InputAdornment>
              ),
            }}
          >
            {paymentEasyTypeTemp.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl size="medium">
          &nbsp;&nbsp;&nbsp;&nbsp;
          <TextField
            size="small"
            className="m-2"
            id="paymentEasyLimitPeriod"
            name="paymentEasyLimitPeriod"
            variant="outlined"
            select
            value={createValue?.paymentEasyLimitPeriod}
            onChange={handleChange}
            helperText={helperText?.paymentEasyLimitPeriod}
            error={error?.paymentEasyLimitPeriod}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "center",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  간편결제할부기간&nbsp;
                  <FontAwesomeIcon
                    icon="fa-solid fa-asterisk"
                    size="xs"
                    color="red"
                  />
                </InputAdornment>
              ),
            }}
          >
            {paymentEasyLimitPeriodList.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </div>

      <FilledInput
        fullWidth
        className="m-2"
        id="outlined-basic"
        variant="outlined"
        disabled
        startAdornment={
          <InputAdornment position="start">단말기코드</InputAdornment>
        }
        inputProps={{ "aria-label": "weight" }}
        placeholder={
          createValue?.tid
            ? createValue?.tid
            : "간편결제 허용 가맹점은 자동부여 (등록 후 수정페이지에서 확인 후 단말기 등록 처리를 해주세요.)"
        }
      />
    </Card>
  );
};

export default EasyPayInput;
