import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Divider,
  InputAdornment,
  TextField,
  FormControlLabel,
  Switch,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { FormLabel } from "@mui/material";
import CommonTextFieldFullwidth from "components/commonInput/CommonTextField";

import React, { useEffect, useState } from "react";

const ServiceInput = ({
  createValue,
  handleChange,
  error,
  helperText,
  handleCheck,
  setCreateValue,
}) => {
  //
  const removeSpaces = (e) => {
    let str = e;
    return str.replace(/\s+/g, "");
  };
  // ! 옵션 모음
  // const pgList = [
  //   {
  //     value: "1",
  //     label: "미분류",
  //   },
  //   {
  //     value: "2",
  //     label: "웰컴",
  //   },
  //   {
  //     value: "3",
  //     label: "KSNet",
  //   },
  // ];

  const bankNameList = [
    { label: "웰컴저축은행", value: "웰컴저축은행" },
    { label: "한국은행", value: "한국은행" },
    { label: "KDB산업은행", value: "KDB산업은행" },
    { label: "IBK기업은행", value: "IBK기업은행" },
    { label: "KB국민은행", value: "KB국민은행" },
    { label: "Sh수협은행", value: "Sh수협은행" },
    { label: "한국수출입은행", value: "한국수출입은행" },
    { label: "NH농협은행", value: "NH농협은행" },
    { label: "지역농축협", value: "지역농축협" },
    { label: "우리은행", value: "우리은행" },
    { label: "SC제일은행", value: "SC제일은행" },
    { label: "한국씨티은행", value: "한국씨티은행" },
    { label: "대구은행", value: "대구은행" },
    { label: "부산은행", value: "부산은행" },
    { label: "광주은행", value: "광주은행" },
    { label: "제주은행", value: "제주은행" },
    { label: "전북은행", value: "전북은행" },
    { label: "경남은행", value: "경남은행" },
    { label: "새마을금고", value: "새마을금고" },
    { label: "신용협동조합", value: "신용협동조합" },
    { label: "상호저축은행", value: "상호저축은행" },
    { label: "HSBC", value: "HSBC" },
    { label: "산림조합", value: "산림조합" },
    { label: "우체국", value: "우체국" },
    { label: "KEB하나은행", value: "KEB하나은행" },
    { label: "신한은행", value: "신한은행" },
    { label: "케이뱅크", value: "케이뱅크" },
    { label: "카카오뱅크", value: "카카오뱅크" },
    { label: "토스뱅크", value: "토스뱅크" },
    { label: "유안타증권", value: "유안타증권" },
    { label: "KB증권", value: "KB증권" },
    { label: "골든브릿지투자증권", value: "골든브릿지투자증권" },
    { label: "한양증권", value: "한양증권" },
    { label: "리딩투자증권", value: "리딩투자증권" },
    { label: "BNK투자증권", value: "BNK투자증권" },
    { label: "IBK투자증권", value: "IBK투자증권" },
    { label: "KTB투자증권", value: "KTB투자증권" },
    { label: "미래에셋대우", value: "미래에셋대우" },
    { label: "삼성증권", value: "삼성증권" },
    { label: "한국투자증권", value: "한국투자증권" },
    { label: "NH투자증권", value: "NH투자증권" },
    { label: "교보증권", value: "교보증권" },
    { label: "하이투자증권", value: "하이투자증권" },
    { label: "HMC투자증권", value: "HMC투자증권" },
    { label: "키움증권", value: "키움증권" },
    { label: "이베스트투자증권", value: "이베스트투자증권" },
    { label: "SK증권", value: "SK증권" },
    { label: "대신증권", value: "대신증권" },
    { label: "한화투자증권", value: "한화투자증권" },
    { label: "하나금융투자", value: "하나금융투자" },
    { label: "신한금융투자", value: "신한금융투자" },
    { label: "동부증권", value: "동부증권" },
    { label: "유진투자증권", value: "유진투자증권" },
    { label: "메리츠종합금융증권", value: "메리츠종합금융증권" },
    { label: "카카오페이증권", value: "카카오페이증권" },
    { label: "부국증권", value: "부국증권" },
    { label: "신영증권", value: "신영증권" },
    { label: "케이프증권", value: "케이프증권" },
  ];

  const contractStatusList = [
    { value: "1", label: "신청" },
    { value: "2", label: "정상" },
    { value: "3", label: "해지" },
  ];

  return (
    <Card className="p-4 mb-4">
      <div className="font-size-lg font-weight-bold">
        서비스 설정 및 정산계좌 정보
      </div>
      <Divider className="my-4" />
      <Grid container>
        {/* <Grid xs={12}>
          <Box>
            <TextField
              fullWidth
              className="m-2"
              id="pgCompanyId"
              name="pgCompanyId"
              variant="outlined"
              select
              // label="PG"
              value={createValue?.pgCompanyId}
              onChange={handleChange}
              helperText={helperText?.pgCompanyId}
              error={error?.pgCompanyId}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    PG&nbsp;
                    <FontAwesomeIcon
                      icon="fa-solid fa-asterisk"
                      size="xs"
                      color="red"
                    />
                  </InputAdornment>
                ),
              }}
            >
              {pgList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Grid> */}
        <Grid xs={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              className="m-2"
              size="small"
              id="contractStatus"
              name="contractStatus"
              variant="outlined"
              select
              // label="계약상태"
              value={createValue?.contractStatus * 1}
              onChange={handleChange}
              helperText={helperText?.contractStatus}
              error={error?.contractStatus}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    계약상태&nbsp;
                    <FontAwesomeIcon
                      icon="fa-solid fa-asterisk"
                      size="xs"
                      color="red"
                    />
                  </InputAdornment>
                ),
              }}
            >
              {contractStatusList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </Grid>
        <Grid xs={6}>
          <div className="d-flex flex-column align-center">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FormLabel className="m-2">
                로그인&nbsp;
                <FontAwesomeIcon
                  icon="fa-solid fa-asterisk"
                  size="xs"
                  color="red"
                />
              </FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={createValue?.loginAllowCheck === "Y"}
                    onChange={handleCheck}
                  />
                }
                name="loginAllowCheck"
                id="loginAllowCheck"
                label={createValue?.loginAllowCheck === "Y" ? "허용" : "차단"}
                // onClick={handleCheck}
                helperText={helperText?.loginAllowCheck}
                error={error?.loginAllowCheck}
              />
            </div>
            <h6 className="ml-2 ">
              ※ 수수료율은 업체수수료율 관리 페이지에서 등록
            </h6>
          </div>
        </Grid>

        <Grid xs={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              className="m-2"
              id="bankName"
              name="bankName"
              variant="outlined"
              size="small"
              select
              fullWidth
              value={removeSpaces(`${createValue?.bankName}`)}
              onChange={handleChange}
              helperText={helperText?.bankName}
              error={error?.bankName}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    입금은행명&nbsp;
                    <FontAwesomeIcon
                      icon="fa-solid fa-asterisk"
                      size="xs"
                      color="red"
                    />
                  </InputAdornment>
                ),
              }}
            >
              {bankNameList.map((option) => (
                <MenuItem
                  key={removeSpaces(option.value)}
                  value={removeSpaces(option.value)}
                  sx={{ not: true, width: "100%" }}
                >
                  {removeSpaces(option.label)}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </Grid>
        <Grid xs={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field="accountHolder"
              meaning="예금주"
              required={true}
            />
          </div>
        </Grid>
        <Grid xs={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field="accountNumber"
              meaning="계좌번호"
              required={true}
            />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ServiceInput;
