import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { getAxios } from "hook/axiosInstance";
import { useEffect } from "react";
import { useState } from "react";
import SettleModalTable from "./SettleModalTable";

const SettlePaymentModal = ({
  modalOpen,
  scroll,
  handleCloseModal,
  companyCodeUserSeq,
  startDate,
  endDate,
  propsCheckBool,
}) => {
  return (
    <>
      {window.sessionStorage.getItem("userAuth") == "SA" && (
        <Dialog
          open={modalOpen}
          onClose={handleCloseModal}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="xl"
        >
          <DialogTitle id="scroll-dialog-title">
            업체_정산_내역
            {/* <h6>{company?.franchise?.user?.agentName}</h6> */}
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <SettleModalTable
              companyCodeSeq={companyCodeUserSeq}
              propsStartDate={startDate}
              propsEndDate={endDate}
              propsCheckBool={propsCheckBool}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              닫기
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {window.sessionStorage.getItem("userAuth") != "SA" && (
        <>
          <DialogTitle id="scroll-dialog-title">
            업체_정산_내역
            {/* <h6>{company?.franchise?.user?.agentName}</h6> */}
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <SettleModalTable companyCodeSeq={companyCodeUserSeq} />
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleCloseModal} color="primary">
              닫기
            </Button> */}
          </DialogActions>
        </>
      )}
    </>
  );
};
export default SettlePaymentModal;
