import NoData from "components/commonTable/NoData";
import SelectLimit from "components/commonTable/SelectLimit";
import { getAxios } from "hook/axiosInstance";
import { useEffect } from "react";
import { useState } from "react";
import Pagination from "react-js-pagination";
import { formatToWon, listToDate, maskCardNo } from "utils/formatFunction";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "../../../../node_modules/@material-ui/core/index";
import { ButtonSelectDateBox } from "utils/ButtonSelectDateBox";
import excelIcon from "assets/custom/excelIcon.svg";
import styled from "styled-components";
import exportToXlsx from "utils/excelExport";
import PasswordModal from "layouts/company/companyCommission/PasswordModal";

const SettleCompanyDetailTable = ({
  setModalOpen,
  companyCodeUserSeq,
  propsStartDate,
  propsEndDate,
  propsCheckBool,
}) => {
  function formatDate(date) {
    if (date == null) {
      return "";
    }
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  // ! Loading
  const [loading, setLoading] = useState(false);
  const [userCompanyLevel, setUserCompanyLevel] = useState(
    window.sessionStorage.getItem("level")
  );

  const [startDate, setStartDate] = useState(
    propsStartDate ? propsStartDate : undefined
  );
  const [endDate, setEndDate] = useState(
    propsEndDate ? propsEndDate : undefined
  );
  const [searchKeyword, setSearchKeyword] = useState("paymentDate");
  const [searchFlag, setSearchFlag] = useState(false);
  useEffect(() => {
    if (propsCheckBool) {
      if (propsCheckBool.dateType === "settlementDate") {
        setSearchKeyword("settlementDate");
      } else {
        setSearchKeyword("paymentDate");
      }
    } else {
      getListApi(limit);
    }
  }, [propsCheckBool]);
  useEffect(() => {
    if (searchKeyword) {
      if (!searchFlag) {
        getListApi(limit);
        setSearchFlag(true);
      }
    }
  }, [searchKeyword]);
  // ! GET 요청
  const [listData, setListData] = useState({ dataList: [] });
  const [totalData, setTotalData] = useState({
    totalApprove: 0,
    totalCancel: 0,
    totalCommission: 0,
    TotalCommissionRate1: 0,
    TotalCommissionRate2: 0,
    TotalCommissionRate3: 0,
    TotalCommissionRate4: 0,
    TotalCommissionRate5: 0,
    TotalCommissionRate6: 0,
    TotalCommissionRate7: 0,
    TotalCommissionRate8: 0,
    TotalCommissionRate9: 0,
  });
  const [limit, setLimit] = useState(15);
  const [address, setAddress] = useState(
    `settlement/company/list/franchise/detail?limit=${limit}&companyCodeSeq=${companyCodeUserSeq}`
  );
  const getListApi = async (limit) => {
    const tempAd = `settlement/company/list/franchise/detail?limit=${limit}&companyCodeSeq=${companyCodeUserSeq}&startDate=${formatDate(
      startDate
    )}&endDate=${formatDate(endDate)}&dateType=${searchKeyword}`;
    setAddress(tempAd);
    setLoading(true);
    const resApiData = await getAxios(tempAd);
    if (resApiData.data.header.result_code === "200") {
      setLoading(false);
      setListData(resApiData.data.data);
      setTotalData(resApiData.data.data.settlementFranchiseTotalDto);
    }
    // else {
    //   setListData([]);
    // }
  };
  const [userAuth, setUserAuth] = useState();

  useEffect(() => {
    // getListApi(limit);
    setUserAuth(window.sessionStorage.getItem("userAuth"));
  }, [limit]);

  // ! N개씩 보기

  const checkEventHandler = ({ target }) => {
    setLimit(target.value);
  };

  const changeSearchKeyword = ({ target }) => {
    setSearchKeyword(target.value);
  };
  //  ! 페이징
  const [page, setPage] = useState(1);

  const handleChangePage = (page) => {
    setPage(page);
    paging(page);
  };

  const paging = async (page) => {
    const tempAd = `settlement/company/list/franchise/detail?limit=${limit}&page=${page}&companyCodeSeq=${companyCodeUserSeq}&startDate=${formatDate(
      startDate
    )}&endDate=${formatDate(endDate)}&dateType=${searchKeyword}`;
    setAddress(tempAd);
    const res = await getAxios(tempAd);
    res.data.header.result_code === "200"
      ? setListData(res.data.data)
      : setListData([]);
  };

  const searchApi = async () => {
    const tempAd = `settlement/company/list/franchise/detail?limit=${limit}&page=1&companyCodeSeq=${companyCodeUserSeq}&startDate=${formatDate(
      startDate
    )}&endDate=${formatDate(endDate)}&dateType=${searchKeyword}`;
    setAddress(tempAd);
    const res = await getAxios(tempAd);
    res.data.header.result_code === "200"
      ? setListData(res.data.data)
      : setListData([]);
  };
  const emptyRows =
    limit - Math.min(limit, listData.totalCount - (page - 1) * limit);

  const dateType = [
    { id: "settlementDate", name: "정산일기준" },
    { id: "paymentDate", name: "결제일기준" },
  ];
  const [modalFlag, setModalFlag] = useState(false);
  const [exportFlag, setExportFlag] = useState(false);
  useEffect(() => {
    if (exportFlag) {
      exportToXlsx("업체별정산 내역_", address);
      setExportFlag(false);
      setModalFlag(false);
    }
  }, [exportFlag]);
  return (
    <>
      <PasswordModal
        postCodeFlag={modalFlag}
        handleClose={() => setModalFlag(false)}
        setExportFlag={() => setExportFlag(true)}
      />
      <MiniSearchBox>
        <TextField
          className="m-2"
          variant="outlined"
          select
          fullWidth
          onChange={changeSearchKeyword}
          label="날짜 구분"
          size="small"
          value={`${searchKeyword}`}
          style={{ backgroundColor: "white", width: "200px" }}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: "center",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            },
          }}
        >
          {dateType?.map((field) => {
            const id = field?.id;
            const name = field?.name;
            return (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            );
          })}
        </TextField>
        <div
          style={{
            display: "flex",
            position: "relative",
            alignItems: "space-between",
            width: "100%",
          }}
        >
          <div className="button-box d-flex align-center justify-center">
            <ButtonSelectDateBox
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              className="m-2"
            />
            {userAuth === "SA" ? (
              <Button
                variant="contained"
                size="small"
                color="secondary"
                className="p-0 mb-2 mt-2"
                onClick={() => setModalFlag(true)}
              >
                <img src={excelIcon} alt="EXCEL" style={{ width: "23px" }} />
              </Button>
            ) : undefined}

            <Button
              variant="contained"
              size="small"
              color="primary"
              className="p-0 m-2"
              onClick={searchApi}
            >
              검색
            </Button>
          </div>
        </div>
        <SelectLimit checkEventHandler={checkEventHandler} />
      </MiniSearchBox>
      <Box height="5px" />
      <div className="table-responsive">
        <table className="text-nowrap mb-0 table table-striped">
          <TableHead className="thead-light">
            <tr>
              <th className="list-th text-center" rowSpan="2">
                NO
              </th>
            </tr>
            <tr>
              <th className="list-th text-center" style={{ width: "" }}>
                거래일자
              </th>
              <th className="list-th text-center" style={{ width: "" }}>
                결제
              </th>

              {userCompanyLevel <= 1 && (
                <th className="list-th text-center" colSpan="1">
                  총판
                </th>
              )}
              {userCompanyLevel <= 2 && (
                <th className="list-th text-center" colSpan="1">
                  지사
                </th>
              )}
              {userCompanyLevel <= 3 && (
                <th className="list-th text-center" colSpan="1">
                  대리점1
                </th>
              )}
              {userCompanyLevel <= 4 && (
                <th className="list-th text-center" colSpan="1">
                  대리점2
                </th>
              )}

              {userCompanyLevel <= 5 && (
                <th className="list-th text-center" colSpan="1">
                  대리점3
                </th>
              )}

              {userCompanyLevel <= 6 && (
                <th className="list-th text-center" colSpan="1">
                  대리점4
                </th>
              )}

              {userCompanyLevel <= 7 && (
                <th className="list-th text-center" colSpan="1">
                  대리점5
                </th>
              )}

              {userCompanyLevel <= 8 && (
                <th className="list-th text-center" colSpan="1">
                  대리점6
                </th>
              )}

              {userCompanyLevel <= 9 && (
                <th className="list-th text-center" colSpan="1">
                  대리점7
                </th>
              )}
              <th className="list-th text-center" style={{ width: "" }}>
                터미널
              </th>
              <th className="list-th text-center" style={{ width: "" }}>
                승인번호
              </th>
              <th className="list-th text-center" style={{ width: "" }}>
                승인금액
              </th>
              <th className="list-th text-center" style={{ width: "" }}>
                취소금액
              </th>
              <th className="list-th text-center" style={{ width: "" }}>
                거래금액
              </th>
              <th className="list-th text-center" style={{ width: "" }}>
                수수료
              </th>
              {userCompanyLevel <= 1 && (
                <th className="list-th text-center" style={{ width: "" }}>
                  정산금액(총)
                </th>
              )}
              {userCompanyLevel <= 2 && (
                <th className="list-th text-center" style={{ width: "" }}>
                  정산금액(지)
                </th>
              )}
              {userCompanyLevel <= 3 && (
                <th className="list-th text-center" style={{ width: "" }}>
                  정산금액(대1)
                </th>
              )}
              {userCompanyLevel <= 4 && (
                <th className="list-th text-center" style={{ width: "" }}>
                  정산금액(대2)
                </th>
              )}
              {userCompanyLevel <= 5 && (
                <th className="list-th text-center" style={{ width: "" }}>
                  정산금액(대3)
                </th>
              )}
              {userCompanyLevel <= 6 && (
                <th className="list-th text-center" style={{ width: "" }}>
                  정산금액(대4)
                </th>
              )}
              {userCompanyLevel <= 7 && (
                <th className="list-th text-center" style={{ width: "" }}>
                  정산금액(대5)
                </th>
              )}
              {userCompanyLevel <= 8 && (
                <th className="list-th text-center" style={{ width: "" }}>
                  정산금액(대6)
                </th>
              )}
              {userCompanyLevel <= 9 && (
                <th className="list-th text-center" style={{ width: "" }}>
                  정산금액(대7)
                </th>
              )}
              <th className="list-th text-center" style={{ width: "" }}>
                정산일자
              </th>
              <th className="list-th text-center" style={{ width: "" }}>
                취소일자
              </th>
            </tr>
          </TableHead>
          <TableBody>
            {listData.dataList.length > 0 ? (
              listData.dataList.map((row, idx) => {
                // ! 번호
                const rowNum = listData.totalCount - limit * (page - 1) - idx;

                const labelId = "enhanced-table-checkbox";

                // ! 날짜
                const tradingDate = listToDate(row.tradingDate);
                const cancelDate =
                  row.paymentYn === "2" ? listToDate(row.tradingDate) : "  ";
                const orgDate =
                  row.paymentYn === "2" ? listToDate(row.orgAppDate) : "  ";
                const settlementPlanDate = listToDate(row?.settlementPlanDate);

                // ! 카드
                // const parsedCardNo = maskCardNo(row?.cardNo);

                // ! 정산예정
                let settlementYn;
                if (row.settlementYn == "N") {
                  settlementYn = "정산예정";
                } else if (row.settlementYn == "Y") {
                  settlementYn = "정산완료";
                } else if (row.settlementYn === "P") {
                  settlementYn = "정산보류";
                }

                // ! 승인
                let paymentYn;

                if (row.paymentYn === "1") {
                  paymentYn = "승인";
                } else if (row.paymentYn === "2") {
                  paymentYn = "취소";
                }
                const mathFn = (numData) => {
                  let mathNum = 0;
                  if (row.paymentYn === "1") {
                    mathNum = formatToWon(Math.floor(numData));
                  } else {
                    if (Math.ceil(numData) === -0) {
                      mathNum = 0;
                    } else {
                      mathNum = Math.ceil(numData);
                    }
                  }
                  return mathNum;
                };

                return (
                  <>
                    <TableRow hover tabIndex={-1} key={idx}>
                      <TableCell className="text-center">{rowNum}</TableCell>
                      <TableCell>{row.tradingDate}</TableCell>
                      <TableCell>
                        {row.paymentYn === "1" ? (
                          <span>{paymentYn}</span>
                        ) : (
                          <span className="text-danger">{paymentYn}</span>
                        )}
                      </TableCell>
                      {userCompanyLevel <= 1 && (
                        <TableCell>{row.companyName1}</TableCell>
                      )}
                      {userCompanyLevel <= 2 && (
                        <TableCell>{row.companyName2}</TableCell>
                      )}
                      {userCompanyLevel <= 3 && (
                        <TableCell>{row.companyName3}</TableCell>
                      )}
                      {userCompanyLevel <= 4 && (
                        <TableCell>{row.companyName4}</TableCell>
                      )}
                      {userCompanyLevel <= 5 && (
                        <TableCell>{row.companyName5}</TableCell>
                      )}
                      {userCompanyLevel <= 6 && (
                        <TableCell>{row.companyName6}</TableCell>
                      )}
                      {userCompanyLevel <= 7 && (
                        <TableCell>{row.companyName7}</TableCell>
                      )}
                      {userCompanyLevel <= 8 && (
                        <TableCell>{row.companyName8}</TableCell>
                      )}
                      {userCompanyLevel <= 9 && (
                        <TableCell>{row.companyName9}</TableCell>
                      )}
                      <TableCell align="right">{row.terminalCode}</TableCell>
                      <TableCell align="right">{row.approvalNumber}</TableCell>

                      {/* 여기부터 승인금액 */}
                      <TableCell align="right">
                        {row.paymentYn === "1"
                          ? formatToWon(row.tradingAmount)
                          : formatToWon(0)}
                      </TableCell>
                      <TableCell align="right">
                        {row.paymentYn === "2" ? (
                          <span className="text-danger">
                            {formatToWon(row.tradingAmount)}
                          </span>
                        ) : (
                          formatToWon(0)
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {formatToWon(row.tradingAmount)}
                      </TableCell>
                      <TableCell align="right">
                        {formatToWon(row.commissionAmount)}
                      </TableCell>
                      {/* 여기부터 Math.floor 사용 */}
                      {/* row.paymentYn === "1" ?"승인":"취소" */}
                      {userCompanyLevel <= 1 && (
                        <TableCell align="right">
                          {mathFn(
                            (row.tradingAmount * row.commissionRate1) / 100
                          )}
                        </TableCell>
                      )}
                      {userCompanyLevel <= 2 && (
                        <TableCell align="right">
                          {mathFn(
                            (row.tradingAmount * row.commissionRate2) / 100
                          )}
                        </TableCell>
                      )}
                      {userCompanyLevel <= 3 && (
                        <TableCell className="p-1" align="right">
                          {mathFn(
                            (row.tradingAmount * row.commissionRate3) / 100
                          )}
                        </TableCell>
                      )}
                      {userCompanyLevel <= 4 && (
                        <TableCell align="right">
                          {mathFn(
                            (row.tradingAmount * row.commissionRate4) / 100
                          )}
                        </TableCell>
                      )}
                      {userCompanyLevel <= 5 && (
                        <TableCell align="right">
                          {mathFn(
                            (row.tradingAmount * row.commissionRate5) / 100
                          )}
                        </TableCell>
                      )}
                      {userCompanyLevel <= 6 && (
                        <TableCell align="right">
                          {mathFn(
                            (row.tradingAmount * row.commissionRate6) / 100
                          )}
                        </TableCell>
                      )}
                      {userCompanyLevel <= 7 && (
                        <TableCell align="right">
                          {mathFn(
                            (row.tradingAmount * row.commissionRate7) / 100
                          )}
                        </TableCell>
                      )}
                      {userCompanyLevel <= 8 && (
                        <TableCell align="right">
                          {mathFn(
                            (row.tradingAmount * row.commissionRate8) / 100
                          )}
                        </TableCell>
                      )}
                      {userCompanyLevel <= 9 && (
                        <TableCell align="right">
                          {mathFn(
                            (row.tradingAmount * row.commissionRate9) / 100
                          )}
                        </TableCell>
                      )}
                      <TableCell>
                        {row.settlementDoneDate
                          ? row.settlementDoneDate
                          : "없음"}
                      </TableCell>
                      <TableCell>
                        {row?.paymentYn === "1" ? null : (
                          <>
                            <span className="text-danger">
                              {row.orgTradingDate}
                            </span>
                            <br />
                            <span className="">{row.tradingDate}</span>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })
            ) : (
              <NoData
                colNum={14}
                comment="결제내역이 없습니다."
                isLoading={loading}
              />
            )}
            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 43 * emptyRows }}>
                <TableCell colSpan={60} />
              </TableRow>
              
            )} */}
          </TableBody>
          <TableRow className="table-dark">
            {window.sessionStorage.getItem("userAuth") == "SA" && (
              <TableCell colSpan={13}></TableCell>
            )}
            {window.sessionStorage.getItem("userAuth") != "SA" && (
              <TableCell colSpan={14 - userCompanyLevel * 1}></TableCell>
            )}
            <TableCell align="center">합계</TableCell>
            <TableCell align="right">
              <span className="font-weight-bold font-size-sm">
                {formatToWon(totalData.totalApprove)}
              </span>
            </TableCell>
            <TableCell align="right">
              <span className="text-google">
                <span className="font-weight-bold font-size-sm">
                  {formatToWon(totalData.totalCancel)}
                </span>
              </span>
            </TableCell>
            <TableCell align="right">
              <span className="font-weight-bold font-size-sm">
                {formatToWon(totalData.totalTradingAmount)}
              </span>
            </TableCell>
            <TableCell align="right">
              <span className="font-weight-bold font-size-sm">
                {formatToWon(totalData.totalCommission)}
              </span>
            </TableCell>
            {userCompanyLevel <= 1 && (
              <TableCell align="right">
                <span className="font-weight-bold font-size-sm">
                  {formatToWon(totalData.totalCommissionRate1)}
                </span>
              </TableCell>
            )}
            {userCompanyLevel <= 2 && (
              <TableCell align="right">
                <span className="font-weight-bold font-size-sm">
                  {formatToWon(totalData.totalCommissionRate2)}
                </span>
              </TableCell>
            )}
            {userCompanyLevel <= 3 && (
              <TableCell align="right">
                <span className="font-weight-bold font-size-sm">
                  {formatToWon(totalData.totalCommissionRate3)}
                </span>
              </TableCell>
            )}
            {userCompanyLevel <= 4 && (
              <TableCell align="right">
                <span className="font-weight-bold font-size-sm">
                  {formatToWon(totalData.totalCommissionRate4)}
                </span>
              </TableCell>
            )}
            {userCompanyLevel <= 5 && (
              <TableCell align="right">
                <span className="font-weight-bold font-size-sm">
                  {formatToWon(totalData.totalCommissionRate5)}
                </span>
              </TableCell>
            )}
            {userCompanyLevel <= 6 && (
              <TableCell align="right">
                <span className="font-weight-bold font-size-sm">
                  {formatToWon(totalData.totalCommissionRate6)}
                </span>
              </TableCell>
            )}
            {userCompanyLevel <= 7 && (
              <TableCell align="right">
                <span className="font-weight-bold font-size-sm">
                  {formatToWon(totalData.totalCommissionRate7)}
                </span>
              </TableCell>
            )}
            {userCompanyLevel <= 8 && (
              <TableCell align="right">
                <span className="font-weight-bold font-size-sm">
                  {formatToWon(totalData.totalCommissionRate8)}
                </span>
              </TableCell>
            )}
            {userCompanyLevel <= 9 && (
              <TableCell align="right">
                <span className="font-weight-bold font-size-sm">
                  {formatToWon(totalData.totalCommissionRate9)}
                </span>
              </TableCell>
            )}

            <TableCell colSpan={6} align="center"></TableCell>
          </TableRow>
        </table>
      </div>
      <Pagination
        activePage={page}
        itemsCountPerPage={limit}
        totalItemsCount={listData.totalCount}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        firstPageText={"‹‹"}
        lastPageText={"››"}
        onChange={(event) => {
          handleChangePage(event);
        }}
        style={{ display: "flex", justifyContent: "center" }}
      />
      <Button onClick={() => setModalOpen(false)} color="primary">
        닫기
      </Button>
    </>
  );
};

export default SettleCompanyDetailTable;

const MiniSearchBox = styled.div`
  display: flex;
  flex-direction: row;
  border: 0.3px solid rgba(20, 100, 200, 0.4);
  border-radius: 10px;
  width: 100%;
  background-color: rgba(20, 100, 200, 0.05);
  padding: 5px;
  /* margin: 10px; */
`;
