import { ExampleWrapperSimple } from "layouts/wrappers/ExampleWrapperSimple";
import React, { Fragment } from "react";

import { ExampleWrapperSeamless } from "../../wrappers/ExampleWrapperSeamless";
import FormsControlsBasic from "./FormsControlsBasic";
import FormsControlsInputGroups from "./FormsControlsInputGroups";
import NoticeInput from "./NoticeInput";

// import

const FormsControls = () => {
  return (
    <Fragment>
      {/* <PageTitle
        titleHeading="Controls"
        titleDescription="Wide selection of forms controls, using a standardised code base, specifically for React."
      /> */}
      <ExampleWrapperSimple sectionHeading="공지사항">
        <NoticeInput />
      </ExampleWrapperSimple>
      {/* <ExampleWrapperSimple sectionHeading="Basic">
        <FormsControlsBasic />
      </ExampleWrapperSimple>
      <ExampleWrapperSimple sectionHeading="Input groups">
        <FormsControlsInputGroups />
      </ExampleWrapperSimple> */}
    </Fragment>
  );
};
export default FormsControls;
