import { format } from "date-fns";
import { ko } from "date-fns/locale";
import { postAxios } from "hook/axiosInstance";
import { useState } from "react";
import { DateDiv, SDatePicker } from "utils/ButtonSelectDateBox";
import { formatDate, formatToWon, listToDate, now } from "utils/formatFunction";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "../../../../node_modules/@material-ui/core/index";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});
const DeductCreateModal = ({
  modalOpen,
  handleModalClose,
  scroll,
  selectedRow,
}) => {
  const classes = useStyles();

  const [pickDate, setPickDate] = useState(now);

  // ! 전송로직
  const [deductValue, setDeductValue] = useState({
    franchiseCode: selectedRow.franchise.id,
    id: selectedRow.id,
  });
  const [validationError, setValidationError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "depositAmount") {
      if (value > Math.abs(selectedRow.settlementAmount)) {
        setDeductValue({ ...deductValue, [name]: "" });
        setValidationError(true); 
      } else {
        setDeductValue({ ...deductValue, [name]: value });
        setValidationError(false);
      }
    } else if (name === "depositMsg") {
      setDeductValue({ ...deductValue, [name]: value });
    }
  };

  const handleDeductClick = () => {
    if (!validationFunc(deductValue)) {
      alert("금액이 결제금액보다 큽니다.");
      return;
    }
    if (window.confirm("정말 입력하시겠습니까?")) {
      sendDeduct(deductValue);
    }
  };

  const sendDeduct = async (deductValue) => {

    const deductRes = await postAxios("payment/deduct/deposit", deductValue);
    if (deductRes.data.header.result_code === "200") {
      alert("완료!");
      handleModalClose();
    } else {}
  };

  // ! Validation
  const validationFunc = (values) => {
    return values.depositAmount > -selectedRow?.tradingAmount ? false : true;
  };
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { height: 435 } }}
      open={modalOpen}
      onClose={handleModalClose}
      scroll="body"
      fullwidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      maxHeight="lg"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          <h2 className="font-weight-bold font-size-xl">입금내역 등록</h2>
        </DialogTitle>
      </div>
      <DialogContent dividers={scroll === "paper"}>
        {/* <DialogContentText
          id="scroll-dialog-description"
          // ref={descriptionElementRef}
          tabIndex={-1}
        >
          <TypeaheadSearch /> 
        </DialogContentText>*/}

        <div className="table-responsive">
          <table className="text-nowrap mb-0 table table-striped">
            <thead className="thead-light">
              <tr>
                <th className="text-center">가맹점명</th>
                <th className="text-center">차감일자</th>
                <th className="text-center" style={{ width: "40%" }}>
                  입금내역
                </th>
                <th className="text-center" style={{ width: "20%" }}>
                  입금금액(원)
                </th>
                <th className="text-center">등록</th>
              </tr>
            </thead>
            <TableBody>
              {/* {(rowsPerPage > 0
            ? listData.dataList.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : listData.dataList
          ).map((row, idx) => { */}
              <TableRow tabIndex={-1}>
                <TableCell align="center" style={{ width: "40%" }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    style={{ width: "100%", backgroundColor: "white" }}
                    disabled
                    value={selectedRow.franchise.user.agentName}
                  />
                </TableCell>
                <TableCell align="center">
                  <DateDiv>
                    <Icon
                      fontSize="small"
                      style={{
                        zIndex: "100",
                        position: "relative",
                        left: "45px",
                      }}
                    >
                      calendar_month
                    </Icon>
                    <SDatePicker
                      locale={ko}
                      selected={pickDate}
                      dateFormat="yyyy년 MM월 dd일"
                      onChange={(date) => setPickDate(date)}
                      disabled
                    />
                  </DateDiv>
                </TableCell>
                <TableCell align="center" style={{ width: "40%" }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    style={{ width: "100%", backgroundColor: "white" }}
                    name="depositMsg"
                    value={deductValue?.depositMsg}
                    onChange={handleChange}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    size="small"
                    style={{ width: "100%", backgroundColor: "white" }}
                    name="depositAmount"
                    type="number"
                    value={deductValue?.depositAmount}
                    onChange={handleChange}
                    classes={{ root: classes.input }}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleDeductClick}
                  >
                    등록
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </table>

          <Box height="5px" />

          <table className="table table-bordered">
            <tbody style={{ fontSize: "2em" }}>
              <tr>
                <td className="reg-td-title">취소일자</td>
                <td className="reg-td" style={{ color: "red" }}>
                  {listToDate(selectedRow.tradingDate)}
                </td>
                <td className="reg-td-title">원거래일자</td>
                <td className="reg-td">
                  {listToDate(selectedRow?.orgAppDate) || " "}
                </td>
              </tr>
              <tr>
                <td className="reg-td-title">정산예정일자</td>
                <td className="reg-td">
                  {listToDate(selectedRow?.settlementPlanDate) || " "}
                </td>
                <td className="reg-td-title">터미널</td>
                <td className="reg-td">{selectedRow?.terminal?.tid}</td>
              </tr>
              <tr>
                <td className="reg-td-title">승인번호</td>
                <td className="reg-td">{selectedRow?.approvalNumber}</td>
                <td className="reg-td-title">매입사/할부</td>
                <td className="reg-td">
                  {selectedRow?.cardAcqName} / {selectedRow?.inMonth}
                </td>
              </tr>
              <tr>
                <td className="reg-td-title">취소금액</td>
                <td className="reg-td" style={{ color: "red" }}>
                  {formatToWon(selectedRow?.tradingAmount)}
                </td>
                <td className="reg-td-title">차감할금액</td>
                <td className="reg-td" style={{ color: "red" }}>
                  {selectedRow?.settlementAmount}
                </td>
              </tr>
              <tr>
                <td className="reg-td-title">수수료</td>
                <td className="reg-td">{selectedRow?.commissionAmount}</td>
                <td className="reg-td-title">미차감잔액</td>
                <td
                  className="reg-td"
                  style={{ color: "blue", fontWeight: "bold" }}
                >
                  {formatToWon(selectedRow?.settlementAmount)}
                </td>
              </tr>{" "}
            </tbody>
          </table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} color="secondary" variant="outlined">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeductCreateModal;
