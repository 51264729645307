import React from "react";

import { ExampleWrapperSimple } from "layouts/wrappers/ExampleWrapperSimple";
import FranchiseInput from "./FranchiseInput";

const FranchiseCreate = () => {
  return (
    <ExampleWrapperSimple sectionHeading='가맹점 등록'>
      <FranchiseInput />
    </ExampleWrapperSimple>
  );
};

export default FranchiseCreate;
