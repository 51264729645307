import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { getAxios } from "hook/axiosInstance";
import { listToDate } from "utils/formatFunction";
import { useEffect, useState } from "react";
import {
  CardContent,
  IconButton,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "../../../../node_modules/@material-ui/core/index";
import { FontAwesomeIcon } from "../../../../node_modules/@fortawesome/react-fontawesome/index";
import { faPenToSquare } from "../../../../node_modules/@fortawesome/free-regular-svg-icons/index";
import NoData from "components/commonTable/NoData";
import Pagination from "react-js-pagination";
import TerminalDetailModal from "../../terminal/terminalList/TerminalDetailModal";

const TidListModal = ({ modalOpen, setModalOpen, scroll, franchiseName }) => {
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [tidModal, setTidModal] = useState(false);
  const [listData, setListData] = useState({ dataList: [] });
  const [selected, _setSelected] = useState([]);
  const [limit, setLimit] = useState(15);
  const [selectDetail, setSelectDetail] = useState("");
  const [checkBool, setCheckBool] = useState("");
  const [keyword, setKeyword] = useState("");
  let parseCheckBool = "";
  for (let key in checkBool) {
    parseCheckBool = parseCheckBool + "&" + key;
    parseCheckBool = parseCheckBool + "=" + checkBool[key];
  }
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const getListApi = async (limit) => {
    setLoading(true);
    const tempAd = `terminal/list?keyword=${encodeURI(
      franchiseName
    )}&limit=${limit}&columnName=franchiseName`;
    const resApiData = await getAxios(tempAd);
    if (resApiData.data.header.result_code === "200") {
      setLoading(false);
      setListData(resApiData.data.data);
    }
    // else {
    //   setListData([]);
    // }
  };
  const handleChangePage = (page) => {
    const paging = async () => {
      const tempAd = `terminal/list?&page=${page}&keyword=${encodeURI(
        franchiseName
      )}&limit=${limit}&columnName=franchiseName`;
      const res = await getAxios(tempAd);
      if (res.data.header.result_code === "200") {
        setListData(res.data.data);
      }
    };
    setPage(page);
    paging();
  };
  const [userAuth, setUserAuth] = useState();
  useEffect(() => {
    setUserAuth(window.sessionStorage.getItem("userAuth"));
    getListApi(limit);
  }, [limit]);

  // ! 수정 - 전송 로직
  const handleDetailClick = (row, idx) => {
    setTidModal(true);
    setSelectDetail(row);
  };
  const emptyRows =
    limit - Math.min(limit, listData.totalCount - (page - 1) * limit);

  // ! 삭제 - 전송로직
  const handleModalClose = () => {
    setTidModal(false);
    getListApi(limit);
  };
  return (
    <>
      {/* selectDetail={selectDetail}
          scroll={scroll}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          fromFranchiseList={false}
          handleModalClose={handleModalClose} */}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="xl"
      >
        <DialogTitle id="scroll-dialog-title">
          <h1>단말기 리스트</h1>
        </DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          style={{ backgroundColor: "#f9faff" }}
        >
          <CardContent className="p-0">
            <div className="table-responsive">
              <table className="text-nowrap mb-0 table table-striped">
                <TableHead className="thead-light">
                  <tr>
                    <th className="text-center">번호</th>
                    <th className="text-center">등록일자</th>
                    <th className="text-center">단말기코드(TID)</th>
                    <th className="text-center">시리얼번호</th>
                    <th className="text-center">PG</th>
                    <th className="text-center">가맹점명</th>
                    <th className="text-center" style={{ width: "20%" }}>
                      업체명
                    </th>
                    <th className="text-center">대표자명</th>
                    <th className="text-center">담당자연락처</th>
                    <th className="text-center">사</th>
                    <th className="text-center">정산주기</th>
                    <th className="text-center">상태</th>
                    <th className="text-center">아이디</th>
                    {userAuth == "SA" && (
                      <th className="text-center">정보수정</th>
                    )}
                  </tr>
                </TableHead>
                <TableBody>
                  {listData.dataList.length > 0 ? (
                    listData.dataList.map((row, idx) => {
                      const isItemSelected = isSelected(row.name);

                      // ! 날짜
                      const parsedDate = listToDate(row.createDate);

                      // ! 번호
                      const rowNum =
                        listData.totalCount - limit * (page - 1) - idx;

                      // ! 사업자구분
                      const cp = row?.franchise?.user?.companyType;
                      let companyType = "";
                      switch (cp) {
                        case "1":
                          companyType = "비";
                          break;
                        case "2":
                          companyType = "개";
                          break;
                        case "3":
                          companyType = "법";
                          break;
                        case "4":
                          companyType = "기타";
                          break;
                        default:
                          console.log(`Sorry, we are out of .`);
                      }

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={idx}
                          selected={isItemSelected}
                        >
                          <TableCell className="text-center">
                            {rowNum}
                          </TableCell>
                          <TableCell align="center">{parsedDate}</TableCell>
                          <TableCell align="center">{row.tid}</TableCell>
                          <TableCell align="center">
                            {row.terminalCode}
                          </TableCell>

                          <TableCell className="text-center">
                            {row.franchise.pgCompany.pgCompanyName.pgName}(
                            {row.franchise.pgCompany.mid})
                          </TableCell>
                          <TableCell align="left">
                            {row.franchise.user.agentName}
                          </TableCell>
                          <TableCell align="left">{row.path}</TableCell>
                          <TableCell align="left">
                            {row.franchise.user.ceoName}
                          </TableCell>
                          <TableCell align="center">
                            {row.franchise.user.managerContact}
                          </TableCell>
                          <TableCell align="center">{companyType}</TableCell>
                          <TableCell align="center">
                            {"D + " + row.franchise.settlementCycle}
                          </TableCell>
                          <TableCell align="center">
                            {row.terminalStatus}
                          </TableCell>
                          <TableCell align="left">
                            {row.franchise.user.userId}
                          </TableCell>
                          {userAuth == "SA" && (
                            <TableCell
                              align="center"
                              onClick={() => handleDetailClick(row, idx)}
                              className="p-1"
                            >
                              <IconButton size="small" color="primary">
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })
                  ) : (
                    <>
                      <NoData
                        comment="등록된 가맹점이 없습니다."
                        colNum={22}
                        isLoading={loading}
                      />
                    </>
                  )}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={14} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow></TableRow>
                </TableFooter>
              </table>
            </div>
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={listData?.totalCount}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              firstPageText={"‹‹"}
              lastPageText={"››"}
              onChange={(event) => {
                handleChangePage(event);
              }}
              // onPageChange={handleChangePage}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </CardContent>
          {tidModal ? (
            <TerminalDetailModal
              selectDetail={selectDetail}
              scroll={scroll}
              modalOpen={tidModal}
              setModalOpen={setTidModal}
              fromFranchiseList={false}
              handleModalClose={handleModalClose}
            />
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default TidListModal;
