import axios from "axios";
import { gogokeyEncrypt } from "utils/seed";

// export const axiosLoginInstance = axios.create({
//   baseURL: process.env.REACT_APP_SERVER_URL,
//   headers: { Authorization: window.sessionStorage.getItem("jwt")},
// });

export const axiosLoginInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

export const axiosTerminalInstance = axios.create({
  baseURL: process.env.REACT_APP_TERMINAL_URL,
});

export const postTerminalAxios = async (endpoint, body, mid) => {
  return await axiosTerminalInstance.post(
    endpoint,
    body,
    mid !== "" && {
      headers: {
        gogo_key: gogokeyEncrypt(mid),
      },
    }
  );
};

// export const putImgAxios = async (endpoint, body) =>
//   await axiosLoginInstance.put(endpoint, body, {
//     headers: {
//       Authorization: window.sessionStorage.getItem("jwt"),
//       "Content-Type": "multipart/form-data",
//     },
//   });
// export const postImgAxios = async (endpoint, body) =>
//   await axiosLoginInstance.post(endpoint, body, {
//     headers: {
//       Authorization: window.sessionStorage.getItem("jwt"),
//       "Content-Type": "multipart/form-data",
//     },
//   });

export const getAxios = async (endpoint) =>
  await axiosLoginInstance.get(endpoint, {
    headers: {
      Authorization: window.sessionStorage.getItem("jwt"),
    },
  });

export const postAxios = async (endpoint, body) =>
  await axiosLoginInstance.post(endpoint, body, {
    headers: {
      Authorization: window.sessionStorage.getItem("jwt"),
    },
  });

export const putAxios = async (endpoint, body) =>
  await axiosLoginInstance.put(endpoint, body, {
    headers: {
      Authorization: window.sessionStorage.getItem("jwt"),
    },
  });
export const patchAxios = async (endpoint, body) =>
  await axiosLoginInstance.patch(endpoint, body, {
    headers: {
      Authorization: window.sessionStorage.getItem("jwt"),
    },
  });

export const deleteAxios = async (endpoint, body) =>
  await axiosLoginInstance.delete(endpoint, body, {
    headers: {
      Authorization: window.sessionStorage.getItem("jwt"),
    },
  });
