import React, { useEffect, useState } from "react";

import {
  Checkbox,
  Card,
  CardContent,
  Button,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Pagination from "react-js-pagination";
import { getAxios, putAxios } from "hook/axiosInstance";
import {
  formatOnlyDate,
  formatToWon,
  listToDate,
  maskCardNo,
} from "utils/formatFunction";
import SelectLimit from "components/commonTable/SelectLimit";
import NoData from "components/commonTable/NoData";
import PayAlert from "components/PayAlert";
import { Box } from "../../../../node_modules/@material-ui/core/index";
import { Search } from "components/commonTable/Search";
import { useRecoilState } from "recoil";
import { listLinkStateAtom } from "recoilStates/searchRecoil";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  selected: {
    backgroundColor: "rgba(245, 0, 87, 0.08) !important",
  },
}));

const PendingTable = () => {
  // ! Loading
  const [loading, setLoading] = useState(false);

  // ! GET 요청

  const [listData, setListData] = useState({ dataList: [] });
  const [limit, setLimit] = useState(15);
  const [address, setAddress] = useState();
  const [loadFlag, setLoadFlag] = useState(false);
  const [page, setPage] = useState(1);
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);

  const getListApi = async () => {
    setLoading(true);
    if (!loadFlag) setLoadFlag(true);
    if (listLinkState) {
      const tempAd = listLinkState;
      setAddress(tempAd);
      const resApiData = await getAxios(tempAd);
      if (resApiData.data.header.result_code === "200") {
        setLoading(false);
        setListData(resApiData.data.data);
      }
    }
  };

  useEffect(() => {
    if (listLinkState) {
      getListApi();
    }
  }, [page, limit]);

  useEffect(() => {
    if (listLinkState)
      if (!loadFlag) {
        getListApi();
      }
  }, [listLinkState, loading]);

  const handleChangePage = (e) => {
    setPage(e);
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/page=[^&]*/, `page=${e}`);
    setListLinkState(tempLink);
  };
  const emptyRows =
    limit - Math.min(limit, listData.totalCount - (page - 1) * limit);

  const classes = useStyles();

  // ! 검색 관련

  // ^ 검색조건

  const checkFields = [
    { id: "all", name: "모두" },
    { id: "franchiseName", name: "가맹점명" },
    { id: "appNo", name: "승인번호" },
    { id: "cardNo", name: "카드번호" },
    { id: "commissionRate", name: "수수료율" },
    { id: "tid", name: "단말기코드" },
  ];

  // ! 선택 관련
  const [selected, setSelected] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listData.dataList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    } else {
      setSelected([]);
    }
  };

  // ! 정산 보류 해제 로직

  const handleUnpending = async () => {
    if (window.confirm("정말 보류해제 하시겠습니까?")) {
      const pendingData = {
        createId: sessionStorage.getItem("userId"),
        pendingData: selected,
      };
      const pendingRes = await putAxios("payment/unpending", pendingData);
      if (pendingRes.status === 200) {
        await getListApi(limit);
        setIsSuccess("success");
        setResultMsg("보류 해제가 완료되었습니다.");
      } else {
        setIsSuccess("error");
        setResultMsg(pendingRes?.data.header.result_message);
        alert("오류! " + pendingRes?.data?.header?.result_message);
      }
    }
  };

  // ! 취소 성공 여부
  // ^ 취소 성공 -> isSuccess = "success"
  // ^ 취소 실패 -> isSuccess = "error"
  const [isSuccess, setIsSuccess] = useState("");
  const [resultMsg, setResultMsg] = useState("");

  useEffect(() => {
    let timer = setTimeout(() => setIsSuccess(""), 10000);
    return () => {
      clearTimeout(timer);
    };
  }, [isSuccess]);
  return (
    <>
      <Search
        dateFlag={true}
        pageFlag={true}
        link={"payment/pending/list?"}
        checkList={["searchField"]}
        getListApi={getListApi}
        limit={true}
        setPage={setPage}
        searchField={checkFields}
      />
      <Box height="5px" />
      {
        // ! 성공여부 알림창
        isSuccess === "" ? null : (
          <PayAlert
            isSuccess={isSuccess}
            actionName="보류 해제"
            resultMsg={resultMsg}
          />
        )
      }
      <Card className="card-box mb-4">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <small>결제내역 관리</small>
            <b>보류내역 조회</b>
          </div>
          <div className="card-header--actions">
            {selected.length > 0 ? (
              <span>{selected.length} 개 선택</span>
            ) : (
              <></>
            )}
          </div>
          <Button
            variant="contained"
            color="primary"
            className="m-2"
            size="small"
            onClick={handleUnpending}
          >
            보류해제
          </Button>
          <SelectLimit limit={limit} setLimit={setLimit} setPage={setPage} />
        </div>
        <CardContent className="p-0">
          <div className="table-responsive">
            <table className="text-nowrap mb-0 table table-striped">
              <TableHead className="thead-light">
                <tr>
                  <TableCell className="p-0">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < listData.totalCount
                      }
                      checked={
                        listData.totalCount > 0 && selected.length === limit
                      }
                      onChange={handleSelectAllClick}
                      selectedAll={selectedAll}
                    />
                  </TableCell>
                  <th className="text-center">번호</th>
                  <th className="text-center">거래일자</th>
                  <th className="text-center" style={{}}>
                    결제
                  </th>
                  <th className="text-center" style={{}}>
                    가맹점명
                  </th>
                  <th className="text-center">터미널</th>
                  <th className="text-center">매입사</th>
                  <th className="text-center">할부</th>
                  <th className="text-center">카드번호</th>
                  <th className="text-center">승인번호</th>
                  <th className="text-center">승인금액</th>
                  <th className="text-center">취소금액</th>
                  <th className="text-center">거래금액</th>
                  <th className="text-center">수수료</th>
                  <th className="text-center">정산금액</th>
                  <th className="text-center">(%)▲</th>
                  <th className="text-center">정산예정</th>
                  <th className="text-center">PG</th>
                  <th className="text-center">AGENT</th>
                  <th className="text-center">
                    취소일자
                    <br />
                    (원거래일자)
                  </th>
                </tr>
              </TableHead>

              <TableBody>
                {listData.dataList.length > 0 ? (
                  listData.dataList.map((row, idx) => {
                    // ! 번호
                    const rowNum =
                      listData.totalCount - limit * (page - 1) - idx;

                    const isItemSelected = isSelected(row?.id);
                    const labelId = "enhanced-table-checkbox";

                    // ! 날짜
                    const tradingDate = listToDate(row?.tradingDate);
                    const cancelDate =
                      row?.paymentYn === "2"
                        ? listToDate(row?.tradingDate)
                        : "  ";
                    const orgDate =
                      row?.paymentYn === "2"
                        ? listToDate(row?.orgAppDate)
                        : "  ";
                    const settlementPlanDate = formatOnlyDate(
                      row?.settlementPlanDate
                    );

                    // ! 카드
                    const parsedCardNo = maskCardNo(row?.cardNo);

                    // ! 정산예정
                    let settlementYn;
                    if (row?.settlementYn == "N") {
                      settlementYn = "정산예정";
                    } else if (row?.settlementYn == "Y") {
                      settlementYn = "정산완료";
                    } else if (row?.settlementYn === "P") {
                      settlementYn = "정산보류";
                    }

                    // ! 승인
                    let paymentYn;

                    if (row?.paymentYn === "1") {
                      paymentYn = "승인";
                    } else if (row?.paymentYn === "2") {
                      paymentYn = "취소";
                    }

                    return (
                      <TableRow
                        hover
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={idx}
                        selected={isItemSelected}
                        className={isItemSelected ? classes.selected : ""}
                      >
                        <TableCell className="p-0">
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row?.id)}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell className="text-center">{rowNum}</TableCell>
                        <TableCell align="center">{tradingDate}</TableCell>
                        <TableCell align="center">
                          {row?.paymentYn === "1" ? (
                            <span>{paymentYn}</span>
                          ) : (
                            <span className="text-danger">{paymentYn}</span>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {row?.franchise.user.agentName}
                        </TableCell>
                        <TableCell align="center">
                          {row?.terminal.tid}
                        </TableCell>
                        <TableCell align="center">{row?.cardAcqName}</TableCell>
                        <TableCell align="center">
                          {row?.inMonth === "00" || row?.inMonth === "0"
                            ? "일시불"
                            : row?.inMonth + "개월"}
                        </TableCell>
                        <TableCell align="center">
                          {parsedCardNo ? parsedCardNo : row.cardNo}
                        </TableCell>
                        <TableCell align="center">
                          {row?.approvalNumber + ""}
                        </TableCell>
                        <TableCell align="right">
                          {row?.paymentYn === "1"
                            ? formatToWon(row?.tradingAmount)
                            : formatToWon(0)}
                        </TableCell>
                        <TableCell align="right">
                          {row?.paymentYn === "2" ? (
                            <span className="text-danger">
                              {formatToWon(row?.tradingAmount)}
                            </span>
                          ) : (
                            formatToWon(0)
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {formatToWon(row?.tradingAmount)}
                        </TableCell>
                        <TableCell align="right">
                          {row?.commissionAmount
                            ? formatToWon(row?.commissionAmount)
                            : "없음"}
                        </TableCell>
                        <TableCell align="right">
                          {row?.settlementAmount
                            ? formatToWon(row?.settlementAmount)
                            : "없음"}
                        </TableCell>
                        <TableCell align="right">
                          {row?.franchise?.user?.commissionRate
                            ? row?.franchise?.user?.commissionRate
                            : "없음"}
                        </TableCell>
                        <TableCell align="center" className="p-0">
                          {settlementPlanDate}
                          <br />
                          <span className="text-twitter fw-bolder">
                            {settlementYn}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          {row?.pgName ? row?.pgName : "없음"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.franchise.company.user.agentName}
                        </TableCell>
                        <TableCell align="center" className="p-1">
                          {row?.paymentYn === "1" ? null : (
                            <>
                              <span className="text-danger">{cancelDate}</span>
                              <br />
                              <span className="">{orgDate}</span>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <>
                    <NoData
                      comment="보류내역이 없습니다."
                      colNum={22}
                      isLoading={loading}
                    />
                  </>
                )}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={20} />
                  </TableRow>
                )}
              </TableBody>
              <TableRow className="table-dark">
                <TableCell colSpan={9}></TableCell>
                <TableCell align="center">합계</TableCell>
                <TableCell align="right">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(
                      listData?.paymentListSumDto?.sumAccAmount || 0
                    )}
                  </span>
                </TableCell>
                <TableCell align="right">
                  <span className="text-danger">
                    <span className="font-weight-bold font-size-sm">
                      {formatToWon(
                        listData?.paymentListSumDto?.sumCancelAmount || 0
                      )}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="right">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(
                      listData?.paymentListSumDto?.sumTradingAmount || 0
                    )}
                  </span>
                </TableCell>
                <TableCell align="right">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(
                      listData?.paymentListSumDto?.sumCommissionAmount || 0
                    )}
                  </span>
                </TableCell>
                <TableCell align="right">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(
                      listData?.paymentListSumDto?.sumSettlementAmount || 0
                    )}
                  </span>
                </TableCell>
                <TableCell colSpan={6} align="center"></TableCell>
              </TableRow>
            </table>
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={listData.totalCount}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              firstPageText={"‹‹"}
              lastPageText={"››"}
              onChange={(event) => {
                handleChangePage(event);
              }}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default PendingTable;
