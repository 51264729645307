import TitleOutlet from "components/TitleOutlet";
import { ExampleWrapperSimple } from "layouts/wrappers/ExampleWrapperSimple";
import BasicInput from "./BasicInput";

const EasyPay = () => {
  const besang = false;
  return (
    <>
      <TitleOutlet title="간편결제" />
      <ExampleWrapperSimple sectionHeading="간편결제 관리">
        <BasicInput besang={besang} />
      </ExampleWrapperSimple>
    </>
  );
};

export default EasyPay;
