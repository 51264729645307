import React, { useEffect, useState } from "react";

import {
  Checkbox,
  Card,
  CardContent,
  Button,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableFooter,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { getAxios, putAxios } from "hook/axiosInstance";
import { formatToWon, listToDate } from "utils/formatFunction";
import NoData from "components/commonTable/NoData";
import { Box } from "../../../../node_modules/@material-ui/core/index";
import SettleFranchiseTermTableHeader from "./SettleFranchiseTermTableHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  selected: {
    backgroundColor: "rgba(245, 0, 87, 0.08) !important",
  },
}));

const SettleFranchiseExecuteTable = () => {
  // ! 로딩
  const [isLoading, setIsLoading] = useState(false);

  // ! GET 요청

  const [listData, setListData] = useState({ dataList: [] });
  const [limit, setLimit] = useState(15);
  const [sumData, setSumData] = useState("");

  const getListApi = async (limit) => {
    setIsLoading(true);
    const resApiData = await getAxios(`settlement/franchise/standby/list`);
    if (resApiData.data.header.result_code === "200") {
      setIsLoading(false);
      setListData(resApiData.data.data);
      setSumData(resApiData.data.totalData);
    }
    // else {
    //   setListData([]);
    // }
  };

  useEffect(() => {
    getListApi(limit);
  }, [limit]);

  const [page, setPage] = useState(1);

  const emptyRows =
    limit - Math.min(limit, listData.totalCount - (page - 1) * limit);

  const classes = useStyles();

  const handleChangePage = (page) => {
    setPage(page);
    const paging = async () => {
      const res = await getAxios(`settlement/franchise/standby/list`);
      res.data.header.result_code === "200"
        ? setListData(res.data.data)
        : setListData([]);
    };
    paging();
  };

  // const handleChangeRowsPerPage = (event) => {
  //   if (parseInt(event.target.value) > listData.dataList.length) {
  //     setLimit(-1);
  //     return;
  //   }
  //   setLimit(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const handleSelectAllClick = (event) => {
  //   setSelectedAll(!selectedAll);

  //   if (event.target.checked) {
  //     const newSelecteds = listData.dataList.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   } else {
  //     setSelected([]);
  //   }
  // };

  // ! 선택 관련
  const [selected, setSelected] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const isSelected = (franchiseCode) => selected?.indexOf(franchiseCode) !== -1;

  function ShowCheckbox(row) {
    return (
      Math.abs(row?.settlementAmountCancel) + row?.tradingAmountCancelFinish <=
      row?.settlementAmountOk
    );
  }

  const handleClick = (event, row) => {
    const franchiseCode = row?.franchiseCode;
    const selectedIndex = selected?.indexOf(franchiseCode);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = [...selected, franchiseCode];
    } else {
      newSelected = selected.filter((code) => code !== franchiseCode);
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listData
        .filter((row) => ShowCheckbox(row))
        .map((row) => row.franchiseCode);
      setSelected(newSelecteds);
      setSelectedAll(true);
    } else {
      setSelected([]);
      setSelectedAll(false);
    }
  };

  // ! 정산보류 / 해제 로직

  const handleSettle = () => {
    if (selected.length <= 0) {
      alert("선택한 가맹점이 없습니다.");
    } else {
      window.confirm("정말 정산 하시겠습니까?");
      requestsettle();
    }
  };

  const requestsettle = async () => {
    // const settlmentPayload = selected?.map((row) => {
    //   return { [row.settlementDto.franchise.id]: row.paymentList };
    // });
    const settlementData = {
      createId: sessionStorage.getItem("userId"),
      pendingData: selected,
    };
    const settleres = await putAxios(
      `settlement/franchise/execute`,
      settlementData
    );
    if (settleres.data === "Y") {
      alert("정산 실행 완료!");
      window.location.reload();
    } else {
      alert("실패!");
    }
  };
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("num");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  return (
    <>
      <Box height="5px" />
      <Card className="card-box mb-4">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <small>가맹점 정산</small>
            <b>가맹점 정산하기</b>
          </div>
          <div className="card-header--actions">
            {selected?.length > 0 ? (
              <span>{selected?.length} 개 선택</span>
            ) : (
              <></>
            )}
          </div>
          <Button
            variant="contained"
            color="primary"
            className="m-2"
            onClick={handleSettle}
          >
            가맹점 정산 실행
          </Button>
          {/* <Button
            variant="contained"
            color="secondary"
            onClick={() => exportToXlsx(listData.dataList, "yes")}
          >
            <img src={excelIcon} alt="EXCEL" style={{ width: "23px" }} />
            &nbsp; 내보내기
          </Button> */}
        </div>
        <CardContent className="p-0">
          <div className="table-responsive">
            <table className="text-nowrap mb-0 table table-striped mx-0">
              <TableHead className="thead-light">
                <tr>
                  <th rowSpan="2">
                    <Checkbox
                      indeterminate={
                        selected?.length > 0 &&
                        selected?.length < listData.length &&
                        !selectedAll
                      }
                      checked={
                        (selectedAll ||
                          (listData.length > 0 &&
                            selected?.length === listData.length)) &&
                        selected?.length > 0
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ "aria-label": "select all desserts" }}
                      style={{
                        position: "relative",
                        padding: "0 0 18px 0",
                        left: "-3px",
                      }}
                    />
                  </th>
                  <th
                    className="list-th text-center"
                    rowSpan="2"
                    style={{ width: "80px", paddingBottom: "32px" }}
                  >
                    NO
                  </th>
                  <th
                    className="list-th text-center"
                    rowSpan="2"
                    style={{
                      paddingBottom: "32px",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    가맹점명
                  </th>
                  <th
                    className="list-th text-center"
                    colSpan="4"
                    style={{ borderRight: "1px solid #ccc" }}
                  >
                    정상매출
                  </th>
                  <th
                    className="list-th text-center"
                    colSpan="5"
                    style={{ borderRight: "1px solid #ccc" }}
                  >
                    차감매출(정산후취소)
                  </th>
                  <SettleFranchiseTermTableHeader
                    id="amountMoneyMinus"
                    order={order}
                    orderBy={orderBy}
                    label="정산전 취소금액"
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id="settlementResultAmount"
                    order={order}
                    orderBy={orderBy}
                    label="정산금액"
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.length}
                    numSelected={selected.length}
                  />
                  <th
                    className="list-th text-center"
                    rowSpan="2"
                    style={{ width: "110px", paddingBottom: "32px" }}
                  >
                    은행명
                  </th>
                  <th
                    className="list-th text-center"
                    rowSpan="2"
                    style={{ width: "150px", paddingBottom: "32px" }}
                  >
                    계좌번호
                  </th>
                  <th
                    className="list-th text-center"
                    rowSpan="2"
                    style={{ width: "110px", paddingBottom: "32px" }}
                  >
                    예금주
                  </th>
                </tr>

                <tr>
                  <SettleFranchiseTermTableHeader
                    id="cntOk"
                    order={order}
                    orderBy={orderBy}
                    label="건수"
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id="tradingAmountOk"
                    order={order}
                    orderBy={orderBy}
                    label="거래금액"
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id="commissionAmountOk"
                    order={order}
                    orderBy={orderBy}
                    label="수수료"
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id="settlementAmountOk"
                    order={order}
                    orderBy={orderBy}
                    label="정산금액"
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.length}
                    numSelected={selected.length}
                  />



                  <SettleFranchiseTermTableHeader
                    id="cntCancel"
                    order={order}
                    orderBy={orderBy}
                    label="건수"
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id="tradingAmountCancel"
                    order={order}
                    orderBy={orderBy}
                    label="취소금액"
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id="commissionAmountCancel"
                    order={order}
                    orderBy={orderBy}
                    label="수수료"
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id="settlementAmountCancel"
                    order={order}
                    orderBy={orderBy}
                    label="차감할금액"
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.length}
                    numSelected={selected.length}
                  />
                  <SettleFranchiseTermTableHeader
                    id="tradingAmountCancelFinish"
                    order={order}
                    orderBy={orderBy}
                    label="차감완료금액"
                    onSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    rowCount={listData.length}
                    numSelected={selected.length}
                  />
                </tr>
              </TableHead>

              <TableBody>
                {listData.length > 0 ? (
                  stableSort(
                    listData,
                    getComparator(order, orderBy)
                  ).map((row, idx) => {
                    // ! 번호
                    const rowNum = listData.length - idx;

                    const isItemSelected = isSelected(row.franchiseCode);
                    const labelId = "enhanced-table-checkbox";

                    // ! 날짜
                    // const appDate = listToDate(row.settlementDto.appCommission);

                    let settlementPlanD = row?.settlementPlanDate
                      ? listToDate(row?.settlementPlanDate)
                      : "없음";

                    // ! 카드
                    // const parsedCardNo = maskCardNo(row.settlementDto.cardNo);
                    return (
                      <TableRow
                        hover
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={idx}
                        selected={isItemSelected}
                        className={isItemSelected ? classes.selected : ""}
                      >
                        <TableCell className="p-0">
                          {row.settlementResultAmount >= 0 ? (
                            <Checkbox
                              checked={isItemSelected}
                              onClick={(event) => handleClick(event, row)}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          ) : undefined}
                        </TableCell>
                        <TableCell className="text-center">{rowNum}</TableCell>
                        {/* <TableCell align="center">{appDate}</TableCell> */}
                        <TableCell align="center">
                          {row?.franchiseName}
                        </TableCell>
                        <TableCell align="center">{row?.cntOk}</TableCell>
                        {/* //! 정상매출-건수 */}
                        <TableCell align="center">
                          {formatToWon(row?.tradingAmountOk || 0)}
                          {/* //! 정상매출-거래금액 */}
                        </TableCell>
                        <TableCell align="center">
                          {formatToWon(row?.commissionAmountOk || 0)}
                          {/* //! 정상매출-수수료 */}
                        </TableCell>
                        <TableCell align="center">
                          {formatToWon(row?.settlementAmountOk || 0)}
                          {/* //! 정상매출-정산금액 */}
                        </TableCell>
                        <TableCell align="center">{row?.cntCancel}</TableCell>
                        {/* //! 차감매출-건수 */}
                        <TableCell align="center">
                          {row?.tradingAmountCancel !== null
                            ? formatToWon(row?.tradingAmountCancel || 0)
                            : "0"}
                          {/* //! 차감매출-취소금액 */}
                        </TableCell>
                        <TableCell align="center">
                          {row?.commissionAmountCancel !== null
                            ? formatToWon(row?.commissionAmountCancel || 0)
                            : "없음"}
                          {/* //! 차감매출-수수료 */}
                        </TableCell>
                        <TableCell align="center">
                          {row?.settlementAmountCancel !== null
                            ? formatToWon(row?.settlementAmountCancel || 0)
                            : "없음"}
                          {/* //! 차감매출-차감할금액 */}
                        </TableCell>
                        <TableCell align="center">
                          {row?.tradingAmountCancelFinish !== null
                            ? formatToWon(row?.tradingAmountCancelFinish || 0)
                            : "없음"}
                          {/* //! 차감매출-차감완료금액 */}
                        </TableCell>

                        <TableCell align="center">
                          {row?.amountMoneyMinus !== null
                            ? formatToWon(row?.amountMoneyMinus || 0)
                            : "없음"}
                          {/* //! 정산전 취소금액 */}
                        </TableCell>
                        <TableCell align="center">
                          <span
                            className={
                              row?.settlementResultAmount > 0
                                ? "text-primary"
                                : row?.settlementResultAmount === 0
                                  ? ""
                                  : "text-danger"
                            }
                          >
                            {row?.settlementResultAmount !== null
                              ? formatToWon(row?.settlementResultAmount || 0)
                              : "없음"}
                            {/* //! 정산금액 */}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          {row?.bankName}
                          {/* //! 은행명 */}
                        </TableCell>
                        {/* <TableCell align="center">{appDate}</TableCell> */}
                        <TableCell align="center">
                          {row?.accountNumber}
                          {/* //! 계좌번호 */}
                        </TableCell>

                        <TableCell align="right" className="text-center p-1">
                          {row?.accountHolder}
                          {/* //! 예금주 */}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <>
                    <NoData
                      comment="정산할 내역이 없습니다."
                      colNum={20}
                      isLoading={isLoading}
                    />
                  </>
                )}

                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={20} />
                  </TableRow>
                )} */}
              </TableBody>
              <TableRow className="table-dark">
                <TableCell colSpan={2}></TableCell>
                <TableCell align="center">
                  <span class="font-weight-bold">합계</span>
                </TableCell>
                <TableCell align="center">
                  <span class="font-weight-bold">
                    {formatToWon(sumData?.cntOk || 0)}
                    {/* //! 정상매출-건수 */}
                  </span>
                </TableCell>
                <TableCell align="right">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(sumData?.tradingAmountOk || 0)}
                    {/* //! 정상매출-거래금액 */}
                  </span>
                </TableCell>
                <TableCell align="right">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(sumData?.commissionAmountOk || 0)}
                    {/* //! 정상매출-수수료 */}
                  </span>
                </TableCell>
                <TableCell align="right">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(sumData?.settlementAmountOk || 0)}
                    {/* //! 정상매출-정산금액 */}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(sumData?.cntCancel || 0)}
                    {/* //! 차감매출-건수 */}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(sumData?.tradingAmountCancel || 0)}
                    {/* //! 차감매출-취소금액 */}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(sumData?.commissionAmountCancel || 0)}
                    {/* //! 차감매출-수수료 */}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(sumData?.settlementAmountCancel || 0)}
                    {/* //! 차감매출-차감할금액 */}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(sumData?.tradingAmountCancelFinish || 0)}
                  </span>
                  {/* //! 차감매출-차감완료금액 */}
                </TableCell>
                <TableCell align="center">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(sumData?.amountMoneyMinus || 0)}
                    {/* //! 정산전 취소금액 */}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="font-weight-bold font-size-sm">
                    {formatToWon(sumData?.settlementResultAmount || 0)}
                    {/* //! 정산금액 */}
                  </span>
                </TableCell>
                <TableCell colSpan={6}></TableCell>
              </TableRow>
            </table>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default SettleFranchiseExecuteTable;
