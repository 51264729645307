import FileSaver from "file-saver";
import { getAxios } from "hook/axiosInstance";
import { convertCompany } from "layouts/settlement/dashboards/SettleModalTable";

const XLSX = require("xlsx");
const fileType = "application/vnd.ms-excel;charset=utf-8";
const fileExtension = ".xlsx";

// ! 엑셀 export
const ExportToXlsx = async (fileName, apiAddress, type) => {
  // ^ 요청 먼저 보냄
  if (type) {
    const response = await getAxios(`${apiAddress}`);
    const sum = response.data.data.map((row) => {
      let arr = row;
      let un = convertCompany(row);
      arr.splice(0, 10);
      arr.unshift(un);
      const addData = arr[3] * 1 + arr[4] * 1;
      const addData2 =
        arr[6] * 1 +
        arr[7] * 1 +
        arr[8] * 1 +
        arr[9] * 1 +
        arr[10] * 1 +
        arr[11] * 1 +
        arr[12] * 1 +
        arr[13] * 1 +
        arr[14] * 1;
      arr.splice(5, 0, `${addData}`);
      arr.splice(15, 0, `${addData2}`);
      arr.splice(16, 2);
      return arr;
    });
    const today = new Date().toISOString().substring(0, 10);
    const ws = XLSX.utils.json_to_sheet(sum, {
      skipHeader: true,
    });
    const new_worksheet_data = [
      headerTopArray,
      headerArray,
      ...XLSX.utils.sheet_to_json(ws, { header: 1 }),
    ];
    const new_workbook = XLSX.utils.book_new();
    const new_worksheet = XLSX.utils.aoa_to_sheet(new_worksheet_data);
    XLSX.utils.book_append_sheet(new_workbook, new_worksheet, "Sheet1");
    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 0, c: 5 } },
      { s: { r: 0, c: 15 }, e: { r: 0, c: 20 } },
    ];
    new_worksheet["!merges"] = merge;
    const wb = { Sheets: { data: new_worksheet }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + today + fileExtension);
  } else {
    const address = addexcel(apiAddress);
    const response = await getAxios(`${address}`);
    const today = new Date().toISOString().substring(0, 10);
    const ws = XLSX.utils.json_to_sheet(response?.data?.data, {
      // skipHeader: true,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + today + fileExtension);
  }
};

export default ExportToXlsx;

const headerArray = [
  "",
  "가맹점 수수료율",
  "결제건수",
  "승인금액",
  "취소금액",
  "거래금액",
  "정산금액",
  "정산금액",
  "정산금액",
  "정산금액",
  "정산금액",
  "정산금액",
  "정산금액",
  "정산금액",
  "정산금액",
  "정산합계",
  "은행명",
  "코드",
  "계좌번호",
  "이체금액",
  "예금주",
];
const headerTopArray = [
  "업체명",
  "구분",
  "",
  "",
  "",
  "",
  "총판",
  "지사",
  "대리점1",
  "대리점2",
  "대리점3",
  "대리점4",
  "대리점5",
  "대리점6",
  "대리점7",
  "은행정보",
  "",
  "",
  "",
  "",
  "",
];

// header받아서 넣어주기
// const ws = XLSX.utils.json_to_sheet(response?.data?.data?.dataList, {
//  skipHeader: true,
// });
// const new_worksheet_data = [headerArray, ...XLSX.utils.sheet_to_json(ws, { header: 1 })];
// const new_workbook = XLSX.utils.book_new();
// const new_worksheet = XLSX.utils.aoa_to_sheet(new_worksheet_data);
// XLSX.utils.book_append_sheet(new_workbook, new_worksheet, 'Sheet1');
// const wb = { Sheets: { data: new_worksheet }, SheetNames: ["data"] };
// const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
// const data = new Blob([excelBuffer], { type: fileType });
// FileSaver.saveAs(data, fileName + today + fileExtension);

// const limitRemove=(address)=>{
//   let pageAddress=address.replace(/limit=(15|50|100)(&|$)/g, "")
//   return pageAddress.replace(/page=\d+(&|$)/g, "")
//}
const addexcel = (address) => {
  return address.replace(/(.*\/)(\w+)(\?.*)/, "$1$2/excel$3");
};
