import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Divider,
  FilledInput,
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
  Box,
} from "@material-ui/core";
import { Grid } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import clsx from "clsx";
import CommonTextFieldFullwidth from "components/commonInput/CommonTextField";
import { getAxios } from "hook/axiosInstance";

import React, { useState } from "react";
import { phonePattern } from "utils/validationCheckFunction";
import { socNoPattern } from "utils/validationCheckFunction";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "../../../../node_modules/@material-ui/core/index";

const BasicInput = ({
  classes,
  createValue,
  handleChange,
  handleModalOpen,
  error,
  helperText,
  setIsDuplicateChecked,
  selectDetail,
  highCompanyData,
  accountNumberFlag,
  setAccountNumberFlag,
}) => {
  //
  const companyTypeList = [
    {
      value: "1",
      label: "비사업자",
    },
    {
      value: "2",
      label: "개인사업자",
    },
    {
      value: "3",
      label: "법인사업자",
    },
    {
      value: "4",
      label: "기타",
    },
  ];

  const checkIdDuplicate = async (createValue) => {
    const userId = createValue?.userId;

    if (!userId) {
      alert("아이디를 입력해주세요");
      return;
    }
    const resDuplicate = await getAxios(`franchise/check/userId/${userId}`);
    if (resDuplicate.data === false) {
      setIsDuplicateChecked(true);
      alert("사용 가능한 ID입니다.");
    } else {
      setIsDuplicateChecked(false);
      alert("이미 사용중인 ID입니다.");
    }
  };

  const checkPhoneFn = async (createValue) => {
    const managerContact = createValue?.managerContact;
    if (!managerContact) {
      alert("담당자연락처를 입력해주세요");
      return;
    }
    const resDuplicate = await getAxios(
      `franchise/phone/duplication?phoneNumber=${managerContact}`
    );
    if (resDuplicate.data.data === "Y") {
      alert("중복되지 않은 연락처입니다.");
    } else {
      alert("중복된 연락처가 존재합니다.");
    }
  };

  const ClipboardButton = async (e) => {
    const textToCopy = e; // 저장할 값을 설정합니다.

    try {
      await navigator.clipboard.writeText(textToCopy);
      alert("클립보드에 값이 복사되었습니다!");
    } catch (err) {
      console.error("클립보드 복사에 실패했습니다:", err);
    }
  };
  return (
    <Card className="p-4 mb-4">
      <div className="font-size-lg font-weight-bold">기본 정보</div>
      <Divider className="my-2" />
      <div>
        <Grid container columnSpacing={2}>
          <Grid xs={12}>
            <Box>
              <FormControl
                disabled
                size="small"
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                {/* <InputLabel htmlFor="company-code-search-input">업체코드 검색버튼 클릭</InputLabel> */}
                <FilledInput
                  size="small"
                  id="companyPath"
                  name="companyPath"
                  value={
                    selectDetail
                      ? highCompanyData?.highCompanyPath
                      : createValue?.highCompanyPath
                  }
                  labelwidth={60}
                  startAdornment={
                    <InputAdornment position="start">
                      상위업체 Path &nbsp;
                      <FontAwesomeIcon
                        icon="fa-solid fa-asterisk"
                        size="xs"
                        color="red"
                      />
                    </InputAdornment>
                  }
                  inputProps={{ "aria-label": "weight" }}
                  error={error?.highCompanyPath}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid xs={5.5}>
            <FormControl
              className={classes.margin}
              variant="filled"
              size="small"
              fullWidth
            >
              <FilledInput
                size="small"
                disabled
                id="compayCode"
                name="compayCode"
                value={
                  selectDetail
                    ? highCompanyData?.highCompanyCode
                    : createValue?.highCompanyCode
                }
                onChange={handleChange}
                aria-describedby="filled-weight-helper-text"
                fullWidth
                startAdornment={
                  <InputAdornment position="start">
                    업체코드 &nbsp;
                    <FontAwesomeIcon
                      icon="fa-solid fa-asterisk"
                      size="xs"
                      color="red"
                    />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid xs={0.5}></Grid>
          <Grid xs={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FormControl
                disabled
                className={clsx(classes.margin, classes.textField)}
                variant="filled"
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-amount">
                  {createValue?.highCompanyName ? null : "업체명"}
                </InputLabel>
                <FilledInput
                  size="small"
                  id="darijom"
                  value={
                    selectDetail
                      ? highCompanyData?.highCompanyName
                      : createValue?.highCompanyName
                  }
                  aria-describedby="filled-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>

              <Button
                variant="contained"
                color="primary"
                className="m-2"
                onClick={handleModalOpen}
                style={{ minWidth: "auto", whiteSpace: "nowrap" }}
              >
                업체코드검색
              </Button>
            </div>
          </Grid>

          <Grid xs={7.5}>
            <FormControl
              size="small"
              className={classes.margin}
              variant="outlined"
              fullWidth
            >
              <TextField
                disabled={selectDetail ? true : false}
                id="userId"
                name="userId"
                variant="outlined"
                size="small"
                label={
                  <span position="start">
                    아이디&nbsp;
                    <FontAwesomeIcon
                      icon="fa-solid fa-asterisk"
                      size="xs"
                      color="red"
                    />
                  </span>
                }
                type={"text"}
                placeholder="2~12자 이내 영문과 숫자만 가능"
                value={createValue?.userId}
                onChange={handleChange}
                error={error?.userId}
                helperText={
                  helperText?.userId ||
                  "※ 비밀번호는 자동으로 생성(1234)됩니다."
                }
              />
            </FormControl>
          </Grid>
          <Grid xs={0.5}> </Grid>
          <Grid xs={4}>
            {selectDetail ? null : (
              <Button
                variant="contained"
                color="primary"
                className="m-2"
                onClick={() => checkIdDuplicate(createValue)}
                style={{ minWidth: "auto", whiteSpace: "nowrap" }}
              >
                ID 중복체크
              </Button>
            )}
          </Grid>
          {selectDetail ? (
            <Grid xs={12}>
              <CommonTextFieldFullwidth
                handleChange={handleChange}
                createValue={createValue}
                helperText={helperText}
                error={error}
                field="userPw"
                meaning="비밀번호"
                required={true}
              // type="password"
              />
            </Grid>
          ) : null}
          <Grid xs={5.5}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field="agentName"
              meaning="가맹점명"
              required={true}
            />
          </Grid>
          <Grid xs={0.5}></Grid>
          <Grid xs={6}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field="franchiseNickname"
              meaning="가맹점별칭"
              required={false}
            />
          </Grid>

          <Grid xs={5.5}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field="ceoName"
              meaning="대표자명"
              required={true}
            />
          </Grid>
          <Grid xs={0.5}></Grid>
          <Grid xs={6}>
            <TextField
              size="small"
              className="m-2"
              id="ceoId"
              name="ceoId"
              variant="outlined"
              type={"text"}
              maxLength="14"
              label="대표자주민번호"
              placeholder="000000-0000000"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    대표자주민번호&nbsp;
                    {createValue?.companyType === "1" ? (
                      <FontAwesomeIcon
                        icon="fa-solid fa-asterisk"
                        size="xs"
                        color="red"
                      />
                    ) : undefined}
                  </InputAdornment>
                ),
              }}
              value={
                createValue?.ceoId?.length === 13
                  ? socNoPattern(createValue?.ceoId)
                  : createValue?.ceoId
              }
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          {/* <Grid xs={0.5}></Grid> */}

          <Grid xs={5.5}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field="managerName"
              meaning="담당자명"
              required={true}
            />
          </Grid>
          <Grid xs={0.5}></Grid>
          <Grid xs={11}>
            <Box display="flex">
              <TextField
                style={{ width: "50%" }}
                size="small"
                className="m-2"
                id="managerContact"
                name="managerContact"
                variant="outlined"
                onChange={handleChange}
                label={
                  <span position="start">
                    담당자연락처 &nbsp;
                    <FontAwesomeIcon
                      icon="fa-solid fa-asterisk"
                      size="xs"
                      color="red"
                    />
                  </span>
                }
                placeholder="010-xxxx-xxxx"
                value={
                  createValue?.managerContact?.length === 11
                    ? phonePattern(createValue?.managerContact)
                    : createValue?.managerContact
                }
                error={error?.managerContact}
                helperText={helperText?.managerContact}
                fullWidth
              />
              <IconButton onClick={e => ClipboardButton(createValue?.managerContact)}>
                <ContentCopyIcon />
              </IconButton>
              <Button
                variant="contained"
                color="primary"
                className="m-2"
                onClick={() => checkPhoneFn(createValue)}
                style={{ minWidth: "auto", whiteSpace: "nowrap" }}
              >
                중복체크
              </Button>
            </Box>
            {/* <Box>
              <FormGroup
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0 0 0 10px",
                  margin: 0,
                  textAlign: "left",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel
                  style={{ margin: "0 0 0 -11px" }}
                  control={
                    <Checkbox
                      onChange={() => setAccountNumberFlag(!accountNumberFlag)}
                      checked={accountNumberFlag}
                      style={{ color: "#3f51b5" }}
                    />
                  }
                  label={
                    <p
                      style={{
                        padding: 0,
                        fontSize: "14px",
                        margin: "0",
                        textAlign: "left",
                      }}
                    >
                      담당자 연락처와 계좌번호가 같을 경우 체크해주세요
                    </p>
                  }
                />
              </FormGroup>
            </Box> */}
          </Grid>
          <Grid xs={5.5}>
            <TextField
              size="small"
              sx={{ width: "1200px" }}
              className="m-2"
              id="companyType"
              name="companyType"
              variant="outlined"
              select
              fullWidth
              // label="사업자구분"
              value={createValue?.companyType * 1}
              onChange={handleChange}
              helperText={helperText?.companyType}
              error={error?.companyType}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    사업자구분&nbsp;
                    <FontAwesomeIcon
                      icon="fa-solid fa-asterisk"
                      size="xs"
                      color="red"
                    />
                  </InputAdornment>
                ),
              }}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
            >
              {companyTypeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid xs={0.5}></Grid>
          <Grid xs={6}>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field="managerEmail"
              meaning="담당자이메일"
              type="email"
            />
          </Grid>
          <Grid xs={12} >
            <Box display='flex' alignItems='center'>
              <Typography style={{ padding: '0 16px 7px 10px' }}>규모구분</Typography>
              <RadioGroup row name="scale" value={createValue.scale ? createValue.scale : '일반기업'} onChange={handleChange}>
                <FormControlLabel
                  control={
                    <Radio id="scale" className="align-self-start" />
                  }
                  label="영세"
                  value="영세"
                />
                <FormControlLabel
                  control={
                    <Radio id="scale" className="align-self-start" />
                  }
                  label="중소1"
                  value="중소1"
                />
                <FormControlLabel
                  control={
                    <Radio id="scale" className="align-self-start" />
                  }
                  label="중소2"
                  value="중소2"
                />
                <FormControlLabel
                  control={
                    <Radio id="scale" className="align-self-start" />
                  }
                  label="중소3"
                  value="중소3"
                />
                <FormControlLabel
                  control={
                    <Radio id="scale" className="align-self-start" />
                  }
                  label="일반기업"
                  value="일반기업"
                />
              </RadioGroup>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default BasicInput;
